import React from "react";
import styled from "styled-components";
import Footer from "../../feature/Footer/Footer";

const AuthTemplateForm = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    &.sign_up {
        height: 100%;
    }
`;

/** 로그인, 비밀번호 찾기, 아이디 찾기, 회원가입 등 인증과 관련된 페이지 템플레이트 */
const AuthTemplate = ({children, type}) => {
    return (
        <>
        <AuthTemplateForm className={type}>
            {children}
        </AuthTemplateForm>
        <Footer/>
        </>
    );
}

export default AuthTemplate;