import React from "react";
import styled from "styled-components";
import { addComma } from "../../apis/CommonFunc";

const StyledPriceDiv = styled.div`
    text-align: left;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    p {
        font-size: 17px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: -0.02em;
        text-align: left;
        font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
    }
`;

const StyledProfitDiv = styled.div`
    text-align: right;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    p {
        font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: -0.04em;
        text-align: left;
        margin: 4px 0px;
    }

`;

const Banner = ({content}, key) => {
    const style = {
        color: "909090"
    }
    const upStyle = {
        color: "#FF645A"
    }
    const downStyle = {
        color: "3D85F0"
    }
    const upAndDown = +(content.yield) > 0 ? "+" : +(content.yield) < 0 ? "-" : "";
    const priceUpAndDown = +(content.difference) > 0 ? "+" : +(content.difference) < 0 ? "-" : "";

    return (
        <div key={key} className="trading-content">
            <p className="trading-content-address">{localStorage.getItem("locale") === 'ko' ? content.address : content.addressJibunEn}</p>
            <div className="trading-content-information">
                <StyledPriceDiv>
                    <p>{addComma(content.priceMetarexAsk)} KRW</p>
                    <p>{addComma(content.paymentCoinAmount)} ATC</p>
                </StyledPriceDiv>
                <StyledProfitDiv>
                    <p style={+(content.difference) > 0 ? upStyle : +(content.difference) < 0 ? downStyle : style}>
                        {priceUpAndDown} {addComma(content.difference)} KRW
                    </p>
                    <p style={+(content.yield) > 0 ? upStyle : +(content.yield) < 0 ? downStyle : style}>
                        {upAndDown} {isNaN(+(content.yield)) ? 0 : +(content.yield).toFixed(2)}%
                    </p>
                </StyledProfitDiv>
            </div>
        </div>
    )
}

export default Banner;