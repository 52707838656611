import React, { useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import styled from "styled-components";
import ReactLoading from 'react-loading';

import { axiosGet } from "../apis/AxiosAPI";
import { moveTopOnPageAuto } from "../apis/CommonFunc";
import { isNull, mobile } from "../apis/Validation";

import PageBanner from "../components/PageBanner";
import PageTab from "../components/PageTab";
import BoardButton from "../components/Board/BoardButton";
import BoardContent from "../components/Board/BoardContent";
import SupportTemplate from "../components/SupportTemplate";
import MoreButton from "../components/MoreButton";
import TopButton from "../components/TopButton";
import Footer from "../feature/Footer/Footer";

const VideosTemplate = styled.div`
    width: 100%;
    max-width: 1200px;
    min-height: 90vh;
    max-height: auto;
    padding-top: 100px;
`;

const VideoEmpty = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    >p{
        font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
        font-size: 20px;
        font-weight: 800;
    }
`;

const VideosGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 350px);
    grid-template-rows: repeat(auto, 224px);
    grid-gap: 20px;
    @media only screen and (max-width: 768px) {
        padding: 0px 20px;
        max-width: 500px;
        display: flex;
        flex-direction: column;
    }
`;

const VideosDiv = styled.div`
    width: 100%;
    flex-direction: column;
    >img{
        width: 100%;
        cursor: pointer;
        height: calc(100%-21px);
        margin-bottom: 20px;
        position: relative;
        &:hover{
            transition: .3s;
            filter: brightness(30%);
            // 여기는 나중에
        }
    }
    >p{
        font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
        font-size: 16px;
        font-weight: 800;
        line-height: 16px;
        letter-spacing: -0.02em;
        margin-bottom: 40px;
    }
`;

const StyledVideoDetail = styled.div`
    padding: 50px 0px;
    margin-bottom: 60px;
    border-bottom: 1px solid #D9DDE2;
    width: 100%;
    display: flex;
    justify-content: center;
    >div {
        flex-direction: column;
        width: 100%;
    }
    >p {
        width: 100%;
    }
    @media only screen and (max-width: 768px) {
        padding: 40px 20px 50px 20px;
        font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: -0.02em;
        text-align: left;
        margin-bottom: 20px;
        div {
            margin-bottom: 50px;
        }
    }
`;

const LoadingTemplate = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;


const Videos = ({useParam, useMatch}) => {
    const [openPage, setOpenPage] = useState(false);
    const [videoList, setVideoList] = useState([]);
    const [videoListForPage, setVideoListForPage] = useState([]);
    const [currentIndex, setCurrentIndex] = useState({});
    const [disabled, setDisabled] = useState(false);
    const [currnetEl, setCurrentEl] = useState(undefined);

    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();
    const {title} = useParam();
    const match = useMatch('/videos')


    // 최초 접속
    useLayoutEffect(()=>{
        moveTopOnPageAuto();
        requestVideoList();
    },[]);

    const requestVideoList = () => {
        axiosGet('video/select')
            .then(response => {
                if(JSON.parse(response.data.result)) {
                    setVideoList(response.data.data);
                    let initEnd = 6;
                    if(response.data.data.length < initEnd) {
                        initEnd = response.data.data.length;
                    }
                    setCurrentIndex({start: 0, end: initEnd});
                }
            })
            .catch(e => {
                console.log(`get video List API, error message: ${e}`);
            });
    }

    // 주소를 통한 접근
    useLayoutEffect(()=>{
        moveTopOnPageAuto();

        

        // 비디오 컨텐츠 접근
        if(!isNull(title)){
            setOpenPage(true);
            if(videoList.length !== 0){
                const searchEl = videoList.filter(el => el.subjectKr === (title).replaceAll("-", " "));
                console.log(!isNull(searchEl[0]));
                if(!isNull(searchEl[0])) {
                    setCurrentEl(searchEl[0]);
                    return;
                }
                setOpenPage(false);
                navigate('/videos');
                return;
            }
        }

        // 뒤로가기로 접근
        if( match ){
            setOpenPage(false);
            setDisabled(false);
            let initEnd = 6;
            if(videoList.length < initEnd) {
                initEnd = videoList.length;
            }

            const addVideos = videoList.slice(0, initEnd);
            setCurrentIndex({start: 0, end: initEnd});
            setVideoListForPage(addVideos);

            return;
        }
    },[videoList]);

    // 컨텐츠 추가 감지
    useLayoutEffect(()=>{
        const start = Number(currentIndex.start);
        const end = Number(currentIndex.end);
        const addVideos = videoList.slice(start, end);
        if(start === 0 && end <= 6) {
            setVideoListForPage(addVideos);
        } else {
            setVideoListForPage(videoListForPage.concat(addVideos));
        }
        setIsLoading(false);
    },[currentIndex]);

    const addVideoList = () => {
        setIsLoading(true);
        setTimeout(()=>{
            const start = currentIndex.end;
            const end = start + 3;
            setCurrentIndex({start, end});

            if(videoList.length <= end) {
                setDisabled(true);
            }
        }, 800);
    }

    const onClickVideo = (el) => {
        setCurrentEl(el);
        setOpenPage(true);
        navigate(`/videos/${(el.subjectKr).replaceAll(" ", "-")}`);
        moveTopOnPageAuto();
    }

    // 모바일 스크롤 이벤트
    const listener = () => {
        if(document.body.scrollHeight <= window.innerHeight + window.pageYOffset){
            if(videoList.length <= currentIndex.end || isLoading){
                return;
            }
            addVideoList();
        }
    }

    useLayoutEffect(() => {
        if(mobile() && match ){
            window.addEventListener('scroll', listener);
            return () => { window.removeEventListener('scroll', listener)}
        }
    }, [window.pageYOffset, currentIndex])

    return (
        <>
        <SupportTemplate>
            <PageBanner style={{backgroundImage: "url('/images/bg/support_banner.jpg')"}}>{t('header_menu_reference_room')}</PageBanner>
            <PageTab category="support" type="videos"/>
            <div>
                { openPage
                    ?
                    currnetEl &&
                    <BoardContent style={{paddingTop: '100px'}}>
                        <div className="subject">
                            <h2>{localStorage.getItem('locale') === 'ko' ? currnetEl.subjectKr : localStorage.getItem('locale') == 'en' ? currnetEl.subjectEn : currnetEl.subjectCn}</h2>
                            <span>{currnetEl.insertedTs.split(" ")[0]}</span>
                        </div>
                        <StyledVideoDetail>
                            <iframe
                                width="1280"
                                height="720"
                                src={currnetEl.uri}
                                title={localStorage.getItem('locale') === 'ko' ? currnetEl.subjectKr : localStorage.getItem('locale') == 'en' ? currnetEl.subjectEn : currnetEl.subjectCn}
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen>
                            </iframe>
                        </StyledVideoDetail>
                        <div>
                            <BoardButton
                                setOpenPage={setOpenPage}
                                setDisabled={setDisabled}
                                setCurrentIndex={setCurrentIndex}
                                url="/videos"
                            />
                        </div>
                    </BoardContent>
                    :
                    <VideosTemplate>
                        {videoListForPage.length === 0
                            ? <VideoEmpty><p>{t('reference_room_no_contents')}</p></VideoEmpty>
                            : <VideosGrid>
                                {videoListForPage.map((el, index) =>
                                        <VideosDiv key={index}>
                                            <img
                                                src={`https://i.ytimg.com/vi/${el.uri.split("/")[el.uri.split("/").length-1]}/mqdefault.jpg`}
                                                alt={el.subjectKr}
                                                onClick={() => onClickVideo(el)}
                                            />
                                            <p>{localStorage.getItem('locale') === 'ko' ? el.subjectKr : localStorage.getItem('locale') == 'en' ? el.subjectEn : el.subjectCn}</p>
                                        </VideosDiv>
                                    )
                                }
                            </VideosGrid>
                        }
                        {
                            ((6 < videoList.length) && !openPage) &&
                            <>
                                {isLoading ? <LoadingTemplate>
                                                <ReactLoading type={'spin'} color={'skyblue'} height={'40px'} width={'40px'} />
                                            </LoadingTemplate>
                                        : <MoreButton disabled={disabled} onClick={addVideoList}/>}
                                <TopButton/>
                            </>
                        }
                    </VideosTemplate>
                }
            </div>
        </SupportTemplate>
        {(!mobile() || videoListForPage.length <= 6) && <Footer/>}
        </>
    );
}

export default Videos;
