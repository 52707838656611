import { useState, useEffect } from "react";
import debounce from './debounce';

export function useScroll() {
    // 스크롤 y값을 담아둠
    const [scrollY, setScrollY] = useState(0);

    const listener = () => {
        setScrollY(window.pageYOffset);
    };

    useEffect(() => {
        // debounce는 스크롤 최적화를 위해 만든 함수.
        window.addEventListener("scroll", debounce(listener, 17));
        return () => {
        window.removeEventListener("scroll", listener);
        }
    },[debounce]);

    return {
        scrollY
    };
}