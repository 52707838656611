import React, { useEffect } from "react";
import styled from "styled-components";
import { moveTopOnPageAuto } from "../../apis/CommonFunc";

const MainDiv = styled.div`
    .terms-body {
        padding: 100px 40px;
    }

    .terms-body h1 {
        margin: 10px 0px 30px 0px;
    }

    .terms-body h3 {
        margin: 50px 0px 30px 0px;
    }

    .terms-body p {
        margin: 10px 0px 30px 25px;
    }

    .terms-body ol,
    .terms-body ul {
        margin-left: 40px;
    }

    .terms-body ol li,
    .terms-body ul li {
        list-style: auto;
        list-style-type: auto;
        margin-bottom: 12px;
        line-height: 25px;
        font-size: 14px;
        color: #444;
    }

    .terms-body ul > li > ol,
    .terms-body ul > li > ul,
    .terms-body ol > li > ol,
    .terms-body ol > li > ul {
        margin-top: 12px;
    }

    .terms-body ul > li > ol > li,
    .terms-body ul > li > ul > li,
    .terms-body ol > li > ol > li,
    .terms-body ol > li > ul > li {
        font-size: 12px;
    }

    .terms-body ol > li > ol > li {
        list-style-type: upper-alpha;
    }
    .terms-body ol > li > ul > li {
        list-style-type: circle;
    }

    @media only screen and (max-width: 768px) {
    }

`;


const TermsOfService = () => {
    useEffect(() => {
        moveTopOnPageAuto();
    }, []);

    return (
        <MainDiv>
            <section className="terms-body">
                <h1>메타렉스 이용약관</h1>

                <h3>제1조 (목적)</h3>
                <p>
                    이 약관은 주식회사 티에스네트(이하: 회사)가 제공하는 메타렉스 및 메타렉스 관련 제반 서비스(이하: 서비스)의 이용조건 및 절차에 관한 회사와 회원 간의 권리 의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.
                </p>
                <h3>제2조 (약관의 명시, 설명과 개정)</h3>
                <ol>
                    <li>이 약관의 내용은 회사의 서비스 회원가입 관련 사이트에 게시하거나 기타의 방법으로 이용자에게 공지하고, 이용자가 회원으로 가입하면서 이 약관에 동의함으로써 효력을 발생합니다.</li>
                    <li>회사는 "약관의 규제에 관한 법률", "정보통신망 이용촉진 및 정보보호 등에 관한 법률", "특정 금융거래정보의 보고 및 이용 등에 관한 법률" 등 관련법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.</li>
                    <li>회사가 약관을 개정할 경우에는 적용일자 및 개정 사유를 명시하여 현행 약관과 함께 회사 사이트의 초기화면이나 팝업화면 또는 공지사항으로 그 적용일자 7일 이전부터 공지합니다. 다만, 회원에게 불리하거나 중대한 내용의 변경의 경우에는 적용일자 30일 이전에 회원에게 공지 및 개별 통지합니다</li>
                    <li>회사가 전항에 따라 개정약관을 공지하면서 회원에게 7일간의 기간 내에 의사 표시를 하지 않으면 의사표시가 표명된 것으로 본다는 뜻을 명확하게 공지하였음에도 회원이 명시적으로 거부의 의사표시를 하지 아니한 경우 회원이 개정약관에 동의한 것으로 봅니다.</li>
                    <li>회원이 개정약관의 적용에 동의하지 않는다는 명시적 의사를 표명한 경우 회사는 개정 약관의 내용을 적용할 수 없으며, 이 경우 회원은 이용계약을 해지할 수 있습니다. 다만, 기존 약관을 적용할 수 없는 특별한 사정이 있는 경우에는 회사는 이용계약을 해지할 수 있습니다.</li>
                </ol>
                <h3>제3조 (약관 외 준칙)</h3>
                <p>서비스 이용과 관련하여는 이 약관을 우선 적용하며, 이 약관에서 정하지 않은 사항은 관계 법령 또는 상관례 따릅니다.</p>
                <h3>제4조 (용어의 정의)</h3>
                <p>이 약관에서 사용하는 용어의 정의는 다음과 같습니다.</p>
                <ol>
                    <li>회원: 이 약관을 승인하고 회원가입을 하여 회사와 서비스 이용계약을 체결한 자를 말합니다.</li>
                    <li>아이디(ID): 회원의 식별과 서비스 이용을 위하여 회원이 등록하는 전자우편 주소 또는 휴대전화 번호를 말합니다.</li>
                    <li>비밀번호: 회원의 동일성 확인과 회원정보의 보호를 위하여 회원이 회사가 승인한 문자와 숫자의 조합대로 설정한 것을 말합니다.</li>
                    <li>가상자산: 메타렉스에서 전자적으로 존재하는 정보로 서비스의 목적을 말합니다.</li>
                    <li>결제통화(A): 아스터코인 등 가상자산 거래 등에 사용되는 포인트를 말합니다. 회사가 정한 방법에 따라 생성되고 현금으로도 출금이 가능합니다. 다만, 별도의 이자가 발생하지는 않습니다.</li>
                    <li>컨텐츠 서비스: 상품권이나 선불카드, 상품권몰, 게시판 등의 유∙무료 서비스를 말합니다.</li>
                </ol>
                <h3>제5조 (이용계약의 성립)</h3>
                <ol>
                    <li>이용자는 회사가 정한 가입 양식에 따라 회원정보를 기입한 후 이 약관에 동의한다는 의사표시를 함으로서 회원가입을 신청합니다.</li>
                    <li>
                        회원가입은 회사의 승낙이 회원에게 도달한 시점으로 합니다. 회사는 다음 각 호의 경우 승낙을 거절하거나 유보할 수 있습니다.
                        <ol>
                            <li>실명이 아니거나 타인의 명의를 사용하여 신청한 경우</li>
                            <li>허위 정보를 기재하여 신청한 경우</li>
                            <li>고객 확인을 위한 서류 제출 등 관계 법령에서 정한 요건을 이행하지 않은 경우회사가 정한 이용신청요건에 미비한 부분이 있었을 때</li>
                            <li>설비에 여유가 없거나 기술상 장애가 있는 등 회사의 사정상 승낙이 곤란한 경우</li>
                            <li>사회의 안녕질서 또는 미풍양속을 저해할 목적으로 신청한 경우</li>
                        </ol>
                    </li>
                    <li>이용 계약은 회원ID 단위로 체결합니다. 이용계약이 성립되면, 이용신청자는 회원으로 등록됩니다.</li>
                    <li>실명이 아니거나 타인의 이름, 전화번호 등의 개인정보를 도용하여 허위 가입한 회원은 법적인 보호를 받을 수 없으며, 이에 따른 민사, 형사상의 모든 책임은 가입한 회원이 져야 합니다.</li>
                    <li>만 19세 미만은 회사에서 제공하는 가상자산 거래 관련 서비스 이용을 제한합니다.</li>
                    <li>
                        이용자가 국제자금세탁방지기구(FATF, Financial Action Task Force)에서 발표한 국제기준 미이행∙비협조 국가 또는 지역의 국민, 시민권자, 영주권자, 거주자 등인 경우 서비스 이용이 제한될 수 있으며, 회원은 다음 각 호를 보증합니다.
                        <ol>
                            <li>국제기준 미이행∙비협조 국가의 국민, 시민권자, 영주권자, 거주자 등이 아닐 것</li>
                            <li>국제기준 미이행∙비협조 국가 체류 중에 서비스를 이용하지 않을 것</li>
                        </ol>
                    </li>
                    <li>제1.항에 따른 신청에 있어 회사는 관계 법령에 의하여 전문기관을 통한 고객확인 및 본인인증을 요청할 수 있습니다. 만일, 이러한 회사의 제공 요청을 거부하여 이용자의 신원이 확인되지 않아 발생하게 되는 불이익에 대하여 회사는 책임을 지지 않습니다.</li>
                    <li>회원 간의 가상자산 거래시 양측이 중요한 거래라고 판단할 시 메타렉스가 제공하는 ‘사이버자산 매매계약서’를 작성할 수 있으며, 이는 양측간의 사적 계약으로 갈음합니다.</li>
                </ol>
                <h3>제6조 (회원정보 사용에 대한 동의)</h3>
                <ol>
                    <li>회사는 회원의 개인정보를 서비스 제공 등 이용계약의 이행을 위한 목적으로 이용합니다.</li>
                    <li>회원이 회사 및 회사와 제휴한 서비스들을 편리하게 이용할 수 있도록 하기 위해 회원 정보는 회사와 제휴한 업체에 제공될 수 있습니다. 단, 회사는 회원 정보 제공 이전에 제휴 업체, 제공 목적, 제공할 회원 정보의 내용 등을 사전에 공지하고 회원의 동의를 얻어야 합니다. 회원정보 사용에 대한 자세한 사항은 개인정보 처리방침에서 확인하실 수 있습니다.</li>
                </ol>
                <h3>제7조 (회원정보의 변경)</h3>
                <ol>
                    <li>회원은 개인정보 수정 화면을 통하여 언제든지 본인의 개인정보를 열람하고 수정할 수 있습니다. 다만, 서비스 관리를 위해 필요한 실명, 생년월일, 성별, 아이디 등은 수정이 불가능합니다.</li>
                    <li>회원은 회원가입 신청 시 기재한 사항이 변경되었을 경우 온라인으로 수정을 하거나 전자우편 기타 방법으로 회사에 대하여 그 변경사항을 알려야 합니다.</li>
                    <li>제1.항의 변경사항을 회사에 알리지 않아 발생한 불이익에 대하여 회사는 책임지지 않습니다.</li>
                </ol>
                <h3>제8조 (회원 아이디와 비밀번호 관리에 대한 회원의 의무)</h3>
                <ol>
                    <li>아이디와 비밀번호에 관한 모든 관리책임은 회원에게 있습니다. 회원에게 부여된 아이디와 비밀번호의 관리소홀, 부정사용에 의하여 발생하는 모든 결과에 대한 책임은 회원에게 있으며, 회사는 이에 대한 책임을 지지 않습니다. 다만, 회사의 고의 또는 과실로 인한 경우에는 그러하지 아니합니다.</li>
                    <li>회원은 자신의 아이디가 부정하게 사용된 사실을 알게 될 경우 반드시 회사에 그 사실을 통지하고 회사의 안내에 따라야 합니다.</li>
                    <li>제2.항의 경우에 해당 회원이 회사에 그 사실을 통지하지 않거나, 통지한 경우에도 회사의 안내에 따르지 않아 발생한 불이익에 대하여 회사는 책임지지 않습니다.</li>
                </ol>
                <h3>제9조 (정보의 제공)</h3>
                <p>회사는 전화, 전자우편, 문자서비스(LMS/SMS), SNS 등의 방법으로 다음과 같은 서비스에 대해서 이용편의를 위한 이용안내 등 및 상품에 관한 정보를 제공할 수 있습니다. 이 경우 회원은 언제든지 수신을 거부할 수 있습니다.</p>
                <ol>
                    <li>가상자산 거래 관련 서비스</li>
                    <li>이벤트, 마케팅 관련 등의 서비스(단, 해당 정보의 수신을 동의한 회원에 한함)</li>
                    <li>기타 회사가 수시로 결정하여 회원에게 제공하는 서비스</li>
                </ol>
                <h3>제10조 (서비스의 종류)</h3>
                <ol>
                    <li>회사가 제공하는 서비스의 종류에는 가상자산의 거래중개(거래API제공, 시세정보 검색 등을 포함)‧입금‧출금‧보관 및 컨텐츠(상품권몰, 선불카드) 서비스 등이 있습니다.</li>
                    <li>회사가 제공하는 서비스의 종류는 회사의 사정에 의하여 수시로 변경될 수 있으며, 제공되는 서비스에 대한 저작권 및 지식재산권은 회사에 귀속됩니다.</li>
                    <li>회사는 서비스와 관련하여 회원에게 회사가 정한 이용조건에 따라 계정, 아이디, 서비스, 포인트 등을 이용할 수 있는 이용권한만을 부여하며, 회원은 이를 활용한 유사 서비스 제공 및 상업적 활동을 할 수 없습니다.</li>
                </ol>
                <h3>제11조 (서비스의 내용)</h3>
                <ol>
                    <li>회사는 서비스의 종류에 따라 각 서비스의 특성, 절차 및 방법에 대한 사항을 서비스 화면을 통하여 공지하며, 회원은 회사가 공지한 각 서비스에 관한 사항을 이해하고 서비스를 이용해야 합니다.</li>
                    <li>회사는 상당한 이유가 있는 경우에 운영상, 기술상의 필요에 따라 제공하고 있는 개별 서비스의 전부 또는 일부를 변경하거나 종료할 수 있습니다. 이 경우 회사는 최소 7일전에 해당 내용을 이용자에게 알립니다.</li>
                </ol>
                <h3>제12조 (서비스의 중지 등)</h3>
                <ol>
                    <li>서비스의 이용은 회사의 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴 1일 24시간을 원칙으로 합니다. 다만 정기 점검 등의 필요로 회사가 사전에 고지하여 정한 날이나 시간은 그러하지 않습니다.</li>
                    <li>회사는 서비스를 일정범위로 분할하여 각 범위 별로 이용가능 시간을 별도로 정할 수 있습니다. 이 경우 그 내용을 사전에 공지합니다.</li>
                    <li>
                        회사는 다음 각 호에 해당하는 경우 서비스 제공을 중지할 수 있습니다. 이 경우 회사는 불가피한 경우가 아닌 한 서비스 제공 중지를 사전 고지합니다.
                        <ol>
                            <li>서비스용 설비의 보수 등 공사로 인한 부득이한 경우</li>
                            <li>전기통신사업법에 규정된 기간통신사업자가 전기통신 서비스를 중지했을 경우</li>
                            <li>회사가 직접 제공하는 서비스가 아닌 제휴업체 등의 제3자를 이용하여 제공하는 서비스의 경우 제휴 업체 등의 제3자가 서비스를 중지했을 경우</li>
                            <li>기타 불가항력적 사유가 있는 경우</li>
                        </ol>
                    </li>
                    <li>회사는 국가비상사태, 정전, 서비스 설비의 장애 또는 서비스 이용의 폭주 등으로 정상적인 서비스 이용에 지장이 있는 때에는 서비스의 전부 또는 일부를 제한하거나 정지할 수 있습니다.</li>
                </ol>
                <h3>제13조 (서비스 이용 제한 및 해지)</h3>
                <ol>
                    <li>회사는 다음 각 호의 경우 로그인을 차단하거나 거래∙입금∙출금을 중단 또는 지연하는 방법 등으로 회원의 서비스 이용을 제한할 수 있습니다.
                        <ul>
                            <li>타인의 ID 또는 비밀번호를 도용하거나 개인정보를 사용한 경우</li>
                            <li>서비스 운영을 고의로 방해한 경우</li>
                            <li>가입한 이름이 실명이 아닌 경우</li>
                            <li>형법 등 관계 법령을 위반하거나 사회적 공익을 저해할 목적으로 서비스를 이용하는 경우</li>
                            <li>타인의 개인정보를 무단으로 수집∙저장∙공개 하거나 기타 타인의 정당한 권리를 침해하는 경우</li>
                            <li>서비스의 안정적 운영을 방해할 목적으로 다량의 정보를 전송하거나 광고성 정보를 전송하는 경우</li>
                            <li>정보통신설비의 오작동이나 정보 등의 파괴를 유발시키는 컴퓨터 바이러스 프로그램 등을 유포하는 경우</li>
                            <li>회사의 서비스를 이용하여 얻은 정보를 회사의 사전 승낙없이 복제 또는 유통하거나 상업적으로 이용하는 경우</li>
                            <li>같은 사용자가 회사의 정책에 반하여 복수의 ID를 등록한 경우</li>
                            <li>수수료 등의 이익을 얻을 목적으로 타인을 대행하여 가상자산을 거래하는 경우</li>
                            <li>회원이 미성년자인 경우</li>
                            <li>보안비밀번호를 5회 연속하여 잘못 입력한 경우</li>
                            <li>해킹, 계정탈취, 명의도용, 사기, 보이스피싱 등의 범죄, 자금세탁, 기타 이와 유사한 불법행위 등과 관련 있거나 관련성이 있다고 합리적으로 의심되는 경우</li>
                            <li>통정매매, 가장매매, 시세조종, 부정거래행위, 시장질서 교란행위, 기타 회사와의 합의를 위반하는 행위 등과 관련 있거나 관련성이 있다고 합리적으로 의심되는 경우</li>
                            <li>전호의 사유로 가상자산 또는 원화를 취득한 경우</li>
                            <li>민사소송∙집행법이나 형사소송법 등의 관련 법령에 따라 서비스 제한을 요청 받는 경우</li>
                            <li>국가기관 또는 금융기관으로부터 회원의 계정이 불법행위에 이용된 것으로 의심된다는 정보의 제공이 있는 경우</li>
                            <li>입∙출금 정보의 오기, 금융사고 등으로 인해 타인의 가상자산 또는 원화를 권한 없이 취득하거나 전송 받는 경우</li>
                        </ul>
                    </li>
                    <li>회사는 회원의 서비스 이용을 제한하고자 하는 경우에는 그 사유, 일시 및 기간을 정하여 이 약관 제9조에서 정한 방법을 이용하여 해당 회원에게 통지합니다. 다만, 긴급하게 서비스 이용을 제한하여야 할 필요가 있다고 인정되는 경우, 회사는 사전 통지없이 서비스 이용을 제한할 수 있습니다.</li>
                    <li>회원은 회사의 서비스 이용 제한에 대해 이의를 제기할 수 있습니다. 회사는 회원의 서비스 이용 제한 사유가 해소되는 경우 지체없이 서비스 이용 제한을 해지합니다.</li>
                    <li>회사는 회원의 서비스 이용 제한 사유가 발생할 경우 이를 해결하기 위해 노력합니다. 다만, 서비스 이용 제한 사유가 해소되지 않는 경우 회사는 상당한 기간을 정하여 회원에게 최고 후 이용계약을 해지할 수 있습니다. 회원은 회사의 이용계약 해지에 대해 이의를 제기 할 수 있습니다.</li>
                    <li>회원이 이용계약을 해지하고자 할 때에는 자신의 모든 자산(가상자산과 원화)을 출금한 후 회사에 해지 신청을 하여야 합니다.</li>
                </ol>
                <h3>제14조 (환불 규정)</h3>
                <ol>
                    <li>환불 가능 기간(구매일로부터 14일 이내) 환불 시 결제 시점 아스터 코인 수량 100% 전량 환불이 가능합니다.</li>
                    <li>이후 환불 여부는 법률검토를 거쳐서 회사 내부에서 결정됩니다.</li>
                    <li>환불은 아스터코인으로만 환불이 됩니다.</li>
                </ol>
                <h3>제15조 (투자유의종목지정 등)</h3>
                <ol>
                    <li>회사는 가상자산 투자자 보호 등을 위하여 특정 가상자산을 투자유의종목으로 지정할 수 있습니다.</li>
                    <li>회사는 투자유의종목을 지정하거나 그 지정을 해지하는 경우 이를 회원에게 공지해야 합니다.</li>
                    <li>회사는 투자유의종목으로 지정된 날로부터 30일이 경과할 때까지 투자유의종목으로 지정된 사유가 해소되지 않을 경우 해당 가상자산의 거래지원 서비스를 종료하기로 결정할 수 있습니다.</li>
                    <li>회사는 제3.항에 따라 특정 가상자산의 거래지원 서비스를 종료하기로 결정한 경우, 거래지원 서비스 종료일 전에 최소 30일 이상의 기간을 두고, 거래지원 서비스를 종료하기로 결정한 사실 및 이와 관련된 후속 절차를 회원에게 공지 및 개별 통지하기로 합니다.</li>
                    <li>회사는 거래지원 서비스가 종료된 가상자산의 경우 입금과 메인넷∙하드포크∙에어드랍∙스왑 등 모든 기술적 지원을 제공하지 않을 수 있습니다.</li>
                </ol>
                <h3>제16조 (서비스 수수료)</h3>
                <p>회사는 회원에게 서비스를 제공하는 대가로 수수료를 절대 요구하지 않습니다.</p>
                <h3>제17조 (개인정보의 보호)</h3>
                <p>회사는 관계 법령이 정하는 바에 따라 회원의 개인정보를 보호하기 위해 노력합니다. 회원의 개인정보는 서비스의 원활한 제공을 위하여 회원이 동의한 목적과 범위 내에서만 이용됩니다. 회사는 법령에 의하거나 회원이 별도로 동의하지 아니하는 한, 회원의 개인정보를 제3자에게 제공하지 아니하며, 이에 대한 자세한 사항은 개인정보 처리방침에서 정합니다.</p>
                <h3>제18조 (회사의 면책사항 및 손해배상)</h3>
                <ol>
                    <li>회사는 서비스와 관련하여 이 약관에 명시되지 않은 어떠한 사항에 대하여 보증을 하지 않습니다. 또한, 회사는 회사가 발행하거나 지급을 보증하지 아니한 가상자산의 가치를 보증하지 않습니다.</li>
                    <li>회사는 천재지변, 디도스(DDos) 공격, IDC장애, 서비스 접속의 폭등으로 인한 서버 다운, 기간통신사업자의 회선 장애 등 기타 불가항력적인 사유로 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제됩니다. 다만, 회사의 고의 또는 과실이 있는 경우에는 그러하지 아니합니다.</li>
                    <li>회사는 블록체인의 문제, 가상자산 발행 관리 시스템 자체의 하자 또는 기술적 문제, 통신서비스 업체의 불량, 정기적인 서버점검 등으로 인하여 불가피하게 장애가 발생하였을 경우에 책임을 지지 않습니다. 다만, 회사의 고의 또는 과실이 있는 경우에는 그러하지 아니합니다.</li>
                    <li>회사는 회원의 귀책사유로 인한 서비스 이용 장애나 그 결과에 대하여는 책임을 지지 않습니다. 다만, 회사의 고의 또는 과실이 있거나 회원에게 정당한 사유가 있는 경우에는 그러하지 아니합니다.</li>
                    <li>회사는 회원간 또는 회원과 제3자 상호간에 서비스를 매개로 하여 거래 등을 한 경우에는 책임이 면제됩니다. 다만, 회사의 고의 또는 과실이 있는 경우에는 그러하지 아니합니다.</li>
                    <li>회사는 무료로 제공되는 서비스 이용과 관련하여 관련법에 특별한 규정이 없거나 회사의 고의 또는 과실이 없는 한 책임을 지지 않습니다.</li>
                    <li>회사는 안정적인 서비스 제공을 위해 정기적, 비정기적 또는 긴급한 서버 점검을 실시할 수 있습니다. 만약 서버 점검 완료 후 국내외 가상자산 거래소의 가상자산 시세와 서비스 상의 거래 대기 주문의 시세가 비정상적으로 차이가 날 경우, 회사는 회원을 보호하기 위하여 내부 정책에 따라 대기 주문 건을 취소할 수 있습니다.</li>
                    <li>누구든지 서비스 오류, 전산장애 또는 기타 사유로 인하여 제3자 소유의 가상자산을 권한 없이 취득하거나 전송 받게 될 경우, 회사는 당사자에게 사전 통지 후 해당 가상자산을 회수하거나 원상회복 시키는 등 필요한 조치를 취할 수 있습니다.</li>
                    <li>회원이 회사에게 손해배상을 청구할 경우 회사는 회원과 합의하여 회원의 전자지갑으로 가상자산 또는 원화를 지급하는 방법으로 회원의 손해를 배상할 수 있습니다.</li>
                </ol>
                <h3>제19조 (대리 및 보증의 부인)</h3>
                <ol>
                    <li>회사는 회원을 대리하는 권한을 가지고 있지 않으며, 회사의 어떠한 행위도 회원을 위한 대리행위로 간주되지 않습니다.</li>
                    <li>회사는 회사가 제공하는 서비스를 통하여 이루어지는 회원 간의 거래와 관련하여 거래 의사의 존재 여부 및 진위, 적법성에 대하여 보증하지 않습니다.</li>
                    <li>회사에 연결(링크)된 사이트(상품권몰, 유료컨텐츠 제공업체 등)는 회사와 계약을 통하여 제휴관계를 맺은 업체들로서 회사는 단지 제휴업체와의 연결(링크)만을 제공할 뿐이며, 제휴업체의 서비스 운영 및 관리, 물품의 판매, 배송 및 환불 등에 대한 모든 책임은 회사에 고의 또는 과실이 없는 한 제휴업체가 부담하고 회사는 일절 관여하지 않습니다.</li>
                </ol>
                <h3>제20조 (관할법원 및 준거법)</h3>
                    <ol>
                        <li>본 서비스 이용 약관은 대한민국 법률에 따라 규율되고 해석되며, 회사의 요금체계 등 서비스 이용과 관련하여 회사와 회원 간에 발생한 분쟁으로 소송이 제기되는 경우 대한민국 법률이 적용됩니다.</li>
                        <li>서비스 이용과 관련하여 회사와 회원 간에 발생한 소송의 경우 법령에 정한 절차에 따른 법원을 관할 법원으로 합니다.</li>
                    </ol>
                <h3>부칙</h3>
                <p>이 약관은 2021년 8월 25일부터 적용됩니다.</p>
            </section>
        </MainDiv>
    )
}

export default TermsOfService;