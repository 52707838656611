import React, { useLayoutEffect, useEffect, useState } from "react";
import { t } from "i18next";
import { Link } from "react-router-dom";
import ReactLoading from 'react-loading';
import styled from "styled-components";

import ChangeName from "../components/Modal/contents/ChangeName";
import ChangePw from "../components/Modal/contents/ChangePw";
import ModalScreen from "../components/Modal/ModalScreen";
import AuthCapsLock from "../components/Auth/AuthCapsLock";
import ModalMypage from "../components/Modal/ModalMypage";
import Footer from "../feature/Footer/Footer";

import { isNull, isValidPassword } from "../apis/Validation";
import { axiosGet, axiosPost } from "../apis/AxiosAPI";
import { isSessionValidate, isValidationSessionId } from "../apis/BrowserStorageAPI";
import { addComma, moveTopOnPageAuto } from "../apis/CommonFunc";

import PointTable from '../feature/Point/PointTable';
import Payment from '../components/Payment/Payment';
import WithdrawPoint from '../components/Modal/My/Point/WithdrawPoint';

const StyledMyPageTemplate = styled.div`
    width: 100vw;
    height: 100vh;
    margin-bottom: 180px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media only screen and (max-width: 768px) {
        height: auto;
        margin-bottom: 60px;
        padding-top: 100px;
    }
    h1 {
        font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 34px;
        text-align: center;
        margin-bottom: 32px;
    }
    h2 {
        font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
        font-size: 42px;
        font-style: normal;
        font-weight: 700;
        line-height: 34px;
        text-align: center;
        margin-bottom: 50px;
    }
    div {
        width: 100%;
        display:flex;
        justify-content: center;
    }

    .functional-buttons-wrapper {
        display: grid;
        grid-gap: 10px 10px;
        grid-template-columns: 1fr 1fr;
        width: 300px;
        .withdraw-button, .charge-button {
            width: auto;
            height: 48px;
            padding: 10px 20px;
            background: #05141F;
            -webkit-appearance: none;
            -webkit-border-radius: 0;
            border: none;
            color: #fff;
            font-family: 'Nanum Gothic','Roboto','Noto Sans SC',sans-serif;
            font-size: 14px;
            font-weight: 700;
            line-height: 16px;
            margin-bottom: 10px;
            cursor: pointer;
        }

        .charge-button {
            background: #fff;
            color: #05141F;
            border: 1px solid #05141F;
        }
    }

    .withdraw-note-wrapper {
        width: 300px;
        padding: 0px 0px 10px 0px;
        font-size: 14px;
        color: red;
    }



`;

const FormTemplate = styled.form`
    height: auto;
    display: flex;
    flex-direction: column;
    >input[type=password]{
        width: 400px;
        height: 48px;
        border: 1px solid #D9DDE2;
        font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        padding: 0px 14px;
        &:focus {
            outline: 1px solid #05141F;
            border-radius: 2px;
        }
    }
    >input[type=submit]{
        width: 400px;
        height: 48px;
        background: #05141F;
        -webkit-appearance: none;
        -webkit-border-radius: 0;
        border: none;
        color: #fff;
        font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
        font-size: 14px;
        font-weight: 700;
        line-height: 16px;
        margin-bottom: 10px;
        cursor: pointer;
        &:hover{
            background:#3D85F0;;
            transition:.3s;
        }
    }
    >a{
        width: 400px;
        height: 48px;
        border: 1px solid #05141F;
        color: #05141F;
        font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover{
            background:#3D85F0;;
            transition:.3s;
            color: #fff;
            border: none;
        }
    }
    @media only screen and (max-width: 768px) {
        width: 100%;
        padding: 0px 20px;
        >input[type=password]{
            width: 100%;
        }
        >input[type=submit]{
            width: 100%;
        }
        >a{
            width: 100%;
        }
    }
`;






const MyPage = () => {
    const [password, setPassword] = useState("");               // 마이페이지에 접속하기 위한 비밀번호
    const [success, setSuccess] = useState(false);              // 비밀번호 인증 성공 후 마이페이지에 접속
    const [open, setOpen] = useState(false);                    // 모달의 오픈(true), 클로즈(false)
    const [current, Setcurrent] = useState("");                 // 현재 누른 버튼이 무엇인지 체크 name은 true, password는 false
    const [isProceeding, setIsProceeding] = useState(false);    // 데이터 통신 중일 경우 true, 아닐 경우 false
    const [disabled, setDisabled] = useState(false);            // 데이터 통신 중일 경우 버튼의 disabled 여부
    const [isCapsLock, setIsCapsLock] = useState(false);        // 비밀번호 capslock 버튼 확인


    const [totalPointAmount, setTotalPointAmount] = useState(0);

    const [pointHistoryList, setPointHistoryList] = useState([]);// 포인트 내역이 들어간다.
    const [pageNum, setPageNum] = useState(1);
    const [totalCount, setTotalCount] = useState(0);


    {/** 최초 로딩 */}
    useEffect(()=>{
        if(!isSessionValidate()) {
            alert(t('market_view_login'));
            window.location.href ='/signin';
            return false;
        }

        isValidationSessionId()
            .then(() => {
                if(!isSessionValidate()) {
                    window.location.href ='/signin';
                    return false;
                }
            });

        moveTopOnPageAuto();
    },[]);

    const openModal = (e) => {
        setOpen(!open);
        if(isNull(e) || isNull(e.target)){ return; }

        e.preventDefault();
        if(e.target.tagName === "BUTTON"){
            Setcurrent(e.target.dataset.modal.split("_")[1]);
        }
    }


    useLayoutEffect(() => {
        getPointAmount();
        moveTopOnPageAuto();
    }, [pageNum]);

    const getPointAmount = async () => {
        axiosGet(`api/mypage/point/point`)
            .then(response => {
                if(JSON.parse(response.data.result)){
                    setTotalPointAmount(response.data.data);
                    getPointHistory();
                } else {
                    alert(response.data.message);
                    if(response.data.href){
                        window.location.replace(response.data.href)
                    }
                }
            }).catch(e => {
                console.log(`notice page error message: ${e}`);
            });
    }

    const PAGE_PER_NUM = 10;
    const getPointHistory = async () => {
        axiosGet(`api/mypage/point/history?pageNum=${pageNum}&numPerPage=${PAGE_PER_NUM}`)
            .then(response => {
                if(JSON.parse(response.data.result)){
                    setPointHistoryList(response.data.data);
                    setTotalCount(response.data.totalCount);
                } else {
                    alert(response.data.message);
                }
            }).catch(e => {
                console.log(`notice page error message: ${e}`);
            });
    }


    const [asterPayments, setAsterPayments] = useState(null);
    const CLIENT_KEY = 'test_ck_OEP59LybZ8Bdv6A1JxkV6GYo7pRe';
    useEffect(() => {
        try {
            setTimeout(() => {
                setAsterPayments(new window.AsterPayments(CLIENT_KEY));
            }, 500);
        } catch (error) {
            console.warn(`AsterPay Load failed... ... ${error}`);
        }
    }, []);

    //포인트 결제 팝업 보여주기 여부. true = show
    const [isPaymentShow , setIsPaymentShow] = useState(false);

    //출금 신청 팝업 보여주기 여부. true = show
    const [isShowWithdrawPoint , setIsShowWithdrawPoint] = useState(false);

    const onClickChargeButton = () => {
        setIsPaymentShow(true);
    }

    const onClickWithdrawButton = () => {
        if(window.confirm(`${t('withdraw_caution_002')}`)) {
            alert(`${t('withdraw_caution')}` +'');
            setIsShowWithdrawPoint(true);
        } else {
        }
    }

    const list = [];

    return (
        <>
        {open &&
            <ModalScreen>
                {
                    isProceeding
                    ? <ReactLoading type={'spin'} color={'skyblue'} height={'40px'} width={'40px'} />
                    : <ModalMypage title={current==='name' ? t('mypage_change_name') : t('mypage_change_password')} closeModal={openModal}>
                        {
                        current === 'name'
                        ? <ChangeName closeModal={openModal} setIsProceeding={setIsProceeding}/>
                        : <ChangePw closeModal={openModal} setIsProceeding={setIsProceeding}/>
                        }
                    </ModalMypage>
                }
            </ModalScreen>
        }
        {isShowWithdrawPoint &&
            <WithdrawPoint
                setIsShowWithdrawPoint = {setIsShowWithdrawPoint}
                data={
                    {
                        "pointBalance" : totalPointAmount
                    }
            }/>
        }
        <StyledMyPageTemplate>
            <h1>{t('point_title')}</h1>
            <h2>{addComma(totalPointAmount)} point</h2>

            {/* 여기에 '충전하기', '출금하기' 버튼이 보여집니다. */}
            <div className="functional-buttons-wrapper">
                {
                    false &&
                    <button type="button" className="charge-button" onClick={onClickChargeButton}>충전하기</button>
                }
                <button type="button" className="withdraw-button" onClick={onClickWithdrawButton}>출금하기</button>
            </div>
            <div className="withdraw-note-wrapper">{t('withdraw_caution')}</div>
            {/* 여기에 포인트 내역이 보여집니다. */}
            <PointTable data={pointHistoryList} pageNum={pageNum} setPageNum={setPageNum} totalCount={totalCount} PAGE_PER_NUM={PAGE_PER_NUM}></PointTable>

        </StyledMyPageTemplate>
        <Footer/>
        {isPaymentShow &&
            <Payment
                setIsPaymentShow    = {setIsPaymentShow}
                asterPayment       = {asterPayments}
                paymentParams       = {{}}
            >
            </Payment>
        }
        </>
    );
}

export default MyPage;
