import React from "react";
import styled from "styled-components";

const StyledTopBanner = styled.div`
    width: 100vw;
    height: 300px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    >.blur {
        height: 100%;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.65);
        display: flex;
        align-items: center;
        justify-content: center;
        h1 {
            color: #fff;
            width: 100%;
            max-width: 1200px;
            font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
            font-size: 48px;
            font-style: normal;
            font-weight: 700;
            line-height: 56px;
            letter-spacing: 0.03em;
            text-align: center;
        }
    }
    @media only screen and (max-width: 768px) {
        height: 300px;
        .blur {
            h1 {
                padding: 20px 20px 45px 20px;
                max-width: 100%;
                width: 100%;
                font-size: 36px;
                line-height: 47px;
            }
        }
    }
`;

// 각 페이지 상단의 배너
const PageBanner = ({children, style}) => {
    return (
        <StyledTopBanner style={style}>
            <div className="blur">
                <h1>{children}</h1>
            </div>
        </StyledTopBanner>
    );
}

export default PageBanner;