import React, { useState, useEffect } from 'react';
import { useTranslation} from "react-i18next";
import './addressList.css';
import { searchAddressList } from './addressListUtil';
import {EnAxios} from '../kakaoMap/enAxios'


/**
 * 주소 목록
 */
const AddressList = (props) => {
    // 카카오맵 api에 주소 목록 요청 후 상태로 저장
    const [page, setPage] = new useState(undefined);
    // 화면에 실제로 출력할 주소 목록
    const [infoAddressList, setInfoAddressList] = useState(undefined);
    // 주소 목록의 열기, 닫기 여부
    const [btnToggle, setBtnToggle] = useState(false);
    // 카카오맵 api 객체
    const kakaoMap = props.kakaoMap;
    const listOnOff = props.listOnOff
    const setListOnOff = props.setListOnOff

    /*
     * 주소 목록 페이징을 위한 스크롤 이벤트
     * 다음 페이지 목록 요청 후 데이터는 
     */
    const scrollAddressList = (e) => {
        let element = e.target;
        if (element.scrollHeight - element.scrollTop <= element.clientHeight) {
            // searchAddressList 함수로 다음 페이지 목록 요청시 콜백함수가 자동으로 실행되어 다음페이지 정보가 추가로 저장됨.
            page.pagination.nextPage();
        }
    };

    /*
     * 주소 클릭
     */
    const clickAddress = (address) => {
        setListOnOff(false);
        kakaoMap.changeKakaoLineSet(address.address_name, address.y, address.x);
        props.setAddress({address:address});
        props.setSearchType('list');
    };

    useEffect(() => {
        // 페이지 로딩 후 주소 목록을 초기화한다.
        setInfoAddressList(undefined);
        
        /*
         * 목록 요청
         * 스크롤 페이징시마다 page.pagination.nextPage() 함수가 실행되어 콜백함수가 자동으로 실행됨.
         */
        const paginationCallbcak = (page) => {
            // PC인 경우에만 컴포넌트를 업데이트 한다.
            if(window.innerWidth < 1024 || 2 <= window.devicePixelRatio) return;
            
            setPage(page);

            // 검색 결과가 없을 때
            if(page.status === 'ZERO_RESULT') {
                setListOnOff(false);
                setBtnToggle(false);
                return;
            }

            // 검색 목록 출력
            if(props.searchType === 'search') {
                setListOnOff(props.keyword !== undefined);
                setBtnToggle(props.keyword !== undefined);
            }
        };

        // 첫 페이지 요청. 그리고 스크롤 페이징시마다 호출할 콜백함수 전달.
        searchAddressList(props.keyword, paginationCallbcak);
    }, [props.keyword]);

    // 스크롤 페이징시마다 호출되는 useEffect
    useEffect(() => {
        if(!page) return;

        // 주소목록의 첫페이지 호출시 첫번째 데이터를 출력하기 위한 처리
        if(page.data[0] && page.pagination.current === 1) {
            const firstAddress = page.data[0];
            kakaoMap.changeKakaoLineSet(firstAddress.address_name, firstAddress.y, firstAddress.x);
            props.setAddress({address:firstAddress});
            props.setSearchType('list');
        }
        // 스크롤 페이징시마다 주소목록을 추가
        if(props.searchType === 'search' || props.searchType === 'list') {
            if(infoAddressList) {
                setInfoAddressList(infoAddressList.concat(page.data));
            } else {
                setInfoAddressList(page.data);
            }
        }
    }, [page]);

    return (
        <>
            <section id={'address-list'} className={`${listOnOff ? '' : 'close'}`}>
                { infoAddressList && 
                    <>
                        <dl onScroll={scrollAddressList} >
                            <dt>{props.keyword}</dt>
                            
                            {infoAddressList.map((address, i) => (
                                <dd key={i} onClick={() => clickAddress(address)}>
                                    <Address address={address} />
                                </dd>
                            ))}
                        </dl>
                    </>
                }
                
                <button onClick={() => setListOnOff(!listOnOff)} className={`btn-toggle ${btnToggle ? '': 'off'}`}></button>
            </section>
        </>
    );
};

/**
 * 주소 목록 아이템
 */
const Address = (props) => {
    
    const sideSearchKakaoLang = localStorage.getItem("locale");
    const {t} = useTranslation();
    const placeName = props.address.place_name;
    let addressName = props.address.address_name;
    let roadAddressName = props.address.road_address_name;


    if(!addressName) addressName = '';
    if(!roadAddressName) roadAddressName = '';

    const [kakaoAddress , setKakaoAddressM ] = useState("")
    
    
    useEffect( (e) => {
        async function addressListAxios(){
            // let res =  await EnAxios(addressName)
            // 영문 주소 land값 없앰
            let res =  await EnAxios(roadAddressName)
            setKakaoAddressM(res.data)
        }
		addressListAxios();    
    },[roadAddressName , EnAxios , setKakaoAddressM]);

    let addr = (kakaoAddress.data && kakaoAddress.data.results) ? kakaoAddress.data.results.juso[0].jibunAddr : '-'
    let addrRoad = (kakaoAddress.data && kakaoAddress.data.results) ? kakaoAddress.data.results.juso[0].roadAddr : '-'

    return (
        <dl>
            <dt>{placeName}</dt>
            { 0 < roadAddressName.trim().length &&
                <dd className='road'>
                    <span>{t("map_road_address")}</span>
                    <p>
                        {
                            sideSearchKakaoLang !== 'ko' ? 
                            addrRoad : roadAddressName
                        }
                    </p>
                </dd>
            }
            { 0 < addressName.trim().length &&
                <dd className='legacy'>
                    <span>{t("map_address")}</span>
                    <p>
                        {   
                            sideSearchKakaoLang !== 'ko' ? 
                            addr : addressName
                        }
                    </p>
                </dd>
            }
        </dl>
    );
};

export default AddressList;