import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { t } from "i18next";


const PaddingDiv = styled.div`
    padding: 20px;
`;

const ModalDiv = styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0px;
    top: 0px;
    background: rgba(0,0,0,0.7);
    padding: 0px;
    z-index:1000;

    .popup-image {
        width: 100%;
        cursor: pointer;
    }
    .botom-right-no-display-in-today-check-box-wrapper {
        bottom: 0px;
        right: 0px;
        padding: 5px;
        text-align: right;

        & * {
            cursor: pointer;
        }

    }

    .children-wrapper {
        padding: 20px;
        .event-join-button {
            margin: 0px auto;
            background: #05141F;
            color: #ffffff;
            width: 100%;
            padding: 10px;
            margin-top: 0px;
            cursor: pointer;
        }
    }

    /**popup-20230301-01*/
    .popup-20230301-01 {
        .content-title {
            color: #ff645a;
            margin-bottom: 10px;
        }
        .content-sub-title {
            font-size: 24px;
            margin-bottom: 10px;
        }
        .content-description-list {
            margin-left: 20px;
            li {
                list-style: circle;
            }
        }
    }




`;

const ContentDiv = styled.div`
    width: 320px;
    height: auto;
    _min-height: 568px;
    background: rgba(255,255,255);
    padding: 0px;
    margin: 0px auto;
    position: relative;
`;

const PrevBtnSpan = styled.span`
    position: absolute;
    top: 10px;
    left: 20px;
    width: 27px;
    height: 27px;
    cursor: pointer;
    &::before {
        content: ' ';
        position: absolute;
        width: 13px;
        height: 1px;
        top: calc(100% - 9px);
        left: 0px;
        background: #000;
        transform-origin: center;
        transition: .3s;
        transform: rotate(45deg);
    }
    &::after{
        content: ' ';
        position: absolute;
        width: 13px;
        height: 1px;
        background: #000;
        top: calc(0% + 9px);
        left: 0px;
        transform-origin: center;
        transition: .3s;
        transform: rotate(-45deg);
    }

    &:hover {
        &::before {
            transform: rotate(0.625turn);
        }
        &::after{
            transform: rotate(-0.625turn);
        }
    }

`;

const CloseBtnSpan = styled.span`
    position: absolute;
    top: 10px;
    right: 10px;
    width: 27px;
    height: 27px;
    cursor: pointer;
    &::before {
        content: ' ';
        position: absolute;
        width: 27px;
        height: 1px;
        top: calc(50% - 1px);
        background: #000;
        transform-origin: center;
        transition: .3s;
        transform: rotate(45deg);
    }
    &::after{
        content: ' ';
        position: absolute;
        width: 27px;
        height: 1px;
        background: #000;
        top: calc(50% - 1px);
        transform-origin: center;
        transition: .3s;
        transform: rotate(-45deg);
    }

    &:hover {
        &::before {
            transform: rotate(0deg);
        }
        &::after{
            transform: rotate(-0deg);
        }
    }

`;

const TitleDiv = styled.div`
    color: #444;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    text-align: center;
    padding: 20px 0px;
    border-bottom: 3px solid #DFE1E5;
`;

const EventPopup = ({
    setIsPopupShow,
    popupTitle,
    popupImageUrl,
    popupImageClickLink,
    popupImageClickLinkTarget,
    showsHidingCheckBoxForADay,
    children}) => {

    const [currentStatus , setCurrentStatus] = useState(0);
    const enumStatusIndex =
    {
        enterPrice      : 0,
        selectMethod    : 1,
        enterCardInfo   : 2,
        complete        : 3,
    };
    const enumStatus =
    [
        {
            pageID                  : "enterPrice",
            title                   : "point_title",
            bottomButtonLabel       : "next",
        },
        {
            pageID                  : "selectMethod",
            title                   : "point_payment_method_title",
            bottomButtonLabel       : "next",
        },
        {
            pageID                  : "enterCardInfo",
            title                   : "Card infomation",
            bottomButtonLabel       : "next",
        },
        {
            pageID                  : "complete",
            title                   : "Complete",
            bottomButtonLabel       : "Done",
        }
    ];


    // 구매 정보 JSON
    const [args , setArgs] = useState(
        {
            ['amount']: 0,
            ['expiration-month']: "0",
            ['expiration-year']: "0",
        }
   );

   {/*하단 다음 버튼을 누르기 전에 유효성 체크를 위해 각 component에서 재 정의할 수 있도록 지원한다. 해당 function은 true를 리턴해야 다음 화면으로 넘어간다.*/}
   const [onClickNextButtonPrevius , setOnClickNextButtonPrevius] = useState(() => function() {
       return true;
   });

    useEffect(()=>{
    },[args]);

    const closeModal = (e) => {
        e.preventDefault();
        setIsPopupShow(false);
    }

    const onClickPreviusButton = () => {
        if( currentStatus > 0 ) {
            setCurrentStatus(prev => prev - 1);
        }
    }

    const onClickPopupImage = () => {
        window.open(popupImageClickLink, popupImageClickLinkTarget);
    }

    const onChangeEventBotomRightNoDisplayInTodayCheckBox = (e) => {
        if( e.target.checked ) {
            setCookie("isEventPopup20220614Disabled", "true");
        } else {
            setCookie("isEventPopup20220614Disabled", "false");
        }
    }

    function setCookie(name, value) {
        var todayDate = new Date();
        todayDate.setHours( 24 );
        document.cookie = name+ "=" + escape( value ) + "; path=/; expires=" +   todayDate.toGMTString() + ";";
    }


    useEffect(() => {
        return () => {
        }
    }, [])


    return (
        <ModalDiv>
            <ContentDiv>
                {currentStatus !== 0 &&
                    <PrevBtnSpan onClick={onClickPreviusButton}/>
                }
                <CloseBtnSpan onClick={closeModal}/>
                <TitleDiv>
                    {popupTitle}
                </TitleDiv>
                {
                    popupImageUrl &&
                    <div className="popup-image-wrapper">
                        <img className="popup-image" src={popupImageUrl} onClick={onClickPopupImage}/>
                    </div>
                }
                <div className="children-wrapper">
                    {children}
                </div>
                {showsHidingCheckBoxForADay &&
                    <div className="botom-right-no-display-in-today-check-box-wrapper">
                        <label>오늘하루이창 열지 않기<input className="botom-right-no-display-in-today-check-box" type="checkbox" onChange={onChangeEventBotomRightNoDisplayInTodayCheckBox}/></label>
                    </div>
                }
            </ContentDiv>
        </ModalDiv>
    )
}

export default EventPopup;
