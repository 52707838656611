import { t } from "i18next";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import '../../../components/animation.css';

const AwesomepiaServiceTemplate = styled.div`
    width: 100%;
    height: auto;
    max-width: 1440px;
    display: flex;
    justify-content: center;
    margin-bottom: 240px;
    ul {
        visibility: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        &.on {
            visibility: visible;
            li {
                &:nth-of-type(1){
                    animation: slideUp .5s 0s;
                    animation-fill-mode: both;
                }
                &:nth-of-type(2){
                    animation: slideUp .5s .5s;
                    animation-fill-mode: both;
                }
                &:nth-of-type(3){
                    animation: slideUp .5s 1.0s;
                    animation-fill-mode: both;
                }
                &:nth-of-type(4){
                    animation: slideUp .5s 1.5s;
                    animation-fill-mode: both;
                }  
            }
        }
        li {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 295px;
            height: 370px;
            border: 1px solid #D9DDE2;
            margin-right: 20px;
            &:last-of-type {
                margin-right: 0px;
            }
            h3 {
                color: #3D85F0;
                font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
                font-size: 24px;
                font-style: normal;
                font-weight: 800;
                line-height: 36px;
                letter-spacing: 0em;
                text-align: center;
                margin-bottom: 38px;
                opacity: 0.5;
            }
            img {
                width: 96px;
                height: 100px;
                margin-bottom: 38px;
            }
            p {
                color: #05141F;
                font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
                text-align: center;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: 0em;
                height: 50px;
            }          
        }
    }
    
    @media only screen and (max-width: 768px) {
        max-width: 100%;
        padding: 0px 20px;
        margin-bottom: 110px;
        ul {
            width: 100%;
            display: grid;
            grid-template-columns: [col] 49% [col] 49%;
            grid-template-rows: [row] 250px [row] 250px;
            grid-gap: 10px;
            li {
                width: 100%;
                height: 100%;
                margin: 0px;
                h3 {
                    margin-bottom: 20px;
                }
                img {
                    width: 50px;
                    height: 50px;
                    margin-bottom: 20px;
                }
                p {
                    width: 100%;
                    padding: 0px 19px;
                }
            }
        }
    }

`;

const AwesomepiaService = () => {
    const [scrollLocation, setScrollLoacation] = useState(0);
    const [fadeIn, setFadeIn] = useState(false);
    const ulRef = useRef();

    const listener = () => {
        setScrollLoacation(window.pageYOffset);
    }

    useEffect(() => {
        window.addEventListener('scroll', listener);
        setFadeIn(ulRef.current.offsetTop < scrollLocation + window.innerHeight);
        return () => { window.removeEventListener('scroll', listener)}
    }, [scrollLocation])


    return (
        <AwesomepiaServiceTemplate>
                <ul ref={ulRef} className={fadeIn ? "on" : ""}>
                    <li>
                        <h3>01</h3>
                        <img src={`${process.env.PUBLIC_URL}/images/future/awesomepia/1_platform.svg`} alt="platform"/>
                        <p>{t("metarex_future_awesomepia_li_1")}</p>
                    </li>
                    <li>
                        <h3>02</h3>
                        <img src={`${process.env.PUBLIC_URL}/images/future/awesomepia/2_heathcare.svg`} alt="heathcare"/>
                        <p>{t("metarex_future_awesomepia_li_2")}</p>
                    </li>
                    <li>
                        <h3>03</h3>
                        <img src={`${process.env.PUBLIC_URL}/images/future/awesomepia/3_vrar.svg`} alt="vrar"/>
                        <p>
                            {t("metarex_future_awesomepia_li_3")}<br/>
                            {t("metarex_future_awesomepia_li_4")}
                        </p>
                    </li>
                    <li>
                        <h3>04</h3>
                        <img src={`${process.env.PUBLIC_URL}/images/future/awesomepia/4_bora.svg`} alt="bora"/>
                        <p>
                            {t("metarex_future_awesomepia_li_5")}<br/>
                            {t("metarex_future_awesomepia_li_6")}
                        </p>
                    </li>
                </ul>
        </AwesomepiaServiceTemplate>
    );
}

export default AwesomepiaService;