import React, { useState, useEffect } from 'react';
import ArrowDown from './arrow-down.png'
import './addressListMobile.css';
import { searchAddressList } from './addressListUtil';
import { useTranslation} from "react-i18next";
import {EnAxios} from '../kakaoMap/enAxios'


/**
 * 주소 목록 (모바일)
 */
const AddressListM = (props) => {
    const {t} = useTranslation();
    // 검색어
    const keyword = props.keyword;
    // 검색 타입
    const searchType = props.searchType;
    // 클릭한 주소정보를 상위에 전달하는 콜백함수
    const setAddress = props.setAddress;
    // 주소목록에서 주소 클릭시 검색타입을 'list'로 전달
    const setSearchType = props.setSearchType;
    // 헤더 영역에서 주소 검색 버튼 클릭 여부
    const isClickSearch = props.isClickSearch;
    // 주소 목록 출력 여부
    const [isListShow, setListShow] = useState(true);
    // 주소 목록 페이지 정보
    const [page, setPage] = new useState(undefined);
    // 주소 목록 
    const [addressList, setAddressList] = useState(undefined);
    const [listOnOff, setListOnOff] = useState(false);

    useEffect(()=>{
    },[listOnOff])

    // 주소 목록에서 주소 클릭시 맵의 위치를 이동하기 위한 카카오맵 API 객체
    const kakaoMap = props.kakaoMap;

    /*
     * 주소 목록 페이징을 위한 스크롤 이벤트
     * 다음 페이지 목록 요청 후 데이터는 
     */
    const scrollAddressList = (e) => {
        let element = e.target;
        if (element.scrollHeight - element.scrollTop <= element.clientHeight) {
            page.pagination.nextPage();
        }
    };

    /**
     * 주소목록 열기 닫기 버튼 이벤트
     */
    const clickListOpenClose = () => {
        setListShow(!isListShow);
    };

    /*
     * 주소 클릭
     */
    const clickAddress = (address) => {
        kakaoMap.changeKakaoLineSet(address.address_name, address.y, address.x);
        props.setAddress({address:address});
        props.setSearchType('list');
    };

    useEffect(() => {
        // 페이지 로딩 후 주소 목록을 초기화한다.
        setAddressList(undefined);

        /*
         * 목록 요청
         * 스크롤 페이징시마다 page.pagination.nextPage() 함수가 실행되어 콜백함수가 자동으로 실행됨.
         */
        const paginationCallbcak = (data) => {
            setPage(data);

            // 검색 결과가 없을 때
            if(data.status === 'ZERO_RESULT') {
                setListOnOff(false);
                return;
            }

            // 검색 목록 출력
            if(props.searchType === 'search') {
                setListOnOff(props.keyword !== undefined);
            }
        };

        // 첫 페이지 요청. 그리고 스크롤 페이징시마다 호출할 콜백함수 전달.
        searchAddressList(keyword, paginationCallbcak);
    }, [keyword]);

    // 스크롤 페이징시마다 호출되는 useEffect
    useEffect(() => {
        if(!page) return;

        // 주소목록의 첫페이지 호출시 첫번째 데이터를 출력하기 위한 처리
        if(page.data[0] && page.pagination.current === 1) {
            const firstAddress = page.data[0];
            kakaoMap.changeKakaoLineSet(firstAddress.address_name, firstAddress.y, firstAddress.x);
            setAddress({address:firstAddress});
        }

        // 스크롤 페이징시마다 주소목록을 추가
        if(searchType === 'search' || searchType === 'list') {
            if(addressList) {
                setAddressList(addressList.concat(page.data));
            } else {
                setAddressList(page.data);
            }
        }
    }, [page]);


    return (
        <>
            { (keyword && addressList && searchType === 'search') && 
                <section id={'address-list-m'} className={props.isShow ? '' : 'hide'}>
                    <button className={isListShow ? '' : 'close'} onClick={clickListOpenClose}>
                        <img src={ArrowDown} alt={'매물 정보 창 열기 및 닫기'} />
                    </button>

                    <article className={isListShow ? '' : 'close'}>
                        <h5>{keyword}</h5>

                        <ul onScroll={scrollAddressList}>
                            {addressList && 
                                addressList.map((address, i) => <Address key={i} address={address} onClick={() => clickAddress(address)} />)
                            }

                            {(!addressList || addressList.length === 0) && 
                                <li>{t("map_null_serach")}</li>
                            }
                        </ul>
                    </article>
                </section>
            }

            { (isClickSearch && addressList && searchType === 'search') && 
                <section id={'address-list-m'} className={props.isShow ? '' : 'hide'}>
                    <button className={isListShow ? '' : 'close'} onClick={clickListOpenClose}>
                        <img src={ArrowDown} alt={'매물 정보 창 열기 및 닫기'} />
                    </button>

                    <article className={isListShow ? '' : 'close'}>
                        <h5>{keyword}</h5>

                        <ul onScroll={scrollAddressList}>
                            {addressList && 
                                addressList.map((address, i) => <Address key={i} address={address} onClick={() => clickAddress(address)} />)
                            }

                            {(!addressList || addressList.length === 0) && 
                                <li>{t("map_null_serach")}</li>
                            }
                        </ul>
                    </article>
                </section>
            }
        </>
    );
};

/**
 * 주소 목록 아이템
 */
const Address = (props) => {
    const sideSearchKakaoLang = localStorage.getItem("locale");
    const {t} = useTranslation();
    const placeName = props.address.place_name;
    let addressName = props.address.address_name;
    let roadAddressName = props.address.road_address_name;

    if(!addressName) addressName = '';
    if(!roadAddressName) roadAddressName = '';

    const [kakaoAddressM , setKakaoAddressM ] = useState("")
    
    useEffect( (e) => {
        async function addressM(){
            // 영문주소 
            let res =  await EnAxios(roadAddressName)
            setKakaoAddressM(res.data)
        }
		addressM();    
    },[roadAddressName , EnAxios , setKakaoAddressM]);

    let addr = (kakaoAddressM.data && kakaoAddressM.data.results) ? kakaoAddressM.data.results.juso[0].jibunAddr : '-'
    let addrRoad = (kakaoAddressM.data && kakaoAddressM.data.results) ? kakaoAddressM.data.results.juso[0].roadAddr : '-'
    
    return (
        <li onClick={() => props.onClick(props.address)}>
            <strong>{placeName}</strong>

            <dl>
                { 0 < roadAddressName.trim().length &&
                    <>
                        <dt>{t("map_road_address")}</dt>
                        <dd className={'road'}>
                            {
                                sideSearchKakaoLang !== 'ko' ? 
                                addrRoad: roadAddressName
                            }
                        </dd>
                    </>
                }
                { 0 < addressName.trim().length &&
                    <>
                        <dt>{t("map_address")}</dt>
                        <dd className={'lagacy'}>
                            {   
                                sideSearchKakaoLang !== 'ko' ? 
                                addr : addressName
                            }
                        </dd>
                    </>
                }   
            </dl>
        </li>
    );
};


export default AddressListM;