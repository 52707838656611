import React, { useEffect } from "react";
import styled from "styled-components";

import TopButton from "../../components/TopButton";

import { moveTopOnPageAuto } from "../../apis/CommonFunc";
import Footer from "../Footer/Footer";

const Template = styled.section`
    width: 100vw;
    height: 100%;
    padding-top: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media only screen and (max-width: 768px) {
        width: 100%;
        height: auto;
    }
`;


const MetarexTemplate = ({ children }) => {
    useEffect(()=>{
        moveTopOnPageAuto();
    },[]);

    return (
        <>
            <TopButton/>
            <Template> { children } </Template>
            <Footer/>
        </>
    );
}

export default MetarexTemplate;