import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";

import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TenspaceService from "./TenspaceService";
import '../../../components/animation.css';
import { t } from "i18next";



const StyledTenspaceTemplate = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media only screen and (max-width: 768px) {
        align-items: flex-start;
    }
`;

const StyledTenspaceInformationTemplate = styled.div`
    width: 100%;
    height: auto;
    max-width: 1440px;
    display: flex;
    margin-bottom: 240px;
    @media only screen and (max-width: 768px) {
        max-width: 100%;
        flex-direction: column;
        margin-bottom: 100px;
    }
`;


const StyledTenspaceInformationDiv = styled.div`
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    &:first-of-type {
        padding-left: 100px;
    }
    h2 {
        font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
        color: #05141F;
        width: auto;
        height: auto;
        font-size: 40px;
        font-weight: 800;
        line-height: 60px;
        letter-spacing: 0em;
        text-align: left;
    }
    p {
        width: 580px;
        color: #666;
        font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 800;
        line-height: 36px;
        letter-spacing: 0em;
        text-align: left;
        margin-bottom: 40px;
    }
    a {
        color: #3D85F0;
        width: 250px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #3D85F0;
        box-sizing: border-box;
        border-radius: 50px;

        font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;

        padding: 21px 30px;
        .fa-long-arrow-alt-right {
            width: 20px;
            margin-left: 10px;
        }

        margin-bottom: 100px;
    }
    .imgs {
        display: flex;
        flex-direction: column;
        div {
            display: flex;
            p {
                width: 350px;
                color: #05141F;
                font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: 0em;
                text-align: left;
                margin-left: 20px;
            }
            img {
                margin-bottom: 30px;
            }
        }
        opacity: 0;
        &.active {
            animation: fadeIn 2s .5s;
            animation-fill-mode: both;
        }
    }


    @media only screen and (max-width: 768px) {
        width: 100%;
        &:first-of-type {
            padding-left: 20px;
        }
        h2 {
            font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
            font-size: 30px;
            line-height: 50px;
            margin-bottom: 80px;
        }
        p {
            width: 100%;
            padding: 0px 20px;
            margin-bottom: 40px;
        }
        a {
            margin-left: 20px;
            margin-bottom: 80px;
        }
        .imgs {
            display: flex;
            flex-direction: column;
            div {
                flex-direction: column;
                p {
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 24px;
                    padding: 0px;

                }
                img {
                    padding: 0px 17px;
                    margin-bottom: 10px;
                }
            }
        }
    }
`;

const Tenspace = () => {
    const [scrollLocation, setScrollLoacation] = useState(0);
    const [fadeIn, setFadeIn] = useState(false);
    const imgsRef = useRef();

    const listener = () => {
        setScrollLoacation(window.pageYOffset);
    }

    useEffect(() => {
        window.addEventListener('scroll', listener);
        if(imgsRef.current.offsetTop < scrollLocation + window.innerHeight){
            setFadeIn(true);
        } else {
            setFadeIn(false);
        }
        return () => { window.removeEventListener('scroll', listener)}
    }, [scrollLocation])



    return (
        <StyledTenspaceTemplate>
            <StyledTenspaceInformationTemplate>
                <StyledTenspaceInformationDiv>
                    <h2>
                        {t("metarex_future_tenspace_title_1")}<br/><br/>
                        {t("metarex_future_tenspace_title_2")}<br/>
                        {t("metarex_future_tenspace_title_3")}<br/>
                        {t("metarex_future_tenspace_title_4")}<br/>
                        {t("metarex_future_tenspace_title_5")}
                    </h2>
                </StyledTenspaceInformationDiv>
                <StyledTenspaceInformationDiv>
                    <p>
                    {t("metarex_future_tenspace_p_1")}<br/>
                    {t("metarex_future_tenspace_p_2")}<br/>
                    {t("metarex_future_tenspace_p_3")}<br/>
                    {t("metarex_future_tenspace_p_4")}<br/>
                    {t("metarex_future_tenspace_p_5")}<br/>
                    {t("metarex_future_tenspace_p_6")}<br/>
                    </p>
                    <a href="https://tenspace.co.kr/" rel="noopener noreferrer" target="_blank" title={t("metarex_future_tenspace_hover")}>
                        {t("metarex_future_tenspace_btn")} <FontAwesomeIcon icon={faLongArrowAltRight}/>
                    </a>
                    <div className={`imgs${fadeIn ? " active" : ""}`} ref={imgsRef} >
                        <div>
                            <img src={`${process.env.PUBLIC_URL}/images/future/tenspace/1_ai_banking.svg`} alt="ai_banking"/>
                            <p>
                                {t("metarex_future_tenspace_p_7")}<br/>
                                {t("metarex_future_tenspace_p_8")}
                            </p>
                        </div>
                        <div>
                            <img src={`${process.env.PUBLIC_URL}/images/future/tenspace/2_gartner.svg`} alt="gartner"/>
                            <p>
                                {t("metarex_future_tenspace_p_9")}<br/>
                                {t("metarex_future_tenspace_p_10")}<br/>
                                {t("metarex_future_tenspace_p_11")}
                            </p>
                        </div>
                    </div>
                </StyledTenspaceInformationDiv>
            </StyledTenspaceInformationTemplate>
            <TenspaceService/>
        </StyledTenspaceTemplate>
    );
}

export default Tenspace;