import React, { useEffect, useState } from "react";
import styled from "styled-components";

const TemplateBlock = styled.div`
  
  height: 400px;

  position: relative;
  background: white;
  border:2px solid black;
  border-radius:16px
  box-shadow: 0 0 8px 0 rgba(0,0,0,0.04);

  margin: 0 auto;

  margin-top: 0px;
  margin-bottom:32px;
  display: flex;
  flex-direction:column;
`;

function Template2({ children }) {
  return <TemplateBlock>{children}</TemplateBlock>;
}

export default Template2;
