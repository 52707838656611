import axios from 'axios';

const EnAxios =  ( keyword ) => {
    axios.defaults.withCredentials = true
    const responses =  axios.get(`${process.env.REACT_APP_API_METAREX}/api/realestate/koreanAddrToEnglishAddr?keyword=${encodeURI(keyword)}` ,  {withCredentials : true});
    return responses
}



export { 
    EnAxios
}