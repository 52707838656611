import React, { useEffect, useState } from 'react';
import { t } from 'i18next';

import Input from '../../feature/MarketView/Input'
import ConditionSearch from '../../feature/MarketView/ConditionSearch'
import ForSale from '../../feature/MarketView/ForSale'
import MoreButton from '../../components/MoreButton';
import PageBanner from '../../components/PageBanner';
import PageTab from '../../components/PageTab';
import Payment from '../../components/Payment/Payment';
import Footer from '../../feature/Footer/Footer';

import { axiosGet } from '../../apis/AxiosAPI';
import { mobile } from '../../apis/Validation';
import { isSessionValidate, isValidationSessionId } from '../../apis/BrowserStorageAPI';
import { moveTopOnPageAuto } from '../../apis/CommonFunc';
import { useScroll } from '../../components/ScrollUtill/useScroll';
import TopButton from '../../components/TopButton';


import { getPriceDollarKRW } from "../../apis/PriceDollarAPI";

import './MarketView.css'

const MarketView = (props) => {

    const AsterPayments = {};
    /* 불러오는 매물의 개수, 4개로 고정 */
    const PER_PAGE = 4;
    
    /* 조건의 지역들을 선택시 onclick이벤트에 사용되는 state값*/
    const [seoulToggle , setSeoulToggle] = useState(false)
    const [gyeonggiToggle , setGyeonggiToggle] = useState(false)
    const [incheonToggle , setIncheonToggle] = useState(false)
    const [busanToggle , setBusanToggle] = useState(false)


    /* width값 토대로 모바일 pc 구분하기 위한 bloean값*/
    const [forSaleWidth , setForSaleWidth] = useState(false);

    /* 페이지의 넓이값을 바탕으로 pc,moblie을 나눈다. 넓이를 769로 지정한건 태블릿 디자인이 없어 해당 사이즈가 될시 pc디자인이 깨지기에 모바일 디자인 적용*/
    const forSaleResizeHandler = () => {
        if(window.innerWidth < 769) {
            setForSaleWidth(true);
        } else if (window.innerWidth > 769){
            setForSaleWidth(false);
        }
    };

    /*
     * 화면 로딩 직후, 화면 리사이즈 이벤트
     */
    useEffect(() => {
        window.addEventListener('load', forSaleResizeHandler);
        window.addEventListener('resize', forSaleResizeHandler);
        window.addEventListener('scroll', forSaleResizeHandler);
        

        return () => {
            window.removeEventListener('load', forSaleResizeHandler);
            window.removeEventListener('resize', forSaleResizeHandler);
            window.addEventListener('scroll', forSaleResizeHandler);
        } 
    }, [forSaleWidth , forSaleResizeHandler]);

    /* axios 데이터 넣음*/
    const [marketViewAxios , setMarketViewAxios] = useState(undefined);
    /* 총개수*/
    const [marketTotalCount , setMarketTotalCount] = useState(0);
    /* 페이지수*/
    const [marketPCPageNum, setMarketPCPageNum] = useState(1);
    /* pc, 모바일 구분*/
    const [marketPagePCTotalNum, setMarketPagePCTotalNum] = useState(8);
    const [marketPageMTotalNum, setMarketPageMTotalNum] = useState(8);
    /* 로딩*/
    const [marketLoading , setMarketLoading] = useState(false);
    /* pc버전 클릭여부에 따라 스타일값 초기화*/
    const [marketClickCount , setMarketClickCount] = useState(1);
    /* 모바일 페이징 마지막 */
    const [marketMLast , setMarketMLast] = useState(false);
    /* 가격대 클릭*/
    const [priceToggle , setPriceToggle] = useState(false)

    /* 밑에것들은 json 보내는데 참고할것들.*/
    const [marketKeyWord, setMarketKeyWord] = useState("");
    /* 가격선택*/
    const [priceSelect , setPriceSelect] = useState("");
    /* 전체, 추천, 일반매물 버튼값*/
    const [marketType , setMarketType] = useState("")
    const [marketDrop , setMarketDrop] = useState(false)
    /* 조건 지역 클릭한 값*/
    const [query, setQuery] = useState("")
    /* 조건 가격 담는곳*/
    const [marketPrice, setMarketPrice] = useState("")
    /* 조건 클릭한 지역값이 담기는 곳*/
    const [axiosArray , setAxiosArray] = useState("")
    /* 검색 input값*/
    const [marketInputValue , setMarketInputValue] = useState("")

    /*포인트 결제 팝업 보여주기 여부. true = show*/
    const [isPaymentShow , setIsPaymentShow] = useState(false);

    useEffect(()=>{
        /**axiosArray에 query값(지역)을 추가하고 newSet으로 중복을 제거한다. */
        const set = new Set(axiosArray.concat(query));
        setAxiosArray([...set]);
    },[query , seoulToggle , gyeonggiToggle , incheonToggle , busanToggle])
    
    const marketDropdown = () =>{
        setMarketDrop(!marketDrop)
    }
    
    
    useEffect(() => {
        let marketViewUrl = (pageNum,numPerPage,type,city,priceRange,keyword) => `realestate/sales/view-market?pageNum=${encodeURI(pageNum)}&numPerPage=${encodeURI(numPerPage)}&type=${encodeURI(type)}&city=${encodeURI(city)}&priceRange=${priceRange}&keyword=${encodeURI(keyword)}`
        axiosGet(marketViewUrl( marketPCPageNum , PER_PAGE , marketType , axiosArray , priceSelect , marketKeyWord)) 
            .then(response => {
                if(JSON.parse(response.data.result)){
                    setMarketViewAxios(response.data);
                    setMarketTotalCount(response.data.totalCount);
                    setMarketLoading(true);
                    /**pageNum이 1이면 2로 변경, 처음에 매물 8개 받아와야 하기에 이렇게 함. */
                    if(marketPCPageNum === 1){
                        setMarketPCPageNum(2);
                    }
                } else {
                    alert(response.data.message);
                }
            }).catch(e => {
                console.log(`error message: ${e}`);
            })
    }, [setMarketViewAxios, marketClickCount , marketPCPageNum  ]);

    /* axios 데이터값*/
    let marketViewArray = [];
    marketViewArray.push(marketViewAxios);

    /* axios에서 받은 데이터 추가*/
    let [marketViewDataArray, setMarketViewDataArray] = useState([]);

    useEffect(()=>{
        setMarketViewDataArray(marketViewDataArray.concat(marketViewArray));
        
    },[setMarketViewDataArray , marketViewAxios]);

    useEffect(()=>{
        for(let i = 0; i < axiosArray.length; i++) {
            let axiosArrayName = axiosArray[i];
            /* 지역 두번 클릭시 데이터를 초기화후 지역 값이 담긴 setAxiosArray안에 있는 값을 제거해준다.*/
            if(axiosArrayName === '서울' && seoulToggle === false)  {
                setMarketViewDataArray([]);
                setAxiosArray(axiosArray.filter(data => data !== "서울"));
            }else if(axiosArrayName === '경기' && gyeonggiToggle === false)  {
                setMarketViewDataArray([]);
                setAxiosArray(axiosArray.filter(data => data !== "경기"));
            }else if(axiosArrayName === '인천' && incheonToggle === false)  {
                setMarketViewDataArray([]);
                setAxiosArray(axiosArray.filter(data => data !== "인천"));
            }else if(axiosArrayName === '부산' && busanToggle === false)  {
                setMarketViewDataArray([]);
                setAxiosArray(axiosArray.filter(data => data !== "부산"));
            }
        }
    },[query,   seoulToggle , gyeonggiToggle , incheonToggle , busanToggle , axiosArray , setAxiosArray , setMarketViewDataArray]);

    const [marketPcLast , setMarketPcLast] = useState(false);
    /* 화면 끝부분. 700은 footer높이값만큼 빼준것*/
    const forSaleScrollHeight = document.documentElement.scrollHeight - 100;
    /* 스크롤 위치*/
    const forSaleScrollTop = document.documentElement.scrollTop;
    let innerHeight = window.innerHeight;

    useEffect(()=>{
        /* 모바일 화면 끝*/
        if (forSaleScrollTop + innerHeight >= forSaleScrollHeight && forSaleWidth=== true){
            const nextStartPoint = marketPCPageNum + 1;
            if(marketPageMTotalNum >= marketTotalCount && forSaleWidth === true) { 
                setMarketMLast(true);
                setMarketPcLast(true);
                return; 
            }
            setMarketPCPageNum(nextStartPoint);
            setMarketPageMTotalNum(marketPCPageNum * 4);
        }
    }, [forSaleScrollTop , forSaleScrollHeight , useScroll().scrollY]);

    const [asterPayments, setAsterPayments] = useState(null);
    const CLIENT_KEY = 'test_ck_OEP59LybZ8Bdv6A1JxkV6GYo7pRe';
    useEffect(() => {
        if(!isSessionValidate()) {
            alert(t('market_view_login'));
            window.location.href ='/signin';
            return false;
        }
        isValidationSessionId()
            .then(() => {
                if(!isSessionValidate()) {
                    window.location.href ='/signin';
                    return false;
                } else {
                    /* 달러시세를 가져온다.*/
                    getPriceDollarKRW();
                }
            });
        moveTopOnPageAuto();
        try {
            setTimeout(() => {
                setAsterPayments(new AsterPayments(CLIENT_KEY));
            }, 500);
        } catch (error) {
            console.warn(`AsterPay Load failed... ... ${error}`);
        }
    }, []);

    /* 더보기*/
    const MarketMoreBtn = (e) => {
        /* 더보기 누르면 페이지 번호 1씩 증가*/
        setMarketPCPageNum(marketPCPageNum + 1);
        /* 페이지 번호 1씩 증가한 값에, 매물 개수인 4개씩 추가시켜 현재 매물의 개수를 찾아낸다.*/
        setMarketPagePCTotalNum((marketPCPageNum + 1) * 4);
        /* 더보기 버튼 클릭 횟수*/
        setMarketClickCount(marketClickCount + 1);
    }

    /* 추천, 일반 매물 클릭*/
    const [suggestionColor , setSuggestionColor] = useState(false);
    const [normalColor , setNormalColor] = useState(false);

    /* 추천 매물 버튼 이벤트*/
    const marketSugTypeBtn = (e) => {
        if(suggestionColor === true){
            setMarketType(e.target.value);
        }else if(suggestionColor===false){
            setMarketType("");
        }
        setSuggestionColor(!suggestionColor);
    }

    /* 일반 매물 버튼 이벤트*/
    const marketNormalTypeBtn = (e) => {
        if(normalColor===true){
            setMarketType(e.target.value);
        }else if(normalColor===false){
            setMarketType("");
        }
        setNormalColor(!normalColor);
    }

    useEffect(()=>{
        if((suggestionColor === true && normalColor === true) || (suggestionColor=== false && normalColor === false) ){
            /* 둘다 클릭되었거나, 둘다 클릭되지 않을때에는 빈값을 준다.*/
            setMarketType("");
        }else  if(normalColor === false && suggestionColor === true){
            setMarketType("추천매물");
        }else if(normalColor === true && suggestionColor === false){
            setMarketType("일반매물");
        }
    },[suggestionColor,normalColor , marketType , setMarketType]);

    
    /* pageNum 바꾸는 곳*/
    /* 지역값*/
    useEffect(()=>{
        /* 지역 클릭하고 더보기 버튼이 1 일때. 즉 지역 클릭시에 데이터를 초기화 시킨후 pageNum을 2로 줘서 8개 매물을 받아온다.*/
        if(axiosArray.length > 0 && marketClickCount === 1){
            setMarketViewDataArray([]);
            setMarketPCPageNum(2);
        }
    }, [  marketClickCount , setMarketPCPageNum ,axiosArray, priceSelect,marketKeyWord, marketType , query]);

    /* 검색값*/
    useEffect(()=>{
        if(marketClickCount === 1 && marketKeyWord !== ""){
            setMarketViewDataArray([]);
            setMarketPCPageNum(2);
        }
    }, [  marketClickCount , setMarketPCPageNum , marketKeyWord ,axiosArray , priceSelect, marketType]);

    /* 가격 및 직접입력 */
    useEffect(()=>{
        if(marketClickCount === 1 && priceSelect !== ""){
            setMarketViewDataArray([]);
            setMarketPCPageNum(2);
        }
    }, [  marketClickCount , setMarketPCPageNum , priceSelect ,axiosArray,marketKeyWord, marketType]);

    /* 추천, 일반 매물 */
    useEffect(()=>{
        if(marketClickCount === 1 && (marketType === "" || marketType)){
            setMarketViewDataArray([]);
            setMarketPCPageNum(2);
        }
    }, [  marketClickCount , setMarketPCPageNum , priceSelect ,axiosArray,marketKeyWord , marketType ]);

    /* 매물 개수와 totalcount값 비교하는 로직*/
    useEffect(()=>{
        if(marketPagePCTotalNum >= marketTotalCount ) { 
            setMarketPcLast(true);
        }else{
            setMarketPcLast(false);
        }
    },[marketPagePCTotalNum , marketTotalCount, marketPcLast , marketClickCount]);

    /* 전체 state 초기값*/
    useEffect(()=>{
        if((marketPCPageNum === 2 && axiosArray.length !== 0) || (marketPCPageNum === 2 && marketKeyWord !== "")|| (marketPCPageNum === 2 && priceSelect !== "") || (marketType === "" || marketType)){
            setMarketPCPageNum(1);

            /* 더보기 클릭개수 1로 초기화*/
            setMarketClickCount(1);
            /* 더보기 diabled 초기화*/
            setMarketPcLast(false);
            /* input에 onchange이벤트로 받아온 값을 페이지가 새로고침 즉 조건이 눌렸을때 값을 넣어준다. */
            setMarketKeyWord(marketInputValue);
            /* 총 매물 (기본값 8개로) 초기화*/
            setMarketPagePCTotalNum(8);
        }
    }, [axiosArray ,marketKeyWord ,setMarketPCPageNum , priceSelect , marketType]);;


    {/*충전 필요 point*/}
    const [needPoint , setNeedPoint] = useState(0);
    {/*포인트 충전 시, 자동 결제 되도록 하기 위해, Payment에 넘겨줄 부가 정보를 담는 용도로 사용*/}
    const [paymentParams , setPaymentParams] = useState({});

    return(
        <div style={{paddingTop: '60px'}}>
            <PageBanner style={{backgroundImage: "url('/images/bg/market_banner.jpg')"}}>{t("market_view_title")}</PageBanner>
            <PageTab category="market" type="view" />
            <TopButton/>
            <section className="buyContainer">
                <div className="marketSearchContainerWarp">
                    <div className="marketDropContainer">
                        <button onClick={marketDropdown} className={marketDrop === true ? "marketDropBackground" : ""}>
                            {
                                marketDrop === true ?
                                <img src={`${process.env.PUBLIC_URL}/images/icon/market_condition_icon_click.svg`} alt="조건 아이콘" />
                                :
                                <img src={`${process.env.PUBLIC_URL}/images/icon/market_condition_icon.svg`} alt="조건 아이콘" />
                            }
                            {t("market_view_search_condition")} 
                        </button>
                        <ul>
                            <li>
                                <button value={"추천매물"} onClick={marketSugTypeBtn} className={suggestionColor === true ? "gray" : "blue"}>
                                    {
                                        suggestionColor === true ?
                                        <img src={`${process.env.PUBLIC_URL}/images/icon/market_check_icon_click.svg`} alt="추천 매물 체크" />    
                                        :
                                        <img src={`${process.env.PUBLIC_URL}/images/icon/market_check_icon.svg`} alt="추천 매물 체크" />    
                                    }
                                    {t("market_view_suggestion_button")}
                                </button>
                            </li>
                            <li>
                                <button value={"일반매물"} onClick={marketNormalTypeBtn} className = {normalColor === true ? "gray" : "blue"} >
                                    {   
                                        normalColor === true ?
                                        <img src={`${process.env.PUBLIC_URL}/images/icon/market_check_icon_click.svg`} alt="일반매물 체크" />    
                                        :
                                        <img src={`${process.env.PUBLIC_URL}/images/icon/market_check_icon.svg`} alt="일반매물 체크" />    
                                    }
                                    {t("market_view_normal_button")}
                                </button>
                            </li>
                        </ul>
                        {/* 조건 누를시 재렌더링을 피하기 위해 style값으로 조정. style만 변경되기에 조건 눌러도 새로 랜더링 되지 않는다. */}
                        <div className={marketDrop === true ? 'display' : 'none'}>
                            <ConditionSearch
                                query={query} setQuery={setQuery} setMarketPrice={setMarketPrice} marketPrice={marketPrice} setPriceSelect={setPriceSelect} setSeoulToggle={setSeoulToggle} setGyeonggiToggle={setGyeonggiToggle} setIncheonToggle={setIncheonToggle} setBusanToggle={setBusanToggle} seoulToggle ={seoulToggle} gyeonggiToggle={gyeonggiToggle} incheonToggle={incheonToggle} busanToggle={busanToggle}    priceToggle ={priceToggle} setPriceToggle={setPriceToggle} marketPCPageNum={marketPCPageNum} priceSelect={priceSelect}>
                            </ConditionSearch>
                        </div>
                    </div>
                    <Input 
                        setMarketKeyWord={setMarketKeyWord} marketInputValue={marketInputValue} setMarketInputValue={setMarketInputValue}
                    ></Input>
                </div>
                <section className="forSaleContainer">
                    {
                        marketViewDataArray && true &&
                        marketViewDataArray.map((data, i) => 
                            (
                                <ForSale 
                                    key={i} 
                                    marketViewData      = {data} 
                                    marketKeyWord       = {marketKeyWord}
                                    asterPayments       = {asterPayments}
                                    marketLoading       = {marketLoading}
                                    setIsPaymentShow    = {setIsPaymentShow}
                                    setNeedPoint        = {setNeedPoint}
                                    setPaymentParams    = {setPaymentParams}
                                />
                            ))
                    }
                    {
                        forSaleWidth === false && 
                            <MoreButton disabled={marketPcLast} onClick={MarketMoreBtn}/>
                    }
                </section>
            </section>
            {isPaymentShow &&
                <Payment 
                    setIsPaymentShow    = {setIsPaymentShow}
                    asterPayment       = {asterPayments}
                    needPoint           = {needPoint}
                    paymentParams       = {paymentParams}
                >
                </Payment>
            }
            {!mobile() && <Footer/>}
        </div>
    )
}

export default MarketView;
