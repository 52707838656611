import React, { useLayoutEffect, useEffect, useState } from "react";
import { moveTopOnPageAuto } from "../apis/CommonFunc";
import SupportTemplate from "../components/SupportTemplate";
import BoardTemplate from "../components/Board/BoardTemplate";
import ModalLoading from "../components/Modal/ModalLoading";
import { axiosGet } from "../apis/AxiosAPI";
import { t } from "i18next";
import Footer from "../feature/Footer/Footer";
import PageBanner from "../components/PageBanner";
import PageTab from "../components/PageTab";

const Notice = () => {
    const [noticeList, setNoticeList] = useState([]);
    const [pageNum, setPageNum] = useState(1);
    const [totalCount, setTotalCount] = useState(0);

    
    const [isLoading, setIsLoading] = useState(true);

    //useLayoutEffect(() => {
    useEffect(() => {
        noticeSelect();
        moveTopOnPageAuto();
    }, [pageNum]);

    const PAGE_PER_NUM = 5;
    const noticeSelect = async () => {
        axiosGet(`notice/select?pageNum=${pageNum}&numPerPage=${PAGE_PER_NUM}`)
            .then(response => {
                setIsLoading(false);
                if(JSON.parse(response.data.result)){
                    setNoticeList(response.data.data);
                    setTotalCount(response.data.totalCount);
                } else {
                    alert(response.data.message);
                }
            }).catch(e => {
                console.log(`notice page error message: ${e}`);
            });
    }

    return (
        <>
        <SupportTemplate>
            {/*로딩 시 보여줄 progress bar */
                isLoading && 
                <ModalLoading>
                    Loading...
                </ModalLoading>
            }            
            <PageBanner style={{backgroundImage: "url('/images/bg/support_banner.jpg')"}}>{t("notice_title")}</PageBanner>            
            <PageTab category="support" type="notice"/>
            {noticeList.length !== 0 &&
                <div>
                    <BoardTemplate boardContents={noticeList} pageNum={pageNum} setPageNum={setPageNum} totalCount={totalCount} PAGE_PER_NUM={PAGE_PER_NUM} url='/notice'/>
                </div>
            }
        </SupportTemplate>
        <Footer/>
        </>
    )
}

export default Notice;