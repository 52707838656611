import React, { useState, useEffect } from 'react';
import './sideInfoMobile.css';
import { 
    landData,
    initMetarexAddressData,
    initAddressData,
    initAddressListData,
    requestBuyItem,
    krInitAddressData
} from './sideInfoUtil'
import { 
    AxiosUrl
} from '../kakaoMap/axiosUrl';
import ArrowDown from './arrow-down.png';
import { useTranslation } from "react-i18next";
import KakaoMapLoading from '../kakaoMap/KakaoMapLoading';

/**
 * 토지 및 메타렉스 상세정보
 */
const SideInfoM = (props) =>{

    const sideSearchKakaoLangM = localStorage.getItem("locale");

    const {t} = useTranslation();
    // 주소 정보
    const address = props.address;
    // 검색 키워드
    const keyword = props.keyword;
    // 검색 타입
    const searchType = props.searchType;
    // 메타렉스 주소 정보
    const [metarexAddress, setMetarexAddress] = useState(undefined);
    // 토지 및 메타렉스 건물 가격 정보
    const [land, setLand] = useState(undefined);
    // 토지 및 메타렉스 상세정보 열기, 닫기 여부
    const [isOpenSideInfo, setOpenSideInfo] = useState(true);
    // 토지 정보 열기 닫기 여부
    const [isOpenDetail, setOpenDetail] = useState(false);

    // 토지 및 메타렉스 정보 열기, 닫기 버튼 이벤트
    const clickSideInfoOpenClose = () => {
        setOpenSideInfo(!isOpenSideInfo);
    };

    // 토지 정보 열기 닫기 버튼 이벤트
    const clickDetailOpenClose = () => {
        setOpenDetail(!isOpenDetail);
    };

    // // 맵 클릭
    // useEffect(() => {
    //     if(!address) { return; }

    //     (async () => {
    //         const detail = address.address ? address.address.address_name : '  ';
    //         let res = await AxiosUrl(detail)
    //         setLand(landData(res.data));
    //         setOpenSideInfo(true);
    //     })();

    //     return () => {};
    // }, [address]);


    const [mapLoadingMobile, setMapLoadingMobile] = useState(false)

    const setClickedSoldoutItem = props.setClickedSoldoutItem;
    const keywordData = props.keywordData;
    
    useEffect(() => {

        setClickedSoldoutItem (
            () => () => {
                return true;
            }
        );
    },[props.keywordData]);

    
    const setIsPopupPurchaseRequisitionShow = props.setIsPopupPurchaseRequisitionShow;

    useEffect(()=>{
        if(!address) { return; }
        const mapLoadingAxios = async () => {
            try{
                setMapLoadingMobile(true)
                const detail = address.address ? address.address.address_name : '  ';
                await AxiosUrl(detail).then(res => setLand(landData(res.data)))
                setOpenSideInfo(true);
            }catch(e){
                console.log(e)
            }
            setMapLoadingMobile(false)
        }
        mapLoadingAxios()
        return () => {};
    },[address , setLand , setMapLoadingMobile , setOpenSideInfo])

    // URL 요청
    useEffect(() => {
        if(keyword && searchType === 'url') {
            (async () => {
                let res = await AxiosUrl(keyword)
                setLand(landData(res.data));
                setMetarexAddress(initMetarexAddressData(res.data));
            })();
        }

        return () => {};
    }, [keyword, searchType]);

    const KakaoLangAddress = localStorage.getItem("locale");
    
    return (
        <>
            {
                mapLoadingMobile === true &&
                    <KakaoMapLoading/>
            }
            { (address && land && searchType !== 'search') &&
                <section id={'side-info-m'} >
                    <button className={isOpenSideInfo ? 'side-info-open-close' : 'side-info-open-close close' } onClick={clickSideInfoOpenClose}>
                        <img src={ArrowDown} alt={'매물 정보 창 열기 및 닫기'} />
                    </button>
                    <article className={isOpenSideInfo ? '' : 'close'}>

                        {(keyword && searchType === 'url') && (
                            <>
                                { metarexAddress && <AddressInfo address={metarexAddress}/> }
                                { (metarexAddress && land ) && 
                                <LandInfo address={metarexAddress} land={land} isOpenDetail={isOpenDetail} clickDetailOpenClose={clickDetailOpenClose}
                                keywordData={keywordData}
                                setIsPopupPurchaseRequisitionShow={setIsPopupPurchaseRequisitionShow}
                                 /> }
                            </>
                        )}
                        
                        {(searchType === 'mapClick') && (
                            <>
                                { 
                                    (address && sideSearchKakaoLangM !== 'ko') ? 
                                    <AddressInfo address={initAddressData(address)}/> 
                                    :
                                    (address && sideSearchKakaoLangM === 'ko') &&
                                    <AddressInfo address={krInitAddressData(address)}/> 
                                }

                                { (land) && 
                                <LandInfo 
                                address={krInitAddressData(address)} 
                                land={land} 
                                isOpenDetail={isOpenDetail} 
                                clickDetailOpenClose={clickDetailOpenClose}
                                keywordData={keywordData}
                                setIsPopupPurchaseRequisitionShow={setIsPopupPurchaseRequisitionShow}
                                 /> }
                            </>
                        )}

                        {(searchType === 'list') && (
                            <>
                                { 
                                    (address && sideSearchKakaoLangM !== 'ko') ?
                                    <AddressInfo address={initAddressData(address)}/> 
                                :
                                    (address && sideSearchKakaoLangM === 'ko') &&
                                    <AddressInfo address={krInitAddressData(address)}/>
                                }
                                
                                {/* 이 부분 수정함 */}
                                { (land) && 
                                <LandInfo address={krInitAddressData(address)} land={land} isOpenDetail={isOpenDetail} clickDetailOpenClose={clickDetailOpenClose}
                                keywordData={keywordData}
                                setIsPopupPurchaseRequisitionShow={setIsPopupPurchaseRequisitionShow}
                                 /> }
                            </>
                        )}

                        {(searchType === 'search') && (
                            <>
                                { (address) && <AddressInfo address={initAddressListData(address)}/> }
                                { (land) && <LandInfo address={initAddressData(address)} land={land} isOpenDetail={isOpenDetail} clickDetailOpenClose={clickDetailOpenClose}
                                keywordData={keywordData}
                                setIsPopupPurchaseRequisitionShow={setIsPopupPurchaseRequisitionShow}
                         /> }
                            </>
                        )}
                    </article>
                </section>
            }

            { ((!keyword || keyword === '') && searchType === 'search') &&
                <section id={'side-info-m'}>
                    <button className={isOpenSideInfo ? 'side-info-open-close' : 'side-info-open-close close' } onClick={clickSideInfoOpenClose}>
                        <img src={ArrowDown} alt={'매물 정보 창 열기 및 닫기'} />
                    </button>
                    <article className={isOpenSideInfo ? '' : 'close'}>

                        {/* URL로 키워드가 전달된 경우 */}
                        {(keyword && searchType === 'url') && (
                            <>
                                { metarexAddress && <AddressInfo address={metarexAddress}/> }
                                { (metarexAddress && land) &&
                                <LandInfo address={metarexAddress} land={land} isOpenDetail={isOpenDetail} clickDetailOpenClose={clickDetailOpenClose}
                                keywordData={keywordData}
                                setIsPopupPurchaseRequisitionShow={setIsPopupPurchaseRequisitionShow}
                                 /> }
                            </>
                        )}

                        {/* 카카오맵 클릭시 */}
                        {(searchType === 'mapClick') && (
                            <>
                                { 
                                    (address && KakaoLangAddress !== 'ko') ? 
                                        <AddressInfo address={initAddressData(address)} />
                                    :
                                    (address && KakaoLangAddress === 'ko') &&
                                        <AddressInfo address={initAddressListData(address)} />
                                }
                                
                                { 
                                    (address && land) &&
                                        <LandInfo 
                                            address={krInitAddressData(address)} 
                                            land={land} 
                                            isOpenDetail={isOpenDetail} 
                                            clickDetailOpenClose={clickDetailOpenClose} 
                                            keywordData={keywordData}
                                            setIsPopupPurchaseRequisitionShow={setIsPopupPurchaseRequisitionShow}
                                        /> 
                                }
                            </>
                        )}
                        
                        {/* 주소 목록의 주소를 클릭한 경우 */}
                        {(searchType === 'list') && (
                            <>
                                { (address) && <AddressInfo address={initAddressListData(address)}/> }
                                { (address && land) &&
                                <LandInfo address={krInitAddressData(address)} land={land} isOpenDetail={isOpenDetail} clickDetailOpenClose={clickDetailOpenClose}
                                    keywordData={keywordData}
                                    setIsPopupPurchaseRequisitionShow={setIsPopupPurchaseRequisitionShow}
                                    /> }
                            </>
                        )}

                        {/* 상단 헤더 검색에서 주소를 검색한 경우  */}
                        {(searchType === 'search') && (
                            <>
                                {/* 맵클릭후 검색버튼 누르면 스타일 깨지는 현상 수정 */}
                                { 
                                    (address && sideSearchKakaoLangM !== 'ko') ?
                                    <AddressInfo address={initAddressData(address)}/> 
                                :
                                    (address && sideSearchKakaoLangM === 'ko') &&
                                    <AddressInfo address={krInitAddressData(address)}/>
                                }
                                {/* { (address) && <AddressInfo address={initAddressListData(address)}/> } */}
                                { (address && land) &&
                                    <LandInfo address={initAddressData(address)} land={land} isOpenDetail={isOpenDetail} clickDetailOpenClose={clickDetailOpenClose}
                                    keywordData={keywordData}
                                    setIsPopupPurchaseRequisitionShow={setIsPopupPurchaseRequisitionShow}
                                    />
                                }
                            </>
                        )}
                    </article>
                </section>
            }

            {/* URL로 키워드가 전달된게 아니고 처음 화면 로딩시 */}
            { (!address && !metarexAddress)  && 
                <span className='empty-selected'>{t("map_empty_title")}</span>
            }
        </>
    );
}; 

/**
 * 도로명, 지번 등 주소 정보
 */
const AddressInfo = (props) => {
    const {t} = useTranslation();

    const sideKakaoLangM = localStorage.getItem("locale");
    const landAddressM = props.address
    const [landInfoAdd, setLandInfo ] = useState("")
    
    useEffect( (e) => {
        async function EnMapInfoData(){
            const result = await landAddressM;
            setLandInfo(result)
        }
		EnMapInfoData();    
    },[landAddressM]);


    // 주소값 영문을 받아올때는 함수를 한번 거쳐서 오기에 경로가 다르다. 그렇기에 밑과 같이 조건을 줌.
    const enAddressIncludeM = sideKakaoLangM !== 'ko' ? landInfoAdd.address :
    landAddressM.address

    let checkArea = ['서울', '경기', '인천', '부산' , 'Seoul' , 'Gyeonggi' , 'Incheon' , 'Busan'];
    // 주소값과 위의 배열값을 비교. 주소값이 없으면 null처리
    const isInclude = checkArea.some(area => enAddressIncludeM ? enAddressIncludeM.includes(area) : null) 

    return (
        <> 
            {
                (sideKakaoLangM !== 'ko' && isInclude === true) &&
                    <dl>
                        <dt>{t("map_road_address")}</dt>
                        <dd className={'road'}>
                            {landInfoAdd.addressRoad ? landInfoAdd.addressRoad : " "}
                        </dd>
                        <dt>{t("map_address")}</dt>
                        <dd className={'lagacy'}>{landInfoAdd.address}</dd>
                    </dl>
            } 
            {
                (sideKakaoLangM === 'ko' && isInclude === true) &&
                        <dl>
                            <dt>{t("map_road_address")}</dt>
                            <dd className={'road'}>{landAddressM.addressRoad}</dd>
                            <dt>{t("map_address")}</dt>
                            <dd className={'lagacy'}>{landAddressM.address}</dd>
                        </dl>
            }
            {
                (isInclude === false)&& 
                    <strong>{t("map_no_include")}</strong>
            } 
        </>
    );
};

/**
 * 토지 및 메타렉스 가격 정보
 */
const LandInfo = (props) => {
    const {t} = useTranslation();
    const land = props.land;
    const isOpenDetail = props.isOpenDetail;
    const clickDetailOpenClose = props.clickDetailOpenClose;
    
    const landAddressM = props.address ? props.address : null;

    const [landInfoAddM, setLandInfo ] = useState("")

    useEffect( (e) => {
        async function EnMapInfoData(){
            const result = await landAddressM;
            setLandInfo(result)
        }
		EnMapInfoData();    
    },[landAddressM]);

    const landInfoKakaoLangM = localStorage.getItem("locale");

        // 주소값 영문을 받아올때는 함수를 한번 거쳐서 오기에 경로가 다르다. 그렇기에 밑과 같이 조건을 줌.

        const enAddressIncludeMInfo = landInfoKakaoLangM !== 'ko' ? landInfoAddM.addressRoad :
        landAddressM.address

        let checkAreaInfo = ['서울', '경기', '인천', '부산' , 'Seoul' , 'Gyeonggi' , 'Incheon' , 'Busan'];
        // 주소값과 위의 배열값을 비교. 주소값이 없으면 null처리
        
        const isIncludeInfo = checkAreaInfo.some(area => enAddressIncludeMInfo ? enAddressIncludeMInfo.includes(area) : null) 

        if(!isIncludeInfo) {
            land.resource = '-';
        }

    return (
        <> 
            <>
                <aside className={isOpenDetail ? '' : 'close'}>
                    <button onClick={clickDetailOpenClose}>
                        <span>{isOpenDetail ? t("map_info_close") : t("map_info_open")}</span>
                        <img src={ArrowDown} alt={'매물 정보 창 열기 및 닫기'} />
                    </button>

                    <ul>
                        <li><span>{t("map_land_size")}</span><span>{land.landSize} {t("map_per_pyeong")}</span></li>
                        <li><span>{t("map_build_age")}</span><span>{land.buildAge} {t("map_year")}</span></li>
                        <li><span>{t("map_floor_size")}</span><span>{land.floorSize} {t("map_floor")}</span></li>
                        <li><span>{t("map_resource")}</span><span>{t("map_resource_data")}</span></li>
                    </ul>

                    <div>
                        <strong>{t("map_land_price")}</strong>
                        <div>
                            <span>
                                { 
                                    !(land && land.landPrice) ? 
                                    <span>{t("map_empty_price")}</span> : 
                                    <span>
                                        {land.landPrice} 
                                        {t("map_land_price_area_won")}
                                    </span>
                                }
                            </span>
                            <span>
                                { 
                                    !(land && land.landPriceArea)  ? 
                                    "" 
                                    :
                                    <span> 
                                        {t("map_land_price_per_pyeong")}
                                        {land.landPriceArea}
                                        {t("map_land_price_area_won")}
                                    </span>
                                }
                            </span>
                        </div>
                    </div>
                </aside>
                <p>
                    <strong>{t("map_meta_land_price")}</strong>
                    <span>
                        <span>
                            { 
                                !(land &&  land.metaLandPrice) ? 
                                ""  : 
                                <span>
                                    {land.metaLandPrice}
                                    {t("map_land_price_area_won")}
                                </span>
                            }
                        </span>
                        <span>
                            { 
                                !(land && land.metaLandPriceArea) ? 
                                <span>{t("map_empty_price")}</span> 
                                :
                                <span> 
                                    {t("map_land_price_per_pyeong")}
                                    {land.metaLandPriceArea}
                                    {t("map_land_price_area_won")}
                                </span>
                            }
                        </span>
                    </span>
                </p>
                { 
                    ( land && land.metaLandPrice !== '0') && (land &&  land.landPriceArea !== "")  && 
                    <div>
                        <button className='button' onClick={() => requestBuyItem(props.address.address, props.keywordData, props.setIsPopupPurchaseRequisitionShow) }>
                        { typeof props.keywordData !== 'undefined' && JSON.parse(props.keywordData.isSoldout) ? JSON.parse(props.keywordData.isMine) ? "보유 매물" : t("make_purchase_request") : t("map_buy_btn")}
                        </button>
                    </div>
                }
            </>

            {/* {(!isIncludeInfo) &&
                <>
                    <button>
                        <span>{t("map_info_null")}</span>
                        <img src={ArrowDown} alt={'매물 정보 창 열기 및 닫기'} />
                    </button>
                    <p>
                        <strong>{t("map_meta_land_price")}</strong>
                        <span>
                            <span>{land.metaLandPrice}</span>
                            <span>{land.metaLandPriceArea}</span>
                        </span>
                    </p>
                </>
            } */}
        </>
    );
};

export default SideInfoM;
