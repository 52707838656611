import { ADD_USER, REMOVE_USER, UPDATE_USER_NAME } from "./types";

const initState = {};

const userReducer = (state = initState, action) => {
    switch(action.type) {
        case ADD_USER:
            return action.data;
        case REMOVE_USER:
            return action.data;
        case UPDATE_USER_NAME:
            return {...state, ['name'] : action.data}
        default: 
            return state;
    }
}

export default userReducer;