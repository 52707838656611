import React, { useLayoutEffect, useRef, useEffect, useState } from "react";
import { t } from "i18next";
import { Link } from "react-router-dom";
import { createRoot } from "react-dom/client";

import ReactLoading from 'react-loading';
import styled from "styled-components";
import { useLoadScript } from "@react-google-maps/api";
import { Wrapper } from "@googlemaps/react-wrapper";

import { isNull, isValidPassword } from "../../apis/Validation";
import { axiosGet, axiosPost } from "../../apis/AxiosAPI";
import { isSessionValidate, isValidationSessionId } from "../../apis/BrowserStorageAPI";
import { addComma, moveTopOnPageAuto } from "../../apis/CommonFunc";



const StyledGlobalLiveStreamikngTemplate = styled.div`
    width: 100vw;
    height: 100vh;
    @media only screen and (max-width: 768px) {
    }
    .my-google-map {
        padding-top: 70px;
		width: 100%;
		height: 100vh;
		background-color: rgb(229, 227, 223);

		.marker {
			position: relative;
			width: auto;
			height: auto;
			display: flex;
			flex-direction: column;
			border-radius: 5px 5px 0px 0px;
			border: 1px solid #222;
			background: rgba(0, 0, 0, 0.5);
			.item-icon {
				position: static;
				margin: 10px auto;
				width: 35px;
				height: auto;
                @media only screen and (max-width: 768px) {
				    width: 28px;
                }
			}
			.item-name {
				position: static;
				left: 0px;
				bottom: 0px;
				width: auto;
				height: auto;
				border: 1px solid #eee;
				text-align: center;
				padding: 5px;
				color: #222;
				background-color: rgba(255, 255, 255, 0.9);
                @media only screen and (max-width: 768px) {
                    font-size: 10px;
                }
			}
		}
		.custom-marker {
			width: 100px;
			height: 100px;
			background-color: green;
		}
		.info-window-content {
		}
    }

`;


{/*
const center = {
	lat: 37.8,
	lng: 126.90912041344816
};

const center = {
	lat: 35.65946674377283,
	lng: 139.70056228205175
};

*/}

const center = {
	lat: 40.75799148981769,
	lng: -73.98553274888572,
};

let markers = [];


const getCurrentTimestampAsMilliSeconds = () => {
    return Math.floor(Date.now() / 1);
}

const requestOffsetMilliSeconds = 1300;
let latestRequest = getCurrentTimestampAsMilliSeconds();

const getItemsOnScreen = async (map, setData) => {

    if( latestRequest + requestOffsetMilliSeconds > getCurrentTimestampAsMilliSeconds() ) {
        return false;
    }
    latestRequest = getCurrentTimestampAsMilliSeconds();

    if( !map ) {
		return false;
	}

    const MINIMUM_LEVEL = 3;

    // 지도의 현재 레벨을 얻어옵니다
    const level = map.getZoom();

	const bounds = map.getBounds();
	if( !bounds ) {
		return false;
	}
	if( !bounds.getNorthEast() ) {
		return false;
	}
	var areaBounds = {
		 southWestLongitude: bounds.getSouthWest().lng(),
		 southWestLatitude: bounds.getSouthWest().lat(),
		 northEastLongitude: bounds.getNorthEast().lng(),
		 northEastLatitude: bounds.getNorthEast().lat(),
	};

    {/* 매물이 많아지면 이 값을 줄여야 함*/}
    if( level < MINIMUM_LEVEL ) {
        return false;
    }

	deleteMarkers(map, setData);
    axiosGet(`api/realestate/specify-items-on-screen?southWestLongitude=${areaBounds.southWestLongitude}&southWestLatitude=${areaBounds.southWestLatitude}&northEastLongitude=${areaBounds.northEastLongitude}&northEastLatitude=${areaBounds.northEastLatitude}`)
        .then(response => {
            if(JSON.parse(response.data.result)){
				setData(response.data.data);
            } else {
                alert(response.data.message);
            }
        }).catch(e => {
            console.log(`notice page error message: ${e}`);
        });
}


function Boracamera({map, data, setData}) {


    return (
        <>
            {Object.entries(data).map(([key, item]) => (
                <Marker
                    key={key}
                    map={map}
                    position={
						{
							lat: item.latitude,
							lng: item.longitude,
						}
					}
                    dataVideoUrl={
                        item.videoList &&
                        item.videoList[0] &&
                        item.videoList[0].url &&
                            item.videoList[0].url
                    }
                >
                    <div className={`marker`}>
						<img className="item-icon" draggable="false" src="/images/icon/mapItem/bora_camera_thkim_20221111_1245.png?v=20221111" alt="" role="presentation" title="보라카메라" />
                        <h2 className="item-name">BORA CAMERA</h2>
                    </div>
                </Marker>
            ))}
        </>
    );
}

// Sets the map on all markers in the array.
function setMapOnAll(map) {
  for (let i = 0; i < markers.length; i++) {
    markers[i].map = map;
  }
}

// Removes the markers from the map, but keeps them in the array.
function hideMarkers(map, setData) {
	setData({});
  	setMapOnAll(null);
}

// Shows any markers currently in the array.
function showMarkers(map) {
  setMapOnAll(map);
}

// Deletes all markers in the array by removing references to them.
function deleteMarkers(map, setData) {
  hideMarkers(map, setData);
  markers = [];
}

function Marker({map, children, position, dataVideoUrl}) {
    const markerRef = useRef();
    const rootRef = useRef();



    useEffect(() => {
        if ( !rootRef.current) {
            const container = document.createElement("div");
            rootRef.current = createRoot(container);

            markerRef.current = new window.google.maps.marker.AdvancedMarkerView({
                position,
                content: container,
            });
			markers.push(markerRef.current);
        }
    }, []);

    let videoContents = ``;

    if( dataVideoUrl.indexOf("https://youtu.be") > -1 ) {
        const youtubeId = dataVideoUrl.replace("https://youtu.be/", "");
        videoContents +=
        `               <iframe style="background:black;"  width="220" height="176" src="https://www.youtube.com/embed/${youtubeId}?playlist=${youtubeId}&autoplay=1&mute=1&loop=1&controls=0" frameBorder="0">`+
        `               </iframe>`;
    } else {
        videoContents += `<h2> No Video </h2>`;
    }

	const contentString =
		'<div id="content" class="info-window-content">' +
        videoContents +
		"</div>";

	const infowindow = new window.google.maps.InfoWindow({
		content: contentString,
		ariaLabel: "Uluru",
	});

	const onClickMarker = () => {

		infowindow.open({
		  anchor: markerRef.current,
		  map,
		});
	};

    useEffect(() => {
        rootRef.current.render(children);
		markerRef.current.position = position;
		markerRef.current.map = map;
        const listener = markerRef.current.addListener("click", onClickMarker);
        return () => listener.remove();
    }, [map, position, children]);

}

const GlobalLiveStreaming = () => {


    useEffect(()=>{
    },[]);


    return (
        <>
            <StyledGlobalLiveStreamikngTemplate>
				<Wrapper
					apiKey={process.env.REACT_APP_PUBLIC_GOOGLE_MAPS_API_KEY}
					version="beta"
					libraries={["marker"]}
				>
					<MyMap />
				</Wrapper>
            </StyledGlobalLiveStreamikngTemplate>
        </>
    );
}


function MyMap() {
	const [map, setMap] = useState();
	const ref = useRef();

    const [data, setData] = useState({});

    const mapOptions = {
        mapId: process.env.REACT_APP_PUBLIC_GOOGLE_MAP_ID,
        center: center,
        //zoom: 6,
        zoom: 18,
        //mapTypeId: 'terrain',
        //mapTypeId: 'satellite',
        mapTypeId: window.google.maps.MapTypeId.HYBRID,
        //heading: 90,
        heading: 0,
        tilt: 45,
        zoomControl: false,
        scaleControl: true,
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUI: true,
        //gestureHandling: "none",
        keyboardShortcuts: false,
    }

	useEffect(() => {
		setMap( new window.google.maps.Map(ref.current, mapOptions) );
	}, []);

	useEffect(() => {
        if( map ) {
			map.addListener("zoom_changed", () => {
				getItemsOnScreen(map, setData);
			});
			getItemsOnScreen(map, setData);
			map.addListener("bounds_changed", () => {
				getItemsOnScreen(map, setData);
			});
        }
	}, [map]);

	return (
		<>
			<div ref={ref} id="map" className="my-google-map"/>
			{map && <Boracamera map={map} data={data} setData={setData} />}
		</>
	);
}

export default GlobalLiveStreaming;
