import React from "react";
import styled from "styled-components";
import { moveTopOnPageSmooth } from "../apis/CommonFunc";


// 맨 위로 버튼 컴포넌트
const ButtonTemplate = styled.button`
    position: fixed;
    bottom: 30px;
    right: 30px;
    width: 54px;
    height: 54px;
    border: 1px solid #05141F;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    cursor: pointer;

    opacity: .3;
    &:hover {
        opacity: 1;
    }
    span {
        width: 1px;
        height: 30px;
        background: #05141F;
        display: flex;
        align-items: center;
        justify-content: center;
        &::before {
            content: '';
            position: absolute;
            left: 15px;
            top: calc(50% - 10px);
            width: 13px;
            height: 1px;
            background: #05141F;
            transform: rotate(-45deg);
        }
        &::after {
            content: '';
            position: absolute;
            top: calc(50% - 10px);
            right: 14px;
            width: 13px;
            height: 1px;
            background: #05141F;
            transform: rotate(45deg);
        }
    }

    @media only screen and (max-width: 768px) {
        width: 40px;
        height: 40px;
        bottom: 10px;
        right: 10px;
        opacity: 1;
        span {
            height: 25px;
            &::before {
                content: '';
                left: 9px;
                top: calc(50% - 9px);
            }
            &::after {
                right: 8px;
                top: calc(50% - 9px);
            }
        }
    }
`;

const TopButton = () => {
    return (
        <ButtonTemplate onClick={moveTopOnPageSmooth}>
            <span/>
        </ButtonTemplate>
    );
}

export default TopButton;