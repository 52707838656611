import React from "react";
import styled from "styled-components";
import { mobile } from "../../apis/Validation";

const Template = styled.div`
    padding: 5px;
    margin-bottom: 50px;
    @media only screen and (max-width: 768px) {
        margin-bottom: 30px;
    }
    >p{
        font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
        font-size: 16px;
        font-weight: 700;
        line-height: 22px;
        text-align: left;
        margin-bottom: 16px;
        background-color: #f2f2f2;
        padding: 6px 3px;
    }
    >span{
        font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        text-align: left;
        word-break: keep-all;
    }
`;

const HowToUseItem = ({title, detail}) => {
    return (
        <Template>
            <p>{title}</p>
            <span dangerouslySetInnerHTML={{__html: mobile ? String(detail).replace('<br>', ' ') : detail}}/>
        </Template>
    );
}

export default HowToUseItem;