import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { t } from "i18next";

import { axiosGet, axiosPost } from '../../../../apis/AxiosAPI';
import { addComma } from "../../../../apis/CommonFunc";
import { isValidAstercoinAddr } from '../../../../apis/Validation';
import { isNull, isNumber } from "../../../../apis/Validation";

import { useInput } from "../../../../hooks/useInput";

const ModalDiv = styled.div`
    .enter-withdraw-data-wrapper {
        padding: 20px;
        .label-withdraw {
            display: block;
            font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 14px;
            color: #05141F;
            margin-bottom: 8px;
        }

        .value-point-balance {
            display: block;
            margin-bottom: 20px;
        }

        .select-your-deposit-exchange {
            width: 100%;
            height: 44px;
            background: #FFFFFF;
            border: 1px solid #EEEEEE;
            box-sizing: border-box;
            box-shadow: 0px 2px 2px rgb(144 144 144 / 20%);
            border-radius: 4px;
            margin-bottom: 20px;
        }

        .input-withdraw {
            margin-bottom: 20px;
            width: 100%;
            height: 48px;
            background: #FFFFFF;
            border: 1px solid #D9DDE2;
            box-sizing: border-box;
            padding: 5px;
        }
    }

`;

const WithdrawSelectExchange = ({ args, setArgs, setOnClickNextButtonPrevius}) => {

    const clientLocale = localStorage.getItem("locale");

    const  { updateInput } = useInput(setArgs);

    useEffect(() => {
        setOnClickNextButtonPrevius (
            () => () => {
                if(!(args['exchange-no'] === "1" || args['exchange-no'] === "2" || args['exchange-no'] === "3")){
                    alert(t("sales_registration_modal_confirm13"));
                    return false;
                }
                if(isValidAstercoinAddr( args['wallet-address'] ) === false){
                    alert(t("sales_registration_modal_confirm15"));
                    return false;
                }

                if( isNull(Number(args['withdraw-amount'])) ) {
                    alert(t("enter_withdraw_amount"));
                    return false;
                }

                if( args['withdraw-amount'] == '' ) {
                    alert(t("enter_withdraw_amount"));
                    return false;
                }
                if( isNumber(String(args['withdraw-amount']).replace(/,/gi, "")) == false ) {
                    alert('포인트 수량이 올바르지 않습니다.');
                    return false;
                }

                {/* withdrawAmount is 출금수량 */}
                const withdrawAmount    = Number(String(args['withdraw-amount']).replace(/,/gi, ""));
                {/* balanceAmount is 현재 보유 POINT */}
                const balanceAmount     = Number(args['balance-amount']);

                if( withdrawAmount > balanceAmount ) {
                    alert('출금할 포인트 수량이 보유포인트 수량보다 많습니다.');
                    return false;
                }
                const coinPriceOriginKrw    = Number(args['coin-price-origin-krw']);
                const dollarPriceKrw        = Number(args['dollar-price-krw']);

                if( isNumber(dollarPriceKrw) == false || dollarPriceKrw <= 0 ) {
                    alert('원달러 시세가 유효하지 않습니다. 잠시후 다시 시도해주시기 바랍니다.');
                    return false;
                }
                if( dollarPriceKrw * withdrawAmount < 100000 ) {
                    alert(t("alert_withdraw_point_price_min"));
                    return false;
                }


                axiosPost('api/mypage/point/withdraw-point/basic-information', args)
                .then(response => {
                    if(JSON.parse(response.data.result)) {
                        setArgs(prev => prev && {
                            ...prev,
                            ['uuid']: response.data.data,
                        });
                    } else {
                        alert(response.data.message);
                        if(!isNull(response.data.code) && response.data.code === "NO_HAVE_SHARES") {
                            window.location.href = window.location.pathname;
                        } else if(response.data.message === '로그인이 필요합니다.') {
                            localStorage.removeItem('sessionId');
                            window.location.href ='/signin';
                        }
                    }
                }).catch(e => {
                    console.log(`error message: ${e}`);
                });

                setArgs(prev => prev && {
                    ...prev,
                    ['withdraw-amount']: withdrawAmount,
                });

                return true;
            }
        );


    }, [args]);

    // 거래소 받아오는 axios 요청
    const [exchangeName, setExchangeName] = useState();
    const [hanbitcoIndex, setHanbitcoIndex] = useState(null);
    useEffect(()=>{
        axiosGet('api/sales/exchange-list')
        .then(res=> {
            console.log('thkim 20230413 %o', res.data);
            setExchangeName(res.data);
            let idx = null;
            for( idx in res.data.data) {
                if( String(res.data.data[idx].exchangeNameEn).toLocaleLowerCase() === 'hanbitco' ) {
                    setHanbitcoIndex(idx);
                }
            }
        })
        .catch(e => {
            console.log(`error message: ${e}`);
        });
    },[]);

    // 언어에 따라 경고창 표시를 변경하는 로직
    useEffect(()=>{
        if( isNull(hanbitcoIndex) == false ) {
            if(clientLocale === 'ko' && args['exchange-no'] === '1'){
                alert(exchangeName.data[hanbitcoIndex].selectionAlertKr)
            }else if(clientLocale === 'en' && args['exchange-no'] === '1'){
                alert(exchangeName.data[hanbitcoIndex].selectionAlertEn)
            }else if(clientLocale === 'cn' && args['exchange-no'] === '1'){
                alert(exchangeName.data[hanbitcoIndex].selectionAlertCn)
            }
        }

        if( typeof args['wallet-address'] == 'undefined' ) {
            setArgs(prev => prev && {
                ...prev,
                ['wallet-address']: ``,
            });
        }

        {/* 버튼을 누를 때, 거래소 영문 명을 저장 한다. */}
        const eleSelectedOption = document.querySelector(`option[value="${args['exchange-no']}"]`);
        if( isNull(eleSelectedOption) == false ) {
            const exchange = `${String(eleSelectedOption.getAttribute("data-exchange")).toLowerCase()}`;
            setArgs(prev => prev && {
                ...prev,
                ['exchange']: exchange,
            });

            {/* 거래소 선택에 따라 시세를 가져온다. */}
            if( isNull(exchange) == false && exchange !== 'null' ) {
                axiosGet(`api/price/atc?exchange=${exchange}`)
                .then(response => {

                    if( isNull(response.data.data.atc_krw) ) {
                        return false;
                    }

                    if( isNull(response.data.data.krw) ) {
                        return false;
                    }

                    if( isNaN( response.data.data.atc_krw ) ) {
                        return false;
                    }

                    setArgs(prev => prev && {
                        ...prev,
                        ['coin-price-krw']: Math.floor(Number(response.data.data.atc_krw)*10)/10,
                        ['coin-price-origin-krw']: Number(response.data.data.atc_krw),
                        ['coin-price-origin-usd']: Number(response.data.data.atc_usd),
                        ['dollar-price-krw']: Number(response.data.data.krw),
                        ['pricesNoDollar']: Number(response.data.data.pricesNoDollar),
                        ['pricesNoCryptocurrency']: Number(response.data.data.pricesNoCryptocurrency),
                    });

                    {/* balanceAmount is 보유 POINT 수량 */}
                    const balanceAmount    = Number(args['balance-amount']);

                    {/* dollarPriceKrw is 원달러시세 */}
                    const dollarPriceKrw        = Number(response.data.data.krw);

                    if( isNumber(dollarPriceKrw) == false || dollarPriceKrw <= 0 ) {
                        alert('원달러 시세가 유효하지 않습니다. 잠시후 다시 시도해주시기 바랍니다.');
                        return false;
                    }
                    if( dollarPriceKrw * balanceAmount < 100000 ) {
                        alert(t("alert_withdraw_point_price_min"));
                        return false;
                    }

                }).catch(e => {
                    console.log(`api/price/atc?exchange=${args['exchange']} error message: ${e}`);
                });
            }
        }





    },[args['exchange-no'] , clientLocale]);

    return (
        <ModalDiv>
            <div className="enter-withdraw-data-wrapper">
                <ul>
                    <li>
                        <span className="label-withdraw">{t('current_point_balance')}</span>
                        <span className="value-point-balance">{addComma( args['balance-amount'])}  POINT</span>
                    </li>
                    <li>
                        <span className="label-withdraw">{t("sales_registration_modal_exchange")}</span>
                        <select name="exchange-no" onChange={updateInput} className="select-your-deposit-exchange" id="select-your-deposit-exchange" value={args['exchange-no']}>
                            <option value="DEFAULT" disabled="disabled">{t("sales_registration_modal_exchange_select")}</option>
                            {
                                exchangeName &&
                                Object.keys(exchangeName.data).length > 0 &&
                                exchangeName.data && exchangeName.data.map((data , i)=>
                                <option key={i} data={data} value={exchangeName.data[i].exchangeNo} data-exchange={exchangeName.data[i].exchangeNameEn}>
                                {/* 언어에 따라 받아오는 데이터의 종류를 변경 */}
                                {
                                    clientLocale === 'ko' ? exchangeName.data[i].exchangeNameKr : exchangeName.data[i].exchangeNameEn
                                }
                                </option>
                            )}
                        </select>
                    </li>
                    <li>
                        <span className="label-withdraw">{t("sales_registration_modal_wallet_address")}</span>
                        <input className="input-withdraw input-wallet" placeholder={t("sales_registration_modal_confirm10")} onChange={updateInput} name="wallet-address" value={args['wallet-address']}></input>
                    </li>
                    <li>
                        <span className="label-withdraw">{t("enter_withdraw_amount")}</span>
                        <input className="input-withdraw input-withdraw-amount" placeholder={t("enter_withdraw_amount")} onChange={updateInput} name="withdraw-amount" value={args['withdraw-amount'] != 0 ? args['withdraw-amount'] : ''}></input>
                    </li>
                </ul>
            </div>
        </ModalDiv>
    )
}

export default WithdrawSelectExchange;
