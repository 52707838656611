import { removeUser } from "../redux/user/actions";
import { axiosGet, axiosPost } from "./AxiosAPI";

export const removeLocalStorage = () => {
    localStorage.removeItem('findIdMobile');
    localStorage.removeItem('authTokenMobile');
}

// 로컬스토리지에 sessionId 값이 존재하는지 체크하는 함수
export const isSessionValidate = () => {
    const target = 'sessionId';
    return localStorage.getItem(target) !== null && typeof localStorage.getItem(target) !== 'undefined' && localStorage.getItem(target) !== 'undefined';
}

// 세션이 유효한지 체크하는 API
export const isValidationSessionId = async () => {
    return axiosGet('api/user/isValidSessionId')
        .then(response => {
            if(!JSON.parse(response.data.result)) {
                alert(response.data.message);
                localStorage.removeItem('sessionId');
            } else {
                localStorage.setItem('sessionId', response.data.sessionId);
            }
        })
        .catch(e => console.log(e));
}

// 서버측의 sessionId를 삭제(만료)시키는 API
export const removeServerSessionId = async (dispatch) => {
    axiosPost('api/user/removeSessionId')
        .then(response => {
            if(JSON.parse(response.data.result)) {
                localStorage.removeItem('sessionId');
                dispatch(removeUser());     
            }
        }).catch(e => {
            console.log(`error message: ${e}`);
        });
}

// 서버에 유저 locale 정보를 저장하는 API
export const userLocaleToServer = async (locale) => {
    axiosPost(`api/user/localeBySessionId?locale=${locale}`)
        .then(response => {
            console.log('userLocaleServer')
        }).catch(e => {
            console.log(`error message: ${e}`);
        });
}