import React, { useLayoutEffect, useEffect, useState } from "react";
import styled from "styled-components";
import { t } from "i18next";

import { axiosGet, axiosPost } from '../../../../apis/AxiosAPI';
import { addComma } from "../../../../apis/CommonFunc";
import { isValidAstercoinAddr } from '../../../../apis/Validation';
import { isNull } from "../../../../apis/Validation";

import { useInput } from "../../../../hooks/useInput";

const ModalDiv = styled.div`

    .enter-withdraw-data-wrapper {
        padding: 20px;
        .li-result {
            padding-bottom: 16px;
            .label-result {
                display: block;
                font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
                font-style: normal;
                font-weight: 800;
                font-size: 14px;
                color: #05141F;
                margin-bottom: 8px;
                &::before {
                    content: '+ ';
                }
            }
            .value-result {
                display: block;
                padding-left: 15px;
                line-break: anywhere;
                font-weight: 300;
                font-size: 14px;
                &.value-result-emphasis {
                    font-weight: 700;
                    font-size: 16px;
                    color: red;
                }
            }
            .withdraw-warning {
                color: red;
            }
        }

    }

`;

const WithdrawSelectResult = ({ args, setArgs, setOnClickNextButtonPrevius}) => {

    const clientLocale = localStorage.getItem("locale");

    const  { updateInput } = useInput(setArgs);

    useLayoutEffect(()=>{
        console.log( 'args %o', args );
        setArgs(prev => prev && {
            ...prev,
            ['withdraw_cryptocurrency_amount']: Math.floor((Number(args['withdraw-amount'])/args['coin-price-origin-usd'])*10)/10,
        });

    }, []);

    useEffect(() => {
        setOnClickNextButtonPrevius (
            () => () => {
                if(window.confirm(`출금전 거래소 및 지갑주소를 꼭 확인하세요!\n잘못된 입금은 복구가 불가합니다.\n\n출금신청하시겠습니까?`)) {
                    axiosPost('api/mypage/point/withdraw-point/request', args)
                    .then(response => {
                        if(JSON.parse(response.data.result)) {
                            alert(response.data.message);
                            window.location.reload();
                        } else {
                            alert(response.data.message);
                            if(!isNull(response.data.code) && response.data.code === "NO_HAVE_SHARES") {
                                window.location.href = window.location.pathname;
                            } else if(response.data.message === '로그인이 필요합니다.') {
                                localStorage.removeItem('sessionId');
                                window.location.href ='/signin';
                            } else if( !isNull(response.data.href) ) {
                                localStorage.removeItem('sessionId');
                                window.location.href = response.data.href;
                            }
                        }
                    }).catch(e => {
                        console.log(`error message: ${e}`);
                    });
                } else {
                    return false;
                }
            }
        );
    }, [args]);

    return (
        <ModalDiv>
            <div className="enter-withdraw-data-wrapper">
                <ul>
                    <li className="li-result">
                        <span className="label-result">환산된 아스터코인 수량</span>
                        <span className="value-result value-result-emphasis">{Math.floor((Number(args['withdraw-amount'])/args['coin-price-origin-usd'])*10)/10} ATC</span>
                    </li>
                    <li className="li-result">
                        <span className="label-result">아스터코인 기준 시세</span>
                        <span className="value-result">{args['current-coin-price']}</span>
                    </li>
                    <li className="li-result">
                        <span className="label-result">원달러 기준 시세</span>
                        <span className="value-result">{args['dollar-price-krw']} KRW</span>
                    </li>
                    <li className="li-result">
                        <span className="label-result">거래소 / 지갑주소</span>
                        <span className="value-result">{args['exchange']} / {args['wallet-address']}</span>
                    </li>
                    <li className="li-result">
                        <span className="label-result withdraw-warning">!주의</span>
                        <span className="value-result withdraw-warning">!블록체인 특성상 사용자 부주의로 잘못 입금된 암호화폐는 복구가 불가능합니다.</span>
                    </li>




                </ul>
            </div>
        </ModalDiv>
    )
}

export default WithdrawSelectResult;
