import { t } from "i18next";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import '../../../components/animation.css';

const StyledTsnetTemplate = styled.div`
    display: flex;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const StyledTsnetInformationTemplate = styled.div`
    max-width: 1440px;
    width: 100%;
    height: auto;
    display: flex;
    margin-bottom: 200px;
    padding-top: 240px;
    .title {
        color: #05141F;
        font-family: 'Roboto';
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: 60px;
        letter-spacing: 0em;
        text-align: left;
        padding-left: 100px;
    }
    p {
        color: #666;
        font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 36px;
        letter-spacing: 0em;
        text-align: left;
    }


    @media only screen and (max-width: 768px) {
        max-width: 100%;
        flex-direction: column;
        margin-bottom: 0px;
        padding: 100px 20px;
        .title {
            padding-left: 0px;
            width: auto;
            margin-bottom: 80px;
        }
    }
`;

const StyledTsnetInformationDiv = styled.div`
    width: 50%;
    @media only screen and (max-width: 768px) {
        width: 100%;
    }
`;

const StyledTsnetAndTenspace = styled.div`
    position: relative;
    width: 100%;
    height: auto;
    margin-bottom: 100px;
    @media only screen and (max-width: 768px) {
        position: static;
    }
`;

const StyledLineDiv = styled.div`
    position: absolute;
    left: 0;
    top: 50%;
    height: 1px;
    width: 1150px;
    background-color: #05141F;
    &::before {
        content: " ";
        width: 1px;
        height: 5px;
        background-color: #05141F;
        position: absolute;
        left: calc(1150px - 2.5px);
        top: 0.5px;
        transform: rotate(45deg);
    }

    &::after {
        content: " ";
        width: 1px;
        height: 5px;
        background-color: #05141F;
        position: absolute;
        left: calc(1150px - 2.5px);
        top: -4px;
        transform: rotate(-45deg);
    }
    &.active {
        animation: leftIn 1.5s 0s;
        animation-fill-mode: both;
        &::before, &::after {
            animation: fadeIn .5s 1.5s;
            animation-fill-mode: both;
        }
    }

    @media only screen and (max-width: 768px) {
        position: relative;
        left: 50%;
        top: 0;
        height: 500px;
        width: 1px;
        &::before {
            width: 5px;
            height: 1px;
            left: 0;
            top: calc(500px - 2.5px);
            transform: rotate(-45deg);
        }
        &::after {
            width: 5px;
            height: 1px;
            position: absolute;
            left: -4px;
            top: calc(500px - 2.5px);
            transform: rotate(45deg);
        }

        &.active {
            width: 1px;
            &::before, &::after {
                animation: none;
            }
            animation: none;
        }

    }
`;

const StyledCircleWrap = styled.div`
    display: flex;
    top: 0px;
    left: 0px;
    padding: 0px;
    width: 100%;
    height: auto;
    .tenspace {
        mix-blend-mode: normal;
        position: relative;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 210px;
        top: -22px;
        left: 380px;
        p {
            width: 100%;
            color: #3D85F0;
            font-family: 'Roboto';
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 21px;
            letter-spacing: 0em;
            text-align: center;
            margin-bottom: 20px;
        }
        div{
            width: 212px;
            height: 212px;
            border-radius: 50%;
            background-color: #05141F;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
        }
    }

    .security {
        display: flex;
        justify-content: center;
        width:390px;
        height: 220px;
        position: relative;
        top: 22px;
        background-color: #fff;
        left: calc(380px + 100px);
        div{
            width: 212px;
            height: 212px;
            border-radius: 50%;
            background-color: rgba(243, 244, 245, 1);
            display: flex;
            justify-content: center;
            align-items: center;
            color: #05141F;
            font-family: 'Roboto';
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 21px;
            letter-spacing: 0em;
            text-align: center;
            mix-blend-mode: multiply;
            &:first-of-type {
                position: absolute;
                top: 0px;
                left: 0px;
            }
            &:last-of-type {
                position: absolute;
                top: 0px;
                left: 180px;
            }
        }
    }

    .tsnet {
        position: relative;
        top: 0px;
        left: 580px;
        .circle {
            div {
                &:first-of-type {
                    top: 0px;
                    left: 0px;
                    position: absolute;
                    text-indent: -9999px;
                    height: 258px;
                    width: 258px;
                    border: 1px solid #05141F;
                    border-radius: 50%;
                }
                &:last-of-type {
                    top: calc(50% - 87px);
                    left: 42px;
                    position: absolute;
                    height: 174px;
                    width: 174px;
                    border: 1px dashed #05141F;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    color: #05141F;
                    font-family: 'Roboto';
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 21px;
                    letter-spacing: 0em;
                    text-align: center;
                }    
            }
        }
        p {
            width: 100%;
            color: #3D85F0;
            position: absolute;
            left: 105px;
            top: 280px;
            font-family: 'Roboto';
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 21px;
            letter-spacing: 0em;
            text-align: center;
            margin-bottom: 20px;
        }
    }

    

    @media only screen and (max-width: 768px) {
        position: relative;
        height: 300px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .tenspace {
            position: relative;
            top: -500px;
            left: 0;
        }
        .security {
            width: 100%;
            position: relative;
            top: -450px;
            left: 0px;
            background-color: transparent;
            z-index: 2;
            div{
                width: 175px;
                height: 175px;
                &:first-of-type {
                    position: relative;
                    left: 10px;
                }
                &:last-of-type {
                    position: relative;
                    left: -8px;
                }
            }
        }

        .tsnet {
            position: relative;
            top: -430px;
            left: 0px;
            .circle {
                div {
                    &:first-of-type {
                        position: relative;
                    }
                    &:last-of-type {
                        top: 42px;
                        left: 42px;
                    }    
                }
            }
            p {
                position: relative;
                left: 0;
                top: 0;
                margin-top: 20px;
                margin-bottom: 0px;
            }
        }
    }
`;

const Tsnet = () => {
    const [scrollLocation, setScrollLoacation] = useState(0);
    const [fadeIn, setFadeIn] = useState(false);
    const divRef = useRef();

    const listener = () => {
        setScrollLoacation(window.pageYOffset);
    }

    useEffect(() => {
        window.addEventListener('scroll', listener);
        if(divRef.current.offsetTop < scrollLocation + window.innerHeight){
            setFadeIn(true);
        } else {
            setFadeIn(false);
        }
        return () => { window.removeEventListener('scroll', listener)}
    }, [scrollLocation]);

    return (
        <StyledTsnetTemplate>
            <StyledTsnetInformationTemplate>
                <StyledTsnetInformationDiv>
                    <p className="title">
                        TSNET<br/>
                        ASTER<br/>
                        AI block Chain<br/>
                        Project<br/>
                    </p>
                </StyledTsnetInformationDiv>
                <StyledTsnetInformationDiv>
                    <p>{t("metarex_future_tsnet_p_1")}</p>
                    <p>{t("metarex_future_tsnet_p_2")}</p>
                    <p>{t("metarex_future_tsnet_p_3")}</p>
                </StyledTsnetInformationDiv>
            </StyledTsnetInformationTemplate>

            <StyledTsnetAndTenspace ref={divRef}>
                <StyledLineDiv className={fadeIn ? "active" : ""}/>

                <StyledCircleWrap>
                    <div className="tenspace">
                        <p>Tenspace</p>
                        <div>
                            ASTER BANK<br/>
                            ASTER RM
                        </div>
                    </div>
                    <div className="security">
                        <div>
                            SECURITY ISSUE
                        </div>
                        <div>
                            COMPENSATION<br/>
                            ISSUE
                        </div>
                    </div>
                    <div className="tsnet">
                        <div className="circle">
                            <div>outer circle</div>
                            <div>AI Block<br/>Solution</div>
                        </div>
                        <p>Tsnet</p>
                    </div>
                </StyledCircleWrap>
            </StyledTsnetAndTenspace>
        </StyledTsnetTemplate>
    )
}

export default Tsnet;