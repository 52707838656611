import React, { useLayoutEffect, useEffect, useState } from "react";
import styled from "styled-components";
import { t } from "i18next";

import { axiosGet, axiosPost } from '../../../../apis/AxiosAPI';
import { addComma } from "../../../../apis/CommonFunc";
import { isValidAstercoinAddr } from '../../../../apis/Validation';
import { isNull } from "../../../../apis/Validation";

import { useInput } from "../../../../hooks/useInput";

const ModalDiv = styled.div`

    .enter-withdraw-data-wrapper {
        padding: 20px;
        li {
            .value-point-balance {
                display: block;
                margin-bottom: 20px;
                text-align: center;
                font-size: 14px;
                font-weight: 600;
            }
            .open-exchange-for-price-wrapper {
                text-align: center;
                .label-withdraw {
                    display: block;
                    font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 14px;
                    color: #05141F;
                    margin-bottom: 8px;
                }
            }            
        }
        

        
    
        .select-your-deposit-exchange {
            width: 100%;
            height: 44px;
            background: #FFFFFF;
            border: 1px solid #EEEEEE;
            box-sizing: border-box;
            box-shadow: 0px 2px 2px rgb(144 144 144 / 20%);
            border-radius: 4px;
            margin-bottom: 20px;
        }
        
        .input-price-readonly {
            margin-bottom: 3px;
            width: 100%;
            height: 48px;
            background: #f2f2f2;
            border: 1px solid #b0b0b0;
            box-sizing: border-box;
            font-size: 18px;
            font-weight: 700;
            text-align: center;
            padding: 5px;
        }

        .play {
            margin-top: 0px;
            width: calc(100% - 0px);
            display: block;
            text-align: center;
            margin-bottom: 40px;            
            .guide_text {
                width: 108px;
                font-size: 12px;
                font-family: 'Noto Sans KR';
                color: #909090;
                margin-top: 10px;
            }

            .play-and-stop-button {
                width: 60px;
                height: 60px;
                background-color: #D2465A;
                border-radius: 30vw;
                position: relative;
                display: inline-block;
                margin-top: 20px;
                cursor: pointer;
                .play-shape {
                    width: 0px;
                    height: 0px;
                    border-top: 8px solid transparent;
                    border-bottom: 8px solid transparent;
                    border-left: 14px solid #fff;
                    display: inline-block;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    margin-top: -8px;
                    margin-left: -4px;
                }
                .stop-shape {
                    width: 16px;
                    height: 16px;
                    background-color: #fff;
                    display: inline-block;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    margin-top: -8px;
                    margin-left: -8px;
                }
            }
        }

        .coin-price-note {
            font-size:12px;
            color: #D2465A;
            padding: 6px 20px;
            li {
                list-style-type: circle;
            }
        }

        .going-to-check-coin-price {
            padding-left: 0px;
            text-decoration: underline;
        }
    }
    
`;

const WithdrawSelectPrice = ({ args, setArgs, setOnClickPreviusButtonPrevius, setOnClickNextButtonPrevius}) => {

    const clientLocale = localStorage.getItem("locale");

    const  { updateInput } = useInput(setArgs);

    const [isPlaying , setIsPlaying] = useState(isNull(args['selected-coin-price']) ? true : false);

    useEffect(() => {

        setOnClickPreviusButtonPrevius (
            () => () => {

                if( timerId != null ) {
                    clearInterval( timerId );
                    setTimerId( null );
                }
                return true;
            }
        );
        setOnClickNextButtonPrevius (
            () => () => {

                if( isNull(args['selected-coin-price']) ) {
                    alert('시세가 결정되지 않았습니다.');
                    return false;
                }


                if( timerId != null ) {
                    clearInterval( timerId );
                    setTimerId( null );
                }
                return true;
            }
        );
    }, [args]);

    const [timerId , setTimerId] = useState(null);

    useLayoutEffect(()=>{
        if( args['exchange'] == 'hanbitco' ) {
            document.querySelector('#going-to-check-coin-price').setAttribute('href', 'https://hanbitco.com/exchange/ATC-BTC');
        } else if( args['exchange'] == 'bitglobal' ) {
            document.querySelector('#going-to-check-coin-price').setAttribute('href', 'https://www.bitglobal.com/en-us/spot/trade?q=ATC-USDT');
        } else if( args['exchange'] == 'mexc' ) {
            document.querySelector('#going-to-check-coin-price').setAttribute('href', 'https://www.mexc.com/exchange/ATC_USDT');
        }

        if( isPlaying ) {
            시세요청하기();
            setTimerId(setInterval(시세요청하기, 2000));
        }

    }, []);
    
    function 시세요청하기() {

        if( isNull(args['exchange']) ) {
            return false;
        }
        
        setArgs(prev => prev && {
            ...prev,
            ['current-coin-price']: 0,
        });

        axiosGet(`api/price/atc?exchange=${args['exchange']}`)
        .then(response => {

            if( isNull(response.data.data.atc_krw) ) {
                return false;
            }
            if( isNull(response.data.data.krw) ) {
                return false;
            }
            localStorage.removeItem('coin-price-krw');
            localStorage.setItem('coin-price-krw', Math.floor(Number(response.data.data.atc_krw)*10)/10 );
            localStorage.removeItem('dollar-price-krw');
            localStorage.setItem('dollar-price-krw', Number(response.data.data.krw) );

            setArgs(prev => prev && {
                ...prev,
                ['coin-price-krw']: Math.floor(Number(response.data.data.atc_krw)*10)/10,
                ['coin-price-origin-krw']: Number(response.data.data.atc_krw),
                ['coin-price-origin-usd']: Number(response.data.data.atc_usd),
                ['dollar-price-krw']: Number(response.data.data.krw),
                ['pricesNoDollar']: Number(response.data.data.pricesNoDollar),
                ['pricesNoCryptocurrency']: Number(response.data.data.pricesNoCryptocurrency),
            });

            if( isNaN( response.data.data.atc_krw ) == false ) {
                코인시세보여주기( response.data.data.atc_krw );
            }

        }).catch(e => {
            console.log(`sign up mobile error message: ${e}`);
        });


    }


    function 코인시세보여주기( 가격 ) {
        //$('#the-coin-price').html( `${Math.floor(Number(가격)*10)/10} KRW` );

        setArgs(prev => prev && {
            ...prev,
            ['current-coin-price']: `${Math.floor(Number(가격)*10)/10} KRW`,
        });
    }


    const onClickPlayAndStopButton = () => {
        if( isPlaying ) {
            playStatus();
        } else {
            stopStatus();
        }
        setIsPlaying(!isPlaying);
        
    }



    function playStatus() {
        console.log('im playStatus');
        if( timerId != null ) {
            
            console.log('im playStatus timerId != null');
            const selectedPrice = Number(String(localStorage.getItem('coin-price-krw')).replace(/,| |[a-zA-Z]/g, ''));

            console.log('selectedPrice[%o]', selectedPrice);
            if( isNaN(selectedPrice) || selectedPrice == 0 ) {
                console.log( `if( isNaN(selectedPrice) || selectedPrice == 0 ) {` );
                return false;
            }
            clearInterval( timerId );
            setTimerId( null );
            if( selectedPrice > 0) {
                setArgs(prev => prev && {
                    ...prev,
                    ['selected-coin-price']: args['coin-price-krw'],
                });
            }
        }
    }

    function stopStatus() {
        if( timerId == null ) {
            시세요청하기();
            setTimerId(setInterval(시세요청하기, 2000));

            setArgs(prev => prev && {
                ...prev,
                ['selected-coin-price']: null,
            });
        }
    }

    

    return (
        <ModalDiv>
            <div className="enter-withdraw-data-wrapper">
                <ul>
                    <li>
                        <span className="value-point-balance">정지버튼을 눌러 시세를 결정해주세요.</span>
                    </li>
                    <li>                    
                        <div className="play">
                            <div className="play-button-box play-status">
                                {isPlaying ?
                                    <div className="play-and-stop-button stop-button" onClick={onClickPlayAndStopButton} >
                                        <span className="stop-shape"></span>
                                    </div>
                                    :
                                    <div className="play-and-stop-button play-button" onClick={onClickPlayAndStopButton} >
                                        <span className="play-shape"></span>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="open-exchange-for-price-wrapper">
                            <span className="label-withdraw"><a id="going-to-check-coin-price" className="going-to-check-coin-price" href="https://www.bitglobal.com/en-us/spot/trade?q=ATC-USDT" target="_blank">거래소에서 시세 확인하기 (링크)</a></span>
                        </div>
                        <input className="input-price-readonly" readOnly  placeholder="Loading..." onChange={updateInput} name="current-coin-price" value={args['current-coin-price'] != 0 ? args['current-coin-price'] : ''}></input>
                        <ul className="coin-price-note">
                            <li><span className="exchange-name">{String(args['exchange']).toUpperCase()} 거래소</span>에서 제공하는 API에서 제공된 시세입니다.</li>
                            <li>1분정도의 시세 차이가 있을 수 있습니다.</li>
                        </ul>
                    </li>
                </ul>
            </div>
        </ModalDiv>
    )
}

export default WithdrawSelectPrice;