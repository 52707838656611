import React from 'react'; 
import ReactLoading from 'react-loading'; 
import './KakaoMapLoading.css'

function KakaoMapLoading() {
    return (
                <div className='kakaoMapLoadingContainer'> 
                    <div> 
                        {/* <h2>{message}</h2>  */}
                        <h2>Loading...</h2> 
                        <ReactLoading type={'spin'} color={'skyblue'} height={'80%'} width={'80%'} /> 
                    </div> 
                </div> 
            ); 
} 
                    
export default KakaoMapLoading;
