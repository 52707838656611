import React from "react";

import InformationMetarex from "./InformationMetarex";
import PayWithAsterCoin from "./PayWithAsterCoin";

const Footer = () => {
    return (
        <footer>
            <PayWithAsterCoin/>
            <InformationMetarex/>
        </footer>
    )
}

export default Footer;