import React from "react";
import ReactLoading from 'react-loading'; 
import styled from "styled-components";
import ItemCard from "./ItemCard";

const StyledSlideTemplate = styled.div`
    width: 1440px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 10px;
    .container {
        width: 100%;
        overflow: hidden;
        .slide {
            display: flex;
            align-items: center;
            .cardList {
                width: 100%;
                height: 800px;
                display: flex;
                flex-wrap: wrap;
            }
        }
    }
    @media only screen and (max-width: 768px) {
        max-width: 375px;
        width: 100%;
        height: auto;
        padding: 0px 20px;
        .container {
            width: 100%;
            .slide {
                .cardList {
                    width: 100%;
                    height: auto;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
        margin-bottom: 30px;
    }
`;

const LoadingProgressBarDiv = styled.div`
    width: 100%;
    height: calc(100% - 30px);
    background: rgba(0, 0, 0, .1);
    display: flex;
    justify-content: center;
    align-items: center;
`;


const Slide = ({ asterPayments, dataList }) => {
    return (
        <StyledSlideTemplate>
            <div className="container">
                <div className="slide">
                    <div className="cardList">
                        {
                            dataList.length !== 0 
                                ? dataList.map((card, index) => <ItemCard asterPayments={asterPayments} key={index} item={card}/>)
                                : 
                                <LoadingProgressBarDiv>
                                    <ReactLoading type={'spin'} color={'skyblue'} height={'40px'} width={'40px'} />
                                </LoadingProgressBarDiv>
                        }
                    </div>
                </div>
            </div>
        </StyledSlideTemplate>
    )
}

export default Slide;