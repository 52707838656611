import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { t } from "i18next";

import PaymentEnterAmount from './PaymentEnterAmount';
import PaymentSelectMethod from './PaymentSelectMethod';
import PaymentCardInfomation from './PaymentCardInfomation';
import PaymentComplete from './PaymentComplete';


const PaddingDiv = styled.div`
    padding: 20px;
`;

const ModalDiv = styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0px;
    top: 0px;
    background: rgba(0,0,0,0.7);
    padding: 0px;
    z-index:1000;
`;

const ContentDiv = styled.div`
    width: 320px;
    height: auto;
    min-height: 568px;
    background: rgba(255,255,255);
    padding: 0px;
    margin: 0px auto;
    position: relative;
    .withdraw-caution {
        font-size: 12px;
        padding: 0px 20px;
        color: #7824ff;
    }
`;

const PrevBtnSpan = styled.span`
    position: absolute;
    top: 10px;
    left: 20px;
    width: 27px;
    height: 27px;
    cursor: pointer;
    &::before {
        content: ' ';
        position: absolute;
        width: 13px;
        height: 1px;
        top: calc(100% - 9px);
        left: 0px;
        background: #000;
        transform-origin: center;
        transition: .3s;
        transform: rotate(45deg);
    }
    &::after{
        content: ' ';
        position: absolute;
        width: 13px;
        height: 1px;
        background: #000;
        top: calc(0% + 9px);
        left: 0px;
        transform-origin: center;
        transition: .3s;
        transform: rotate(-45deg);
    }

    &:hover {
        &::before {
            transform: rotate(0.625turn);
        }
        &::after{
            transform: rotate(-0.625turn);
        }
    }

`;

const CloseBtnSpan = styled.span`
    position: absolute;
    top: 10px;
    right: 10px;
    width: 27px;
    height: 27px;
    cursor: pointer;
    &::before {
        content: ' ';
        position: absolute;
        width: 27px;
        height: 1px;
        top: calc(50% - 1px);
        background: #000;
        transform-origin: center;
        transition: .3s;
        transform: rotate(45deg);
    }
    &::after{
        content: ' ';
        position: absolute;
        width: 27px;
        height: 1px;
        background: #000;
        top: calc(50% - 1px);
        transform-origin: center;
        transition: .3s;
        transform: rotate(-45deg);
    }

    &:hover {
        &::before {
            transform: rotate(0deg);
        }
        &::after{
            transform: rotate(-0deg);
        }
    }

`;

const TitleDiv = styled.div`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    padding: 20px 0px;
    border-bottom: 3px solid #DFE1E5;
`;

const NextButton = styled.button`
    margin-top: 0px;
    background-color: #D2465A;
    text-align: center;
    line-height: 50px;
    border-radius: 4px;
    border-style: solid;
    border-width: 0px;
    width: 100%;
    height: 50px;
    color: #fff;
    display: inline-block;
    font-family: 'Noto Sans KR';
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
`;

const Payment = ({setIsPaymentShow, asterPayment, children, needPoint, paymentParams}) => {

    const [currentStatus , setCurrentStatus] = useState(0);
    const enumStatusIndex =
    {
        enterPrice      : 0,
        selectMethod    : 1,
        enterCardInfo   : 2,
        complete        : 3,
    };
    const enumStatus =
    [
        {
            pageID                  : "enterPrice",
            title                   : "point_title",
            bottomButtonLabel       : "next",
        },
        {
            pageID                  : "selectMethod",
            title                   : "point_payment_method_title",
            bottomButtonLabel       : "next",
        },
        {
            pageID                  : "enterCardInfo",
            title                   : "Card infomation",
            bottomButtonLabel       : "next",
        },
        {
            pageID                  : "complete",
            title                   : "Complete",
            bottomButtonLabel       : "Done",
        }
    ];


    // 구매 정보 JSON
    const [args , setArgs] = useState(
        {
            ['amount']: needPoint && true ? needPoint : 0,
            ['expiration-month']: "0",
            ['expiration-year']: "0",
            paymentParams,
        }
   );

   {/*하단 다음 버튼을 누르기 전에 유효성 체크를 위해 각 component에서 재 정의할 수 있도록 지원한다. 해당 function은 true를 리턴해야 다음 화면으로 넘어간다.*/}
   const [onClickNextButtonPrevius , setOnClickNextButtonPrevius] = useState(() => function() {
       return true;
   });

    useEffect(()=>{
        console.log('args %o', args);
    },[args]);

    const closeModal = (e) => {
        //새로고침하기
        e.preventDefault();
        setIsPaymentShow(false);
    }

    const onClickPreviusButton = () => {
        if( currentStatus > 0 ) {
            setCurrentStatus(prev => prev - 1);
        }
    }


    const onClickNextButton = () => {

        if( onClickNextButtonPrevius() ) {

            if( currentStatus < enumStatusIndex.complete ) {
                setCurrentStatus(prev => prev + 1);
            } else {
                setIsPaymentShow(false);
            }
        }
    }



    useEffect(() => {
        return () => {
        }
    }, [])


    return (
        <ModalDiv>
            <ContentDiv>
                {currentStatus !== 0 &&
                    <PrevBtnSpan onClick={onClickPreviusButton}/>
                }
                <CloseBtnSpan onClick={closeModal}/>
                <TitleDiv>
                    {t(enumStatus[currentStatus].title)}
                </TitleDiv>
                {currentStatus === enumStatusIndex.enterPrice &&
                    <PaymentEnterAmount
                    args                        = {args}
                    setArgs                     = {setArgs}
                    setOnClickNextButtonPrevius = {setOnClickNextButtonPrevius}
                    >
                    </PaymentEnterAmount>
                }
                {currentStatus === enumStatusIndex.selectMethod &&
                    <PaymentSelectMethod
                    args                        = {args}
                    setArgs                     = {setArgs}
                    setOnClickNextButtonPrevius = {setOnClickNextButtonPrevius}
                    asterPayment               = {asterPayment}
                    >
                    </PaymentSelectMethod>
                }
                {currentStatus === enumStatusIndex.enterCardInfo &&
                    <PaymentCardInfomation
                    args                        = {args}
                    setArgs                     = {setArgs}
                    setOnClickNextButtonPrevius = {setOnClickNextButtonPrevius}
                    >
                    </PaymentCardInfomation>
                }
                {currentStatus === enumStatusIndex.complete &&
                    <PaymentComplete
                        args                        = {args}
                        setArgs                     = {setArgs}
                        setOnClickNextButtonPrevius = {setOnClickNextButtonPrevius}
                    >
                    </PaymentComplete>
                }

                {children}
                <p className="withdraw-caution">
                    {t('withdraw_caution')}
                </p>
                {currentStatus <= enumStatusIndex.complete &&
                <PaddingDiv>
                    <NextButton type="button" onClick={() => onClickNextButton()}>{t(enumStatus[currentStatus].bottomButtonLabel)}</NextButton>
                </PaddingDiv>
                }

            </ContentDiv>
        </ModalDiv>
    )
}

export default Payment;
