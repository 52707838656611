import React from "react";
import Astercoin from "../../feature/MetaRex/future/Astercoin";
import Awesomepia from "../../feature/MetaRex/future/Awesomepia";
import Tenspace from "../../feature/MetaRex/future/Tenspace";
import Tsnet from "../../feature/MetaRex/future/Tsnet";
import OpenPPL from "../../feature/MetaRex/future/OpenPPL";
import AwesomepiaService from "../../feature/MetaRex/future/AwesomepiaService";
import MetarexTemplate from "../../feature/MetaRex/MetarexTemplate";
import PageBanner from "../../components/PageBanner";
import PageTab from "../../components/PageTab";

const Future = () => {
    return (
        <>
        <MetarexTemplate>
            <PageBanner style={{backgroundImage: "url('/images/bg/metarex_bg.png')"}}>The world’s first Virtual Real Estate Platform<br/>linked to the Real Economy</PageBanner>
            <PageTab category="metarex" type="future" />

            <Awesomepia />
            <AwesomepiaService />
            <Tenspace />
            <Astercoin />
            <Tsnet />
            <OpenPPL />
        </MetarexTemplate>
        </>
    )
}

export default Future;