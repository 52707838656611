import React, { useState ,  useEffect } from 'react';
import { t } from 'i18next';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";

import { axiosPost } from '../../apis/AxiosAPI';
import { addComma } from '../../apis/CommonFunc';

import './Paying.css'

function PayingBoxData({data}){
    const payingLang = localStorage.getItem("locale");
    
    // 취소버튼
    const payingRemove = (data) => {
        if(window.confirm("취소하시겠습니까?")){
            const requestItemParams = {
                realestateNo: data.realestateNo,
                insertId: data.insertId,
            };        
            let payingRemoveUrl =  `api/realestate/paying-cancel`
            axiosPost(payingRemoveUrl , requestItemParams )
            .then(response => {
                alert(response.data.message);
                if(JSON.parse(response.data.result)) {
                    window.location.href = '/BuyHistory';
                }
            }).catch(e => {
                console.log(`error message: ${e}`);                
            });
        }
    }
    return(
        <div className="payingBoxContainer">
            <ul>
                <li>
                    {
                        (data.equityRatioHolding || data.totalIssuance) ?
                            <span> {data.equityRatioHolding}/{data.totalIssuance} </span>
                            :
                            <div></div>
                    }
                </li>
                <li>
                    {
                        (data.eventStartDate || data.eventEndDate	) ?                    
                            <span>{t("buy_history_event_period")} {data.eventStartDate} ~ {data.eventEndDate}</span>
                            :
                            <div></div>
                    }
                </li>
                <li>
                    <span>{t("buy_history_price")} : </span>
                    <span>{`${addComma(data.priceMetarexAsk)} ${t("map_land_price_area_won")}`}</span>
                </li>
                <li>
                    <span>{t("buy_history_address")} : </span>
                    {
                        payingLang === "ko" 
                            ? <a href={data.link} >{data.addressJibun}</a>
                            : <a href={data.link} >{data.addressJibunEn}</a>
                    }
                </li>
                <li>
                    {   data.areaSter &&
                        <>
                            <span>{t("buy_history_area")} : </span>
                            <span>{data.areaSter && addComma(data.areaSter)} ster </span>
                        </>
                    }
                </li>
                <li>
                    {
                        data.paymentStartedTs &&
                        <span>{t("buy_history_date_of_purchase")} : {data.paymentStartedTs}</span>
                    }
                </li>
                <li>   
                    {
                        data.exchangeNameKr &&
                        payingLang === "ko" 
                            ? <span>{t("sales_registration_modal_exchange")} : {data.exchangeNameKr}</span>
                            : <span>{t("sales_registration_modal_exchange")} : {data.exchangeNameEn}</span>
                    }
                </li>
                <li>
                    {
                        data.depositWalletAddr &&
                        <>
                            <span>{t("sales_registration_modal_wallet_address")} : </span>
                            <div>{data.depositWalletAddr}</div>                    
                        </>
                    }
                </li>
            </ul>
            <button onClick={() => payingRemove(data)} className='payingCancleBtn'>{t("sales_registration_sales_cancle_btn")}</button>
        </div>
    )
} 

const Paying = (props) => {
    let payingData = props.historyDatas && props.historyDatas
    let buyHistoryWidth = props.buyHistoryWidth

    // 클릭개수
    let payingMobileClick= props.payingMobileClick
    let setPayingMobileClick= props.setPayingMobileClick
    
    let payingPcClick = props.payingPcClick
    let setPayingPcClick = props.setPayingPcClick

    // 총개수
    let payingTotalCount = props.payingTotalCount
    
    // 페이징 pc
    let setPayingPagePcNum= props.setPayingPagePcNum
    let payingPagePcNum = props.payingPagePcNum

    // 페이징 모바일
    let payingPageMobileNum = props.payingPageMobileNum
    let setPayingPageMobileNum = props.setPayingPageMobileNum

    const [payingLeftLast , setPayingLeftLast ] = useState(false)
    const [payingRightLast , setPayingRightLast ] = useState(false)

    // pc나 모바일일때 클릭값과 받아온 페이지 수를 1로 초기화 해줌. 받아온 매물개수가 다르기에 해당 로직
    useEffect(() => {
        if(buyHistoryWidth === false){
            setPayingMobileClick(1)
            setPayingPageMobileNum(1)
        }else if(buyHistoryWidth === true){
            setPayingPcClick(1)
            setPayingPagePcNum(1)
        }
    })

    // slide 왼쪽 슬라이드 버튼 disable 로직
    useEffect(()=>{
        if(payingMobileClick === 1 && buyHistoryWidth === true){
            setPayingLeftLast(true)
        }else if(payingPcClick === 1 && buyHistoryWidth === false){
            setPayingLeftLast(true)

        }else if(payingMobileClick > 1 && buyHistoryWidth === true){
            setPayingLeftLast(false)
        }else if(payingPcClick > 1 && buyHistoryWidth === false){
            setPayingLeftLast(false)
        }
    },[payingMobileClick  , setPayingLeftLast , payingPcClick , buyHistoryWidth])

    // slide 오른쪽 슬라이드 버튼 disable 로직
    useEffect(()=>{
        // pc
        if(Math.ceil(payingTotalCount / 3) > payingPcClick && buyHistoryWidth === false ){
            setPayingRightLast(false)
        }else if((Math.ceil(payingTotalCount / 3) <= payingPcClick) && buyHistoryWidth === false){
            setPayingRightLast(true)
        
            // 모바일
        }else if((payingTotalCount > payingMobileClick) && buyHistoryWidth === true){
            setPayingRightLast(false)
        }else if((payingTotalCount === payingMobileClick) && buyHistoryWidth === true){
            setPayingRightLast(true)
        }
    },[payingMobileClick  , setPayingRightLast , payingTotalCount , payingPcClick , buyHistoryWidth])

    // 위에서 disable처리
    // pc와 모바일 따로 클릭값을 준다. 받아오는 매물의 개수가 다르기때문.
    // 클릭값이 1이 아닐때 1씩 빼줘서 페이지수를 조절. 
    let payingPrevSlide = (e) => {
        if(payingMobileClick !== 1 && buyHistoryWidth === true){
            setPayingPageMobileNum(payingPageMobileNum - 1)
            setPayingMobileClick(payingMobileClick -1)
        }else if(payingPcClick !== 1 && buyHistoryWidth === false){
            setPayingPagePcNum(payingPagePcNum - 1)
            setPayingPcClick(payingPcClick - 1)
        }
    }

    //  pc일때는  매물개수가 3개여서 총 개수에 3을 나눠준뒤, 그 값이 클릭값보다 클때 +1씩 더해준다.
    // 모바일일때는 매물개수가 하나여서 나누지 않고 처리 
    let payingNextSlide = (e) => {
        if(Math.ceil(payingTotalCount / 3) > payingPcClick && buyHistoryWidth === false){
            setPayingPagePcNum(payingPagePcNum + 1)
            setPayingPcClick(payingPcClick + 1)
        }else if(payingTotalCount > payingMobileClick && buyHistoryWidth === true){
            setPayingPageMobileNum(payingPageMobileNum + 1)
            setPayingMobileClick(payingMobileClick + 1)
        }
    }
    
    return(
        <section className="payingContainer">
            <h1 className="payingTitle">{t("buy_history_buying")}</h1>
            {  (payingData && payingData.length === 0) && 
                <div className="payingDataNoneTitleContainer">
                    <h1 className="payingDataNoneTitle">{t("sales_registration_modal_confirm11")} </h1>
                </div>
            }
            <div className="payingSlideContainerWrap">
                <div className="payingSlideContainer" >
                    { payingData && payingData.map((data , i)=> 
                        <PayingBoxData key={i} data={data}/>
                    )}
                </div>
            </div>
            <div className="payingpayingBtnContainer">
                {
                    (payingLeftLast === true) ?
                    <button type="submit" className="payingLeftBtnNo" disabled>
                        <FontAwesomeIcon icon={faChevronLeft}/>
                    </button> 
                    :
                    <button type="submit" className="payingLeftBtn" onClick={payingPrevSlide}>
                        <FontAwesomeIcon icon={faChevronLeft}/>
                    </button> 
                } 
                {
                    
                    (payingRightLast === true) ?
                    <button type="submit" className="payingRightBtnNo" disabled>
                        <FontAwesomeIcon icon={faChevronRight}/>
                    </button>
                    :
                    <button type="submit" className="payingRightBtn" onClick={payingNextSlide}>
                        <FontAwesomeIcon icon={faChevronRight}/>
                    </button>
                }
            </div>
        </section>
    )
}

export default Paying;
