// 숫자에 컴마를 추가하는 함수 (예 - 1,000,000)
export const addComma = (price) => {
    price = String(price);
    if(price === "undefined" || price === 'null' || price === "" ) {
        return "0";
    }

    let decimal = '';
    let num = price.replace(/,/gi, "");

    let isNegative = false;
    if(num.indexOf('-') === 0 ) {
        isNegative = true;
        num = num.replace(/-/gi, "");
    }

    if(num.indexOf('.') > -1 ) {
        let arrSplitDecimalPoint = num.split('.');
        num = arrSplitDecimalPoint[0];
        decimal = '.' + arrSplitDecimalPoint[1];
    }

    var num2 = num.split(/(?=(?:\d{3})+$)/).join(",") + decimal;

    if( isNegative === true ) {
        num2 = "-" + num2;
    }

    return num2;
}

// 새 창을 열기 위한 함수
export const openPage = (e) => {
    e.preventDefault();
    const url = e.currentTarget['href'].replace(window.location['origin']+"/", "");
    window.open(url, '_blank');
}

// 페이지의 맨 위로 부드럽게 이동시켜주는 함수
export const moveTopOnPageSmooth = () => {
    window.scroll({top: 0, behavior: 'smooth'})
}

// 페이지의 맨 위로 바로 이동시켜주는 함수
export const moveTopOnPageAuto = () => {
    window.scroll({top: 0, behavior: 'auto'})
}