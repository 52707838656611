import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {t} from "i18next";

import { isNull, mobile } from "../../apis/Validation";
import { isSessionValidate, removeServerSessionId, userLocaleToServer } from "../../apis/BrowserStorageAPI";
import queryString from 'query-string';

import Logo from "../../feature/Header/Logo";
import Menu from "../../feature/Header/Menu";
import DrawerMenu from "../../feature/Header/DrawerMenu";
import ModalScreen from "../../components/Modal/ModalScreen";

import styled from "styled-components";


const StyledHeaderTemplate = styled.header`
    background: #05141F;
    width: 100vw;
    height: 70px;
    position: fixed;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width:786px) {
        z-index: 1000;
    }
`;

const StyledMenuContainerDiv = styled.div`
    display: flex;
    width: 100%;
    @media screen and (max-width:786px) {
        display: none;
        position: absolute;
        top: 70px;
        background-color: #05141F;
        width: 100%;
        &.clicked {
            display: block;
        }
    }
`

const StyledHeaderContents = styled.div`
    max-width: 1440px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

// 헤더에서 왼쪽부분
const StyledWrapMenu = styled.div`
    width: 100%;
    display: flex;
    text-align: center;
    justify-content: center;
    .dropdown-content {
        display: none;
        &.clicked {
            display: block;
        }
    }

    @media screen and (max-width:786px) {
        display: block;
        background-color: #05141F;
        width: 100%;
    }
`;


// 헤더의 오른쪽 부분
const StyledLoginWrap = styled.div`
    display: flex;
    a {
        float: none;
        color: #fff;
        display: block;
    }
    span {
        color: #fff;
        font-size: 17px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: center;
    }

    .dropdown-content {
        display: none;
        &.clicked {
            display: block;
        }
    }

    @media screen and (max-width:786px) {
        display: block;
        background-color: #05141F;
        width: 100%;
    }
`;

const StyledLoginItemDiv = styled.div`
    height: 100%;
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    a {
        width: 150px;
        height: 70px;
        color: #fff;
        font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
        font-size: 17px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    @media screen and (max-width:786px) {
        display: flex;
        align-items: flex-start;
        padding: 0px 20px;
        background-color: #3D85F0;
        width: 100%;
        flex-direction:column;
        >a{
            justify-content: flex-start;
        }
        span {
            height: 70px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
`;

const MobileButtonTemplate = styled.div`
    display: none;
    width: 70px;
    height: 70px;
    @media screen and (max-width:786px) {
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

const Header = () => {
    const LOCALE = localStorage.getItem('locale') === 'ko' ? "한국어" : localStorage.getItem('locale') === 'en' ? "English" : "简体中文";
    const [active, setActive] = useState({
                                            introduce:false,
                                            header_purchase:false,
                                            sales:false,
                                            support:false,
                                            translate: false,
                                            user_info: false
                                        });
    const [activeMobile, setActiveMobile] = useState(false);
    const [isSession, setIsSession] = useState(false);
    // const userSelector = useSelector((state) => state.user); // redux user값

    const DropDown = (e) => {
        const target = e.currentTarget.dataset.dropdownType.trim();
        ResetActive(target);
    }

    const DropDownMobile = (e) => {
        e.preventDefault();
        setActiveMobile(!activeMobile);
        ResetActive();
    }

    const ResetActive = (target) => {
        const newActive = {};
        for(let key in active) {
            newActive[key] = false;
        }
        setActive({...newActive, [target] : !active[target]});
    }

    const dispatch = useDispatch();
    const SignOut = (e) => {
        if(window.confirm(t('metarex_alert_check_logout'))){
            removeServerSessionId(dispatch);
            setIsSession(false);
            localStorage.removeItem("sessionId");
            setActiveMobile(!activeMobile);
            return;
        }
        e.preventDefault();
    }

    const menuRef = useRef();
    const handleClickOutSide = ({ target }) => {
        if(mobile()){
            if(!menuRef.current.contains(target)) {
                setActiveMobile(false);
            }
        } else {
            if(!menuRef.current.contains(target) || (target.tagName === 'IMG') || (target.tagName === 'A')) {
                ResetActive();
            }
        }
    }

    useEffect(() => {
        window.addEventListener("click", handleClickOutSide);
        return () => {
            window.removeEventListener("click", handleClickOutSide);
        }
    });

    useEffect(() => {
        setIsSession(isSessionValidate());
    }, [isSession]);

    const changeLanguage = (e) => {
        const pathname = window.location.pathname;
        const language = e.target.dataset['i18n'];
        if(language === localStorage.getItem('locale')) {
            return;
        }

        localStorage.setItem('locale', language);

        userLocaleToServer(language)
            .then(() => {
                const parsed = queryString.parse(window.location.search);
                let path = pathname;

                // 사용자 개별 구매 페이지 확인
                if(!isNull(parsed['u'])) {
                    path += `?u=${parsed['u']}`;
                }
                window.location.replace(path);
            })
            .catch(error => console.error(error));
    }

    return (
        <>
        {(mobile() && activeMobile) && <ModalScreen/>}
        <StyledHeaderTemplate ref={menuRef}>
            <StyledHeaderContents >
                <Logo/>
                <StyledMenuContainerDiv className={!mobile() ? "" : activeMobile ? "clicked" : ""}>
                    <StyledWrapMenu>
                        <Menu onClick={DropDown} category={'header_about'} categoryName={t("header_menu_metarex")} active={active}>
                            <li onClick={DropDownMobile}><Link to="/about">{t("header_about")}</Link></li>
                            <li onClick={DropDownMobile}><Link to="/future">{t("header_future")}</Link></li>
                            <li onClick={DropDownMobile}><Link to="/profile">{t("header_profile")}</Link></li>
                            <li onClick={DropDownMobile}><Link to="/organization">{t("header_organizational_chart")}</Link></li>
                        </Menu>
                        <Menu onClick={DropDown} category={'header_purchase'} categoryName={t("header_menu_purchase")} active={active}>
                            <li onClick={DropDownMobile}><Link to="/map">{t("header_purchase")}</Link></li>
                            {isSession && <li onClick={DropDownMobile}><Link to="/BuyHistory">{t("header_purchase_history")}</Link></li>}
                            {process.env.REACT_APP_MODE === 'development' && <li onClick={DropDownMobile}><Link to="/mx-lending">MX Lending</Link></li>}
                            <li onClick={DropDownMobile}><Link to="/global-live-streaming">{t("menu_global_live")}</Link></li>
                        </Menu>
                        { isSession &&
                            <Menu onClick={DropDown} category={'sales'} categoryName={t("header_menu_sales")} active={active}>
                                <li onClick={DropDownMobile}><Link to="/MarketView">{t("header_view_market")}</Link></li>
                                <li onClick={DropDownMobile}><Link to="/SalesRegistRation">{t("header_sales_registration")}</Link></li>
                                {isSession && <li onClick={DropDownMobile}><Link to="/SaleHistory">{t("header_sales_history")}</Link></li>}
                            </Menu>
                        }
                        <Menu onClick={DropDown} category={'support'} categoryName={t("header_menu_support")} active={active}>
                            <li onClick={DropDownMobile}><Link to="/notice">{t("header_notice")}</Link></li>
                            <li onClick={DropDownMobile}><Link to="/how-to-use">{t('manual_title')}</Link></li>
                            <li onClick={DropDownMobile}><Link to="/faq">FAQ</Link></li>
                            <li onClick={DropDownMobile}><Link to="/videos">{t('header_menu_reference_room')}</Link></li>
                        </Menu>
                    </StyledWrapMenu>

                    <StyledLoginWrap>
                        <Menu className="lang" onClick={DropDown} category={'translate'} categoryName={LOCALE} active={active}>
                            <li><span data-i18n="ko" onClick={changeLanguage} style={LOCALE === '한국어' ? {color: '#3D85F0'} : {}}>한국어</span></li>
                            <li><span data-i18n="en" onClick={changeLanguage} style={LOCALE === 'English' ? {color: '#3D85F0'} : {}}>English</span></li>
                            <li><span data-i18n="cn" onClick={changeLanguage} style={LOCALE === '简体中文' ? {color: '#3D85F0'}: {}}>简体中文</span></li>
                        </Menu>

                        {isSession
                            ?
                            <Menu className="sign_in" onClick={DropDown} category={'user_info'} categoryName={`${localStorage.getItem('email').split("@")[0]}`} active={active}>
                                <li onClick={DropDownMobile}><Link to="/mypage">{t('header_mypage')}</Link></li>
                                <li onClick={DropDownMobile}><Link to="/point">{t('header_point')}</Link></li>
                                <li onClick={DropDownMobile}><Link to="/signin" onClick={SignOut}>{t('header_logout')}</Link></li>
                            </Menu>
                            :
                            <StyledLoginItemDiv>
                                <Link to="/signin" className="gnb-signin-out" onClick={()=>{setActiveMobile(!activeMobile)}}>{t("header_login")}</Link>
                            </StyledLoginItemDiv>
                        }
                    </StyledLoginWrap>
                </StyledMenuContainerDiv>

                {/* 모바일 버튼 */}
                <MobileButtonTemplate onClick={DropDownMobile}>
                    <DrawerMenu clicked={activeMobile}/>
                </MobileButtonTemplate>
            </StyledHeaderContents>
        </StyledHeaderTemplate>
        </>
    );
}


export default Header;
