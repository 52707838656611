import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { t } from "i18next";
import styled from "styled-components";

import RichTextEditor from "../../components/RichTextEditor";
import ContactInputBox from "./ContactInputBox";
import Footer from "../Footer/Footer";

import { axiosGet, axiosPost } from "../../apis/AxiosAPI";
import { removeUser } from "../../redux/user/actions";
import { isSessionValidate } from "../../apis/BrowserStorageAPI";
import { isNull, isValidEmail } from "../../apis/Validation";

const StyledContactForm = styled.div`
    width: 100%;
    max-width: 777px;
    margin-top: 50px;
    margin-bottom: 230px;
`;

const StyledButtonDiv = styled.div`
    padding-top: 70px;
    width: 100%;
    display: flex;
    justify-content: center;

    button, a {
        width: 155px;
        height: 48px;
        cursor: pointer;
        border: none;
        transition: all 0.3s;
        border-radius: 2px;

        font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
        font-size: 14px;
        font-weight: 700;
        line-height: 14px;
        letter-spacing: -0.02em;
        text-align: center;

        display: flex;
        justify-content: center;
        align-items: center;
    }

    .cancel {
        background-color: #fff;
        color: #05141F;
        border: 1px solid #D9DDE2;
        margin-right: 10px;
    }
    .post {
        background-color: #2f80ed;
        color: #fff;
        border: 1px solid #2f80ed;
    }

    @media only screen and (max-width: 768px) {
        padding-top: 100px;
    }
`;

const ContactForm = ({setIsSending}) => {
    const [text, setText] = useState("");
    const [email, setEmail] = useState("");
    const [title, setTitle] = useState("");
    const dispatch = useDispatch();

    useEffect(()=>{
        const url = new URL(window.location.href);
        const urlParam = url.searchParams;
        if(isSessionValidate()){
            userEmailBySessionId();
        }
        
        if(text === "") {
            const initText = isNull(urlParam.get('content')) ? "" : decodeURIComponent(urlParam.get('content'));
            setText(initText);
        }
        if(title === '') {
            const initTitle = isNull(urlParam.get('title')) ? "" : decodeURIComponent(urlParam.get('title'));
            setTitle(initTitle);            
        }
    },[]);

    const userEmailBySessionId = async () => {
        await axiosGet('api/user/userEmailBySessionId')
            .then(response => {
                if(JSON.parse(response.data.result)) {
                    setEmail(response.data.email);
                } else {
                    localStorage.removeItem('sessionId');
                    dispatch(removeUser());
                    alert('세션이 만료되어 메인 화면으로 돌아갑니다.');
                    window.location.href = '/';
                }
            })
            .catch(e => console.log(e));
    }


    const 문의사항_등록하기 = () => {
        setIsSending(true);

        if(email === "" || isNull(email)) {
            alert(t('contact_us_alert_email'));
            return false;
        }

        if(!isValidEmail(email)) {
            alert(t('contact_us_alert_email_incorrect'));
            return false;
        }

        if(title === "" || isNull(title)) {
            alert(t('contact_us_alert_title'));
            return false;
        }

        if(text === "" || isNull(text)) {
            alert(t('contact_us_alert_contents'));
            return false;
        }

        const userNo = null;
        const sessionId = localStorage.getItem('sessionId');
        const realestateNo = 0;
        const boardContactUsReplyNo = 0;
        const subjectKr = title;
        const markupStr = text;
        
        contactUsInsert({
            userNo, 
            email,
            sessionId,
            realestateNo, 
            subjectKr,
            markupStr,
            boardContactUsReplyNo,
        });
    }

    // const history = useHistory();

    const contactUsInsert = async (json) => {
        axiosPost('contactUs/insert', json)
            .then(response => {
                alert(response.data.message);
                if(JSON.parse(response.data.result)) {
                    window.location.href = '/';

                }
            }).catch(e => {
                console.log(`error message: ${e}`);                
            }).finally(() => {
                setIsSending(false);
            });
    }

    return (
        <>
        <StyledContactForm>
            <ContactInputBox type="email" placeholder={t("contact_us_email_placeholder")} item={email} setItem={setEmail}/>
            <ContactInputBox type="text" placeholder={t("contact_us_title_placeholder")} item={title} setItem={setTitle}/>
            <RichTextEditor item={text} setText={setText}/>
            <StyledButtonDiv>
                <Link to="/" className="cancel">{t("contact_us_btn_cancel")}</Link>
                <button type="button" className="post" onClick={문의사항_등록하기}>{t("contact_us_btn_submit")}</button>
            </StyledButtonDiv>
        </StyledContactForm>
        <Footer/>
        </>
    )
}

export default ContactForm;