
export const useInput = (setArgs) => {
    const updateInput = (e) => {
        const { name, value, maxLength } = e.target;
        let slicedValue = value;

        if( typeof maxLength !== "undefined" && maxLength > -1 ) {
            slicedValue = value.slice(0, maxLength);
        }

        setArgs(prev => prev && {
            ...prev,
            [name]: slicedValue,
        });
    };

    return {updateInput};
};
