import React, { useEffect, useState, useLayoutEffect } from "react";
import styled from "styled-components";
import { useSelector } from 'react-redux';
import { t } from "i18next";

import { AsterpayAPI } from '../../apis/AsterpayAPI';
import { useDevice } from "../../hooks/useDevice";
import { useInput } from "../../hooks/useInput";
import { addComma } from "../../apis/CommonFunc";
import { isNull } from "../../apis/Validation";

import Wizzpay from "./Wizzpay";


import { getPriceDollarKRW } from "../../apis/PriceDollarAPI";
import { postPaymentParams } from "../../apis/PaymentAPI";


const isEnableGlobalMode = false;

const MainDiv = styled.div`
    & args[type="radio"] {
        width: 16px;
        height: 16px;
    }
    & label {
        cursor: pointer;
    }

    & .coin-wrapper {
        padding: 5px 23px;
        & label {
            margin: 12px 0px;
            display: grid;
            grid-gap: 10px 0px;
            grid-template-columns: 22px 38px auto;
            position: relative;
            width: 100%;
            height: 100%;
            & > input {
            }
            & .coin-icon {
                width: 26px;
            }
        }
    }
`;

const PaddingDiv = styled.div`
    padding: 20px;
`;
const PaddingCardDiv = styled.div`
    padding: 5px 23px;
    display: grid;
    grid-gap: 10px 18px;
    grid-template-columns: 1fr 1fr;
    & label {
        display: flex;
    }
`;

const Padding15px20pxDiv = styled.div`
    padding: 15px 20px;
    border-bottom: 1px solid #EFEFEF;
`;
const PaymentAmountDiv = styled.div`
    _display: none;
    padding: 20px;
    color: #222222;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    border-bottom: 1px solid #EFEFEF;
`;
const ItemNameDiv = styled.div`
    display: none;
    padding: 20px;
    color: #595959;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 14px;
    border-bottom: 1px solid #EFEFEF;
`;
const TotalAmountDiv = styled.div`
    background: #FFF9FA;
    padding: 20px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 14px;
    border-bottom: 3px solid #DFE1E5;
    & > div > span {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        color: #D2465A;
    }
    & > div > .item-price {
        float: right;
        font-weight: 700;
        &.item-price-point::before {
            content:'POINT: ';
        }
        &.item-price-dollar::before {
            content:'DOLLAR: ';
        }
        &.item-price-krw::before {
            content:'KRW: ';
        }
`;
const PaymentMethodInput = styled.input`
    display: none;
`;

const PaymentMethodFieldset = styled.fieldset`
    border: 0px solid #000;
    & > legend {
        display: none;
    }
    & > label > span {
        vertical-align: middle;
    }
`;

const PaymentMethodLabelSpan = styled.span`
    padding-left: 10px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #222222;
    padding-right: 130px;
`;

const DisplayNoneSpan = styled.span`
    display: none;
`;

const TableCellSpan = styled.span`
    position: relative;
    width: 100%;
    height: 100%;
    &::after {
        content: '　';
    }
    & > * {
        margin: 0;
        position: absolute;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }

`;



const PaymentMethodCardInput = styled.input`
`;

const PaymentSelectMethod = ({args, setArgs, setOnClickNextButtonPrevius, asterPayment}) => {

    const  { updateInput } = useInput(setArgs);
    const deivce = useDevice();

    const [isAsterLoading, setIsAsterLoading] = useState(false);
    const userSelector = useSelector((state) => state.user); // redux user값
    const [json, setJson] = useState({});



    useLayoutEffect(()=>{

        // aes 로드
        const aesJavascriptURL = `${process.env.PUBLIC_URL}/js/aes.js?v=20211215_1227`;
        const aesJavascript = document.createElement('script');
        aesJavascript.setAttribute('src', aesJavascriptURL);
        aesJavascript.onload = function() {
            console.warn(`aes Script Load Success > ${aesJavascriptURL}`);
        }
        document.getElementsByTagName('head')[0].appendChild(aesJavascript);

        // pbkdf2 로드
        const pbkdf2JavascriptURL = `${process.env.PUBLIC_URL}/js/pbkdf2.js?v=20211215_1227`;
        const pbkdf2Javascript = document.createElement('script');
        pbkdf2Javascript.setAttribute('src', pbkdf2JavascriptURL);
        pbkdf2Javascript.onload = function() {
            console.warn(`pbkdf2 Script Load Success > ${pbkdf2JavascriptURL}`);
        }
        document.getElementsByTagName('head')[0].appendChild(pbkdf2Javascript);

        // Wizzpay 로드
        const wizzpayJavascriptURL = `${process.env.PUBLIC_URL}/js/function.js?v=20211215_1227`;
        const wizzpayJavascript = document.createElement('script');
        wizzpayJavascript.setAttribute('src', wizzpayJavascriptURL);
        wizzpayJavascript.onload = function() {
          console.warn(`Wizzpay Script Load Success > ${wizzpayJavascriptURL}`);
        }
        document.getElementsByTagName('head')[0].appendChild(wizzpayJavascript);

        {/* 자동 결제를 위해 관련 데이터를 paymentParams에 저장한다. */}
        if( isNull(args.paymentParams) == false ) {
            postPaymentParams(args.paymentParams)
            .then(response => {
                if(JSON.parse(response.data.result)) {
                    const paymentParamsNo = response.data.data;
                    getPriceDollarKRW()
                    .then(response => {
                        if(JSON.parse(response.data.result)){
                            const dollarKRW = Number(response.data.data.price);
                            const asterPrice = args.amount * dollarKRW;
                            console.log(`결제할 금액 ${addComma(asterPrice)}`);

                            const sessionId = localStorage.getItem('sessionId');
                            if( sessionId == null ) {
                                alert(t("market_view_login"));
                                window.location.href ='/signin';
                                return false;
                            }

                            const customerName = userSelector.name;
                            setArgs(prev => prev && {
                                ...prev,
                                ['AMT']: Math.round(Number(asterPrice)),
                                ['pricesNo']: response.data.data.pricesNo,
                                ['BYPASSVALUE']: `pricesNo=${response.data.data.pricesNo};dollarKRW=${dollarKRW};amount=${args.amount};sessionId=${sessionId};paymentParamsNo=${paymentParamsNo}`,
                                ['GOODSNAME']: `${addComma(args.amount)} POINT`,
                                ['RESULTURL']: `${process.env.REACT_APP_API_METAREX}/api/buy/wizzpay/RESULTURL`,
                                ['NOTIURL']: `${process.env.REACT_APP_API_METAREX}/api/buy/wizzpay/NOTIURL`,
                                ['BUYERNAME']: `${customerName}`,
                            });

                        } else {
                            alert(response.data.message);
                        }

                    }).catch(e => {
                        console.log(`getPriceDollarKRW error message: ${e}`);
                    });
                } else {
                    alert(`${response.data.message}`);
                    window.location.reload();
                }
            }).catch(e => {
                console.log(`error message: ${e}`);
                window.location.reload();
            });
        }
      }, []);


    useEffect(() => {
        setOnClickNextButtonPrevius (
            () => () => {
                if( args['payment-method'] == null ) {
                    alert(`${t('point_please_select_a_payment_method_for_alert')}`);
                    return false;
                }

                if( isEnableGlobalMode ) {

                    if( args['payment-method'] === 'coin' ) {
                        if( args['coin'] == null ) {
                            alert('Please select a coin.');
                            return false;
                        }
                    } else if( args['payment-method'] === 'card' ) {
                        if( args['card'] == null ) {
                            alert('Please select a payment card.');
                            return false;
                        }
                    }

                }

                if( isEnableGlobalMode == false ) {

                    if( args['payment-method'] === 'coin' ) {
                        const MIN_PRICE = 100000;
                        const type = "point";
                        let data = {
                            type,
                        };
                        const typeUrl = '';
                        const asterpay = new AsterpayAPI(typeUrl);

                        {/* 기존 아스터페이 로직 */}
                        if(args.AMT < MIN_PRICE) {
                            alert("최소 코인 송금 수량 제한으로 10만원 미만은 결제 요청하실 수 없습니다. 소액은 카드 결제를 이용해주시기 바랍니다.");
                            return false;
                        }

                        // 아스터페이가 로딩되기 전에, 로딩바를 보여주기 위한 변수
                        setIsAsterLoading(true);

                        const randNumber    = String(Math.floor((Math.random() * 1986) + 1)) + String(Math.floor((Math.random() * 9860) + 1)) + String(Math.floor((Math.random() * 8605) + 1)) + String(Math.floor((Math.random() * 6052) + 1));
                        const dataOrderName = `${addComma(args.amount)} ${localStorage.getItem('locale') !== 'ko' ? 'point' : '포인트'}`;
                        const orderNumber   = args.amount;
                        const sessionId     = localStorage.getItem('sessionId');
                        const orderItemType = data.type;


                        const asterpayObj = {
                            currencyType: 'Astercoin',
                            amount: args.AMT,
                            orderId: String(`${Number(new Date())}_${randNumber}`).replace(/\s/g,'_'),
                            orderName: dataOrderName,
                            orderNumber,
                            orderItemType,
                            customerName: userSelector.name,
                            mobile: userSelector.mobile,
                            email: userSelector.email,
                            successUrl: `${process.env.REACT_APP_API_METAREX}/api/asterpay/success`,
                            failUrl: `${process.env.REACT_APP_API_METAREX}/api/asterpay/fail`,
                            customerSession: sessionId
                        }

                        asterpay.아스터페이_실행하기(asterPayment, asterpayObj);
                    } else if( args['payment-method'] === 'card' ) {
                        const WIZZPAY_MAX_PRICE = 10000000;

                        if(args.AMT > WIZZPAY_MAX_PRICE) {
                            alert(`카드 구매 구매금액이 너무 큽니다. 최대 한도는 ${addComma(WIZZPAY_MAX_PRICE)} 원 입니다.`);
                            return false;
                        }


                        var WIZZ_URL    = "https://pgadmin.wizzpay.co.kr";
                        var MID         = `${process.env.REACT_APP_MID}`;
                        var IV_KEY      = `${process.env.REACT_APP_IV_KEY}`;
                        var SALT        = `${process.env.REACT_APP_SALT}`;
                        var PASSWORD    = `${process.env.REACT_APP_PASSWORD}`;

                        var wizzpay = new window.WizzpayISP(WIZZ_URL, MID, IV_KEY, SALT, PASSWORD);
                        wizzpay.setDebugMode(true);

                        var resultFunction = function () {
                            var returnData = wizzpay.getResultData();

                            console.log("return data", returnData);
                            alert("[" + returnData.GOODSNAME + "] 상품의 결제가 완료되었습니다." );
                        };

                        wizzpay.setResultFunction(resultFunction);

                        wizzpay.goPay("merForm");
                        return false;
                    }


                    return false;
                }

                return true;
            }
        );
    }, [args]);

    return (
        <MainDiv>
            <PaymentAmountDiv>
            {t('1Point_equal_1dollar')}
            </PaymentAmountDiv>
            <ItemNameDiv>
            {t('point_please_select_a_payment_method')}
            </ItemNameDiv>
            <TotalAmountDiv>
                <div>
                    <span>
                    {t('point_total')}
                    </span>
                    <span className="item-price item-price-point">
                        {addComma(args.amount)}
                    </span>
                </div>
                <div>
                    <span>(환산 포함)
                    </span>
                    <span className="item-price item-price-dollar">
                        {addComma(args.amount)}
                    </span>
                </div>
                <div>
                    <span>&nbsp;
                    </span>
                    <span className="item-price item-price-krw">
                        {addComma(args.AMT)}
                    </span>
                </div>
            </TotalAmountDiv>
            <PaymentAmountDiv>
            {t('point_payment_method')}
            </PaymentAmountDiv>
            <Padding15px20pxDiv>
                <label>
                    <PaymentMethodInput
                        id="coin"
                        value="coin"
                        name="payment-method"
                        type="radio"
                        checked={args['payment-method'] === "coin"}
                        onChange={updateInput}
                        />
                        {args['payment-method'] === "coin" ?
                            <img src={`${process.env.PUBLIC_URL}/images/icon/check_v_checked.svg`} alt="checked"/> : <img src={`${process.env.PUBLIC_URL}/images/icon/check_v_unchecked.svg`} alt="unchecked"/>
                        }
                        <PaymentMethodLabelSpan>{t('point_coin')}</PaymentMethodLabelSpan>
                </label>
                {args['payment-method'] === "coin" && isEnableGlobalMode &&
                    <PaymentMethodFieldset>
                        <legend>COIN</legend>
                        <div className="coin-wrapper">
                            <label>
                                <TableCellSpan>
                                    <input
                                        id="bitcoin"
                                        value="bitcoin"
                                        name="coin"
                                        type="radio"
                                        checked={args.coin === "bitcoin"}
                                        onChange={updateInput}
                                        />
                                </TableCellSpan>
                                <img src={`${process.env.PUBLIC_URL}/images/icon/payment/coin/bitcoin.svg`} alt="bitcoin" className="coin-icon"/>

                                <TableCellSpan>
                                    <span>Bitcoin</span>
                                </TableCellSpan>
                            </label>
                            <label>
                                <input
                                    id="ethereum"
                                    value="ethereum"
                                    name="coin"
                                    type="radio"
                                    checked={args.coin === "ethereum"}
                                    onChange={updateInput}
                                    />
                                <img src={`${process.env.PUBLIC_URL}/images/icon/payment/coin/ethereum.svg`} alt="ethereum" className="coin-icon"/>
                                <span>Ethereum</span>
                            </label>
                            <label>
                                <input
                                    id="aster"
                                    value="aster"
                                    name="coin"
                                    type="radio"
                                    checked={args.coin === "aster"}
                                    onChange={updateInput}
                                    />
                                <img src={`${process.env.PUBLIC_URL}/images/icon/payment/coin/astercoin.svg`} alt="astercoin" className="coin-icon"/>
                                <span>Aster</span>
                            </label>
                        </div>
                    </PaymentMethodFieldset>
                }
            </Padding15px20pxDiv>
            <Padding15px20pxDiv>
                <label>
                    <PaymentMethodInput
                        id="card"
                        value="card"
                        name="payment-method"
                        type="radio"
                        checked={args['payment-method'] === "card"}
                        onChange={updateInput}
                        />
                        {args['payment-method'] === "card" ?
                            <img src={`${process.env.PUBLIC_URL}/images/icon/check_v_checked.svg`} alt="checked"/> : <img src={`${process.env.PUBLIC_URL}/images/icon/check_v_unchecked.svg`} alt="unchecked"/>
                        }
                        <PaymentMethodLabelSpan>{t('point_card')}</PaymentMethodLabelSpan>
                </label>
                {args['payment-method'] === "card" && isEnableGlobalMode &&
                    <PaymentMethodFieldset>
                        <legend>CARD</legend>
                            <PaddingCardDiv>
                                <label>
                                    <TableCellSpan>
                                        <PaymentMethodCardInput
                                            id="alipay"
                                            value="alipay"
                                            name="card"
                                            type="radio"
                                            checked={args.card === "alipay"}
                                            onChange={updateInput}
                                            />
                                    </TableCellSpan>
                                    <DisplayNoneSpan>alipay</DisplayNoneSpan>
                                    <img src={`${process.env.PUBLIC_URL}/images/icon/payment/card/alipay.svg`} alt="alipay"/>
                                </label>
                                <label>
                                    <TableCellSpan>
                                        <PaymentMethodCardInput
                                            id="american-express"
                                            value="american-express"
                                            name="card"
                                            type="radio"
                                            checked={args.card === "american-express"}
                                            onChange={updateInput}
                                            />
                                    </TableCellSpan>
                                    <DisplayNoneSpan>american-express</DisplayNoneSpan>
                                    <img src={`${process.env.PUBLIC_URL}/images/icon/payment/card/american-express.svg`} alt="american-express"/>
                                </label>
                                <label>
                                    <TableCellSpan>
                                        <PaymentMethodCardInput
                                            id="unionpay"
                                            value="unionpay"
                                            name="card"
                                            type="radio"
                                            checked={args.card === "unionpay"}
                                            onChange={updateInput}
                                            />
                                    </TableCellSpan>
                                    <DisplayNoneSpan>unionpay</DisplayNoneSpan>
                                    <img src={`${process.env.PUBLIC_URL}/images/icon/payment/card/unionpay.svg`} alt="unionpay"/>
                                </label>
                                <label>
                                    <TableCellSpan>
                                        <PaymentMethodCardInput
                                            id="visa"
                                            value="visa"
                                            name="card"
                                            type="radio"
                                            checked={args.card === "visa"}
                                            onChange={updateInput}
                                            />
                                    </TableCellSpan>
                                    <DisplayNoneSpan>visa</DisplayNoneSpan>
                                    <img src={`${process.env.PUBLIC_URL}/images/icon/payment/card/visa.svg`} alt="visa"/>
                                </label>
                                <label>
                                    <TableCellSpan>
                                        <PaymentMethodCardInput
                                            id="alipmastercarday"
                                            value="mastercard"
                                            name="card"
                                            type="radio"
                                            checked={args.card === "mastercard"}
                                            onChange={updateInput}
                                            />
                                    </TableCellSpan>
                                    <DisplayNoneSpan>mastercard</DisplayNoneSpan>
                                    <img src={`${process.env.PUBLIC_URL}/images/icon/payment/card/mastercard.svg`} alt="mastercard"/>
                                </label>
                                <label>
                                    <TableCellSpan>
                                        <PaymentMethodCardInput
                                            id="maestro"
                                            value="maestro"
                                            name="card"
                                            type="radio"
                                            checked={args.card === "maestro"}
                                            onChange={updateInput}
                                            />
                                    </TableCellSpan>
                                    <DisplayNoneSpan>maestro</DisplayNoneSpan>
                                    <img src={`${process.env.PUBLIC_URL}/images/icon/payment/card/maestro.svg`} alt="maestro"/>
                                </label>
                                <label>
                                    <TableCellSpan>
                                        <PaymentMethodCardInput
                                            id="paypal"
                                            value="paypal"
                                            name="card"
                                            type="radio"
                                            checked={args.card === "paypal"}
                                            onChange={updateInput}
                                            />
                                    </TableCellSpan>
                                    <DisplayNoneSpan>paypal</DisplayNoneSpan>
                                    <img src={`${process.env.PUBLIC_URL}/images/icon/payment/card/paypal.svg`} alt="paypal"/>
                                </label>
                                <label>
                                    <TableCellSpan>
                                        <PaymentMethodCardInput
                                            id="jcb"
                                            value="jcb"
                                            name="card"
                                            type="radio"
                                            checked={args.card === "jcb"}
                                            onChange={updateInput}
                                            />
                                    </TableCellSpan>
                                    <DisplayNoneSpan>jcb</DisplayNoneSpan>
                                    <img src={`${process.env.PUBLIC_URL}/images/icon/payment/card/jcb.svg`} alt="jcb"/>
                                </label>
                                <label>
                                    <TableCellSpan>
                                        <PaymentMethodCardInput
                                            id="elo"
                                            value="elo"
                                            name="card"
                                            type="radio"
                                            checked={args.card === "elo"}
                                            onChange={updateInput}
                                            />
                                    </TableCellSpan>
                                    <DisplayNoneSpan>elo</DisplayNoneSpan>
                                    <img src={`${process.env.PUBLIC_URL}/images/icon/payment/card/elo.svg`} alt="elo"/>
                                </label>
                                <label>
                                    <TableCellSpan>
                                        <PaymentMethodCardInput
                                            id="verve"
                                            value="verve"
                                            name="card"
                                            type="radio"
                                            checked={args.card === "verve"}
                                            onChange={updateInput}
                                            />
                                    </TableCellSpan>
                                    <DisplayNoneSpan>verve</DisplayNoneSpan>
                                    <img src={`${process.env.PUBLIC_URL}/images/icon/payment/card/verve.svg`} alt="verve"/>
                                </label>
                            </PaddingCardDiv>
                    </PaymentMethodFieldset>
                }
            </Padding15px20pxDiv>
            <Wizzpay args={args} setArgs={setArgs}></Wizzpay>
        </MainDiv>
    )
}

export default PaymentSelectMethod;
