import { t } from "i18next";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const StyledCategoriesDiv = styled.nav`
    width: 100%;
    height: 80px;
    box-sizing: border-box;
    border-bottom: 1px solid #D9DDE2;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    ul {
        width: 100%;
        max-width: ${props => props.category === 'market' ? '1310px' : '1200px'};
        position: relative;
        top: 1px;
        box-sizing: border-box;
        li {
            vertical-align: bottom;
            a {
                text-decoration: none;
                color: #909090;
            }
            display: inline-block;
            margin-right: 40px;
            padding-bottom: 20px;
            font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
            font-size: 18px;
            font-weight: 700;
            line-height: 18px;
            letter-spacing: 0em;
        }
    }
    @media only screen and (max-width: 768px) {
        height: 60px;
        padding: 0px 20px;
        ul {
            display: flex;
            justify-content: space-between;
            max-width: 100%;
            li {
                font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 16px;
                letter-spacing: 0em;
                text-align: center;
                padding-bottom: 16px;
                margin-right: 20px;
                &:last-of-type {
                    margin-right: 0px;
                }
            }
        }
    }
`;

// 각 페이지의 배너 밑에 위치한 TAP 컴포넌트
const PageTab = ({category, type}) => {
    return (
        <StyledCategoriesDiv category={category}>
            <ul>
                {category === 'support' &&
                <>
                    {type === 'notice' 
                        ? <li style={{borderBottom:'2px solid #3D85F0'}}><Link to="/notice">{t("notice_title")}</Link></li>
                        : <li><Link to="/notice">{t("notice_title")}</Link></li>
                    }
                    {type === 'manual' 
                        ? <li style={{borderBottom:'2px solid #3D85F0'}}><Link to="/how-to-use">{t('manual_title')}</Link></li>
                        : <li><Link to="/how-to-use">{t('manual_title')}</Link></li>
                    }
                    {type === 'faq' 
                        ? <li style={{borderBottom:'2px solid #3D85F0'}}><Link to="/faq">FAQ</Link></li>
                        : <li><Link to="/faq">FAQ</Link></li>
                    }
                    {type === 'videos' 
                        ? <li style={{borderBottom:'2px solid #3D85F0'}}><Link to="/videos">{t('header_menu_reference_room')}</Link></li>
                        : <li><Link to="/videos">{t('header_menu_reference_room')}</Link></li>
                    }
                </>
                }
                {category === 'metarex' &&
                    <>
                        {type === 'about' 
                            ? <li style={{borderBottom:'2px solid #3D85F0'}}> <Link to="/about" style={{color: '#3D85F0'}}>{t("metarex_header_about")}</Link> </li> 
                            : <li ><Link to="/about">{t("metarex_header_about")}</Link></li>
                        }
                        {type === 'future' 
                            ? <li style={{borderBottom:'2px solid #3D85F0'}}> <Link to="/future" style={{color: '#3D85F0'}}>{t("metarex_header_future")}</Link> </li> 
                            : <li><Link to="/future">{t("metarex_header_future")}</Link></li>
                        }
                        {type === 'profile' 
                            ? <li style={{color:'#3D85F0', borderBottom:'2px solid #3D85F0'}}> <Link to="/profile">{t("metarex_header_profile")}</Link> </li> 
                            : <li><Link to="/profile">{t("metarex_header_profile")}</Link></li>
                        }
                        {type === 'chart' 
                            ? <li style={{color:'#3D85F0', borderBottom:'2px solid #3D85F0'}}> <Link to="/organMain">{t("metarex_header_organization")}</Link> </li>
                            : <li><Link to="/organization">{t("metarex_header_organization")}</Link></li>}                
                    </>
                }
                {category === 'market' &&
                    <>
                        {type === 'view' 
                            ? <li style={{borderBottom:'2px solid #3D85F0'}}> <Link to="/MarketView" style={{color: '#3D85F0'}}>{t("header_menu_market")}</Link> </li> 
                            : <li ><Link to="/MarketView">{t("header_menu_market")}</Link></li>
                        }
                        {type === 'sale' 
                            ? <li style={{borderBottom:'2px solid #3D85F0'}}> <Link to="/SalesRegistRation" style={{color: '#3D85F0'}}>{t("header_sales_registration")}</Link> </li> 
                            : <li><Link to="/SalesRegistRation">{t("header_sales_registration")}</Link></li>
                        }
                        {type === 'history' 
                            ? <li style={{color:'#3D85F0', borderBottom:'2px solid #3D85F0'}}> <Link to="/SaleHistory">{t("sale_history_title")}</Link> </li> 
                            : <li><Link to="/SaleHistory">{t("sale_history_title")}</Link></li>
                        }            
                    </>
                }
            </ul>
        </StyledCategoriesDiv>
    );
}

export default PageTab;