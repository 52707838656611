import React, { useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { t } from "i18next";

import { axiosPost } from "../../../apis/AxiosAPI";
import { updateUserName } from "../../../redux/user/actions";

const ChangeNameForm = styled.form`
    display: flex;
    justify-content: center;
    flex-direction: column;
    >label{
        font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
    }
    >input[type=text]{
        width: 100%;
        height: 48px;
        margin: 8px 0px 20px 0px;
        padding: 15px 10px;
        font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
        font-size: 16px;
        font-weight: 700;
        line-height: 18px;
        text-align: left;
        border: 1px solid #D9DDE2;
        border-radius: 2px;
        &:focus {
            outline: 1px solid #05141F;
        }
    }
    >input[type=submit]{
        width: 100%;
        height: 48px;
        border: none;
        font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
        font-size: 14px;
        font-weight: 700;
        line-height: 16px;
        text-align: center;
        border-radius: 2px;
        cursor: pointer;
        color: #fff;  
        background: #05141F;
        -webkit-appearance: none;
        -webkit-border-radius: 0;
        border: none;
        &:hover{
            background:#3D85F0;;
            transition:.3s;
        }
        &:last-of-type{
            color: #05141F;
            background: #fff;
            border: 1px solid #05141F;
            box-sizing: border-box;
            margin-top: 10px;
            &:hover{
                background:#3D85F0;;
                transition:.3s;
                color: #fff;
                border: none;
            }
        }
    }
`;

// modal 페이지에 들어가는 content - 마이페이지 > 이름 변경
const ChangeName = ({closeModal, setIsProceeding}) => {
    const userSelector = useSelector((state) => state.user); // redux user값
    const dispatch = useDispatch();
    const currentName = userSelector.name || localStorage.getItem('name');
    const [changeName, setChangeName] = useState("");
    const [disabled, setDisabled] = useState(false);

    const onChange = (e) => {
        setChangeName(e.target.value.replaceAll(" ", ""));
    }

    const onSubmit = (e) => {
        e.preventDefault();
        setDisabled(true);
        
        if(currentName === changeName || changeName === '') {
            setDisabled(false);
            alert(t('mypage_name_alert_failed_1'));
            return;
        }
        requestChangeName(changeName);
    }


    const requestChangeName = (changeName) => {
        setIsProceeding(true);
        axiosPost(`api/user/changeUserName?newName=${changeName}`)
            .then(response => {
                if(JSON.parse(response.data.result)) {
                    alert(response.data.message);
                    if(JSON.parse(response.data.data)) {
                        dispatch(updateUserName(changeName));       // redux update
                        localStorage.setItem('name', changeName);   // localstorage update
                        return;
                    }
                }
            })
            .catch(error => {
                console.log(`change name failed, error message: ${error}`);
                alert(t('mypage_name_alert_failed_2'));
            }).finally(() =>{
                closeModal();
                setIsProceeding(false);
            });
    }

    return (
        <ChangeNameForm onSubmit={onSubmit}>
            <label htmlFor="current_name">{t('mypage_change_current_name')}</label>
            <input type="text" id="current_name" value={currentName} disabled/>
            <label htmlFor="changed_name">{t('mypage_change_changed_name')}</label>
            <input type="text" id="changed_name" onChange={onChange} placeholder={t('mypage_name_placeholder')}/>
            <input type="submit" value={t('metarex_common_confirm')} disabled={disabled}/>
            <input type="submit" value={t('metarex_common_cancel')} onClick={closeModal}/>
        </ChangeNameForm>
    );
}

export default ChangeName;

