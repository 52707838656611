import React from "react";
import styled from "styled-components";

// "지원" 카테고리 페이지의 화면 템플레이트
const StyledSupportTemplate = styled.div`
    width: 100vw;
    height: auto;
    min-height: 100vh;
    padding-top: 60px;
    padding-bottom: 180px;
    display: flex;
    flex-direction: column;

    >div { 
        width: 100%;
        display:flex;
        align-items: start;
        justify-content: center;
    }
`;

const SupportTemplate = ({children}) => {
    return (
        <>
        <StyledSupportTemplate>
            {children}
        </StyledSupportTemplate>
        </>
    )
}

export default SupportTemplate;
