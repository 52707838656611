import React, { useState ,useRef, useEffect} from 'react';
import { t } from 'i18next';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";

import { addComma } from '../../apis/CommonFunc';
import { axiosPost } from '../../apis/AxiosAPI';

import './SaleRegistRation.css'


function SaleData({ data, saleReigstKeyWord  }) {
    const saleRemove = (data) => {
        const requestItemParams = {
            sessionId : localStorage.getItem('sessionId'),
            realestateNo: JSON.stringify(data.realestateNo)
        };        
        let saleRemoveUrl = `realestate/trade/sellCancel`
        axiosPost(saleRemoveUrl,requestItemParams)
        .then(response => {
            alert(response.data.message);
            if(JSON.parse(response.data.result)) {
                window.location.href = '/SalesRegistRation';
            } else {
                alert(response.data.message);
            }
        }).catch(e => {
            console.log(`error message: ${e}`);                
        });
    }
    // data주소값 확인
    const saleDatas = data.address;
    // 주소값 넣기
    const saleSearchList = saleDatas.indexOf(saleReigstKeyWord);
    const saleLang = localStorage.getItem("locale");

    return (
        <>
            {saleSearchList !== -1 &&
                <div className="SaleDataContainer">
                    <ul className="saleDataInfoBox">
                        <li>
                            <span>
                                {
                                    saleLang === "ko" ?
                                        data.address
                                        :
                                        data.addressJibunEn
                                }
                            </span>
                        </li>
                        <li>
                            {
                                data.areaSter ?  
                                    <>
                                        <span>{t("sales_registration_area")}</span>
                                        <span> {addComma(data.areaSter)} ster</span>
                                    </>
                                    :
                                    <span className="registAreaNone">{t("sales_registration_area")}</span>
                            }
                            {/* <span className="buyHistoryInfoIconContainer">
                                <FontAwesomeIcon icon={faInfoCircle} className="buyHistoryInfoIcon"/>
                            </span> */}
                        </li>
                        <li>
                            <span>{t("sales_registration_purchase_price")}</span>
                            <span> {addComma(data.priceMetarex)} {t("map_land_price_area_won")}</span>
                        </li>
                        <li><span>{t("sale_history_hope_price")}</span><span>{addComma(data.priceMetarexAsk)} {t("map_land_price_area_won")}</span></li>
                    </ul>
                    <div>
                    {/* 클릭시 onRemove 함수안에 user.id값을 넣는다.
                    밑에 선언하면서 id값이 맞지 않는다면 제거하기 위해  */}
                        <button onClick={() => saleRemove(data)}>{t("sales_registration_sales_cancle_btn")}</button>
                    </div>
                </div>
            }
        </>
    );
}


const SaleRegistRation = (props) => {
    // jsonData
    const saleRagistData = props.registDatas && props.registDatas

    // 검색시 나오는 div개수 넣기
    const [saleChildCounter , setSaleChildCounter] = useState(0);
    
    // 윈도우 넓이
    const saleRegistWidth = props.saleWidth
    
    // 검색값 전달
    const saleReigstKeyWord = props.saleKeyWord
    
    // salepageNum 
    const salePageNum= props.salePageNum
    const saleMPageNum = props.saleMPageNum

    const setSalePageNum= props.setSalePageNum
    const setSaleMPageNum = props.setSaleMPageNum

    // total count
    const saleTotalCount = props.saleTotalCount

    // 클릭한 횟수
    const [saleRegistClick , setSaleRegistClick] = useState(1);
    const [saleRegistMClick , setSaleRegistMClick] = useState(1);

    
    useEffect(() => {
        if(saleRegistWidth === false){
            setSaleMPageNum(1)
            setSaleRegistMClick(1)
        }else if(saleRegistWidth === true){
            setSalePageNum(1)
            setSaleRegistClick(1)
        }
    })

    // 검색값과 그에 해당하는 json data비교. -1값이 리턴
    const addressDatas = saleRagistData && saleRagistData.map(data => data.address.indexOf(saleReigstKeyWord))
    
    // 검색값의 결과값을 파악. -1 인 개수를 필터로 가져온다. 
    let saleSearchCounter = addressDatas && addressDatas.filter(data => data !== -1).length

    // 슬라이드 감싸는애 스타일 조정하기 위해
    const saleSlider = useRef();

    // 검색시 초기값으로 되돌아가기
    useEffect(()=>{
        if(saleReigstKeyWord){
            setSaleRegistClick(1)
            setSaleRegistMClick(1)
        }
    },[saleReigstKeyWord ,setSaleRegistMClick, setSaleRegistClick])


    let saleRegistPrevSlide = (e) => {
        setSalePageNum(salePageNum - 1)
        setSaleMPageNum(saleMPageNum - 1)
    
        if(saleRegistWidth === true){
            setSaleRegistMClick(saleRegistMClick - 1)
        }else if(saleRegistWidth === false){
            setSaleRegistClick(saleRegistClick - 1)
        }
    }
    
    let saleRegistNextSlide = (e) => {
        setSalePageNum(salePageNum + 1)
        setSaleMPageNum(saleMPageNum + 1)
        
        if(saleRegistWidth === true){
            setSaleRegistMClick(saleRegistMClick + 1)
        }else if(saleRegistWidth === false){
            setSaleRegistClick(saleRegistClick + 1)
        }
    }


    // 검색시 나오는 값 넣기
    useEffect(() => {
        setSaleChildCounter(saleSlider.current.children)
    },[  saleReigstKeyWord , setSaleChildCounter ,  saleChildCounter])


    
    const [saleRegistLeftLast , setSaleRegistLeftLast ] = useState(false)
    const [saleRegistRightLast , setSaleRigstRightLast ] = useState(false)

    // slide 왼쪽
    useEffect(()=>{
        if((saleRegistClick === 1 && saleRegistWidth === false) || (saleRegistMClick === 1 && saleRegistWidth === true)){
            setSaleRegistLeftLast(true)
        }else if((saleRegistClick !== 1 && saleRegistWidth === false) || (saleRegistMClick !== 1 && saleRegistWidth === true)){
            setSaleRegistLeftLast(false)
        }
    },[saleRegistClick , setSaleRegistLeftLast , saleRegistWidth , saleRegistMClick])
    
    // slide 오른쪽
    useEffect(()=>{
        if(saleRegistWidth === false){
            if(saleRegistClick >= Math.ceil(saleTotalCount / 4)){
                setSaleRigstRightLast(true)
            }else if(saleRegistClick < saleTotalCount / 4){
                setSaleRigstRightLast(false)
            }
        }else if(saleRegistWidth === true){
            if(saleRegistMClick >= saleTotalCount){
                setSaleRigstRightLast(true)
            }else if(saleRegistMClick < saleTotalCount){
                setSaleRigstRightLast(false)
            }
        }
    },[saleRegistClick, setSaleRigstRightLast , saleRegistWidth , saleTotalCount , saleRegistMClick])

    return (
        <>
        <section className="saleContainer">
            <div className="saleSliderContainerWrap">
                    {
                        saleSearchCounter === 0 &&
                        <h1 className="unSalesSearchDataNone">{t("sales_registration_modal_confirm11")}</h1>
                    }
                <div className="saleSliderContainer" ref={saleSlider}>
                    {
                        saleRagistData && saleRagistData.map((data , i)=> 
                        <SaleData 
                            key={i} 
                            data={data} 
                            saleReigstKeyWord={saleReigstKeyWord}
                        />
                    )}
                </div>
            </div>
            <div className="saleRegistBtnContainer">
                {
                    (saleRegistLeftLast === true)?
                    <button type="submit" className="saleLeftNo" disabled>
                        <FontAwesomeIcon icon={faChevronLeft}/>
                    </button>
                    :
                    <button type="submit" className="saleLeft" onClick={saleRegistPrevSlide}>
                        <FontAwesomeIcon icon={faChevronLeft}/>
                    </button>
                }
                {
                    (saleRegistRightLast === true) ?
                    <button type="submit" className="saleRightNo" disabled>
                        <FontAwesomeIcon icon={faChevronRight}/>
                    </button>
                    :
                    <button type="submit" className="saleRight" onClick={saleRegistNextSlide}>
                        <FontAwesomeIcon icon={faChevronRight}/>
                    </button>
                } 
            </div>
        </section>
        </>
    )
}

export default SaleRegistRation;