import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";

const StyledOpenPplTemplate = styled.div`
    display: flex;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 240px;
    @media only screen and (max-width: 768px) {
        height: auto;
        padding-bottom: 0px;
    }
`;

const StyledOpenPplInformationTemplate = styled.div`
    max-width: 1440px;
    width: 100%;
    height: auto;
    margin-bottom: 120px;
    padding-top: 240px;
    @media only screen and (max-width: 768px) {
        padding-top: 0px;
    }
`;

const StyledOpenPplTitle = styled.div`
    display: flex;
    h1 {
        width: 50%;
        color: #05141F;
        font-family: 'Roboto';
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: 60px;
        letter-spacing: 0em;
        text-align: left;
        padding-left: 100px;
    }
    div {
        p{
            width: 100%;
            color: #666;
            font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 36px;
            letter-spacing: 0em;
            text-align: left;
        }
        a {
            margin-top: 5px;
            color: #3D85F0;
            width: 220px;
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #3D85F0;
            box-sizing: border-box;
            border-radius: 50px;

            font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 18px;
            letter-spacing: 0em;
            text-align: left;

            padding: 15px 20px;
            .fa-long-arrow-alt-right {
                width: 20px;
                margin-left: 10px;
            }
        }
    }
    @media only screen and (max-width: 768px) {
        flex-direction: column;
        padding: 0px 20px;
        h1 {
            width: 100%;
            padding-left: 0px;
            margin-bottom: 80px;
        }
        p{
            width: 100%;
            margin-bottom: 80px;
        }
    }
`;


const StyledOpenPplChart = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 120px;
    .circle {
        width: 220px;
        height: 220px;
        border-radius: 50%;
        background-color: rgba(61, 133, 240, 0.1);
        display: flex;
        align-items: center;
        justify-content: center;
        color: #05141F;
        font-family: 'Nanum Gothic', 'Roboto', sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;
        letter-spacing: 0em;
        text-align: center;
    }

    .youtuber {
        height: auto;
        display: flex;
        .explain {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            &:first-of-type {
                margin: 0px 80px 0px 40px;
            }
            &:last-of-type{
                margin: 0px 40px 0px 80px;

            }
            p {
                margin: 20px 0px;
                color: #05141F;
                font-family: 'Nanum Gothic', 'Roboto', sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 20px;
                letter-spacing: 0em;
                text-align: center;
            }
            img {
                &:first-of-type {
                    margin-bottom: 55px;
                }
                &:last-of-type {
                    transform: rotate(180deg);
                }
            }
        }
    }
    .promotion {
        height: auto;
        display: flex;
        .explain {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            &:first-of-type {
                margin: 0px 80px 0px 40px;
            }
            &:last-of-type{
                margin: 0px 40px 0px 80px;

            }
            p {
                margin: 20px 0px;
                color: #05141F;
                font-family: 'Nanum Gothic', 'Roboto', sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 20px;
                letter-spacing: 0em;
                text-align: center;

            }
            img {
                &:first-of-type {
                    transform: rotate(180deg);
                    margin-bottom: 55px;
                }
            }
        }
    }

    .openppl {
        display: flex;
        flex-direction: column;
        align-items: center;
        .top, .bottom {
            p {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 290px;
                height: 60px;
                border: 1px solid #3D85F0;
                background-color: #fff;

                color: #3D85F0;
                font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 16px;
                letter-spacing: 0em;
                text-align: center;
                margin-bottom: 10px;
                &:last-of-type {
                    margin-bottom: 0px;
                }
            }
        }

        .middle {
            margin: 45px 0px;
            width: 192px;
            height: 192px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #3D85F0;
            border-radius: 50%;
            color: #fff;

            font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 26px;
            letter-spacing: 0em;
            text-align: center;
            position: relative;
            &::before {
                content: " ";
                position: absolute;
                height: 45px;
                width: 1px;
                background-color: #3D85F0;
                top: -45px;
                left: 50%;
                
            }
            &::after {
                content: " ";
                position: absolute;
                height: 45px;
                width: 1px;
                top: 100%;
                left: 50%;
                background-color: #3D85F0;
            }
        }
    }

    @media only screen and (max-width: 768px) {
        flex-direction: column;
        .youtuber {
            flex-direction: column;
            align-items: center;
            .circle {
                margin-bottom: 70px;
            }
            .explain {
                flex-direction: row-reverse;
                align-items: center;
                justify-content: center;
                &:first-of-type {
                    margin: 0px 0px 0px 0px;
                }
                &:last-of-type{
                    margin: 0px 0px 0px 0px;
                }
                img {
                    &:first-of-type {
                        margin-bottom: 0px;
                        transform:rotate(90deg);
                    }
                    &:last-of-type {
                        transform:rotate(270deg);
                    }
                }
                p {
                    width: 100px;
                }
            }
        }


        .promotion {
            flex-direction: column;
            align-items: center;
            .circle {
                margin-top: 70px;
            }
            .explain {
                flex-direction: row-reverse;
                align-items: center;
                justify-content: center;
                &:first-of-type {
                    margin: 0px 0px 0px 0px;
                }
                &:last-of-type{
                    margin: 0px 0px 0px 0px;
                }
                img {
                    &:first-of-type {
                        margin-bottom: 0px;
                        transform:rotate(270deg);
                    }
                    &:last-of-type {
                        transform:rotate(90deg);
                    }
                }
                p {
                    width: 100px;
                }
            }
        }

        .openppl {
            margin: 80px 0px;
        }

        .middle {
            margin: 45px 0px;
            width: 192px;
            height: 192px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #3D85F0;
            border-radius: 50%;
            color: #fff;

            font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 26px;
            letter-spacing: 0em;
            text-align: center;
            position: relative;
            &::before {
                content: " ";
                position: absolute;
                height: 45px;
                width: 1px;
                background-color: #3D85F0;
                top: -45px;
                left: 50%;
            }
            &::after {
                content: " ";
                position: absolute;
                height: 45px;
                width: 1px;
                top: 100%;
                left: 50%;
                background-color: #3D85F0;
            }
        }
    }
    }

`;


const OpenPPL = () => {
    return (
        <StyledOpenPplTemplate>
            <StyledOpenPplInformationTemplate>
                <StyledOpenPplTitle>
                    <h1>{t('metarex_future_open_ppl_title')}<br/>OpenPPL</h1>
                    <div>
                        <p>
                            {t("metarex_future_open_ppl_p_1_1")}<br/>
                            {t("metarex_future_open_ppl_p_1_2")}<br/>
                            {t("metarex_future_open_ppl_p_1_3")}<br/>
                            {t("metarex_future_open_ppl_p_1_4")}<br/>
                        </p>
                        <a href="https://openppl.net/main.do" rel="noopener noreferrer" target="_blank" title={t("metarex_future_open_ppl_link")}>
                            {t("metarex_future_open_ppl_link")}
                            <FontAwesomeIcon icon={faLongArrowAltRight}/>
                        </a>
                    </div>
                </StyledOpenPplTitle>
                <StyledOpenPplChart>
                    <div className="youtuber">
                        <div className="circle">
                            {t("metarex_future_open_ppl_div_1")}<br/>
                            {t("metarex_future_open_ppl_div_2")}
                        </div>
                        <div className="explain">
                            <p>{t("metarex_future_open_ppl_p_2_1")}</p>
                            <img src={`${process.env.PUBLIC_URL}/images/future/arrow.svg`} alt="left_arrow"/>
                            <img src={`${process.env.PUBLIC_URL}/images/future/arrow.svg`} alt="right_arrow"/>
                            <p>{t("metarex_future_open_ppl_p_2_2")}</p>
                        </div>
                    </div>
                    <div className="openppl">
                        <div className="top">
                            <p>{t("metarex_future_open_ppl_p_3_1")}</p>
                            <p>{t("metarex_future_open_ppl_p_3_2")}</p>
                            <p>{t("metarex_future_open_ppl_p_3_3")}</p>
                        </div>
                        <div className="middle">
                            <p>
                                {t("metarex_future_open_ppl_p_4_1")}<br/>
                                {t("metarex_future_open_ppl_p_4_2")}
                            </p>
                        </div>
                        <div className="bottom">
                            <p>{t("metarex_future_open_ppl_p_5_1")}</p>
                            <p>{t("metarex_future_open_ppl_p_5_2")}</p>
                            <p>{t("metarex_future_open_ppl_p_5_3")}</p>
                        </div>
                    </div>
                    <div className="promotion">
                        <div className="explain">
                            <p>{t("metarex_future_open_ppl_p_6_1")}</p>
                            <img src={`${process.env.PUBLIC_URL}/images/future/arrow.svg`} alt="right_arrow"/>
                            <img src={`${process.env.PUBLIC_URL}/images/future/arrow.svg`} alt="left_arrow"/>
                            <p>{t("metarex_future_open_ppl_p_6_2")}</p>
                        </div>
                        <div className="circle">
                            {t("metarex_future_open_ppl_p_7_1")}<br/>
                            {t("metarex_future_open_ppl_p_7_2")}
                        </div>
                    </div>

                </StyledOpenPplChart>
            </StyledOpenPplInformationTemplate>
        </StyledOpenPplTemplate>
    )
}

export default OpenPPL;