import React from "react";
import styled from "styled-components";

const AuthCapsLockForm = styled.div`
    height: 20px;
    vertical-align: middle;
    P {
        font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
        color: #FF645A;
        font-size: 14px;
        text-align: center;
    }
`;

const AuthCapsLock = ({isCapsLock}) => {
    return (
        <AuthCapsLockForm>
            {isCapsLock && <p>* WARNING! Caps lock is ON.</p>}
        </AuthCapsLockForm>
    );
}

export default AuthCapsLock;