import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import styled from "styled-components";

import PageBanner from '../../../components/PageBanner';
import Footer from '../../../feature/Footer/Footer';
import MxLendingBox from '../../../feature/BuyHistory/MxLendingBox';

import { axiosGet } from '../../../apis/AxiosAPI';
import { isSessionValidate, isValidationSessionId } from '../../../apis/BrowserStorageAPI';
import { moveTopOnPageAuto } from '../../../apis/CommonFunc';

const MyDiv = styled.div`
    .buyHistoryContainer{
        width: 1310px; 
        height: 100%; 
        margin: 0 auto; 
        padding-top: 130px;
        margin-bottom: 100px;
        position: relative;
        @media only screen and (max-width: 768px) {
            width: 100%; 
            height: auto; 
            padding-top: 0px;
            top: 0;
            left: 0;
            background: #fff;
        }

        .metalive-description {
            @media only screen and (max-width: 768px) {
                font-size: 13px;
                padding: 20px;
                text-align: justify;
            }

            .download-meta-live-wrapper {
                padding-top: 20px;
                @media only screen and (max-width: 768px) {
                    text-align: left;
                }   
            }
        }

        .notice {
            margin: 0px auto;
            text-align: center;
            @media only screen and (max-width: 768px) {
                margin-top: 30px;
                padding: 10px 20px;
                text-align: justify;
            }   
            color: #333;
        }

        
    }
`;

const BuyHistory = (props) => {

    // width값 토대로 모바일 pc 구분.
    const [isMobile , setIsMobile] = useState(null);

    // 모바일 사이즈 확인
    const mobileCheck = () => {
        if(window.innerWidth < 769) {
            setIsMobile(true);
        } else if (window.innerWidth > 769){
            setIsMobile(false);
        }
    };
    
    /**
     * 화면 로딩 직후, 화면 리사이즈 이벤트
     */
    useEffect(() => {
        window.addEventListener('load', mobileCheck);
        window.addEventListener('scroll', mobileCheck);
        window.addEventListener('resize', mobileCheck);
        
        return () => {
            window.removeEventListener('load', mobileCheck);
            window.removeEventListener('scroll', mobileCheck);
            window.removeEventListener('resize', mobileCheck);
        } 
    }, [isMobile , mobileCheck]);

    // Variable containing purchased MX-Lending products
    const [mxLendingItemsArray , setMxLendingItemsArray] = useState([]);

    // Total count for purchased MX-Lending products.
    const [mxLendingItemsTotalCount , setMxLendingItemsTotalCount] = useState(0);
    
    // pc버전 클릭여부에 따라 스타일값 초기화
    const [buyClickCount , setBuyClickCount] = useState(0);
    
    // 페이징
    const [pageNumPc, setPageNumPc] = useState(1);
    const [pageNumMobile, setPageNumMobile] = useState(1);
    
    // 모바일 슬라이더 버튼 클릭 회수
    const [historyMobileClick , setHistoryMobileClick] = useState(1);
    
    // pc 슬라이더 버튼 클릭 회수
    const [historyPcClick , setHistoryPcClick] = useState(1);

    // get items buyed MX-Lending
    const getItems = ( ) => { 

        const pageNum       = isMobile === true ? pageNumMobile : pageNumPc;
        const numPerPage    = isMobile === true ? 1 : 3;

        console.log(`thkim test 20230323 pageNum=${pageNum} pageNumMobile=${pageNumMobile}`);
    
        setMxLendingItemsArray([]);
        axiosGet(`mx-lending?pageNum=${encodeURI(pageNum)}&numPerPage=${encodeURI(numPerPage)}`)
            .then(response => {
                if(JSON.parse(response.data.result)){
                    // 모바일인지 pc인지 데이터를 받아오기전에 판별하기 위해 이곳에 mobileCheck() 함수 적용
                    // 받아온 데이터를 저장, 데이터가 없을시 null처리
                    setMxLendingItemsArray([response.data.data ? response.data.data : null]);
                    // totalCount값을 저장
                    setMxLendingItemsTotalCount(response.data.totalCount);
                } 
            }).catch(e => {
                console.log(`error message: ${e}`);
            });
    };

    useEffect(() => {
        if(!isSessionValidate()) {
            alert(t('market_view_login'));
            window.location.href ='/signin';
            return;
        }
        isValidationSessionId()
            .then(() => {
                if(!isSessionValidate()) {
                    window.location.href ='/signin';
                    return;
                }
            });
        moveTopOnPageAuto();
        mobileCheck();
    }, []);
    
    useEffect(() => {
        if( isMobile == null ) {
            return;
        }
        getItems();
    }, [ pageNumPc, pageNumMobile, isMobile ]);
    
    return(
        <MyDiv style={{paddingTop: '60px'}}>
            <fieldset><fieldset></fieldset></fieldset>
            <PageBanner style={{backgroundImage: "url('/images/bg/buying_banner.jpg')"}}>{t('header_mx_lending')}</PageBanner>
            <section className="buyHistoryContainer">
            <div className="notice">
                <p>{t('mx_lending_notice_calculate')}</p>
            </div>
            {
                mxLendingItemsArray.map((data, i) => 
                    (
                        <MxLendingBox
                            key={i}
                            historyData={data}

                            // 화살표 클릭 개수 가져옴
                            buyClickCount={buyClickCount}
                            setBuyClickCount={setBuyClickCount}
                            
                            // 페이징 pc
                            setPageNumPc={setPageNumPc}
                            pageNumPc={pageNumPc}

                            // 페이징 mobile
                            pageNumMobile = {pageNumMobile}
                            setPageNumMobile = {setPageNumMobile}

                            isMobile={isMobile}
                            totalCount={mxLendingItemsTotalCount}

                            // 클릭 모바일
                            setHistoryMobileClick={setHistoryMobileClick}
                            historyMobileClick={historyMobileClick}

                            // 클릭 pc
                            historyPcClick={historyPcClick}
                            setHistoryPcClick={setHistoryPcClick}

                            mxLendingItemsArray={mxLendingItemsArray}
                            getItems={getItems}
                        />
                    )
                )
            }                
            </section>
            <Footer />
        </MyDiv>
    )
}

export default BuyHistory;
