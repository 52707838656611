import React from "react";
import styled from "styled-components";

const AuthButtonForm = styled.button`
    width: 100%;
    height: 48px;
    background-color: #05141F;
    color: #fff;
    border: none;
    cursor: pointer;
    padding: 10px;
    font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
        background-color: #3D85F0;
        transition: 0.5s;
    }
    &.auth {
        width: 95px;
        font-weight: 400;
        &:hover {
            background-color: #3D85F0;
            transition: 0.5s;
        }
    }
`;

const AuthButton = ({children, onClick, auth}) => {
    return (
        <AuthButtonForm onClick={onClick} className={auth && "auth"}>
            {children}
        </AuthButtonForm>
    );
}

export default AuthButton;