import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { addComma } from '../../apis/CommonFunc';

import './MarketBoard.css'

const MarketBoard = ({data}) => {
    const table = () => {
        const date = data.arrayListPriceTrendDate;
        const price = data.arrayListPriceTrend;
        const list = [];
        for(let i = 0; i < date.length; i++) {
            list.push(
                <tr key={i}>
                    {/* 번호 1씩 증가 */}
                    <td>{[i+1]}</td>
                    {/* 시간 제거 */}
                    <td>{date[i].substr(0,11)}</td>
                    {/* addComma함수로 판매가 콤마 처리 */}
                    <td>{addComma(price[i])}</td>
                </tr>
            );
        }
        return list;
    }
    
    return(
        <table className='marketTable'>
            <thead className='marketThead'>
                <tr>
                    <th>번호</th>
                    <th>날짜</th>
                    <th>판매가</th>
                </tr>
            </thead>
            <tbody>
                { table().map(el => el) }
            </tbody>
        </table>
    )

}

export default MarketBoard;
