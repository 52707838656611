import React, { useEffect, useState } from 'react';
import './map.css';
import './map.mobile.css';
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import Header from '../../feature/Map/header/header';
import HeaderM from '../../feature/Map/header/header.mobile';
import SideInfo from '../../feature/Map/sideInfo/sideInfo';
import SideInfoM from '../../feature/Map/sideInfo/sideInfoMobile';
import AddressList from '../../feature/Map/sideInfo/addressList';
import AddressListM from '../../feature/Map/sideInfo/addressListMobile';
import KakaoMap from '../../feature/Map/kakaoMap/kakaoMap';
import PopupPurchaseRequisition from '../../components/Modal/Buy/PopupPurchaseRequisition';
import { t } from 'i18next';

export default function Map(props) {
    const location = useLocation();
    const params = queryString.parse(location.search);
    // 검색어
    const [keyword, setKeyword] = useState(params.keyword ? params.keyword : undefined);
    // 검색 타입
    const [searchType, setSearchType] = useState(params.keyword ? 'url' : '');
    // 주소 정보
    const [address, setAddress] = useState(undefined);
    // 카카오맵 API 객체
    const [kakaoMap, setKakaoMap] = useState(undefined);
    // 모바일 여부
    const [isMobile, setMobile] = useState(window.innerWidth);
    // 주소 목록 출력 여부
    const [isShowList, setShowList] = useState(false);
    // 상단 헤더 영역에서 검색 버튼 클릭 여부
    const [isClickSearch, setClickSearch] = useState();

    // 주소 목록의 출력 여부(pc)
    const [listOnOff, setListOnOff] = useState(false);


    // 로딩
    const [mapLoading, setMapLoading] = useState(false)

    // sessionId는 로그인 후 받는 값으로 서버와 통신시 사용됨
    if( params.sessionId !== null && typeof params.sessionId !== 'undefined') {
        localStorage.setItem('sessionId', params.sessionId);
    }

    /**
     * 화면 리사이즈 이벤트
     */
    const resizeHandler = () => {
        if(window.innerWidth < 1024) {
            setMobile(true);
        } else if (window.innerWidth > 1024){
            setMobile(false);
        }
    };

    {/*지도에서 판매 완료된 매물 선택 시, 사용된다. src\feature\Map\kakaoMap\KakaoMapScript.js에서 사용된다.*/}
    const [clickedSoldoutItem , setClickedSoldoutItem] = useState(() => function() {
        console.log( '판매 완료된 매물이 선택 됨.' );
        return true;
    });

    {/* 지도에서 매물 선택시, /gio/info/get로 부터 받아온 data값*/}
    const [keywordData, setKeywordData] = useState(undefined);

    {/* 판매 완료 물건 선택 시, 역경매 신청 팝업*/}
    const [isPopupPurchaseRequisitionShow , setIsPopupPurchaseRequisitionShow] = useState(false);

    /**
     * 화면 로딩 직후, 화면 리사이즈 이벤트
     */
    useEffect(() => {
        window.addEventListener('load', resizeHandler);
        window.addEventListener('resize', resizeHandler);
        
        return () => {
            window.removeEventListener('load', resizeHandler);
            window.removeEventListener('resize', resizeHandler);
        } 
    }, [isMobile]  );

    return (
        <div className='metaMapContainer'>
            {/* 클릭 마커. 맵부분 */}
            <KakaoMap 
                keyword={keyword} 
                setSearchType={setSearchType} 
                setAddress={setAddress} 
                setKakaoMap={setKakaoMap} 
                isMobile={isMobile} 
                mapLoading={mapLoading}
                clickedSoldoutItem={clickedSoldoutItem}
                setKeywordData={setKeywordData}
                keywordData={keywordData}
                setIsPopupPurchaseRequisitionShow={setIsPopupPurchaseRequisitionShow}
            />
             {/* PC 화면 */}
            {typeof isMobile === false || window.innerWidth > 1024 && 
                <main className='pc'>                       {/* 상단 헤더 */}
                    <Header setKeyword={setKeyword} setSearchType={setSearchType} setListOnOff={setListOnOff} listOnOff={listOnOff}/>   

                    {/* 좌측 주소, 토지, 메타렉스 등 상세정보 */}
                    <SideInfo address={address} keyword={keyword} searchType={searchType} mapLoading={mapLoading} setMapLoading={setMapLoading}
                    setClickedSoldoutItem={setClickedSoldoutItem}
                    keywordData={keywordData}
                    setIsPopupPurchaseRequisitionShow={setIsPopupPurchaseRequisitionShow}>
                        {/* 주소 목록 */}
                        <AddressList 
                            keyword={keyword} 
                            setKeyword={setKeyword} 
                            searchType={searchType} 
                            setSearchType={setSearchType} 
                            setAddress={setAddress} 
                            kakaoMap={kakaoMap} 
                            setListOnOff={setListOnOff}
                            listOnOff={listOnOff}/>
                    </SideInfo>
                </main>
            }

            {/* 모바일 화면 */}
            {typeof isMobile === true || window.innerWidth < 1024 && 
                <main className='mobile'>
                    
                    {/* 상단 헤더 (모바일) */}
                    <HeaderM 
                        address={address} 
                        searchType={searchType} 
                        setKeyword={setKeyword} 
                        setSearchType={setSearchType} 
                        setShowList={setShowList} 
                        setClickSearch={setClickSearch}
                    />

                    {/* 주소, 토지, 메타렉스 등 상세정보 (모바일) */}
                    <SideInfoM 
                        address={address} 
                        keyword={keyword} 
                        searchType={searchType} 
                        isClickSearch={isClickSearch} 
                        setClickedSoldoutItem={setClickedSoldoutItem}
                        keywordData={keywordData}
                        setIsPopupPurchaseRequisitionShow={setIsPopupPurchaseRequisitionShow}
                    />
                    
                    {/* 주소 목록 (모바일) */}
                    <AddressListM 
                        isShow={isShowList}
                        keyword={keyword} 
                        setKeyword={setKeyword} 
                        searchType={searchType} 
                        setSearchType={setSearchType} 
                        setAddress={setAddress} 
                        kakaoMap={kakaoMap} 
                        isClickSearch={isClickSearch}
                    />
                </main>
            }

        {
            isPopupPurchaseRequisitionShow && 
            <PopupPurchaseRequisition 
            setIsShow={setIsPopupPurchaseRequisitionShow}
            popupTitle={t("make_purchase_request")}
            popupData={keywordData}
            popupImageClickLink={"/notice?boardNo=34"}
            popupImageClickLinkTarget={"_self"}
            showsHidingCheckBoxForADay = {false}
            />
        }
            
        </div>
    ); 
}
