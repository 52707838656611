import React, { useState } from "react";
import styled from "styled-components";
import { t } from "i18next";
import Footer from "../feature/Footer/Footer";
import Button from "../feature/HowToUse/Button";
import HowToUseItem from "../feature/HowToUse/HowToUseItem";
import PageBanner from "../components/PageBanner";
import PageTab from "../components/PageTab";
import SupportTemplate from "../components/SupportTemplate";
import { buyMap, buyMarket, sell, atcExchangeProcessMap, managementItemMap } from "../data/howToUse";

import User from "../feature/Renewal20221005/component/User";

const ToggleTemplate = styled.div`
    width: 1200px;
    min-height: 100vh;
    height: 100% ;
    @media only screen and (max-width: 768px) {
        padding: 0px 0px;
    }
`;

const ManualTemplateTitle = styled.div`
    @media only screen and (max-width: 768px) {            
        padding-top: 14px;
    }
    display: block;
    .manual-template-title-label {
        font-size: 30px;
        @media only screen and (max-width: 768px) {            
            font-size: 24px;
        }
        background: linear-gradient(to top, #abf200 20%, transparent 30%);
        &.manual-template-title-label-management-item {
            background: linear-gradient(to top, #ff007f 20%, transparent 30%);
        }
        &.manual-template-title-label-atc-exchange {
            background: linear-gradient(to top, #ffbb00 20%, transparent 30%);
        }
        
    }
`; 

const ManualTemplate = styled.div`
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(auto, auto);
    grid-gap: 15px;
    padding: 40px 20px;
    @media only screen and (max-width: 768px) {
        grid-template-columns: repeat(1, 1fr);
    }
`; 

const SubTitle = styled.div`
    font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
    font-size: 26px;
    font-weight: 700;
    line-height: 22px;
    text-align: left;
    margin-bottom: 70px;
    margin-top: 60px;
`;

const ButtonTemplate = styled.div`
    border-bottom: 2px solid #05141F;
    padding-bottom: 20px;    
    @media only screen and (max-width: 400px) {            
        padding-top: 20px;
        text-align: center;
    }
    >button {
        margin-bottom: 10px;
    }
`;

const HowToUse = () => {
    const BUTTON_TYPE_MAP = 'map'; 
    const BUTTON_TYPE_MARKET = 'market'; 
    const BUTTON_TYPE_SELL = 'sell';

    const [clicked, setClicked] = useState({
        map: true,
        market: false,
        sell: false
    });

    const onClick = (e) => {
        const buttonType = e.target.dataset.type;

        if(clicked[buttonType]) {
            return;
        }

        const newClicked = {
            map: false,
            market: false,
            sell: false
        }
        setClicked({...newClicked, [buttonType] : !newClicked[buttonType]});
    }

    return (
        <>
        <SupportTemplate>
            <PageBanner style={{backgroundImage: "url('/images/bg/support_banner.jpg')"}}>{t('manual_title')}</PageBanner>            
            <PageTab category="support" type="manual"></PageTab>
            <div>
                <ToggleTemplate>
                    <SubTitle>{t('manual_sub_title')}</SubTitle>
                    <User />
                    <ButtonTemplate>
                        <Button clicked={clicked[BUTTON_TYPE_MAP]} type={BUTTON_TYPE_MAP} onClick={onClick}>{t('manual_tab_menu_map')}</Button>
                        <Button clicked={clicked[BUTTON_TYPE_MARKET]} type={BUTTON_TYPE_MARKET} onClick={onClick}>{t('manual_tab_menu_market')}</Button>
                        <Button clicked={clicked[BUTTON_TYPE_SELL]} type={BUTTON_TYPE_SELL} onClick={onClick} width='156px'>{t('manual_tab_menu_sale')}</Button>
                    </ButtonTemplate>
                    <ManualTemplateTitle>
                        <span className="manual-template-title-label">{t("meta_rex_process")}</span>
                    </ManualTemplateTitle>
                    <ManualTemplate>
                        {
                            clicked[BUTTON_TYPE_MAP] 
                                ? (buyMap.map((el, index) => <HowToUseItem 
                                                                key={index} 
                                                                title={localStorage.getItem('locale') === 'ko' 
                                                                            ? el.title_kr 
                                                                            : localStorage.getItem('locale') === 'en' 
                                                                                ? el.title_en
                                                                                : el.title_cn} 
                                                                detail={localStorage.getItem('locale') === 'ko' 
                                                                            ? el.detail_kr 
                                                                            : localStorage.getItem('locale') === 'en' 
                                                                                ? el.detail_en
                                                                                : el.detail_cn}
                                                            />)
                                                            )
                                : clicked[BUTTON_TYPE_MARKET] 
                                    ? buyMarket.map((el, index) => <HowToUseItem 
                                                                        key={index} 
                                                                        title={localStorage.getItem('locale') === 'ko' 
                                                                                    ? el.title_kr 
                                                                                    : localStorage.getItem('locale') === 'en' 
                                                                                        ? el.title_en
                                                                                        : el.title_cn} 
                                                                        detail={localStorage.getItem('locale') === 'ko' 
                                                                                    ? el.detail_kr 
                                                                                    : localStorage.getItem('locale') === 'en' 
                                                                                        ? el.detail_en
                                                                                        : el.detail_cn}
                                                                    />)
                                    : sell.map((el, index) => <HowToUseItem 
                                                                    key={index} 
                                                                    title={localStorage.getItem('locale') === 'ko' 
                                                                                ? el.title_kr 
                                                                                : localStorage.getItem('locale') === 'en' 
                                                                                    ? el.title_en
                                                                                    : el.title_cn} 
                                                                    detail={localStorage.getItem('locale') === 'ko' 
                                                                                ? el.detail_kr 
                                                                                : localStorage.getItem('locale') === 'en' 
                                                                                    ? el.detail_en
                                                                                    : el.detail_cn}
                                                                />)
                        }
                    </ManualTemplate>
                    {
                        (clicked[BUTTON_TYPE_MAP] == true || clicked[BUTTON_TYPE_MARKET] == true) && 
                        <div>
                            <ManualTemplateTitle>
                                    <span className="manual-template-title-label manual-template-title-label-atc-exchange">{t("atc_listing_exchange_process")}</span>
                                </ManualTemplateTitle>
                                <ManualTemplate>
                                    {atcExchangeProcessMap.map(
                                        (el, index) => <HowToUseItem 
                                                    className="how-to-use-item-atc-exchange"
                                                    key={index} 
                                                    title={localStorage.getItem('locale') === 'ko' 
                                                                ? el.title_kr 
                                                                : localStorage.getItem('locale') === 'en' 
                                                                    ? el.title_en
                                                                    : el.title_cn} 
                                                    detail={localStorage.getItem('locale') === 'ko' 
                                                                ? el.detail_kr 
                                                                : localStorage.getItem('locale') === 'en' 
                                                                    ? el.detail_en
                                                                    : el.detail_cn}
                                            />
                                        )
                                    }
                            </ManualTemplate>

                            <ManualTemplateTitle>
                                    <span className="manual-template-title-label manual-template-title-label-management-item">{t("memberRelated")}</span>
                                </ManualTemplateTitle>
                                <ManualTemplate>
                                    {managementItemMap.map(
                                        (el, index) => <HowToUseItem 
                                                    key={index} 
                                                    title={localStorage.getItem('locale') === 'ko' 
                                                                ? el.title_kr 
                                                                : localStorage.getItem('locale') === 'en' 
                                                                    ? el.title_en
                                                                    : el.title_cn} 
                                                    detail={localStorage.getItem('locale') === 'ko' 
                                                                ? el.detail_kr 
                                                                : localStorage.getItem('locale') === 'en' 
                                                                    ? el.detail_en
                                                                    : el.detail_cn}
                                            />
                                        )
                                    }
                            </ManualTemplate>
                        </div>
                    }
                    
                </ToggleTemplate>
            </div>
        </SupportTemplate>
        <Footer/>
        </>
    );
}

export default HowToUse;
