import React, { useState , useRef, useEffect } from 'react';
import './UnRegistRation.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { useTranslation} from "react-i18next";
import SalesModal from '../../feature/SalesRegistRation/SalesModal'
import { t } from 'i18next';
import ModalScreen from '../../components/Modal/ModalScreen';
import { addComma } from '../../apis/CommonFunc';

// 콤마 찍어주는 함수
const UnRegistPriceFormat = (str) => {
    const comma = (str) => {
        str = String(str);
        return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,");
    };
    const uncomma = (str) => {
        str = String(str);
        return str.replace(/[^\d]+/g, "");
    };
        return comma(uncomma(str));
};

// 실제 화면에 나타나는 매물
function UnRegistRationData({data, registBtn , unSaleReigstKeyWord }){

    const {t} = useTranslation();
    // data주소값 확인
    const unAddressDatas = data.address
    // 주소값 넣기
    const unSearchList = unAddressDatas.indexOf(unSaleReigstKeyWord);

    const unRegistLang = localStorage.getItem("locale");
    return(
        <>
            {unSearchList !== -1 &&
                <div className="unDataContainer" >
                    <ul className="unDataInfoBox">
                        <li>
                            <span>
                                    {
                                        unRegistLang === "ko" ?
                                            data.address
                                            :
                                            data.addressJibunEn
                                    }
                            </span>
                        </li>
                        <li>
                            {
                                data.areaSter ?  
                                    <>
                                        <span>{t("sales_registration_area")}</span>
                                        <span> {addComma(data.areaSter)} ster</span>
                                    </>
                                    :
                                    <span className="unRegistAreaNone">{t("sales_registration_area")}</span>
                            }
                        </li>
                        <li>
                            <span>{t("sales_registration_purchase_price")}</span>
                            <span> {UnRegistPriceFormat(data.priceMetarex)} {t("map_land_price_area_won")}</span>
                        </li>
                        <li>
                            <span>{t("sales_registration_date_of_purchase")}</span>
                            <span> {data.insertedTs}</span>
                        </li>
                    </ul>
                    <div>
                        <button onClick={() => registBtn(data.realestateNo)}>{t("sales_registration_sales_registration_btn")}</button>
                    </div>
                </div>
            }
        </>
    )
} 


const UnRegistRation = (props) => {
    // const {t} = useTranslation();
    
    // 검색값 받아옴.
    const setModal = props.setModal;
    
    // json
    const UnRegistData = props.UnRegistDatas;

    // pc 모바일 구분
    const unRegistWidth = props.unWidth;
    
    // 검색값 전달
    const unSaleReigstKeyWord = props.unSaleKeyWord

    // pageNum setstate 설정
    const setUnSalePageNum = props.setUnSalePageNum
    const setUnSaleMPageNum = props.setUnSaleMPageNum

    // pageNum state 설정
    const unSalePageNum = props.unSalePageNum
    const unSaleMPageNum = props.unSaleMPageNum

    // total count 
    const unSaleTotalCount = props.unSaleTotalCount
    
    // 검색값과 그에 해당하는 json data비교. -1값이 리턴
    const unAddressDatas = UnRegistData && UnRegistData.map(data => data.address.indexOf(unSaleReigstKeyWord))
    
    // 검색값의 결과값을 파악. -1 인 개수를 필터로 가져온다. 
    let searchCounter = unAddressDatas && unAddressDatas.filter(data => data !== -1).length

    const [unId , setUnId] = useState()

    const registBtn = realestateNo => {
        // 클릭한 id값이 2라면. !== 2가 아닌값을 출력시킨다. 
            setUnId(UnRegistData.filter(data=> data.realestateNo === realestateNo));
            setModal(false)
        }

        
    // 클릭한 횟수
    const [unRegistClick , setUnRegistClick] = useState(1);
    const [unRegistMClick , setUnRegistMClick] = useState(1);
    
    
    
    useEffect(() => {
        if(unRegistWidth === false){
            setUnSaleMPageNum(1)
            setUnRegistMClick(1)
        }else if(unRegistWidth === true){
            setUnSalePageNum(1)
            setUnRegistClick(1)
        }
    })

    // 슬라이드 감싸는애 스타일 조정하기 위해
    const UnSlider = useRef();

    // 검색시 초기값으로 되돌아가기
    useEffect(()=>{
        if(unSaleReigstKeyWord){
            setUnRegistClick(1)
            setUnRegistMClick(1)
        }
    },[unSaleReigstKeyWord , setUnRegistClick, setUnRegistMClick])
    
    let unPrevSlide = (e) => {
        setUnSalePageNum(unSalePageNum - 1) 
        setUnSaleMPageNum(unSaleMPageNum - 1) 

        if(unRegistWidth === true){
            setUnRegistMClick(unRegistMClick -1)
        }else if(unRegistWidth === false){
            setUnRegistClick(unRegistClick -1)
        }
    }

    let unNextSlide = (e) => {
        setUnSalePageNum(unSalePageNum+ 1) 
        setUnSaleMPageNum(unSaleMPageNum + 1) 
        
        if(unRegistWidth === true){
            setUnRegistMClick(unRegistMClick + 1)
        }else if(unRegistWidth === false){
            setUnRegistClick(unRegistClick + 1)
        }
    }

    const [unSaleRegistLeftLast , setUnSaleRegistLeftLast ] = useState(false)
    const [unSaleRegistRightLast , setUnSaleRigstRightLast ] = useState(false)

    // slide 왼쪽
    useEffect(()=>{
        if( (unRegistWidth === false && unRegistClick === 1) ||( unRegistWidth === true && unRegistMClick === 1) ){
            setUnSaleRegistLeftLast(true)
        }else if((unRegistWidth === false && unRegistClick !== 1) || (unRegistWidth === true && unRegistMClick !== 1)){
            setUnSaleRegistLeftLast(false)
        }

    },[unRegistClick , unRegistWidth , unRegistMClick])

    // slide 오른쪽
    useEffect(()=>{
        if(unRegistWidth === false){
            if(unRegistClick >= Math.ceil(unSaleTotalCount / 4) ){
                setUnSaleRigstRightLast(true)
            }else if(unRegistClick < unSaleTotalCount / 4){
                setUnSaleRigstRightLast(false)
            }
        }else if(unRegistWidth === true){
            if(unRegistMClick >= unSaleTotalCount){
                setUnSaleRigstRightLast(true)
            }else if(unRegistMClick < unSaleTotalCount){
                setUnSaleRigstRightLast(false)
            }
        }
    },[unRegistClick, setUnSaleRigstRightLast , unRegistWidth , unSaleTotalCount , unRegistMClick])
    
    return(
        <section className="unRegistContainer">
            <div className="unSliderContainerWrap">
                    {
                        searchCounter === 0 &&
                        <h1 className="unSalesSearchDataNone">{t("sales_registration_modal_confirm11")}</h1>
                    }
                <div className="unSliderContainer" ref={UnSlider}>
                    {
                        UnRegistData && UnRegistData.map((data , i)=> 
                        <UnRegistRationData 
                            key={i} 
                            data={data} 
                            registBtn={registBtn} 
                            unSaleReigstKeyWord={unSaleReigstKeyWord}
                            />
                    )}
                </div>
            </div>
            <div className="unRegistBtnContainer">
                {
                    (unSaleRegistLeftLast === true)?
                    <button type="submit" className="UnLeftNo" disabled>
                        <FontAwesomeIcon icon={faChevronLeft}/>
                    </button>
                    :
                    <button type="submit" className="UnLeft" onClick={unPrevSlide}>
                        <FontAwesomeIcon icon={faChevronLeft}/>
                    </button>
                }
                {
                    (unSaleRegistRightLast === true) ?
                    <button type="submit" className="UnRightNo" disabled>
                        <FontAwesomeIcon icon={faChevronRight}/>
                    </button>
                    :
                    <button type="submit" className="UnRight" onClick={unNextSlide}>
                        <FontAwesomeIcon icon={faChevronRight}/>
                    </button>
                } 
            </div>
            {
                !props.modal && 
                    <ModalScreen>
                    {/* 등록 버튼 누를시 나오는 모달창 */}
                    {
                        UnRegistData && UnRegistData.map((data, i) => 
                            (
                                <SalesModal 
                                    key={i} 
                                    salesModalDatas={data} 
                                    setModal={setModal}
                                    unId={unId}
                                    modal ={props.modal}
                                >
                                </SalesModal>
                            ))
                    }
                    </ModalScreen>
                // </div>
            }  
        </section>
    )
}

export default UnRegistRation;