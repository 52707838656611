import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ReactLoading from 'react-loading';

{/**
 빙글 빙글 로딩 화면을 보여주기 위해 생성했다.
 thkim
 2022-11-21
*/}
const ModalScreenDiv = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, .6);
    z-index: 999;
    .loading-wrapper {
        width: 120px;
        height: 120px;
        padding-top: 30vh;
        text-align: center;
        .loading {
        }
        h2 {
            margin-top: 43px;
            color: #ffffff;
        }
    }
`;

// 모달창이 뜰 경우 배경에 깔리는 검은색 배경
const ModalLoading = ({children}) => {
    const [isStart, setIsStart] = useState(true);
    useEffect(()=>{
        var root = document.getElementsByTagName( 'html' )[0];
        if(isStart){
            root.setAttribute( 'class', 'stop-scroll' );
        }
        return () => {
            root.removeAttribute( 'class', 'stop-scroll' );
            setIsStart(false);
        }
    }, [])

    return (
        <ModalScreenDiv>
            <div className="loading-wrapper">
                {/**
                <ReactLoading className="loading" type={'spin'} color={'skyblue'} height={'100%'} width={'100%'} />
                */}
                <h2>{children}</h2>
            </div>
        </ModalScreenDiv>
    )
}

export default ModalLoading;
