import { combineReducers } from "redux";
import userReducer from "./user/reducer";
import { persistReducer } from "redux-persist";
import storage from 'redux-persist/lib/storage'

// redux-persist > https://github.com/rt2zz/redux-persist
const persistConfig = {
    key: 'root',
    storage,
}

const rootReducer = combineReducers({
    user: userReducer
});

export default persistReducer(persistConfig, rootReducer);