import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import History from "../../../feature/MetaRex/profile/History";
import '../../../components/animation.css';

const StyledProfileCard = styled.div`
    visibility: hidden;
    position: relative;
    width: 600px;
    height: 100%;
    background-color: transparent;
    max-width: 600px;
    box-shadow: 0px 20px 16px 0px #9090901A;
    border: 1px solid #EEE;
    padding: 40px;
    &:nth-child(even) {
        margin-top: 400px;
    }
    &:nth-child(odd) {
        margin-bottom: 350px;
    }
    img {
        width: 150px;
        height: 150px;
        border-radius: 50%;
        object-fit: cover;
    }

    &.on {
        visibility: visible;
        animation: slideUp 1s .5s;
        animation-fill-mode: both;
    }
    
    @media only screen and (max-width: 768px) {
        width: 100%;
        max-width: 100%;
        box-shadow: 0px 50px 46px 0px #9090901A;
        padding: 20px;
        &:nth-child(even) {
            margin-top: 0px;
            margin-bottom: 125px;
        }
        &:nth-child(odd) {
            margin-bottom: 125px;
        }
    }
`;

const StyledTitle = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 60px;
    .position {
        height: 100%;
        width: calc(100% - 170px);
        color: #8196b5;
        font-family: 'Roboto';
        font-size: 71px;
        font-style: normal;
        font-weight: 700;
        line-height: 70px;
        margin-left: 20px;
        text-align: right;
        .name {
            margin-top: 10px;
            margin-right: 5px;
            color: #000;
            font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
            font-size: 26px;
            font-style: normal;
            font-weight: 800;
            line-height: 26px;
            letter-spacing: 0em;
            text-align: right;
        }
    }
    @media screen and (max-width:767px) {
        height: 150px;
        align-items: center;
        .position {
            p {
                font-size: 34px;
                line-height: 34px;
            }
            .name {
                font-size: 18px;
                line-height: 18px;
                margin-right: 2px;
            }
        }
    }
`;

const ProfileCard = ({ profileData }) => {
    const url = `${process.env.PUBLIC_URL}/images/profile/${profileData.img_name}`;

    const [scrollLocation, setScrollLoacation] = useState(0);
    const [fadeIn, setFadeIn] = useState(false);
    const profileCardEl = useRef();

    const listener = () => {
        setScrollLoacation(window.pageYOffset);
    }

    useEffect(() => {
        window.addEventListener('scroll', listener);
        setFadeIn(profileCardEl.current.offsetTop < scrollLocation + window.innerHeight);
        return () => { window.removeEventListener('scroll', listener)}
    }, [scrollLocation])

    return (
        <StyledProfileCard ref={profileCardEl} className={fadeIn ? "on" : ""}>
            {(profileData.img_name || profileData.position)  && 
                <StyledTitle>
                    {profileData.img_name && 
                        <img src={url} alt={profileData.img_name} title={profileData.img_name.split(".")[0]}/>
                    }
                    <div className="position">
                        <p>{profileData.position}</p>
                        <p className="name">{profileData.name_kr}</p>
                    </div>
                </StyledTitle>
            }            
            <>
                <History
                    history={profileData.history_cur}
                    etc={profileData.history_cur_etc}
                />
                {
                    (profileData.history_prev !== '' || profileData.history_prev_etc !== '') &&
                    <History 
                        history={profileData.history_prev}
                        etc={profileData.history_prev_etc}
                    /> 
                }
            </>
        </StyledProfileCard>
    );
}

export default ProfileCard;