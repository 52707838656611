import React from "react";
import styled from "styled-components";

const AuthFieldSetForm = styled.fieldset`
    margin:0;
    background-color: #fff;
    width: 400px;
    border: none;
    display: flex;
    flex-direction: column;
    transition: 0.5s;
    legend {
        font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 34px;
        text-align: center;
        margin-bottom: 50px;
    }
    label {
        width: 100%;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
    }

    @media only screen and (max-width: 768px) {
        padding-left: 20px;
        padding-right: 20px;
    }
`;

const AuthFieldSet = ({children, title, style}) => {
    return (
        <AuthFieldSetForm style={style}>
            <legend>{title}</legend>
            {children}
        </AuthFieldSetForm>
    );
}  

export default AuthFieldSet;