import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { t } from "i18next";

import { useDevice } from "../../hooks/useDevice";
import { useInput } from "../../hooks/useInput";


import { getPointAmount } from '../../apis/PointAPI';

const MainDiv = styled.div`
    padding: 20px;
`;

const PaddingDiv = styled.div`
    padding: 20px;
`;

const PointBalanceDiv = styled.div`
    margin-top: 30px;
    font-size: 30px;
    font-weight: 700;
    text-align: center;
`;
const OnePointOneDollarDiv = styled.div`
    margin-top: 10px;
    font-size: 18px;
    text-align: center;
    color: #D2465A;
`;
const HowMuchDiv = styled.div`
    margin-top: 59px;
    font-size: 16px;
    text-align: center;
    color: #D2465A;
`;

const AmountP = styled.p`
    margin-top: 14px;
    font-size: 14px;
    text-align: left;
    font-weight: 500;
`;


const AmountInput = styled.input`
    width: 100%;
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 18px;
    vertical-align: middle;
    text-align: left;
    padding: 10px 10px;
    color: #D2465A;
    font-weight: 600;
    border: 1px solid #a3a3a3;
    border-radius: 4px;
`;
const MaximumP = styled.p`
    margin-top: 0px;
    font-size: 12px;
    text-align: left;
    font-weight: 400;
`;

const PaymentEnterAmount = ({args, setArgs, setOnClickNextButtonPrevius}) => {


    const  { updateInput } = useInput(setArgs);
    const deivce = useDevice();

    const [pointAmount , setPointAmount] = useState(0);
    
    useEffect(() => {
        getPointAmount()
            .then(response => {
                if(JSON.parse(response.data.result)){
                    setPointAmount( response.data.data );
                    {/* 현재보유 point가 dollar로 환산한 수량보다 작다면*/}
                    
                } else {
                    alert(response.data.message);
                }
            }).catch(e => {
                console.log(`getPointAmount error message: ${e}`);
            });
    }, []);

    useEffect(() => {

        setOnClickNextButtonPrevius (
            () => () => {
                if( args['amount'] == null ) {
                    alert(`${t('please_enter_amount')}`);                    
                    return false;
                }
    
                if( args['amount'] <= 0.1 ) {
                    alert(`${t('point_payment_point_amount_very_low')}`);
                    return false;
                }

                if( args['amount'] <= 0 ) {
                    alert(`${t('please_enter_amount')}`);
                    return false;
                }
                
                setArgs(prev => prev && {
                    ...prev,
                    ['amount']: Number(args['amount']),
                });

                return true;
            }
        );
        

    }, [args]);

    return (
        <MainDiv>
            <PointBalanceDiv>
                {pointAmount} point
            </PointBalanceDiv>
            <OnePointOneDollarDiv>
            {t('1Point_equal_1dollar')}
            </OnePointOneDollarDiv>
            <div>
                <HowMuchDiv>
                {t('how_much_would_you_like_to_purchase')}
                </HowMuchDiv>
                <AmountP>{t('point_amount_usd')}</AmountP>
                <AmountInput type="number" name="amount" maxLength="10" onChange={updateInput} value={args.amount != 0 && args.amount}/>
                <MaximumP>{t('point_maximum_usd')}</MaximumP>
            </div>
        </MainDiv>
    )
}

export default PaymentEnterAmount;