import React from "react";
import styled from "styled-components";

const StyledButton = styled.button`
    height: 52px;
    width: ${(props) => props.width || '137px'};
    @media only screen and (max-width: 400px) {            
        width: 90%;
    }
    left: 294px;
    top: 0px;
    border-radius: 10px;
    padding: 18px, 24px, 18px, 24px;
    border: 1px solid #D9DDE2;
    background: #fff;

    margin-right: 10px;
    font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
    font-size: 15px;
    font-weight: 700;
    line-height: 16px;
    text-align: center;
    cursor: pointer;
    &.clicked {
        color: #fff;
        background: #3D85F0;
        cursor: auto;
    }
`;

const Button = ({children, width, clicked, onClick, type}) => {
    return (
        <StyledButton width={width} onClick={onClick} data-type={type} className={clicked && 'clicked'}>{children}</StyledButton>
    );
}

export default Button;