import React, { useEffect, useState } from "react";
import { t } from "i18next";
import styled from "styled-components";

import { moveTopOnPageAuto } from "../apis/CommonFunc";

import ReactLoading from 'react-loading';
import ContactForm from "../feature/ContactUs/ContactForm";
import ModalScreen from "../components/Modal/ModalScreen";


const StyledContactUsTemplate = styled.div`
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 140px;
    @media only screen and (max-width: 768px) {
        padding: 140px 20px 0px 20px;
        height: auto;
    }
`;

const StyledContactUsTitle = styled.h1`
    font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 34px;
    letter-spacing: 0em;
    text-align: left;
    color: #05141F;
    @media only screen and (max-width: 768px) {
        font-size: 24px;
        line-height: 24px;
    }
`;


const ContactUs = () => {
    const [isSending, setIsSending] = useState(false);

    useEffect(() => {
        moveTopOnPageAuto();
    }, []);

    return (
        <>
        {
            isSending &&
                <ModalScreen>
                    <ReactLoading type={'spin'} color={'skyblue'} height={'40px'} width={'40px'} />
                </ModalScreen>
        }
        <StyledContactUsTemplate>
            <StyledContactUsTitle>{t("contact_us_title")}</StyledContactUsTitle>
            <ContactForm setIsSending={setIsSending}/>
        </StyledContactUsTemplate>
        </>
    )
}

export default ContactUs;