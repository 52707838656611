import { t } from "i18next";
import { axiosPost } from "./AxiosAPI";
import { isNull } from "./Validation";


export class AsterpayAPI {
    constructor(url) {
        this.url = url;
    }

    아스터페이_실행하기 = (asterPayment, asterpayObj) => {
        asterPayment.requestPayment(asterpayObj);
    }

    onMessage = (e, json, openModal, setIsAsterLoading, setStartAsterPayment) => {
        // e => event
        // json => 아스터 페이에 전달하는 데이터
        // openModal => 모달창에서 진행되는 거래의 경우 모달창을 열고 닫는 함수(예_지분구매), 없을 시에는 undefined 가 들어온다
        // setIsAsterLoading => 아스터페이 진행중 보여주는 로딩바 관련 함수
        // setStartAsterPayment => 서버측에 리퀘스트를 요청, 거래의 단계 변화를 나타내기 위한 함수
        if( typeof e.data !== undefined ) {
            try {
                const messageJSON = JSON.parse( e.data );

                if( messageJSON.message === 'onStartAsterPayment') {
                    console.log('Asterpayment >> start');
                    return;
                } else if( messageJSON.message === 'onStopAsterPayment') {
                    json.paymentRequestNo = messageJSON.data.paymentRequestNo;
                    console.log('Asterpayment >> stop');
                    return;
                } else if( messageJSON.message === 'onDoneAsterPayment') {
                    console.log('Asterpayment >> end');
                    return;
                } else if( messageJSON.message === 'close') {
                    if(typeof window.closeMessageHandler != 'undefined')  {
                        window.closeMessageHandler();
                    }
                }

            } catch (err) {
                return;
            }
        }


    };


}

const 매물결제시작_요청하기 = async (url, json, setStartAsterPayment) => {
    axiosPost(url, json)
        .then(response => {
            if(JSON.parse(response.data.result)) {
                setStartAsterPayment(true);
            } else {
                window.close();
                alert(response.data.message);
                if(!isNull(response.data.code) && response.data.code === "NO_HAVE_SHARES") {
                    window.location.href = window.location.pathname;
                } else if(response.data.message === '로그인이 필요합니다.') {
                    localStorage.removeItem('sessionId');
                    window.location.href ='/signin';
                }
            }
        }).catch(e => {
            console.log(`error message: ${e}`);
        });
}

const 매물결제취소_요청하기 = async (url, json, setStartAsterPayment) => {
    axiosPost(url, json)
        .then(response => {
            if(JSON.parse(response.data.result)){
                alert(t("market_view_payment_canceled"));
                setStartAsterPayment(false);
            }
        }).catch(e => {
            console.log(`error message: ${e}`);
        });
}

const 매물결제완료_요청하기 = async (url, json) => {
    axiosPost(url, json)
        .then(response => {
            if(JSON.parse(response.data.result)) {
                // 성공
            } else {
                alert(response.data.message);
            }
        }).catch(e => {
            console.log(`error message: ${e}`);
        });
}
