import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import { t } from 'i18next';
import { Link } from "react-router-dom";

import Event20220616Item from '../../../feature/Sales/Events/Event20220616Item';

import PageBanner from '../../../components/PageBanner';
import Payment from '../../../components/Payment/Payment';
import Footer from '../../../feature/Footer/Footer';

import { axiosGet } from '../../../apis/AxiosAPI';
import { moveTopOnPageAuto } from '../../../apis/CommonFunc';


import { getPriceDollarKRW } from "../../../apis/PriceDollarAPI";


{/**
MX-LENDING Event
 */}
const MainDiv = styled.div`
    padding-top: 60px;
    @media only screen and (max-width: 768px) {
    }

    .banner-1-plus-1 {
        font-size: 70px;
        color: #7e9ac5;
        @media only screen and (max-width: 768px) {
            font-size: 56px;
        }
    }

    .content-body {
        margin: 70px auto;
        width: 800px;
        @media only screen and (max-width: 768px) {
            width: 100vw;
            max-width: 768px;
            padding: 0px 40px;
        }

        .what-is-mx-lending {
            font-size: 30px;
            color: #3963a5;
            margin-bottom: 20px;
        }

        .mx-product-introduce-title {
            width: 100%;
            color: #444444;
            font-size: 24px;
            font-weight: 200;
            margin-bottom: 20px;
        }


        .mx-lending-introduce-list {
            margin-left: 19px;
            li {
                list-style: disk;
            }
        }


        .how-to-join-mx-lending {
            font-size: 28px;
            color: #3963a5;
        }

        .event-join-button {
            font-size: 20px;
            margin: 0px auto;
            margin-top: 40px;
            background: #05141F;
            color: #ffffff;
            width: auto;
            max-width: 300px;
            padding: 10px 20px;
            border: 1px solid #222;
        }



    }

`;


const Event20221224 = (props) => {

    
    return(
        <MainDiv >
            <PageBanner style={{backgroundImage: "url('/images/bg/market_banner.jpg')"}}>
                <span className="banner-1-plus-1">MX-Lending</span>
                <br />
                {"글로벌 진출 기념"}
                <br />
                {"이벤트"}
            </PageBanner>
            <section className="content-body">
                <p className="what-is-mx-lending" >MX-Lending이란?</p>
                <p className="mx-product-introduce-title">
                    MX-Lending 서비스는 신규 물건 구매 시, 물건을 아스터코인으로 교환해주는 상품입니다.
                </p>
                <ul className="mx-lending-introduce-list" >
                    <li>자기 준비금 최소 10%</li>
                    <li>7일 단위 정산</li>
                    <li>월/목 신규 참여 가능</li>
                    <li>차액분은 포인트로 충전 또는 ATC로 출금 가능</li>
                    <li>매주 최대 10명 신규 신청 가능</li>
                    <li>총 100명 달성시 이벤트 종료</li>
                </ul>
                <br />
                <br />
                <br />
                <hr />
                <br />
                <h4 className="how-to-join-mx-lending">참여 방법</h4>
                <br />
                <p>
                    <ol className="mx-lending-introduce-list" >
                        <li>본 이벤트는 월, 목 오전 09시 ~ 오후 04시에만 참여가 가능합니다.</li>
                        <li>'아래 이벤트 참여하기' 버튼을 누르시고 원하는 매물을 구매 요청합니다.</li>
                        <li>구매 승인이 완료되면 수신된 링크를 통해 구매를 진행해줍니다.</li>
                        <li>구매 시, MX-Lending 상품으로 진행하셔야 합니다.</li>
                        <li>물건과 교환된 아스터코인은 구매 시점 8일 이후 부터 출금이 가능합니다.</li>
                    </ol>
                    
                </p>
                <div className="event-join-button-wrapper" >
                    <Link to="/map">
                        <button className="event-join-button" type="button">{"이벤트 참여 하기"}</button>
                    </Link>
                </div> 
            </section>
            <Footer/>
        </MainDiv>
    )
}

export default Event20221224;
