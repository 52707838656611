import React, { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import './Input.css'
import { useTranslation } from "react-i18next";
import { useEffect } from 'react';

const Input = ({
    setMarketKeyWord , marketInputValue , setMarketInputValue
}) => {
    const {t} = useTranslation();

    const marketInputChange = (e) => {
        setMarketInputValue(e.target.value)
    }

    // 검색창에서 엔터 이벤트
    const marketKeyPress = (e) => {
        if(e.charCode === 13) {
            marketSearch();
        }
    };

    // 클릭 버튼 클릭
    const marketSearch = () => {
        // if(marketInputValue === '') {
        //     alert('지번을 입력하세요.');
        //     return;
        // }
        // 클릭 버튼 클릭 후 Map 컴포넌트에 검색어와 검색 타입 전달
        setMarketKeyWord(marketInputValue);
    };

    return( 
        <div className="marketSearchDropContainer">
            <div className='marKetSearchContainer'>
                <input type="text" id="keyword" size="15" maxLength='35' className='marketInput' placeholder={t("market_view_input_placeholder")} onChange={marketInputChange}  onKeyPress={marketKeyPress} /> 
                <button type="submit" className='marketSearchIcon' onClick={marketSearch}>
                    <FontAwesomeIcon icon={faSearch} className="searchSvg"/>
                </button>
            </div> 
        </div>
    )
}

export default Input;