import axios from 'axios';
import { numberToKorean } from '../kakaoMap/KakaoMapScriptUtil'
import {EnAxios} from '../kakaoMap/enAxios'
import { addComma } from "../../../apis/CommonFunc";

import { isValidationSessionId, isSessionValidate } from '../../../apis/BrowserStorageAPI';

/**
 * 화면에 출력하기 위한 토지 정보 및 메타렉스 가격정보 생성
 */
const landData = (data) => {
    const unit_price = data.estimated_price ? data.estimated_price.estimated_unit_price : 0;
    const lot_price = data.estimated_price ? data.estimated_price.estimated_lot_price : 0;
    let area = data.parcel_info ? data.parcel_info.area : 0;
    // 토지 면적
    let landSize = Math.round(area / 3.3);
    // 건축나이
    let buildAge = data.parcel_info ? data.parcel_info.age : 0;
    // 층수
    let floorSize = data.parcel_info ? data.parcel_info.floor : 0;
    // 토지가격
    let landPrice = numberToKorean(lot_price);
    // 토지 평당 가격
    let landPriceArea = numberToKorean(unit_price);
    // 메타렉스 가격
    let metaLandPrice = numberToKorean(lot_price * 0.00003);
    // 메타렉스 평당 가격
    let metaLandPriceArea = numberToKorean(unit_price * 0.00003);
    
    if(landSize) {
        landSize = landSize;
    } else {
        landSize = ' - ';
    }

    if(buildAge) {
        buildAge = buildAge;
    } else {
        buildAge = ' - ';
    }

    if(floorSize) {
        floorSize = floorSize;
    } else {
        floorSize = ' - ';
    }

    const d = {
        landSize: landSize,
        buildAge: buildAge,
        floorSize: floorSize,
        landPrice: landPrice,
        landPriceArea: landPriceArea,
        metaLandPrice: metaLandPrice,
        metaLandPriceArea: metaLandPriceArea
    };

    return d;
};

/**
 * 화면에 출력하기 위한 지번, 도로명 생성
 * URL로 키워드가 전달된 경우 지번, 도로명 주소
 */
const initMetarexAddressData = (data) => {
    return {
        address: data.parcel_info ? data.parcel_info.address : '-',
        addressRoad: data.parcel_info ? data.parcel_info.address_road : '-'
    };
};

const kakaoLang = localStorage.getItem("locale");

/**
 *  화면에 출력하기 위한 지번, 도로명 생성
 * 주소 목록에서 클릭한 경우 지번, 도로명 주소 
 */
// 맵 클릭후 검색버튼 누를때 호출
const initAddressListData = (data) => {
    let addr = data.address ? data.address.address_name : '-';
    let addrRoad = data.address ? data.address.road_address_name : '-';

    if(addrRoad === '-') {
        if(data.road_address) {
            addrRoad = data.road_address ? data.road_address.address_name : '-';
        }
    }

    return {
        address: addr,
        addressRoad: addrRoad
    };
};

/**
 * 화면에 출력하기 위한 지번, 도로명 생성
 * 맵에서 클릭한 경우 지번, 도로명 주소
 */
const initAddressData =  async (data) => {
    const details = data.address ? data.address.address_name : '  ';
    let res =  await EnAxios(details)

    let addr;
    let addrRoad;
    
    if(addrRoad === '-') {
        if(data.address) {
            addrRoad = data.address.road_address_name ? data.address.road_address_name : '-';
        }
    }

    const enSearchAddressCounter = res.data.data && res.data.data.results.juso.length

    // 배열로 받는 jsondata값들이 있음. 그것들이 잘못된 데이터들. 일단 배열의 길이가 1보다 크면 -처리후 한국어 주소 출력
    // && test 가 배열 갯수. 10개는 방배동 이런식으로 상세주소 없는것들
    if(kakaoLang !== 'ko' && enSearchAddressCounter < 10){
        addr = res.data.data ? res.data.data.results.juso[0].jibunAddr : '-'
        addrRoad = res.data.data ? res.data.data.results.juso[0].roadAddr : '-'
    }else if(enSearchAddressCounter > 9){
        addr = data.address ? data.address.address_name : '-';
        addrRoad = data.road_address ? data.road_address.address_name : '-';
    }

    return {
        address: addr,
        addressRoad: addrRoad
    };
};

const krInitAddressData = (data) => {

    let addr = data.address ? data.address.address_name : '-';
    let addrRoad = data.road_address ? data.road_address.address_name : '-';
    
    if(addrRoad === '-') {
        if(data.address) {
            addrRoad = data.address.road_address_name ? data.address.road_address_name : '-';
        }
    }

    return {
        address: addr,
        addressRoad: addrRoad
    };
};
/**
 * 서버에 매물 구매 요청
 */
const requestBuyItem = (address, keywordData, setIsPopupPurchaseRequisitionShow) => {
    

    if( typeof keywordData !== 'undefined' && JSON.parse(keywordData.isSoldout) ) {
        if( JSON.parse(keywordData.isMine) ) {
            alert('본인의 매물은 구매 불가합니다.');
            return false;
        }        
        setIsPopupPurchaseRequisitionShow(true);
    } else {
        isValidationSessionId()
        .then(() => {
            if(!isSessionValidate()) {
                window.location.href ='/signin';
                return;
            } else {
                if(window.confirm("매물을 구매요청하시겠습니까?")) {
                    const requestItemParams = {
                        sessionId : localStorage.getItem('sessionId'),
                        keyword : address
                    };        
            
                    axios.defaults.withCredentials = true;
                    axios.post(`${process.env.REACT_APP_API_METAREX}/api/realestate/setRequestItem`, requestItemParams , {withCredentials : true} )
                    .then(res => {
                        alert(res.data.message);
                        if(res.data.href){
                            window.location.replace(res.data.href)
                        }
                    });
                }
            }
        });
    }

};

export {
    landData,
    initMetarexAddressData,
    initAddressData,
    initAddressListData,
    requestBuyItem,
    krInitAddressData
};