import React, { useLayoutEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { t } from "i18next";
import { axiosGet } from "../../apis/AxiosAPI";
import { mobile } from "../../apis/Validation";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'

const StyledMainBannerTemplate = styled.section`
    padding-top: 210px;
    width: 100vw;    
    height: 1064px;
    background-image: url('images/bg/bg_youtube.svg');
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;

    @media screen and (max-width:786px) {
        height: 100%;
    }
`;

const StyledCoverDiv = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1064px;
    background-color: rgba(0,0,0,0.8);
    z-index: 2;
    @media screen and (max-width:786px) {
        display: none;
    }
`;

const StyledContentsDiv = styled.div`
    z-index: 3;
    width: 100vw;
    max-width: 1440px;
    height: 100%;
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .slogan {
        margin-left: 20px;
        h1 {
            color: #fff;
            font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
            font-size: 40px;
            font-style: normal;
            font-weight: 800;
            line-height: 54px;
            letter-spacing: 0em;
            text-align: left;
            margin-bottom: 130px;
        }
        a {
            color: #fff;
            background-color: #3D85F0;
            width: 235px;
            height: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50px;
            font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px;
            letter-spacing: 0em;
        }

    }
    .youtube {
        width: 771px;
        @media screen and (max-width:786px) {
            width: 100%;
        }
        height: 431px;
        display: block;
        margin-right: 20px;
        @media screen and (max-width:786px) {
            margin-right: 0px;
        }
        

        .mexc-logo-div {
            background: rgba(0,0,0,0.0);
            text-align: center;
            padding: 16px 0px;
            .signup-mexc {
                color: #ffffff;
                font-size: 42px;
                vertical-align: super;
                font-weight: 600;
                ::after {
                    content: ' ';
                }
            }
            .signup-mexc-in-korean {
                color: #ffffff;
                font-size: 34px;
                vertical-align: super;
                font-weight: 600;
                ::before {
                    content: ' ';
                }
            }
            .mexc-logo {
                width: 300px;
            }
        }

        .tencommunity-contract-statistics-report-div {         
                         
            a {
                text-align: center;
                div {
                    text-align: center;
                    color: #ffffff;                    
                    .fa-file-pdf {
                        font-size:50px;
                        margin-bottom: 10px;
                    }
                    h5 {
                        font-weight: 600;
                        font-size: 18px;
                    }
                }
            }
        }
    }

    @media screen and (max-width:786px) {
        max-width: 100%;
        flex-direction: column-reverse;
        height: 100%;
        background-color: rgba(0,0,0,0.8);
        .slogan {
            width: 100%;
            margin: 0px;
            h1 {
                font-size: 25px;
                margin-top: 170px;
                margin-bottom: 50px;
                margin-left: 20px;
                margin-right: 20px;
            }
            a {
                margin-left: 20px;
                margin-bottom: 40px;
            }
        }
        .youtube {            
            .mexc-logo-div {
                .signup-mexc {
                    font-size: 22px;
                    font-weight: 400;
                    ::after {
                        content: ' ';
                    }
                }
                .signup-mexc-in-korean {
                    font-size: 20px;
                    font-weight: 600;
                }
                .mexc-logo {
                    width: 160px;
                }
            }
        }
    }

`;

const MainBanner = () => {
    const [youtubeURL, setYoutubeURL] = useState(mobile()
                                                        ? 'https://www.youtube.com/embed/{YOUTUBE_CODE}?&amp;autoplay=1&amp;loop=1&amp;rel=0&amp;showinfo=0&amp;color=white&amp;iv_load_policy=3&amp;playlist={YOUTUBE_CODE}&amp;controls=1'
                                                        : 'https://www.youtube.com/embed/{YOUTUBE_CODE}?&amp;autoplay=1&amp;loop=1&amp;rel=0&amp;showinfo=0&amp;color=white&amp;iv_load_policy=3&amp;playlist={YOUTUBE_CODE}&amp;controls=1&amp;mute=1');
    const [video, setVideo] = useState();

    useLayoutEffect(() => {
        getVideoInformation();
    }, []);

    const getVideoInformation = () => {
        axiosGet('mainVideo/select')
            .then(response => {
                if(JSON.parse(response.data.result)){
                    setVideo((response.data.data)[0]);
                    return;
                }
                alert(response.data.message);
            })
            .catch(error => {
                console.error(error);
            });
    }

    return (
        <StyledMainBannerTemplate>
            <StyledCoverDiv className="blur"/>
            {
                video &&
                <StyledContentsDiv>
                    <div className="slogan">
                        <h1>
                            <div dangerouslySetInnerHTML={{__html: localStorage.getItem('locale') === 'ko' 
                                                                    ? video.sloganKr 
                                                                    : localStorage.getItem('locale') === 'en' 
                                                                        ? video.sloganEn 
                                                                        : video.sloganCn}}></div>
                        </h1>
                        <Link to="/map" className="trading-btn">{t('main_buy_metarex')}</Link>
                    </div>
                    <div className="youtube">
                        <iframe
                                loading="lazy"
                                width="100%"
                                height="100%"
                                title="metaREX"
                                src={youtubeURL.replaceAll("{YOUTUBE_CODE}", video.url)} 
                                frameBorder="0" 
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                                allowFullScreen>
                        </iframe>
                        <div className="mexc-logo-div">                            
                            <a href="https://m.mexc.com/auth/signup?inviteCode=1Q9KH" 
                            rel="noopener noreferrer" 
                            target="_blank"
                            title="MEXC Global">
                                <span className="signup-mexc">{t('signup_mexc')}</span>
                                <img src="../../../images/logo/exchange/MEXC_Global_white.svg" alt="MEXC Global" className="mexc-logo" />
                                <span className="signup-mexc-in-korean">{t('signup_mexc_in_korean')}</span>
                            </a>
                        </div>
                        <div className="tencommunity-contract-statistics-report-div">                            
                            <a href="../../../docs/tencommunity_contract_statistics_report_20220805_1254.pdf" 
                            download="../../../docs/tencommunity_contract_statistics_report_20220805_1254.pdf"
                            rel="noopener noreferrer" 
                            target="_blank"
                            title="MEXC Global">
                                <div>
                                    <FontAwesomeIcon icon={faFilePdf}/>
                                </div>
                                <div>
                                    <h5>Tencommunity 계약통계 Report (05 Aug, 2022)</h5>
                                </div>
                            </a>
                        </div>
                    </div>
                </StyledContentsDiv>
            }
        </StyledMainBannerTemplate>
    )
}

export default MainBanner;