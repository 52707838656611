import { 
    AxiosUrl
} from './axiosUrl';

const { kakao } = window; // 이걸 해줘야 카카오 api 에서 사용하는 변수들을 리엑트가 알 수 있다.

/**
 * 매개변수로 전달받은 정수의 세자리마다 ','(콤마)를 찍어 문자열로 변환
 */
function numberFormat(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

/**
 * 정수를 원 단위 문자열로 변경
 */
function numberToKorean(number){
    let test = localStorage.getItem("locale")
        if(test === 'ko'){
            let inputNumber  = number < 0 ? false : Math.ceil(number);
            let unitWords    = []
            if(test === 'ko'){
                unitWords = ['', '만', '억', '조']
            }else{
                unitWords =  [ '','','','']
            }
            let splitUnit = 10000;
            let splitCount   = unitWords.length;
            let resultArray  = [];
            let resultString = '';
        
            for (let i = 0; i < splitCount; i++){
                let unitResult = (inputNumber % Math.pow(splitUnit, i + 1)) / Math.pow(splitUnit, i);
                //inputNumber 에는 가격 정보들이 전부 포함되어있다. i의 개수는 splitCount의 개수 즉 조까지의 자리수만큼 나눠줌.   
                unitResult = Math.floor(unitResult);
                if (unitResult > 0){
                    resultArray[i] = unitResult;
                }
            }
            
            for (let i = 0; i < resultArray.length; i++){
                if(!resultArray[i]) continue;
                resultString = String(numberFormat(resultArray[i])) + unitWords[i] + resultString;
            }
            return resultString;
        }else{
            let inputNumber  = number < 0 ? false : number;
            let numbers = Math.ceil(inputNumber)
            return numberFormat(numbers)
        }
    }


/**
 * // 좌표로 행정동 주소 정보를 요청합니다
 */
function searchAddrFromCoords(geocoder, coords, callback) {
    geocoder.coord2RegionCode(coords.getLng(), coords.getLat(), callback);         
}

/**
 * 좌표로 법정동 상세 주소 정보를 요청합니다
 */
function searchDetailAddrFromCoords(geocoder, coords, callback) {
    geocoder.coord2Address(coords.getLng(), coords.getLat(), callback);
}

// 마커를 생성하고 지도 위에 마커를 표시하는 함수입니다
function addMarker(mk, mks, m, position, idx, title) {
    // 마커 이미지 url, 스프라이트 이미지를 씁니다
    const imageSrc = 'https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/marker_number_blue.png';         
    // 마커 이미지의 크기
    const imageSize = new kakao.maps.Size(36, 37);
    const imgOptions =  {
        spriteSize : new kakao.maps.Size(36, 691), // 스프라이트 이미지의 크기
        spriteOrigin : new kakao.maps.Point(0, (idx*46)+10), // 스프라이트 이미지 중 사용할 영역의 좌상단 좌표
        offset: new kakao.maps.Point(13, 37) // 마커 좌표에 일치시킬 이미지 내에서의 좌표
    };
    const markerImage = new kakao.maps.MarkerImage(imageSrc, imageSize, imgOptions);
    
    mk = new kakao.maps.Marker({
        position: position, // 마커의 위치
        image: markerImage 
    });

    mk.setMap(m); // 지도 위에 마커를 표출합니다
    mks.push(mk);  // 배열에 생성된 마커를 추가합니다

    return mk;
}

// 지도 위에 표시되고 있는 마커를 모두 제거합니다
function removeMarker(markers) {
    for ( let i = 0; i < markers.length; i++ ) {
        markers[i].setMap(null);
    }   
    markers = [];
}

// 검색결과 목록 또는 마커를 클릭했을 때 호출되는 함수입니다
// 인포윈도우에 장소명을 표시합니다
// 사용안함
// function displayInfowindow(mk, m, infowindow, title) {
//     let content = '<div style="padding:5px;z-index:1;">' + title + '</div>';
//     infowindow.setContent(content);
//     infowindow.open(m, mk);
// }

// 검색결과 목록의 자식 Element를 제거하는 함수입니다
function removeAllChildNods(el) {   
    while (el.hasChildNodes()) {
        el.removeChild (el.lastChild);
    }
}

// 장소검색이 완료됐을 때 호출되는 콜백함수 입니다
function placesSearchCB(data, status, pagination) {
    if (status === kakao.maps.services.Status.OK) {
        // 정상적으로 검색이 완료됐으면 검색 목록과 마커를 표출합니다
        displayPlaces(data);
    }
}

// 검색 결과 목록과 마커를 표출하는 함수입니다
function displayPlaces(mk, mks, m, places, keyword) {
    const listEl = document.getElementById('placesList');
    const menuEl = document.getElementById('menu_wrap');
    const fragment = document.createDocumentFragment();
    const bounds = new kakao.maps.LatLngBounds();

    localStorage.setItem("placeLists", listEl);
    
    if(listEl !== null){
        // 검색 결과 목록에 추가된 항목들을 제거합니다
        removeAllChildNods(listEl);

        // 지도에 표시되고 있는 마커를 제거합니다
        removeMarker(mks);

        for (let i = 0; i <places.length; i++) {
            // 마커를 생성하고 지도에 표시합니다
            const placePosition = new kakao.maps.LatLng(places[i].y, places[i].x);
            mk = addMarker(mk, mks, m, placePosition, i);
            const itemEl = getListItem(i, places[i]); // 검색 결과 항목 Element를 생성합니다

            // 검색된 장소 위치를 기준으로 지도 범위를 재설정하기위해 LatLngBounds 객체에 좌표를 추가합니다
            bounds.extend(placePosition);
            fragment.appendChild(itemEl);
        }

        // 검색결과 항목들을 검색결과 목록 Elemnet에 추가합니다
        listEl.appendChild(fragment);
        menuEl.scrollTop = 0;

        // local에 있는 keyword값 받아온뒤. listEl. 즉 ul태그 앞에 p태그로 넣는것.
        let keywordAppend = localStorage.getItem("keyword");

        let choiceP = document.createElement("li");
        choiceP.innerHTML = "<p class=choice>" + keywordAppend + "</p>";

        listEl.prepend(choiceP);

        // 검색된 장소 위치를 기준으로 지도 범위를 재설정합니다
        m.setBounds(bounds);
    }

    // 검색결과 항목을 Element로 반환하는 함수입니다
    function getListItem(index, places, setPlaceClick, polyline ) {
        let el = document.createElement('li'),
        itemStr = '<span class="markerbg marker_' + (index+1) + '"></span>' +
                    '<div class="info">' +
                    '   <h5>' + places.place_name + '</h5>';
        if (places.road_address_name) {
            itemStr += '    <span>' + places.road_address_name + '</span>' +
                        '   <span class="jibun gray">' +  places.address_name  + '</span>';
        } else {
            itemStr += '    <span>' +  places.address_name  + '</span>'; 
        }

            itemStr += '  <span class="tel">' + places.phone  + '</span>' +
                    '</div>';           
        el.innerHTML = itemStr;
        el.className = 'item';

        el.addEventListener( 'click',  () => {
            // 목록 클릭시 영역 표시
            async function placeClick() {  
                AxiosUrl(places.address_name)
            
                if(!!AxiosUrl.searchData.data.estimated_price){
                const cord =  AxiosUrl.searchData.data.estimated_price.other_parcel[0].geometry.coordinates[0];
                const linePath = cord.map( ([x,y]) => {
                    return new kakao.maps.LatLng(y,x)
                    })
                    if(AxiosUrl.searchData !== null){
                        polyline.setPath(linePath);
                        polyline.setMap(m);
                        let moveLatLng = new kakao.maps.LatLng(cord[0][1] , cord[0][0]);   
                        m.panTo(moveLatLng);
                    }else {
                        polyline.setMap(null);
                    }
                }
            }
            return placeClick();
        })
        return el;
    }
    
}

// 키워드 검색을 요청하는 함수입니다
function searchPlaces(ps, keyword) {
    if(!keyword) {
        return;
    }

    if (!keyword.replace(/^\s+|\s+$/g, '')) {
        alert('키워드를 입력해주세요!');
        return false;
    }

    // 장소검색 객체를 통해 키워드로 장소검색을 요청합니다
    ps.keywordSearch(keyword, placesSearchCB); 
}

// 지도 좌측상단에 지도 중심좌표에 대한 주소정보를 표출하는 함수입니다
function displayCenterInfo(result, status, keyword) {
    if (status === kakao.maps.services.Status.OK) {
        let infoDiv = document.getElementById('centerAddr');

        for(let i = 0; i < result.length; i++) {
            // 행정동의 region_type 값은 'H' 이므로
            if (result[i].region_type === 'A') {
                infoDiv.InnerHTML = result[i].address_name;
                break;
            }
        }
    }    

    // Search
    // 장소 검색 객체를 생성합니다
    // let markers = [];

    let ps = new kakao.maps.services.Places();  

    // 검색 결과 목록이나 마커를 클릭했을 때 장소명을 표출할 인포윈도우를 생성합니다
    // let infowindow = new kakao.maps.InfoWindow({zIndex:1});

    // 키워드로 장소를 검색합니다
    searchPlaces(ps, keyword);
}

export { 
    numberFormat, 
    numberToKorean, 
    searchAddrFromCoords, 
    searchDetailAddrFromCoords, 
    addMarker, 
    removeMarker, 
    // displayInfowindow, 
    removeAllChildNods,
    placesSearchCB,
    displayPlaces,
    searchPlaces,
    displayCenterInfo,
};