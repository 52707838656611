import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import styled from "styled-components";
import ReactLoading from 'react-loading'; 
import { t } from "i18next";
import Banner from "./Banner";
import { mobile } from "../../apis/Validation";
import { axiosGet } from "../../apis/AxiosAPI";

import './LastestTradingHistory.css';

const LoadingProgressBarDiv = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, .1);
`

const LastestTradingHistory = () => {
    const [recentTradingListContents, setRecentTradingListContents] = useState([]);

    useLayoutEffect(() => {
        최근_거래_내역_요청하기();
    },[]);

    const 최근_거래_내역_요청하기 = async () => {
        axiosGet('realestate/p2p/recentTradingList')
            .then(response => {
                if(Number(response.status) === 200) {
                    setRecentTradingListContents(response.data);
                }
            })
            .catch(e => {
                alert(t('metarex_alert_server_problem'));
                console.error(`error Trading History: ${e}`);
            });
    }

    // --- rolling event start
    const [curLocation, setCurLocation] = useState(0);  // 배너 맨 왼쪽의 현재 위치
    const rollingStyles = {
        transform: `translate(${curLocation}px)`
    }

    const containerRef = useRef();

    useEffect(() => {
        let move = 270;             // 한 번 움직일 때의 이동 거리
        const ROLLING_SPEED = 3500; // interval 속도
        if(recentTradingListContents.length !== 0) {
            const containerWindowSize = containerRef.current === 'undefined' ? 0 : window.innerWidth;
            const containerLen = containerRef.current.scrollWidth;
            const interval = setInterval(() => {
                if(containerLen - containerWindowSize <= Math.abs(curLocation-move)) {
                    move = ((containerLen - containerWindowSize) % move);
                    if(!mobile()) { move += 16; }   // scollbar 가로 사이즈
                }
                setCurLocation(prevLocation => prevLocation-move);

                if(containerLen - containerWindowSize <= Math.abs(curLocation)){ setCurLocation(0) } 
            }, ROLLING_SPEED);
            return () => clearInterval(interval);    
        }
    }, [curLocation, recentTradingListContents]);

    // 브라우저의 가로 크기보다 최근 거래내역이 적을 경우, 거래내역을 브라우저의 가로 크기만큼 반복하여 채운다.
    const 최근_거래_내역_보여주기 = () => {
        const SIZE_ONE_BANNER = 250;
        const BROWSER_SIZE = window.innerWidth;
        const BANNER_SIZE = SIZE_ONE_BANNER * recentTradingListContents.length;
        let count = recentTradingListContents.length + 1;

        if(BANNER_SIZE < BROWSER_SIZE) {
            const NECESSARY_SIZE = BROWSER_SIZE - BANNER_SIZE;
            count += (Math.ceil(NECESSARY_SIZE / SIZE_ONE_BANNER));
        }

        let index = 0;
        const array = [];
        for(let i = 0; i < count; i++){
            array.push(recentTradingListContents[index]);
            if(index === (recentTradingListContents.length-1)) {
                index = 0;
                continue;
            }
            index++;
        }

        return array;
    }
    // --- rolling event end
    
    return (
        <section className="section-lastest-trading">
            <div className="wrap">
                <div className="slider">
                    {recentTradingListContents.length !== 0 
                        ?
                        <div className="container" style={rollingStyles} ref={containerRef}>
                            {최근_거래_내역_보여주기().map((content, index) => <Banner content={content} key={index}/>)}
                        </div>
                        :
                        <LoadingProgressBarDiv>
                            <ReactLoading type={'spin'} color={'skyblue'} height={'40px'} width={'40px'} />
                        </LoadingProgressBarDiv> 
                    }
                </div>
            </div>
        </section>
    )
}

export default LastestTradingHistory;