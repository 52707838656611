import React, { useLayoutEffect, useState } from "react";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import ReactLoading from 'react-loading';
import styled from "styled-components";

import { isSessionValidate } from "../../apis/BrowserStorageAPI";
import { isNull } from "../../apis/Validation";
import { addComma } from "../../apis/CommonFunc";
import { axiosGet } from "../../apis/AxiosAPI";
import { AsterpayAPI } from "../../apis/AsterpayAPI";

import ModalScreen from "../../components/Modal/ModalScreen";
import BuyRealestate from "../../components/Modal/contents/BuyRealestate";
import ModalSmall from "../../components/Modal/ModalSmall";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";


const StyledItemTemplate = styled.div`
    position:relative;
    background-color:rgba(255, 255, 255, 0.2);
    width:264px;
    height:400px;
    margin:0px 10px;
    .address{
        color:#000;
        font-family:'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
        font-size:14px;
        font-style:normal;
        font-weight:400;
        line-height:18px;
        cursor:pointer;
        &:hover{
            color:#3D85F0;;
            transition:.5s;
        }
    }
    @media only screen and (max-width: 768px) {
        width:100%;
        height:auto;
        margin:0px;
    }
`;


const StyledImageDiv = styled.div`
    background-size:cover;
    background-repeat:no-repeat;
    background-position:center;
    width:264px;
    height:312px;
    display:flex;
    flex-direction:column;
    justify-content:space-between;
    margin-bottom:10px;
    >.stock {
        display:flex;
        padding:10px;
        justify-content:space-between;
        .stake{
            flex:1;
            font-family:'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
            color:#000;
            font-size:15px;
            font-style:normal;
            font-weight: 600;
            text-shadow:1px 1px 1px #fff;
        }
        .period{
            width:170px;
            text-align:right;
            font-family:'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
            color:#000;
            font-size:15px;
            font-weight: 600;
            text-shadow:1px 1px 1px #fff;
        }
        >p{
            font-family:'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
            font-size:15px;
            color:#05141F;
        }
    }
    .label {
        width:100%;
        height:20px;
        padding:0px 16px;
        position:absolute;
        top:150px;
        left:0px;
        .event{
            width:50px;
            background-color:#47C2BA;
            padding:5px 8px;
            border-radius:50px;
            font-family:'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
            color:#fff;
            font-size:10px;
            font-style:normal;
            font-weight:800;
            line-height:11px;
            letter-spacing:0em;
        }
        .sale{
            margin-left:6px;
            width:60px;
            background-color:#FF645A;
            padding:5px 8px;
            border-radius:50px;
            font-family:'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
            color:#fff;
            font-size:10px;
            font-style:normal;
            font-weight:800;
            line-height:11px;
        }
    }

    .item_container{
        .text_info{
            color:#fff;
            height:105px;
            background:rgba(0,0,0,0.6);
            padding:20px 16px 32px;
            >.off_price{
                display: flex;
                align-items: center;
                svg{
                    margin: 0px 10px;
                    margin-top: 4px;
                }
            }

            .building_alias{
                font-family:'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
                font-size:15px;
                font-style:normal;
                font-weight:800;
                line-height:17px;
            }
            .one_sentence{
                font-family:'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
                font-size:13px;
                font-style:normal;
                font-weight:700;
                line-height:16px;
                margin-top:4px;
                display:block;
                overflow:hidden;
                text-overflow:ellipsis;
                white-space:nowrap;
            }
            .price_metarex{
                margin-top: 4px;
                font-family:'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
                font-size:15px;
                font-weight:700;
                line-height:16px;
            }
        }
    }
    @media only screen and (max-width: 768px){
        width:100%;
        height:400px;
        .label{
            top:203.5px;
        }
        .item_container{
            .text_info{
                height:130px;
            }
        }
    }
`;


const StyledButton = styled.button`
    border:none;
    font-size:15px;
    width:100%;
    height:46px;
    background-color:#000;
    padding:5px;
    cursor:pointer;
    transition:0.5s;
    &:hover{
        background-color:#3D85F0;
    }
    span {
        font-family:'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
        font-size:14px;
        font-style:normal;
        font-weight:700;
        line-height:14px;
        text-align:center;
        color:#fff;
    }
    &:disabled{
        background:#ccc;
        cursor:auto;
    }
    @media only screen and (max-width: 768px){
        height:55px;
        background-color:#3D85F0;
    }
`;


const ItemCard = ({asterPayments, item}) => {
    const [recommend, setRecommend] = useState(false);              // item에 구매가능한 지분이 존재하면 true, 아니면 false
    const [open, setOpen] = useState(false);                        // modal 창 open 관련 변수
    const [ownAmount, setOwnAmount] = useState(0);                  // 소유하고 있는 지분
    const [reservedAmount, setReservedAmount] = useState(0);        // 예약한 지분(구매 신청은 진행했으나 입금하지 않은 매물)
    const [isAsterLoading, setIsAsterLoading] = useState(false);    // 아스터페이가 진행중인지 체크하는 변수
    const RATIO_FOR_SALE = Number(item.offRatio) * 100;             // 할인율

    const navigate = useNavigate();
    const 해당_매물_지도_이동 = () => {
        const url = `/map?keyword=${item.address}`;
        navigate(url);
    }

    useLayoutEffect(() => {
        // 지분 매물인지 체크
        if(!isNull(item.totalUnit) || +(item.totalUnit) !== 0) {
            setRecommend(true);
        }
    }, []);

    const openModal = (e) => {
        if(isNull(e)) {
            setOpen(!open);
            return;
        }

        e.preventDefault();
        if(!isSessionValidate()) {
            alert(t("market_view_login"));
            window.location.href ='/signin';
            return false;
        }

        if(!isNull(item.soldoutTs)){
            return;
        }

        if(+(item.quantityLimitPerPerson) <= 0) {
            return false;
        }

        getUserStockAmount();
        setOpen(!open);
    }

    // 유저가 보유중인 해당 매물의 지분 수
    const getUserStockAmount = () => {
        axiosGet(`api/realestate/my-purchase-quantity?realestateNo=${+(item.realestateNo)}`)
            .then(response => {
                if(JSON.parse(response.data.result)){
                    setOwnAmount(+(response.data.data));
                    getUserReserveStockAmount();
                }
            })
            .catch(e => console.error(e));
    }

    // 유저가 아스터페이는 진행하였으나 결제는 진행하지 않은 지분 수
    const getUserReserveStockAmount = () => {
        axiosGet(`api/realestate/my-reserved-quantity?realestateNo=${+(item.realestateNo)}`)
            .then(response => {
                if(JSON.parse(response.data.result)){
                    setReservedAmount(+(response.data.data));
                }
            })
            .catch(e => console.error(e));
    }

    // 아스터 페이 start
    const asterpay = new AsterpayAPI('realestate/recommendation');
    const [startAsterPayments, setStartAsterPayment] = useState(false); // 아스터 페이 동작관 관련된 버튼 상태를 위한 변수
    const [json, setJson] = useState({});   // 아스터 페이에 전달하려는 JSON

    window.onmessage = function(e) {
        asterpay.onMessage(e, json, openModal, setIsAsterLoading, setStartAsterPayment);
    }

    // 구매를 위한 함수 (quantity => 지분 구매의 수)
    const onClickBuy = (quantity) => {
        if(!isSessionValidate()) {
            alert(t("market_view_login"));
            window.location.href ='/signin';
            return false;
        }

        let asterPrice = +quantity * +(item.priceMetarexUnit);
        if(item.offPriceMetarexUnit) {
            asterPrice = +quantity * +(item.offPriceMetarexUnit);
        }

        if(isNaN(asterPrice)) {
            alert(t("market_view_priced_incorrectly"));
            return false;
        }

        const randNumber    = String(Math.floor((Math.random() * 1986) + 1)) + String(Math.floor((Math.random() * 9860) + 1)) + String(Math.floor((Math.random() * 8605) + 1)) + String(Math.floor((Math.random() * 6052) + 1));
        const orderName     = localStorage.getItem('locale') !== 'ko' ? item.addressJibunEn : item.address;
        const realestateNo  = String(item.realestateNo);
        const sessionId     = localStorage.getItem('sessionId');
        const asterpayObj = {
            currencyType: 'Astercoin',
            amount: Number(asterPrice),
            orderId: String(`${Number(new Date())}_${randNumber}`).replace(/\s/g,'_'),
            orderName: `${orderName}`,
            orderItemType: '추천매물',
            customerName: localStorage.getItem('name'),
            mobile: localStorage.getItem('mobile'),
            email: localStorage.getItem('email'),
            successUrl: `${process.env.REACT_APP_API_METAREX}/api/asterpay/success`,
            failUrl: `${process.env.REACT_APP_API_METAREX}/api/asterpay/fail`,
            orderNumber: realestateNo,
            customerSession: sessionId
        }

        const purchaseQuantity = String(quantity);
        setJson({ realestateNo, purchaseQuantity });

        setIsAsterLoading(true);

        asterpay.아스터페이_실행하기(asterPayments, asterpayObj);
    }

    return (
        <>
        {open &&
            <ModalScreen>
                {isAsterLoading
                    ? <ReactLoading type={'spin'} color={'skyblue'} height={'40px'} width={'40px'} />
                    : <ModalSmall title={t('recommended_purchase_share')} data={item} closeModal={openModal}>
                        <BuyRealestate closeModal={openModal} item={item} ownAmount={ownAmount} reservedAmount={reservedAmount} onClickBuy={onClickBuy}/>
                      </ModalSmall>
                }
            </ModalScreen>
        }
        <StyledItemTemplate>
            <StyledImageDiv style={{backgroundImage: `url(${process.env.REACT_APP_API_METAREX}/api/realestate/theme/image?realestateNo=${item.realestateNo})`}}>
                <div className="stock">
                    {item.totalUnit &&
                        <p className="stake">{Number(item.soldoutNum) + Number(item.reservationsNum)}/{item.totalUnit}</p>
                    }
                    {item.startDate &&
                        <p className="period">{t('recommended_purchase_period')} {(item.startDate).split("T")[0].replaceAll("-", ".")}~{(item.endDate).split("T")[0].replaceAll("-", ".")}</p>
                    }
                 </div>
                <div className="label">
                    {item.rrecommendationTypeName && <span className="event">{item.rrecommendationTypeName}</span>}
                    {RATIO_FOR_SALE !== 0 && <span className="sale">{RATIO_FOR_SALE}% <FontAwesomeIcon icon={faArrowDown}/></span>}
                </div>
                <div className="item_container">
                    <div className="text_info">
                        {item.buildingAlias && <strong className="building_alias">{item.buildingAlias}</strong>}
                        {item.oneSentence && <p className="one_sentence">{item.oneSentence}</p>}
                            {item.priceMetarex &&
                                <p className="price_metarex" style={RATIO_FOR_SALE !== 0 ? {textDecoration:"line-through", color:"#FF645A"} : {}}>
                                    {addComma(item.priceMetarex)} {t('recommended_item_won')}
                                </p>
                            }
                            {RATIO_FOR_SALE !== 0 &&
                                <div className="off_price">
                                    <FontAwesomeIcon icon={faLongArrowAltRight}/>
                                    <p className="price_metarex">{addComma(item.offPriceMetarex)} {t('recommended_item_won')} ( {RATIO_FOR_SALE}% off )</p>
                                </div>
                            }
                    </div>
                    <StyledButton
                        onClick={recommend ? openModal : onClickBuy}
                        disabled={!isNull(item.soldoutTs) || +(item.quantityLimitPerPerson) <= 0 || item.status === "not available for purchase" || Number(item.soldoutNum) + Number(item.reservationsNum) === Number(item.totalUnit)}>
                        <span>{Number(item.soldoutNum) + Number(item.reservationsNum) === Number(item.totalUnit) ? t('recommended_sold_out') : t((item.status).replaceAll(" ", "_"))}</span>
                    </StyledButton>
                </div>
            </StyledImageDiv>
            <span className="address" onClick={해당_매물_지도_이동}>{localStorage.getItem("locale") === 'ko' ? item.address : item.addressJibunEn}</span>
        </StyledItemTemplate>
        </>
    )

}

export default ItemCard;
