import React, {useState, useEffect} from "react";
import { t } from 'i18next';
import axios from 'axios';
import styled from "styled-components";
import { addComma } from "../../../apis/CommonFunc";

import { useInput } from "../../../hooks/useInput";
import { isValidationSessionId, isSessionValidate } from '../../../apis/BrowserStorageAPI';

const MyDiv = styled.div`
    padding: 20px;    
    position: relative;    
    max-height: 74vh;
    overflow-y: auto;

    .what-is-this-function-description {        
        font-size: 13px;
        font-weight: 600;
        text-align: justify;
        padding-bottom: 20px;
        &::before {
            content: '* ';
        }
    }    

    .purchase-requisition-info {

        margin-top: 12px; 
        margin-bottom: 70px; 
        background: #f6f6f6;
        padding: 20px 10px;
        border: 0px solid #ccc;
        border-top: 1px solid #777;
        border-bottom: 1px solid #777;

        .owner-latest-price {
            font-weight: 800;
            font-size: 20px;
            color: #FF645A;
        }

        .my-label {
            margin-bottom: 3px;
            label {                        
                font-size: 14px;
                font-weight: 700;
            }
        }
        .price-request-buy {
            margin-top: 5px;            
            padding: 8px;
            width: 100%;
            font-size: 17px;
        }
        .button-request-buy-wrapper {
            padding: 20px 0px 0px;
            text-align: center;
            .button-request-buy {
                margin: 0px auto;
                background: #05141F;
                color: #ffffff;
                width: 100%;
                padding: 10px;
            }
        }
    }
    

    .building-image-wrapper {
        display:grid; 
        grid-gap: 4px; 
        grid-template-columns: repeat(3, minmax(auto, 1fr));
        align-items: center; 
        justify-content: space-around;
        margin-bottom: 60px;
        .building-image-item {
            position: relative;
            width: 80px;
            height: auto;
            text-align: center;
            padding-bottom: 23px;
            .building-image {
                left: 0px;
                top: 0px;
                width: 100%;
            }
            .building-image-text-wrapper {
                position: absolute;
                width: 100%;
                bottom: 0px;
                left: 0px;
                background: rgba(255,255,255,0.47);
                background: linear-gradient(0deg, rgba(255,255,255,0.9) 0%, rgba(255,255,255,0.2) 100%);
                padding: 5px 0px;
                .building-image-text {
                    color: #555555;
                    font-size: 12px;
                    font-weight: 700;
                    &::before {                        
                        font-weight: 900;
                        content: '[ ';
                    }
                    &::after {
                        font-weight: 900;
                        content: ' ]';
                    }
                }
            }
        }
    }

    

    .metalive-description {

        .in-addition-you-can-experience-it-at-metalive-description {
            font-size: 13px;
            font-weight: 600;
            text-align: justify;
            padding-bottom: 20px;
            &::before {
                content: '* ';
            }
        }
        .download-meta-live-wrapper {
            font-size: 15px;
            font-weight: 600;
        }
    }
    
`;


const PopupPurchaseRequisitionContent = ({popupData}) => {


    //소유자에게 제시할 구매가의 초깃값
    const _priceMetarexAsk = 0;

    // 구매 정보 JSON
    {/* priceMetarexAsk는 '/api/realestate/request-to-purchase-property-already-been-sold'에 요청할 구매 제시 가격 */}
    const [args , setArgs] = useState(
        {
            'priceRequestBuy' : _priceMetarexAsk,
            'priceMetarexAsk' : _priceMetarexAsk,
        }
   );

   useEffect(()=>{
        {/* 소유자에게 요청할 구매 제시 가격(args.priceRequestBuy)이 변경 될때 마다 해당 숫자만 priceMetarexAsk에 넣어준다. */}
        setArgs(prev => prev && {
            ...prev,
            ['priceMetarexAsk']: Number(String(args.priceRequestBuy).replace(/\s+/g, '').replace(/(원|,)+/g, '')),        
        });
    }, [args.priceRequestBuy]);

   const  { updateInput } = useInput(setArgs);

    const addKRW = () => {
        //alert('addKRW');
        setArgs(prev => prev && {
            ...prev,
            ['priceRequestBuy']: addComma(args.priceRequestBuy) + ' 원',            
        });
    }

    const removeKRW = () => {
        //alert('removeKRW');
        setArgs(prev => prev && {
            ...prev,
            ['priceRequestBuy']: String(args.priceRequestBuy).replace(/\s+/g, '').replace(/(원)+/g, ''),
        });
    }

    {/*서버에 이미 구매된 매물에 대해서 구매 요청하기*/}
    const requestBuyItem = (popupData) => {

        isValidationSessionId()
        .then(() => {
            if(!isSessionValidate()) {
                window.location.href ='/signin';
                return;
            } else {
                if( typeof popupData !== 'undefined' && JSON.parse(popupData.isSoldout) ) {
                    if( JSON.parse(popupData.isMine) ) {
                        alert('본인의 매물은 구매 불가합니다.');
                        return false;
                    }
        
                    if( args.priceMetarexAsk <= 0 ) {
                        console.log('20221018 1527 %o', args);
                        alert('구매 가격을 입력해주세요.');
                        return false;
                    }
        
                    if(window.confirm(
                        "소유자에게 판매를 요청하시겠습니까?" + 
                        `\n\n` +
                        `소유자의 이전 구매가: ${addComma(popupData.latestPrice)} 원` +
                        `\n` +
                        `구매자의 구매 요청가: ${args.priceRequestBuy}`
                        )) {
        
                            console.log('thkim 20221018 %o', popupData);
        
                            const requestItemParams = {
                                historyBuyNo : popupData.historyBuy.historyBuyNo,
                                priceMetarexAsk : args.priceMetarexAsk
                            };        
                    
                            axios.defaults.withCredentials = true;
                            axios.post(`${process.env.REACT_APP_API_METAREX}/api/realestate/request-to-purchase-property-already-been-sold`, requestItemParams , {withCredentials : true} )
                            .then(res => {
                                alert(res.data.message);
                            });                
                    }
                }
            }
        });
        
    };

    return (
        <MyDiv>
            {/* <img className="popup-image" src={popupImageUrl} onClick={onClickPopupImage}/> */}  
            <div>
                <p className="what-is-this-function-description">
                    '구매 요청 하기' 기능은 이미 구매가 완료된 매물에 대해서 소유자에게 가격을 제시하여 판매요청하는 기능입니다.
                </p>
            </div>      
            <div className="purchase-requisition-info">
                <div>
                    <div className="my-label">
                        <label>소유자의 이전 구매가:</label>
                    </div>
                    <div className="owner-latest-price">{addComma(popupData.latestPrice)} 원</div>
                </div>
                <br />
                <div>
                    <div className="my-label">
                        <label>구매자의 구매 요청가:</label>
                    </div>                    
                    <div>
                        <input className="price-request-buy" name="priceRequestBuy" type="tel" placeholder="금액을 입력하세요." value={args.priceRequestBuy} onChange={updateInput} onFocus={removeKRW}  onBlur={addKRW}/>
                    </div>                
                </div>
                <div className="button-request-buy-wrapper" >
                    <button className="button-request-buy" type="button" 
                    onClick={() => requestBuyItem(popupData) }>{t("make_purchase_request")}</button>
                </div> 
            </div>            
               
            <div className="building-image-wrapper">
                {/* 아파트 */}
                <div className="building-image-item">
                    <img className="building-image" src="/images/icon/buildingType/아파트02.png?v=20211215_1227" alt="아파트 이미지"/>
                    <div className="building-image-text-wrapper">
                        <span className="building-image-text">아파트</span>
                    </div>
                </div>
                {/* 빌딩 */}
                <div className="building-image-item">
                    <img className="building-image" src="/images/icon/buildingType/빌딩02.png?v=20211215_1227" alt="빌딩 이미지"/>
                    <div className="building-image-text-wrapper">
                        <span className="building-image-text">빌딩</span>
                    </div>
                </div>
                {/* 오피스텔 */}
                <div className="building-image-item">
                    <img className="building-image" src="/images/icon/buildingType/오피스텔02.png?v=20211215_1227" alt="오피스텔 이미지"/>
                    <div className="building-image-text-wrapper">
                        <span className="building-image-text">오피스텔</span>
                    </div>
                </div>
                {/* 빌라 */}
                <div className="building-image-item">
                <img className="building-image" src="/images/icon/buildingType/빌라01.png?v=20211215_1227" alt="빌라 이미지"/>
                    <div className="building-image-text-wrapper">
                        <span className="building-image-text">빌라</span>
                    </div>
                </div>
                {/* 주택 */}
                <div className="building-image-item">
                <img className="building-image" src="/images/icon/buildingType/주택02.png?v=20211215_1227" alt="주택 이미지"/>
                    <div className="building-image-text-wrapper">
                        <span className="building-image-text">주택</span>
                    </div>
                </div>
                {/* 상가 */}
                <div className="building-image-item">
                    <img className="building-image" src="/images/icon/buildingType/상가01.png?v=20211215_1227" alt="상가 이미지"/>
                    <div className="building-image-text-wrapper">
                        <span className="building-image-text">상가</span>
                    </div>
                </div>

                {/* 공장 */}
                <div className="building-image-item">
                    <img className="building-image" src="/images/icon/buildingType/공장01.png?v=20211215_1227" alt="공장 이미지"/>
                    <div className="building-image-text-wrapper">
                        <span className="building-image-text">공장</span>
                    </div>
                </div>
                {/* 토지 */}
                <div className="building-image-item">
                    <img className="building-image" src="/images/icon/buildingType/토지04.png?v=20211215_1227" alt="토지 이미지"/>
                    <div className="building-image-text-wrapper">
                        <span className="building-image-text">토지</span>
                    </div>
                </div>
                {/* 공사 중 */}
                <div className="building-image-item">
                    <img className="building-image" src="/images/icon/buildingType/토지03.png?v=20211215_1227" alt="토지 이미지"/>
                    <div className="building-image-text-wrapper">
                        <span className="building-image-text">공사 중</span>
                    </div>
                </div>
            </div>
            <div className="metalive-description">
                <p className="in-addition-you-can-experience-it-at-metalive-description">
                    {t('the_purchased_land_will_be_provided_with_an_indoor_space_on_meta_live_in_the_additionally_implemented_interior_a_function_to_experience_the_purple_camera_will_be_provided')}
                </p>
                <div className="download-meta-live-wrapper">
                    <a href="https://www.metalive.kr/" target="_blank">메타라이브 다운로드하기 (링크 클릭) https://www.metalive.kr/</a>
                </div>
            </div>
            

        </MyDiv>
    )
}

export default PopupPurchaseRequisitionContent;