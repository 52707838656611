import React from "react";
import styled from "styled-components";
import Board from "./Board";

const StyledTemplate = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 100px;
    max-width: 1200px;
`;

// 게시판의 템플레이트
const BoardTemplate = ({boardContents, pageNum, setPageNum, totalCount, PAGE_PER_NUM, url}) => {
    return (
        <StyledTemplate>
            { boardContents 
                && <Board
                        boardContents={boardContents} 
                        pageNum={pageNum} 
                        setPageNum={setPageNum} 
                        totalCount={totalCount} 
                        PAGE_PER_NUM={PAGE_PER_NUM} 
                        url={url}
                    />
            }
        </StyledTemplate>
    )
}

export default BoardTemplate;