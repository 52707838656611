// 매개변수의 값이 null, 혹은 undefined 인지 체크하는 메소드
export const isNull = (target) => {
    if(target === null || typeof target === 'undefined' || target === 'undefined') {
        return true;
    }
    return false;
}

// 현재 사용중인 웹페이지가 모바일인지 체크
export const mobile = () => {
    // 타블렛 이슈 존재 (아이패드 대응 및 안드로이드 사용하는 태블릿 관련 지원 필요)
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

// 패스워드가 8글자 이상이며 영문, 숫자, 특수문자가 모두 포함되어 있는지 확인
export const isValidPassword = (p) => {
    p = String(p);
    const regUserPassword = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&\^\(\)\+_])[A-Za-z\d@$!%*#?&\^\(\)\+_]{8,}$/;
    return regUserPassword.test(p);   
}

// 전화번호 형식을 체크하는 함수 - 입력된 전화번호 맨 앞에 0이 없는지 체크
export const isCellPhoneStartWithoutZero = (p) => {
    p = String(p);
    const regPhone = /^((1[1|6|7|8|9])[0-9]{7,8})|^(10[0-9]{8})$/;
    return regPhone.test(p);
}

// 입력된 전화번호가 한국 전화번호 양식과 맞는지 체크
export const isCellPhoneForKorea = (p) => {
    p = String(p);
    const regPhone = /^((01[1|6|7|8|9])[1-9]+[0-9]{6,7})|(010[1-9][0-9]{7})$/;
    return regPhone.test(p);
}

// 입력된 이메일 주소가 형식에 맞는지 체크
export const isValidEmail = (p) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(p).toLowerCase());
}

// 지갑주소 형식 체크
export const isValidAstercoinAddr = (p) => {
    var re = /^0x[a-fA-F0-9]{40}$/;
    return re.test(String(p).toLowerCase());
}


export const isNumber = (s) => {
    s += ''; // 문자열로 변환
    s = s.replace(/^\s*|\s*$/g, ''); // 좌우 공백 제거
    if (s == '' || isNaN(s)) return false;
    return true;
}