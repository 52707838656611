import React, { useLayoutEffect, useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

{/**
    version 2022-09-16 11:01
    이 모듈은 astercoin.org와 같이 사용 함.
*/}


const MainDiv = styled.div`
    background: #ffeed5;
    height: auto;
    padding: 40px 20px 0px 20px;

    .metalive-get-it-on-wrapper {
        margin: 0px auto;
        width: 100%;
        max-width: 800px;
        display: flex;
        justify-content: space-between;
        @media screen and (max-width:786px) {
            flex-direction: column-reverse;
        }
        .metalive-get-it-on-android {
            width: 300px;            
            margin-top: 160px;
            @media screen and (max-width:786px) {
                margin-top: 0px;
            }
        }
        .my-description-wrapper {
            margin-top: 140px;
            @media screen and (max-width:786px) {
                margin-top: 20px;
                padding-bottom: 70px;
                text-align:right;
            }
            h2 {
                color: #ff7a45;
                font-size: 42px;
            }
            p {
                color: #777777;
                font-size: 18px;
            }
            .download-app {
                margin-top: 10px;
                margin-bottom: 30px;
                display: flex;
                @media screen and (max-width:786px) {
                    display: block;
                }
                .go-to-download-wrapper {
                    .go-to-android-button {
                        display: inline-block;
                        padding: 8px 20px 10px;
                        margin: 8px 13px;
                        @media screen and (max-width:786px) {
                            margin: 0px;
                            margin-top: 10px;
                        }
                        border-radius: 24px;
                        background-color: #fff;
                        font-weight: 600;
                        line-height: 24px;
                        letter-spacing: -.2px;
                        color: #000;
                        border: 1px solid #a1771b;
                        svg {
                            width: 14px;
                            height: 14px;
                            margin: 6px 0 0 8px;

                            stroke-width: 1;
                            stroke: #000;
                        }
                    }
                }
            }
        }
    }


`;



const Video = styled.div`
  margin-bottom: "0px";
  margin-top: "0px";
  border= "0";
  font-size: "0";
  margin:0px auto;
  vertical-align:bottom;
  padding: 0;
  width: 470px;
  height: auto;
  @media only screen and (max-width: 768px) {
    width: 300px;
    height: auto;
  }
  overflow: hidden;
  video {
    margin-top: 20px;
    width: 100%;
    z-index: -1;
    @media only screen and (max-width: 768px) {
    }
    
  }
  
`;


const GetItOn = () => {
    return (
        <MainDiv>
            {/** Metalive for android*/}
            <div class="metalive-get-it-on-wrapper">
                <img className="metalive-get-it-on-android" src={`images/getItOn/metalive/metalive_download_for_android.png`} alt="metaREX_logo"/>
                <div className="my-description-wrapper">
                    <h2>Metalive</h2>
                    <p>누구보다 먼저 메타라이브에서 제주도 여행을 떠나보세요~!<br /></p>
                    <Video>
                        <video
                            src="videos/renewal20221005/metalive.mp4_cf.mp4"
                            controls
                            autoPlay
                            loop
                            muted
                            margin-bottom="0"
                            margin-top="0"
                            border="0"
                            padding="0"
                            display="block"
                            margin="0"
                        ></video>
                    </Video>
                    <div className="download-app">
                        <div className="go-to-download-wrapper">
                            <Link className="go-to-android-button"
                            to={{
                                pathname: "https://play.google.com/store/search",
                                search: "?q=%EB%A9%94%ED%83%80%EB%9D%BC%EC%9D%B4%EB%B8%8C&c=apps"
                                }}
                                target="_blank"  >
                                Android App Download
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 9" class="ico_outlink"><g data-v-c1d21be8="" fill="none" fill-rule="evenodd"><path data-v-c1d21be8="" d="M1.795 1.074L7.942 1.074 7.942 7.221M7.942 1.074L1.378 7.638" transform="translate(-935 -867) translate(836 848) translate(14 14) translate(85 5)"></path></g></svg>
                            </Link>
                        </div>
                        <div className="go-to-download-wrapper">
                            <Link className="go-to-android-button" to={{ pathname: "https://apps.apple.com/kr/app/%EB%A9%94%ED%83%80%EB%9D%BC%EC%9D%B4%EB%B8%8C/id1661587399" }}    target="_blank"  >
                                IOS App Download
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 9" class="ico_outlink"><g data-v-c1d21be8="" fill="none" fill-rule="evenodd"><path data-v-c1d21be8="" d="M1.795 1.074L7.942 1.074 7.942 7.221M7.942 1.074L1.378 7.638" transform="translate(-935 -867) translate(836 848) translate(14 14) translate(85 5)"></path></g></svg>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </MainDiv>
    )
}


export default GetItOn;
