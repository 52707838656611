import React from "react";
import Vision from "../../feature/MetaRex/about/Vision";
import Function from "../../feature/MetaRex/about/Function";
import Partners from "../../feature/MetaRex/about/Partners";
import MetarexTemplate from "../../feature/MetaRex/MetarexTemplate";
import PageBanner from "../../components/PageBanner";
import PageTab from "../../components/PageTab";

const About = () => {
    return (
        <>
        <MetarexTemplate>
            <PageBanner style={{backgroundImage: "url('/images/bg/metarex_bg.png')"}}>The world’s first Virtual Real Estate Platform<br/>linked to the Real Economy</PageBanner>
            <PageTab category="metarex" type="about" />

            <Vision/>
            <Function/>
            <Partners/>
        </MetarexTemplate>
        </>
    )
}

export default About;