import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useDevice } from "../../hooks/useDevice";
import { useInput } from "../../hooks/useInput";

const MainDiv = styled.div`
    & input[required="true"] {
    }
    & .card-logo-wrapper {
        padding: 13px 20px;
        border-bottom: 1px solid #EFEFEF;
        text-align: center;
        & > .card-logo {
            width: 128px;
        }
    }

    & .input-wrapper {
        padding: 16px 20px;
        & > * {
            margin-bottom: 10px;
        }
        & input {
            border: 1px solid #DFE1E5;
            border-radius: 4px;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            padding: 15px 14px;
            &[name="name-on-card"], &[name="card-number"] {
                width: 100%;
            }
        }
        & .select-wrapper {
            display: grid;
            grid-gap: 10px 18px;
            grid-template-columns: 1fr;
            & select {
                border: 1px solid #DFE1E5;
                border-radius: 4px;
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                padding: 15px 14px;
            }
        }
    }
`;

const PaddingDiv = styled.div`
    padding: 20px;
`;


const PaymentCardInfomation = ({args, setArgs, setOnClickNextButtonPrevius}) => {

    const  { updateInput } = useInput( setArgs);
    const deivce = useDevice();

    useEffect(() => {
        setOnClickNextButtonPrevius (
            () => () => {

                if( args['name-on-card'] == null || String(args['name-on-card']).trim() === '' ) {
                    alert('Please enter name on card.');
                    return false;
                }

                if( args['card-number'] == null || String(args['card-number']).trim() === '' ) {
                    alert('Please enter card number.');
                    return false;
                }
                
                if( args['expiration-month'] == null || String(args['expiration-month']).trim() === '' || String(args['expiration-month']).trim() === '0') {
                    alert('Please enter expiration month.');
                    return false;
                }

                if( args['expiration-year'] == null || String(args['expiration-year']).trim() === '' || String(args['expiration-year']).trim() === '0' ) {
                    alert('Please enter expiration year.');
                    return false;
                }

                if( args['security-code'] == null || String(args['security-code']).trim() === '' ) {
                    alert('Please enter security code.');
                    return false;
                }

                return true;
            }
        );
    }, [args]);

    return (
        <MainDiv>
            {args['payment-method'] === 'card' ?
<div>
    {/*Card를 선택했을 때 */}
    <div className="card-logo-wrapper">
        <img src={`${process.env.PUBLIC_URL}/images/icon/payment/card/high/${args['card']}.svg`} alt={args['card']} className="card-logo"/>             
    </div>
    <div className="input-wrapper">
        <input type="text" name="name-on-card" placeholder={"* Name on card"} onChange={updateInput} value={args['name-on-card'] || ''} required={true}/>
        <input type="text" name="card-number" placeholder={"* Card number"} onChange={updateInput} value={args['card-number'] || ''} required={true}/>
        <div className="select-wrapper">
            <select name="expiration-month" onChange={updateInput} value={args['expiration-month'] || ''} required={true}>
                <option value="0" disabled={true}>*Expiration: Month</option>
                <option value="1" >01</option>
                <option value="2">02</option>
                <option value="3">03</option>
                <option value="4">04</option>
                <option value="5">05</option>
                <option value="6">06</option>
                <option value="7">07</option>
                <option value="8">08</option>
                <option value="9">09</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
            </select>
            <select name="expiration-year" onChange={updateInput} value={args['expiration-year'] || ''} required={true}>
                <option value="0" disabled={true}>*Expiration: Year</option>
                <option value="2022">2022</option>
                <option value="2023">2023</option>
                <option value="2024">2024</option>
                <option value="2025">2025</option>
                <option value="2026">2026</option>
                <option value="2027">2027</option>
                <option value="2028">2028</option>
                <option value="2029">2029</option>
                <option value="2030">2030</option>
                <option value="2031">2031</option>
                <option value="2032">2032</option>
                <option value="2032">2032</option>
            </select>
        </div>
        <input type="text" name="security-code" placeholder={"* Security code"} onChange={updateInput} value={args['security-code'] || ''} required={true}/>
        
    </div>
</div>
                 :
<div>
    {/*Card 외에 것을 선택했을 때 */}
    <h1>Please use Asterpay for coin payment.</h1>
</div>
            }
               
        </MainDiv>
    )
}

export default PaymentCardInfomation;