import React, { useEffect, useState } from 'react';
import { t } from 'i18next';

import Footer from '../../feature/Footer/Footer';


const PaymentFailed = (props) => {
  
    useEffect(() => {
        alert('결제에 실패하였습니다. 결제된 금액이 존재 할 시, 영업 3일내 환불처리 됩니다.');
        document.location.href = "/";
    }, [ ]);    

    return(
        <div style={{paddingTop: '60px'}}>            
            <section className="buyHistoryContainer">                
            </section>
            <Footer />
        </div>
    )
}

export default PaymentFailed;