import React, { useEffect,  useLayoutEffect,  useState } from 'react';
import { t } from 'i18next';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

import { addComma } from '../../apis/CommonFunc';

import './ConditionSearch.css'

const ConditionSearch = ({  query, 
                            setQuery, 
                            setMarketPrice, 
                            marketPrice, 
                            setPriceSelect, 
                            setSeoulToggle, 
                            setGyeonggiToggle, 
                            setIncheonToggle, 
                            setBusanToggle, 
                            seoulToggle, 
                            gyeonggiToggle, 
                            incheonToggle, 
                            busanToggle,  
                            priceToggle, 
                            setPriceToggle,
                            marketPCPageNum,
                            priceSelect  }) => {
    
    // 조건값
    const [minimumPrice, setMinimumPrice]= useState("")
    const [maximumPrice, setMaximumPrice]= useState("")

    // input text 읽어옴
    const [minInputText , setMinInputText] = useState("")
    const [maxInputText , setMaxInputText] = useState("")
    // 검색버튼 클릭 
    const [searchConditionBtn , setSearchConditionBtn] = useState(false)
    // 직접 입력 클릭
    const [selfCheckFocus , setSelfCheckFocus] = useState(false)
    const [focusClick , setFocusClick] = useState(false)
    
    const directInputMin = (e) => {
        setSearchConditionBtn(false)
        setMinInputText(e.target.value)
    }
    const directInputMax = (e) => {
        setSearchConditionBtn(false)
        setMaxInputText(e.target.value)
    }
    const minFoucs = () => {
        setPriceToggle(false)
        setFocusClick(true)
        setSearchConditionBtn(false)
        setMarketPrice("")
    }
    const maxFoucs = () => {
        setPriceToggle(false)
        setFocusClick(true)
        setSearchConditionBtn(false)
        setMarketPrice("")
    }
    const minBlur = () => {
        setFocusClick(false)
    }
    const maxBlur = () => {
        setFocusClick(false)
    }
    // 직접입력
    useEffect(()=>{
        if(focusClick===true || searchConditionBtn===true){
            setSelfCheckFocus(true)
        }else{
            setSelfCheckFocus(false)
        }
    },[selfCheckFocus , minInputText,maxInputText, searchConditionBtn ,focusClick])

    // 공통되는 요소를 밖으로 빼냄
    const priceToggleCommon = (e) =>{
        setMinInputText("")
        setMaxInputText("")
        setSearchConditionBtn(false)
        setMarketPrice(e.target.innerText)
    }
    // 가격대 다시 클릭할때 체크 제거 및 빈값 보내기
    const [million10Clear, setMillion10Clear] = useState(false)
    const [million30Clear, setMillion30Clear] = useState(false)
    const [million100Clear, setMillion100Clear] = useState(false)
    const [million300Clear, setMillion300Clear] = useState(false)
    const [million1000Clear, setMillion1000Clear] = useState(false)

    const priceToggleBtn10 = (e) => {
        // 버튼이 한번 눌렸을때 clear값에 true를 준다.
        setMillion10Clear(true)    
        priceToggleCommon(e)
        setPriceToggle(true)
        
        // clear가 true이고, 누른 값이 밑의 내용일때 celar를 false로 준다.
        // 같은 가격을 두번 눌렀을때도 해제하기 위한 로직
        if(million10Clear === true && marketPrice === "10만원 이상 ~ 30만원 미만" ){
            setMillion10Clear(false)
            setPriceToggle(false)
            // setPriceSelect("")
        } 
    }
    const priceToggleBtn30 = (e) => {
        setMillion30Clear(true)   
        priceToggleCommon(e)
        setPriceToggle(true)
        if(million30Clear === true && marketPrice === "30만원 이상 ~ 100만원 미만"){
            setMillion30Clear(false)
            setPriceToggle(false)
        }
    }
    const priceToggleBtn100 = (e) => {
        setMillion100Clear(true)    
        priceToggleCommon(e)
        setPriceToggle(true)
        if(million100Clear === true && marketPrice === "100만원 이상 ~ 300만원 미만"){
            setMillion100Clear(false)
            setPriceToggle(false)
        }
    }
    const priceToggleBtn300= (e) => {
        setMillion300Clear(true)    
        priceToggleCommon(e)
        setPriceToggle(true)
        if(million300Clear === true && marketPrice === "300만원 이상 ~ 1000만원 미만"){
            setMillion300Clear(false)
            setPriceToggle(false)
        }
    }
    const priceToggleBtn1000 = (e) => {
        setMillion1000Clear(true)    
        priceToggleCommon(e)
        setPriceToggle(true)
        if(million1000Clear === true && marketPrice === "1000만원 이상 ~"){
            setMillion1000Clear(false)
            setPriceToggle(false)
        }
    } 

    // 조건 직접 입력 검색 버튼. 
    const rangeBtn = () => {
        setSearchConditionBtn(true)
        // 직접입력 input값 전송위해 가격대 클릭한 값을 빈값으로 만듬 
        setMinimumPrice("")
        setMaximumPrice("")
    }

    // 직접입력 데이터 보내는곳
    useEffect(()=>{
        // 가격 입력시 첫번째 input값이 클때 순서를 바꿔서 전송, 그 외에는 정상적으로 전송
        if(Number(minInputText) > Number(maxInputText) && searchConditionBtn === true){
            setPriceSelect(maxInputText + "~" + minInputText)
        }else if(Number(minInputText) < Number(maxInputText) && searchConditionBtn === true){
            setPriceSelect(minInputText + "~" + maxInputText)
        }
    },[searchConditionBtn ])
    
    // 지역 선택
    const seoul = (e) => {
        setQuery(e.target.innerText)
        setSeoulToggle(!seoulToggle)
    }
    const gyeonggi = (e) => {
        setQuery(e.target.innerText)
        setGyeonggiToggle(!gyeonggiToggle)
    }
    const incheon = (e) => {
        setQuery(e.target.innerText)
        setIncheonToggle(!incheonToggle)
    }
    const busan = (e) => {
        setQuery(e.target.innerText)
        setBusanToggle(!busanToggle)
    }
    
    // 가격대 데이터 보내는곳
    useLayoutEffect(()=>{
        // 가격대 클릭시, 해당 list의 innerText값을 읽어온다. 그후 한글로 되어있는 형식을 숫자로 변경해 setMinimumPrice에 담는다.
        if(priceToggle === true){
            setMinimumPrice(Number(marketPrice.split("~")[0].split("만원")[0] + "0000"))
            if(marketPrice.split("~")[1] !== ""){
                setMaximumPrice(Number(marketPrice.split("~")[1].split("만원")[0] + "0000"))
                // 끝나는 가격, 1000만원 이상 ~ 은 뒤의 값이 빈값이기에, 만약 값이 없다면 setMaxiMumPrice값을 빈값으로 전송 
            }else if( marketPrice.split("~")[1] === ""){
                setMaximumPrice("")
            }
            // 1000만원 이상처럼 이하값이 없을때는 위에 저장한 숫자형식의 데이터를 setPriceSelect에 담는다.
            if(maximumPrice !== ""){
                setPriceSelect(minimumPrice + "~" + maximumPrice)
            }else if (maximumPrice === "") {
            // 1000만원 이상처럼 이하값이 없을때 빈값으로 전송
                setPriceSelect(minimumPrice + "~" )
            }

        // 직접 입력
        }else if(priceToggle === false && searchConditionBtn === false ){
            // 가격대 클릭했을때 담긴 값을 비워준다.
            setPriceSelect("")

            // 직접입력 input창에 focus시 그전 가격대에서 클릭한값을 사라지지 않게 하기위한 로직.
            if(focusClick === true){
                setMinInputText(minimumPrice)
                setMaxInputText(maximumPrice)
            }
        } 
    },[marketPrice , priceToggle ,minimumPrice ,maximumPrice,setMaximumPrice,priceSelect , marketPCPageNum])
    
    // input에 숫자만 입력가능하도록 하는 로직
    useEffect(()=>{
        if(priceToggle === false && minInputText !== ""  && typeof(minInputText) ==="string"){
            setMinInputText(minInputText.replace(/[^0-9]/g, '').replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, ""))
        }
    },[minInputText ,priceToggle ])
    useEffect(()=>{
        if(priceToggle === false && maxInputText !== ""  && typeof(maxInputText) ==="string" ){
            setMaxInputText(maxInputText.replace(/[^0-9]/g, '').replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, ""))
        }
    },[maxInputText , priceToggle ])

    // 전송하는 priceSelect가 아니라, input값안에 담겨있는 값을 변경시켜주기 위한 로직. 
    // 직접입력에 버튼 클릭시, 값을 비교해 input에 담는다.
    useEffect(()=>{
        if(Number(minInputText) > Number(maxInputText) && searchConditionBtn === true){
            setMinInputText(maxInputText)
            setMaxInputText(minInputText)
        }else{
            setMinInputText(minInputText)
            setMaxInputText(maxInputText)
        }
    },[searchConditionBtn ])

    // 지역 클릭시 값(서울,인천,부산,경기)이 query에 담긴다.
    useEffect(()=>{
        if(query) {
            setQuery(query)
        }
    },[query, seoulToggle , gyeonggiToggle , incheonToggle , busanToggle ])
    
    return (
        <div className="marketSelectContainer"> 
            <div>
                <h2>{t("market_view_whole_area")}</h2>
                <ul>
                    <li onClick={seoul}>
                        <span className={seoulToggle === true ? 'priceBox' : "priceBoxCheck"}></span>
                        <span>서울</span> 
                    </li>
                    <li onClick={gyeonggi}>
                        <span className={gyeonggiToggle === true ? 'priceBox' : "priceBoxCheck"}></span>
                        <span>경기</span> 
                    </li>
                    <li onClick={incheon}>
                        <span className={incheonToggle === true ? 'priceBox' : "priceBoxCheck"}></span>
                        <span>인천</span> 
                    </li>
                    <li onClick={busan}>
                        <span className={busanToggle === true ? 'priceBox' : "priceBoxCheck"}></span>
                        <span>부산</span> 
                    </li> 
                </ul>
            </div>
            <div>
                <h2>{t("market_view_price_range")}</h2>
                <ul>
                    <li onClick={priceToggleBtn10}>
                        <span className={(million10Clear === true && priceToggle === true && marketPrice === "10만원 이상 ~ 30만원 미만" ? 'priceBox' : "priceBoxCheck")}></span>
                        <span>10만원 이상 ~ 30만원 미만</span>
                    </li>
                    <li onClick={priceToggleBtn30}>
                        <span className={(million30Clear === true && priceToggle === true &&marketPrice === "30만원 이상 ~ 100만원 미만" ? 'priceBox' : "priceBoxCheck")}></span>
                        <span>30만원 이상 ~ 100만원 미만</span>
                    </li>
                    <li onClick={priceToggleBtn100}>
                        <span className={(million100Clear === true && priceToggle === true && marketPrice === "100만원 이상 ~ 300만원 미만" ? 'priceBox' : "priceBoxCheck")}></span>
                        <span>100만원 이상 ~ 300만원 미만</span>
                    </li>
                    <li onClick={priceToggleBtn300}>
                        <span className={(million300Clear === true && priceToggle === true &&marketPrice === "300만원 이상 ~ 1000만원 미만" ? 'priceBox' : "priceBoxCheck")}></span>
                        <span>300만원 이상 ~ 1000만원 미만</span>
                    </li>
                    <li onClick={priceToggleBtn1000}>
                        <span className={(million1000Clear === true && priceToggle === true &&marketPrice === "1000만원 이상 ~" ? 'priceBox' : "priceBoxCheck")}></span>
                        <span>1000만원 이상 ~</span>
                    </li>
                </ul>
            </div>
            <div>
                <ul className='priceSelfContainer'>
                    <li>
                        <span className={(selfCheckFocus === true ? 'priceBox' : "priceBoxCheck")}></span>
                        <span>{t("market_view_price_self")}</span>
                    </li>
                    <li>
                        <input onChange={directInputMin} type="text" value={priceToggle === false ? (
                        (Number(minInputText) > Number(maxInputText) && searchConditionBtn === true) ? maxInputText : minInputText) 
                        :
                        addComma(Number(minimumPrice)) + "원"} onFocus={minFoucs} onBlur={minBlur}/>
                        ~  
                        <input  onChange={directInputMax} type="text" value={priceToggle === false ? (
                            (Number(minInputText) > Number(maxInputText) && searchConditionBtn === true) ? minInputText : maxInputText) 
                        :
                        ( marketPrice.split("~")[1] !== "" ? addComma(Number(maximumPrice)) + "원" : "")}  onFocus={maxFoucs}  onBlur={maxBlur}/>
                        <button type="submit" className='conditionSearchIcon' onClick={rangeBtn}>
                            <FontAwesomeIcon icon={faSearch} className="searchSvg"/>
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default ConditionSearch;


