import React, {  useEffect, useRef, useState } from 'react';
import styled from "styled-components";
import ReactLoading from 'react-loading'; 

import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { isSessionValidate } from '../../../apis/BrowserStorageAPI';
import ModalScreen from '../../../components/Modal/ModalScreen';

import { useSelector } from 'react-redux';
import ModalSmall from '../../../components/Modal/ModalSmall';
import BuyRealestate from '../../../components/Modal/contents/BuyRealestate';

import { addComma } from '../../../apis/CommonFunc';
import { AsterpayAPI } from '../../../apis/AsterpayAPI';
import { setPayment } from '../../../apis/PointAPI';

import { isNull } from "../../../apis/Validation";

import './Event20220616Item.css';


const MainDiv = styled.div`


    .forSaleSlideContainer{ 
        height: auto;
        margin: 0 auto 0;
        box-sizing: border-box;
        position: relative;
        overflow: hidden;
        /* 추가 */
        display: grid;
        grid-gap: 10px 18px;
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    .address-name {
        color: #05141F;
        font-weight: 300;
    }
    .plus-symbol {
        color: #b8cae5;
        text-align: center;        
        font-size: 50px;
        font-weight: 700;
        line-height: 50px;
        @media only screen and (max-width: 768px) {
            line-height: 30px;
        }
        vertical-align: middle;
        @media only screen and (max-width: 768px) {
            margin-bottom: 15px;
        }
    }

    .original-price {
        text-align: right;
        font-size: 20px;
        text-decoration: line-through;
        color: #f22323;
        font-weight: 700;
    }
    .event-price {
        text-align: right;
        font-size: 16px;
        color: #222222;
        font-weight: 200;
    }



    /* MarketModal */

    .market-modal-container{
        font-size: 20px;
        box-sizing: border-box;
        padding: 37px;
        @media only screen and (max-width: 768px) {
            padding: 15px;
        }
        position: absolute;
        background-color: #fff;
        top: calc(20% + 60px);
        @media only screen and (max-width: 768px) {
            top: calc(0% + 60px);
        }
        left: 50%;
        transform: translateX(-50%);
        border: 1px solid black;
        width: 100%;
        max-width: 512px;
        height: auto;
        overflow: auto;
        z-index: 1;        
        .market-modal-br {
            width: 100%;
            height: 1px;
            background: #dddddd;
            margin: 0px 0px 30px;
        }
        & > .chartTitle {
            height: 245px;
            position: relative;
            bottom: 0;
            top: 26px;
            font-family:  'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 16px;
            box-sizing: border-box;
            color: #05141F;
        }

        .MarketModalCloseBtn{
            font-size: 40px;
            text-align: right;
            position: absolute;
            color: #05141F;
            top: 0;
            right: 20px;
            background: none;
            border: none;
            width: auto;
            height: auto;
            cursor: pointer;
        }

        .detail-modal-price-container {
            display: grid;
            grid-gap: 10px 18px;
            grid-template-columns: 1fr 1fr;
            & > li{
                width: 100%;
            }
            .detail-modal-original-price {
                text-align: right;
                font-size: 20px;
                @media only screen and (max-width: 768px) {
                    font-size: 16px;
                }
                text-decoration: line-through;
                color: #f22323;
                font-weight: 700;
            }
            .detail-modal-event-price {
                text-align: right;
                font-size: 20px;
                @media only screen and (max-width: 768px) {
                    font-size: 16px;
                }
                color: #222222;
                font-weight: 200;
                font-weight: 700;
            }
        }

        .modal-detail-container {
            & > li {
                padding: 3px 0px;
                &:nth-of-type(2) {
                    padding-top: 20px;
                }

                .modal-detail-address {
                    text-decoration: none;
                    color: #05141F;
                    font-size: 15px; 
                    @media only screen and (max-width: 768px) {
                        font-size: 13px; 
                    }       
                    text-decoration: none;
                    font-style: normal;
                    font-weight: 300;
                    line-height: auto;
                    letter-spacing: 0em;
                    padding: 0px 20px;
                    @media only screen and (max-width: 768px) {
                        padding: 0px 0px;
                    }
            
                    &::before {
                        content: '+ ';
                    }
                }
            }
            .market-view-modal-title {
                font-size: 18px;
                font-weight: 600;
                padding-bottom: 8px;
                border-bottom: 1px solid #777777;
            }
    
            .modal-detail-event-description {
                padding: 20px 0px 5px;
                color:#f22323;
                font-size: 12px;
                text-align: right;
                &::before {
                    content:'! ';
                }
            }
        }        
        
    }

    .market-today-price{
        position: absolute;
        right: 20px;
        bottom: 88px;
        @media only screen and (max-width: 768px) {
            bottom: 86px;
        }
        & > span:nth-child(1) {
            font-family:  'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 16px;
            color: #05141F;
        }

        & > span:nth-child(2) {
            font-weight: 800;
            font-size: 21px;
            margin-left: 8px;
        }
    }


    
    

`;


function MarketModal({data , MarketViewPopUp}) {

    const marketModalLang = localStorage.getItem("locale");

    const {t} = useTranslation();
    
    return(
        <div className='market-modal-container'>
            <button className="MarketModalCloseBtn" onClick={MarketViewPopUp}>x</button>
            <ul className="modal-detail-container">
                <li><span className='market-view-modal-title'>{t("market_view_modal_title")}</span></li>

                <li>
                    <a className="modal-detail-address" href={data[0].urlLink}>{marketModalLang === "ko" ? data[0].address : data[0].addressJibunEn}</a>
                </li>
                <li>
                    <a className="modal-detail-address" href={data[1].urlLink}>{marketModalLang === "ko" ? data[1].address : data[1].addressJibunEn}</a>
                </li>
                {
                    data[0].areaSter && 
                    <li>    
                        <>
                            <span>{t("market_view_area")}</span>
                            <span>{addComma(data[0].areaSter)} ster </span>
                        </>                        
                    </li>
                }
                <li>
                    <p className='modal-detail-event-description'>수량 한정 특가 이벤트 상품으로 소량구매만 가능합니다.</p>
                </li>
            </ul>
            <div className="market-modal-br"></div>
            <ul className="detail-modal-price-container">             
                <li className='detail-modal-original-price'>
                    <span>{t("market_view_modal_buy_price")}: </span>
                    <span>{ addComma(data[0].priceMetarex+data[1].priceMetarex)}  {t("map_land_price_area_won")}</span>
                </li>
                <li className='detail-modal-event-price'>
                    <span>{"이벤트 가"}: </span>
                    <span>{ addComma(data[0].priceMetarex)}  {t("map_land_price_area_won")}</span>
                </li>
            </ul>
        </div>
    )
}


function Event20220616ItemInfo({data , marketKeyWord, asterPayments, setIsPaymentShow, setNeedPoint, setPaymentParams}){
    const {t} = useTranslation();
    const MIN_PRICE = 100000;
    // 로그인 확인용
    // saleCancleUn    
    const [marKetModalPopUp, setMarketModalPopUp ] = useState(true)
    const MarketViewPopUp = () => {
        setMarketModalPopUp(!marKetModalPopUp)
    }
    // data주소값 확인
    const datas = isNull(data[0].address) ? '' : data[0].address;

    const fixYieldData = isNull(data[0].yield) ? 0 : data[0].yield;
    const fixYield = fixYieldData.toFixed(2);

    // 아스터 페이 start
    const [startAsterPayments, setStartAsterPayment] = useState(false);
    const [json, setJson] = useState({});


    const RECOMMENDED_ITEM = (data[0].type === '추천매물') ? true : false;
    const [open, setOpen] = useState(false);
    const [ownAmount, setOwnAmount] = useState(0);
    const [reservedAmount, setReservedAmount] = useState(0);
    const [isAsterLoading, setIsAsterLoading] = useState(false);
    const RATIO_FOR_SALE = Number(data[0].offRatio) * 100;
    const typeUrl = (data[0].type === 'NEW_EVENT220616001') ? 'realestate' : '';
    const asterpay = new AsterpayAPI(typeUrl);

    window.onmessage = function(e) {
        asterpay.onMessage(e, json, openModal, setIsAsterLoading, setStartAsterPayment);
    }

    const userSelector = useSelector((state) => state.user); // redux user값

    const onClickBuy = () => {
        if(!isSessionValidate()) {
            alert(t("metarex_alert_expired_session"));
            window.location.href ='/signin';
            return false;
        }    
        readyToPayment();
    }

    const readyToPayment = () => {
        let asterPrice;
        if(data[0].type === 'NEW_EVENT220616001') {
            asterPrice = Number(data[0].priceMetarex);
        }
        const type="NEW_EVENT220616001";
        const buyByPointParams = {
            data,
            type,
        }
        setPayment(asterPrice, setNeedPoint, setIsPaymentShow, buyByPointParams, setPaymentParams);
    }

    const openModal = (e) => {
        if(!RECOMMENDED_ITEM){
            return undefined;
        }

        if(isNull(e)) {
            setOpen(!open);
            return;
        }

        e.preventDefault();
        if(!isSessionValidate()) {
            alert(t("market_view_login"));
            window.location.href ='/signin';
            return false;
        }

        if(!isNull(data[0].soldoutTs)){
            return;
        }

        if(+(data[0].quantityLimitPerPerson) <= 0) {
            return false;
        }

        //getUserStockAmount();
        setOpen(!open);
    }
    
    const forSaleLangs = localStorage.getItem("locale");

    return(
        <>
            {open && 
                <ModalScreen>
                    {isAsterLoading 
                        ? <ReactLoading type={'spin'} color={'skyblue'} height={'40px'} width={'40px'} />
                        : <ModalSmall title={t('recommended_purchase_share')} data={data} closeModal={openModal}>
                            <BuyRealestate closeModal={openModal} item={data} ownAmount={ownAmount} reservedAmount={reservedAmount} onClickBuy={onClickBuy}/>
                        </ModalSmall>
                    }
                </ModalScreen>
            }
            {
                <div className="forSaleBoxContainer">
                    {
                        // 결제 시간
                        data[0].paymentStartedTs !== null
                            ? 
                                <p className="marketBuyingIcon">{t("market_view_soldout_btn")}</p>
                            : data[0].soldoutTs ? 
                                <p className="marketSoldOutIcon">{t("sale_history_sold_out_btn")}</p>
                            :
                            <p className="marketBuyingIconOpacity">{t("market_view_soldout_btn")}</p>
                    }
                    <ul>
                        <li className="marketAddress">
                            {
                                forSaleLangs === "ko" ?
                                    <div>
                                        <a className='address-name' href={data[0].urlLink} >{data[0].address}</a>
                                        <p className='plus-symbol'>+</p>
                                        <a className='address-name' href={data[1].urlLink} >{data[1].address}</a>
                                    </div>
                                    :
                                    <div>
                                        <a className='address-name' href={data[0].urlLink} >{data[0].addressJibunEn}</a>
                                        <p className='plus-symbol'>+</p>
                                        <a className='address-name' href={data[1].urlLink} >{data[1].addressJibunEn}</a>
                                    </div>
                                    
                            }
                        </li>
                        <li className="marketArea">
                            {
                                data[0].areaSter ? 
                                    <span>{t("market_view_area")} {addComma(data[0].areaSter)} ster</span>
                                :
                                    ""
                            }
                        </li>
                        <li className="market-today-price">                            
                            <div className="original-price">
                                <span>{data.priceMetarexAsk ? t("market_view_now_price") : "판매가 "}</span>
                                <span >{addComma(data[0].priceMetarex + data[0].priceMetarex)} {t("map_land_price_area_won")}</span>
                            </div>
                            <div className="event-price">
                                <span>{data.priceMetarexAsk ? t("market_view_now_price") : "이벤트가 "}</span>
                                <span >{addComma(data[0].priceMetarex)} {t("map_land_price_area_won")}</span>
                            </div>
                        </li>
                    </ul>
                    <div>
                        <button className="lookBtn" onClick={MarketViewPopUp}>{t("market_view_modal_info_btn")}</button>
                        {startAsterPayments 
                        ? <button className="saleBtn disabled" disabled={true}>{t('market_view_payment_btn')}</button>
                        : data[0].soldoutTs !== null 
                                        ? <button className="saleBtn disabled" disabled={true}>{t('market_view_soldout_btn')}</button> 
                                                                                                    : data[0].paymentStartedTs !== null 
                                                                                                                                ? <button className="saleBtn disabled" disabled={true}>{t('market_view_trading_btn')}</button>
                                                                                                                                : <button className="saleBtn" onClick={RECOMMENDED_ITEM ? openModal : onClickBuy}>{t("market_view_buy_btn")}</button>
                        }
                    </div>
                </div>
            }
            {!marKetModalPopUp &&
                <ModalScreen>
                    <MarketModal data={data} MarketViewPopUp={MarketViewPopUp}/>
                </ModalScreen>
            }
        </>
    )
}


const Event20220616Item = ({  marketViewData, marketKeyWord, asterPayments, setIsPaymentShow, setNeedPoint, setPaymentParams }) => {
    // 부모값 스타일 바꾸기 위해 ref사용
    const MarketInfoRef = useRef();

    return(
        <MainDiv className="forSaleSlideContainerWrap">
            <div className='forSaleSlideContainer' ref={MarketInfoRef}>
                {   
                    marketViewData && marketViewData.data.map((data , i) =>
                        <Event20220616ItemInfo key={i} data={data} marketKeyWord={marketKeyWord} asterPayments={asterPayments} setIsPaymentShow={setIsPaymentShow} 
                        setNeedPoint={setNeedPoint}
                        setPaymentParams={setPaymentParams}
                        />
                    )
                }
            </div>

        </MainDiv>
    )
}

export default Event20220616Item;
