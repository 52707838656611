import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { addComma } from '../../apis/CommonFunc';

import styled from "styled-components";
import Pagination from "../../components/Pagination";


const StyledDivTable = styled.div`
    width: 100%;
    text-align: center;
    flex-direction: column;
    & > div {
        padding-top: 20px;
    }
`;

const StyledPointTable = styled.table`
    border-collapse: collapse;
    width: 100%;
    max-width: 300px;
    background-color: white;    
    margin: 0px auto;
    thead {
        tr {
            th {                
                border-top: 1px solid #aaaaaa;
                border-bottom: 1px solid #dddddd;
                padding: 5px 0px;
                line-height: 35px;
                background: #fafafa;
                &:nth-of-type(1) {
                }
            }
        }
    }
    tbody {
        tr {
            &.no-item {
                font-weight: 700;
            }
            td {
                border-bottom: 0px solid #dddddd;
                padding: 5px 0px;
                line-height: 35px;
                background: #ffffff;
                font-size: 14px;
                color: #555555;
                text-align: center;
            }
        }
    }
    @media only screen and (max-width: 768px) {
        thead {
            tr {
                th {
                    &:nth-of-type(1) {
                    }
                }
            }
        }
    }
`;

const PointTable = ({data, pageNum, setPageNum, totalCount, PAGE_PER_NUM}) => {
    const table = () => {
        const list = [];
        if( data.length === 0) {
            list.push(
                <tr className="no-item" key={0}>
                    <td colSpan="3">{'point 내역이 존재하지 않습니다.'}</td>
                </tr>
            );
        } else {
            for(let i = 0; i < data.length; i++) {
                list.push(
                    <tr key={i}>
                        {/* 번호 1씩 증가 */}
                        <td>{data[i].index}</td>
                        {/* addComma함수로 판매가 콤마 처리 */}
                        <td>{addComma(data[i].amount)}</td>
                        {/* 시간 제거 */}
                        <td>{data[i].insertedTs.substr(0,11)}</td>
                    </tr>
                );
            }
        }
        
        return list;
    }
    
    return(
        
        <div>
            {data.length > 0?
                <StyledDivTable>
                    <StyledPointTable>  
                        <thead>
                            <tr>
                                <th>번호</th>
                                <th>POINT</th>
                                <th>날짜</th>
                            </tr>
                        </thead>
                        <tbody>
                            { table().map(el => el) }
                        </tbody>
                    </StyledPointTable>
                    <Pagination pageNum={pageNum} setPageNum={setPageNum} totalCount={totalCount} PAGE_PER_NUM={PAGE_PER_NUM}/>            
                </StyledDivTable>
                :''
            }
        </div>
    )

}

export default PointTable;