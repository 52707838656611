import React, { useEffect, useState } from 'react';
import './SelfInfo.css'
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { isSessionValidate } from '../../apis/BrowserStorageAPI';
import { isNull } from '../../apis/Validation';
import { useSelector } from 'react-redux';
import { addComma } from '../../apis/CommonFunc';


import { AsterpayAPI } from '../../apis/AsterpayAPI';
import { setPayment } from '../../apis/PointAPI';

const SelfInfo = (props) => {
    const {t} = useTranslation();
    window.onmessage = function(e) {
        // 아스터페이 닫기 버튼 눌렀을 경우
        if(e.data === 'onStopAsterPayment') {
            //selfOnStopAsterPayment();
            return;
        }
        if(e.data === 'close') {
            if( typeof window.closeMessageHandler != 'undefined' )  {
                window.closeMessageHandler();
            }
        } 
    };

    
    const selfInfoLang = localStorage.getItem("locale");
    const [data, setData] = useState(null);

    const [type , setType ] = useState( "NEW_SELF_BY_REALESTATE" );

    useEffect(()=>{        
        setData(props.sbrData);        
        setData(prev => prev && {
            ...prev,
            type
        });
    },[props.sbrData, type]);

    
    
    
    let asterPayment = props.asterPayment
    const asterpay = new AsterpayAPI('realestate/requestItem');

    const [selfAddress , setSelfAddress ] = useState();
    useEffect(()=>{        
        if( isNull(data) == false ) {
            if(selfInfoLang !== "ko"){
                setSelfAddress(data.addressJibunEn) 
            } else{
                setSelfAddress(data.address)
            }
        }
    },[selfInfoLang , data]);
    
    const [startAsterPayments, setStartAsterPayment] = useState(false);
    const [selfJson, setSelfJson] = useState({});

    // asterPay
    window.onmessage = function(e) {
        asterpay.onMessage(e, selfJson, undefined, undefined, setStartAsterPayment);
    }

    const selfOnClickBuy = (e) => {
        if(!isSessionValidate()) {
            alert(t("market_view_login"));
            window.location.href ='/signin';
            return false;
        }
        const dataAmount = data.priceMetarex;        
        if(isNull(dataAmount)) {
            alert(t("market_view_priced_incorrectly"));
            return false;
        }

        if( props.mxLendingData.canUsersParticipateInMxLending == true ) {
            if(window.confirm( 
                "MX Lending 상품을 이용하시겠습니까?\n" +
                "\n" +
                "MX Lending\n" +
                "\n" +
                "- 자기준비금10퍼센트  lending 90퍼센트\n" +
                "- 7일단위 close\n" +
                "- 차액분 ATC로 인출가능\n" +
                "\n" +
                "메타렉스 물건 구매 시, ATC로 전환되어 보관되는 서비스 입니다." )) {
                const type="NEW_SELF_BY_REALESTATE_MX_LENDING";
                setType(type);
                readyToPayment(10, type);
            } else {            
                const type="NEW_SELF_BY_REALESTATE";
                setType(type);
                alert("일반 구매로 진행합니다.");
                readyToPayment(1, type);
            }
        } else {
            const type="NEW_SELF_BY_REALESTATE";
            setType(type);
            readyToPayment(1, type);
        }
    }


    const readyToPayment = (devide, myType) => {

        const setNeedPoint      = props.setNeedPoint;
        const setIsPaymentShow  = props.setIsPaymentShow;
        const setPaymentParams  = props.setPaymentParams;
        
        let asterPrice;
        asterPrice = Number(data.priceMetarex) / devide;


        let myData = data;
        myData.type = myType;
         
        
        console.log('thkim 20221215 17:10 %o', myData.type);

        setPayment(asterPrice, setNeedPoint, setIsPaymentShow, myData, setPaymentParams, callbackDoneToPayment);
    }

    const [isItemShow, setIsItemShow] = useState(true);
    const callbackDoneToPayment = () => {
        setIsItemShow(false);
        window.close();
    }

    return(
        <div>
            {isItemShow &&
                <section className="selfInfoContainer">                
                {data ? 
                    <div className="selfInfoContainerWrap">
                        <div className="selfBoxContainer">
                            <ul>
                                <li>
                                    {selfInfoLang !== "ko" ? 
                                        <a>{data && data.addressJibunEn}</a>
                                        :
                                        <a>{data && data.address}</a>
                                    }
                                </li>
                                <li>
                                    <span>{t("market_view_area")}  </span>
                                    <span>{data && addComma(data.areaSter)}  </span>
                                    <span> ster </span>
                                </li>
                                <li>
                                    <span>{t("sales_registration_purchase_price")}</span>
                                    <span> {addComma(data && data.priceMetarex)}</span>
                                    <span>  {t("map_land_price_area_won")}</span>
                                </li>
                                <div className="selfBtnContainer">
                                    <Link to={`/contactus?title=${selfAddress}&content=${selfAddress}`}>
                                        <button className="selfQuestion">{t('self_info_btn')}</button>
                                    </Link>
                                    {
                                        ( startAsterPayments === false ) &&
                                        <button className="selfBuy" onClick={selfOnClickBuy}>{t("market_view_buy_btn")}</button>
                                    }
                                </div>
                            </ul>
                        </div>
                    </div>
                    :
                    <div className="selfInfoContainerWrap">
                        <div className="selfBoxContainerNone">
                        </div>
                    </div>
                }
                </section>
            }
        </div>
    )
}

export default SelfInfo;
