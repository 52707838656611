import React, { useRef } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import './SalesInput.css'
import { useTranslation} from "react-i18next";

const SaleInput = (props) => {
    
    const {t} = useTranslation();
    const SaleKeyWordInput = props.setSaleKeyWord
    const saleKeyWord = useRef();

    const saleKeyPress = (e) => {
        if(e.charCode === 13) {
            if(saleKeyWord.current.value === '') {
                alert(t("map_pc_input_placeholder"));
                return;
            }
            
            // 엔터 입력 후 검색
            saleClickSearch();
        }
    };

    const saleClickSearch = () => {
        if(saleKeyWord.current.value === '') {
            alert(t("map_pc_input_placeholder"));
            return;
        }
        // 클릭 버튼 클릭 후 Map 컴포넌트에 검색어와 검색 타입 전달
        SaleKeyWordInput(saleKeyWord.current.value);
    };


    return( 
        <div className='salesInputBox'>
            <input type="text" id="keyword" size="15" maxLength='35' className="salesInput" placeholder={t("sales_registration_input_placeholder")} ref={saleKeyWord} onKeyPress={saleKeyPress}/> 
            <button type="submit" className='salesSearchIcon'>
                <FontAwesomeIcon icon={faSearch} className="salesSearchSvg"  onClick={saleClickSearch} />
            </button>
        </div> 
    )
}

export default SaleInput;