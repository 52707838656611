import React, { useState, useEffect } from 'react';
import './sideInfo.css';
import { 
    landData,
    initMetarexAddressData,
    initAddressData,
    initAddressListData,
    requestBuyItem,
    krInitAddressData
} from './sideInfoUtil';
import { 
    AxiosUrl
} from '../kakaoMap/axiosUrl';
import { useTranslation } from "react-i18next";
import KakaoMapLoading from '../kakaoMap/KakaoMapLoading';
// 
/**
 * 토지 및 메타렉스 상세정보
 */
const SideInfo = (props) => {
    // 주소 정보
    const address = props.address

    // 메타렉스 주소 정보
    const [metarexAddress, setMetarexAddress] = useState(undefined);
    // 토지 및 메타렉스 건물 가격 정보
    const [land, setLand] = useState(undefined);
    // 검색 키워드
    const keyword = props.keyword;
    // 검색 타입
    const searchType = props.searchType;

    // // 맵 클릭
    // useEffect(() => {
    //     if(!address) { return; }

    //     (async () => {
    //         // PC인 경우에만 컴포넌트를 업데이트 한다.
    //         if(window.innerWidth < 1024 || 2 <= window.devicePixelRatio) return;
            
    //         const detail = address.address ? address.address.address_name : '  ';
    //         let res = await AxiosUrl(detail)
    //         setLand(landData(res.data));
    //     })();

    // }, [address]);

    const mapLoading = props.mapLoading;
    const setMapLoading = props.setMapLoading;

    const setClickedSoldoutItem = props.setClickedSoldoutItem;
    const keywordData = props.keywordData;
    
    useEffect(() => {

        setClickedSoldoutItem (
            () => () => {
                return true;
            }
        );
    },[props.keywordData]);

    
    const setIsPopupPurchaseRequisitionShow = props.setIsPopupPurchaseRequisitionShow;
    
    
    useEffect(()=>{
        
        if(!address) { return; }
        
        const mapLoadingAxios = async () => {
            if(window.innerWidth < 1024 || 2 <= window.devicePixelRatio) return;
            try{
                setMapLoading(true)
                const detail = address.address ? address.address.address_name : '  ';
                await AxiosUrl(detail).then(res => setLand(landData(res.data)))
            }catch(e){
                console.log(e)
            }
            setMapLoading(false)
        }
        mapLoadingAxios()
    },[address , setLand , setMapLoading , landData])

    const sideSearchKakaoLang = localStorage.getItem("locale");

    // URL 요청
    useEffect(() => {
        if(keyword && searchType === 'url') {
            (async () => {
                // PC인 경우에만 컴포넌트를 업데이트 한다.
                if(window.innerWidth < 1024 || 2 <= window.devicePixelRatio) return; 
                
                let res = await AxiosUrl(keyword)
                setLand(landData(res.data));
                setMetarexAddress(initMetarexAddressData(res.data));
            })();
        }
    }, [keyword, searchType]);


    return (
        <section id='side-info'>
            {
                mapLoading === true &&
                    <KakaoMapLoading/>
            }
            {/* URL로 키워드가 전달된게 아니고 처음 화면 로딩시 */}
            {(!keyword && searchType === '') && (
                <>
                    <EmptyInfo/>
                </>
            )}

            {(!keyword && searchType === 'search') && (
                <>
                    <EmptyInfo/>
                </>
            )}

            {(!address && keyword && searchType === 'search') && (
                <>
                    <EmptyInfo/>
                </>
            )}

            {/* URL로 키워드가 전달된 경우 */}
            {(keyword && searchType === 'url') && (
                <>
                    { metarexAddress && <AddressInfo address={metarexAddress}/> }
                    { land && <LandInfo address={metarexAddress} land={land}
                    keywordData={keywordData}
                    setIsPopupPurchaseRequisitionShow={setIsPopupPurchaseRequisitionShow} 
                    /> }
                </>
            )}

            {/* 카카오맵 클릭시 */}
            {(searchType === 'mapClick') && (
                <>
                    { (address && sideSearchKakaoLang !== 'ko') ? 
                        <AddressInfo address={initAddressData(address)} keyword={keyword}/>
                        :
                        <AddressInfo address={krInitAddressData(address)}/> 
                    }
                    { (land) && 
                        <LandInfo address={krInitAddressData(address)} land={land} 
                        keywordData={keywordData}
                        setIsPopupPurchaseRequisitionShow={setIsPopupPurchaseRequisitionShow}
                        />
                    }
                </>
            )}
            {/* 주소 목록의 주소를 클릭한 경우 */}
            {(searchType === 'list') && (
                <>
                    { 
                        ((address && sideSearchKakaoLang !== 'ko') ) ? 
                        <AddressInfo address={initAddressData(address)}/> 
                        : 
                        <AddressInfo address={initAddressListData(address)}/>
                    }
                    
                    { (land) && 
                        <LandInfo address={krInitAddressData(address)} land={land} 
                        keywordData={keywordData}
                        setIsPopupPurchaseRequisitionShow={setIsPopupPurchaseRequisitionShow}
                        />
                    }
                </>
            )}
            {/* 상단 헤더 검색에서 주소를 검색한 경우  */}
            {(searchType === 'search') && (
                <>
                    { (address) && <AddressInfo address={initAddressListData(address)}/> }
                    { (land) && 
                        <LandInfo address={krInitAddressData(address)} land={land} 
                        keywordData={keywordData}
                        setIsPopupPurchaseRequisitionShow={setIsPopupPurchaseRequisitionShow}
                        />
                    }
                </>
            )}
            {props.children}
        </section>
    );
};

/**
 * 화면 로딩시 상세정보에 출력
 */
const EmptyInfo = (props) => {
    const {t} = useTranslation();
    // 메세지
    // const message = props.message;

    return (
        <>
            <div className='template'>
                {/* <p className='templateH'>{message}</p> */}
                <p className='templateH'>{t("map_empty_title")}</p>
            </div>
        </>
    );
};

/**
 * 도로명, 지번 등 주소 정보
 */


const AddressInfo =  (props) => {
    const {t} = useTranslation();
    
    
    const sideKakaoLang = localStorage.getItem("locale");
    const address = props.address ? props.address : null;
    
    const [enAddressInfo, setEnAddressInfo ] = useState("")

    useEffect( () => {
        if(sideKakaoLang !== 'ko'){
            async function EnMapInfoData(){
                const result = await address;
                setEnAddressInfo(result)
            }
            EnMapInfoData();
        }else if(sideKakaoLang === 'ko'){
            const result =  address
            setEnAddressInfo(result)
        }
    },[address]);

    // 주소값 영문을 받아올때는 함수를 한번 거쳐서 오기에 경로가 다르다. 그렇기에 밑과 같이 조건을 줌.
    const enAddressInclude = sideKakaoLang !== 'ko' ? enAddressInfo.address :
    address.address

    let checkArea = ['서울', '경기', '인천', '부산' , 'Seoul' , 'Gyeonggi' , 'Incheon' , 'Busan'];
    // 주소값과 위의 배열값을 비교. 주소값이 없으면 null처리
    const isInclude = checkArea.some(area => enAddressInclude ? enAddressInclude.includes(area) : null) 
    
    return (
        <> 
            {
                (sideKakaoLang !== 'ko' && isInclude === true) &&
                    <dl className='addressBox'>
                        <dt className={'listHeader'}>{t("map_road_address")}</dt>
                        <dd className={'listInfoOne'}>{enAddressInfo.addressRoad}</dd>
                        <dt className={'listHeader'}>{t("map_address")}</dt>
                        <dd className={'listInfoTwo'}>{enAddressInfo.address}</dd>
                    </dl>
            } 
            {
                (sideKakaoLang === 'ko' && isInclude === true) &&
                        <dl className='addressBox'>
                            <dt className={'listHeader'}>{t("map_road_address")}</dt>
                            <dd className={'listInfoOne'}>{address.addressRoad}</dd>
                            <dt className={'listHeader'}>{t("map_address")}</dt>
                            <dd className={'listInfoTwo'}>{address.address}</dd>
                        </dl>
            }
            {
                (isInclude === false)&& 
                    <div className='addressBox'>
                        <strong>{t("map_no_include")}</strong>
                    </div>
            } 
        </>
    );
};

/**
 * 토지 및 메타렉스 가격 정보
 */
const LandInfo = (props) => {

    // const landInfoLocalScale = localStorage.getItem("scale")

    const {t} = useTranslation();
    const land = props.land;
    // const checkArea = ['서울', '경기', '인천', '부산'];
    // const isInclude = checkArea.some(area => props.address.address.includes(area));
    // if(!isInclude) {
    //     land.resource = '-';
    // }
    
    return (
        <div className='jsonBox'>
            <aside className='SlideInfoContainer'>
                <ul className='modalList'>
                    <li>
                        <p className='p'>
                            {t("map_land_size")}
                        </p>
                        <p className='jsonP'>
                            {props.land && props.land.landSize}{t("map_per_pyeong")}
                        </p>
                    </li>
                    <li>
                        <p className='p'>
                            {t("map_build_age")}
                        </p>
                        <p className='jsonP'>
                            {props.land && props.land.buildAge}{t("map_year")}
                        </p>
                    </li>
                    <li>
                        <p className='p'>
                            {t("map_floor_size")}
                        </p>
                        <p className='jsonP'>
                            {props.land && props.land.floorSize}{t("map_floor")}
                        </p>
                    </li>
                    <li>
                        <p className='p'>
                            {t("map_resource")}
                        </p>
                        <p className='jsonP'>
                            {t("map_resource_data")}
                        </p>
                    </li>
                    <li className='border'></li>
                </ul>
                <div className='modalList'>
                    <strong className='landPrice'>
                        {t("map_land_price")}
                    </strong>
                    <div>
                        <span className='landData'>
                            {
                                !(land && land.landPrice) ? 
                                <span>{t("map_empty_price")}</span> : 
                                <span>
                                    {land.landPrice} 
                                    <span>{t("map_land_price_area_won")}</span>
                                </span>
                            }
                        </span>
                        <span className='landDatas'>
                            {
                            !(land && land.landPriceArea)  ? 
                                "" 
                                :
                                <span> 
                                    <span>{t("map_land_price_per_pyeong")}</span>
                                    {land.landPriceArea}
                                    <span>{t("map_land_price_area_won")}</span>
                                </span>
                            }
                        </span>
                    </div>
                </div>
            </aside>
            <p className={(land && land.metaLandPrice !== '0') && (land &&  land.landPriceArea !== "")  ? 'metaBox' : 'metaBox emptyPrice'}>
                <strong className='metaPrice'>
                    {t("map_meta_land_price")}
                </strong>
                <span className='metaPriceData'>
                    <span>
                        { 
                            !(land &&  land.metaLandPrice) ? 
                            ""  : 
                            <span>
                                {land.metaLandPrice}<span>{t("map_land_price_area_won")}</span>
                            </span>
                        }
                    </span>
                    <br />
                    <span>
                        {
                            !(land && land.metaLandPriceArea) ? 
                            <span>{t("map_empty_price")}</span> 
                            :
                            <span> 
                                <span>{t("map_land_price_per_pyeong")}</span>
                                {land.metaLandPriceArea}
                                <span>{t("map_land_price_area_won")}</span>
                            </span>
                        }
                    </span>
                </span>
            </p>
            { 
                ( land && land.metaLandPrice !== '0') && (land &&  land.landPriceArea !== "")  && 
                <div>
                    <button className='button' onClick={() => requestBuyItem(props.address.address, props.keywordData, props.setIsPopupPurchaseRequisitionShow) }>
                    { typeof props.keywordData !== 'undefined' && JSON.parse(props.keywordData.isSoldout) ? JSON.parse(props.keywordData.isMine) ? "보유 매물" : t("make_purchase_request") : t("map_buy_btn")}
                    </button>
                </div>
            }
        </div>
    );
};

export default SideInfo;