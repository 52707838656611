import React from "react";
import { t } from "i18next";

import styled from "styled-components";

const StyledFunctionTemplate = styled.div`
    width: 100vw;
    height: 870px;
    background-image: url('/images/bg/about_function_bg.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    margin-bottom: 200px;

    .blur {
        height: 100%;
        width: 100%;
        background: rgba(229, 229, 229, 0.86);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        
        h2 {
            color: #FF645A;
            font-family: 'Roboto';
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 19px;
            letter-spacing: 0.3em;
            text-align: center;
            margin-bottom: 30px;
        }
        p {
            color: #05141F;
            font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
            font-size: 24px;
            font-weight: 800;
            line-height: 27px;
            letter-spacing: 0em;
            text-align: center;
        }
    }

    @media only screen and (max-width: 768px) {
        width: 100%;
        height: auto;
        .blur {
            padding: 120px 20px;
            p {
                font-size: 19px;
                font-weight: 800;
                line-height: 24px;
            }
        }
    }
`;

const StyledContentsGrid = styled.div`
    margin-top: 100px;
    width: 100%;
    max-width: 1240px;
    display: grid;
    grid-template-columns: 295px 295px 295px 295px;
    grid-gap: 20px;
    color: #05141F;
    .content {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 212px;
        border: 1px solid #D9D9D9;
        font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: center;
        padding: 15px;
        background-color: rgba(255, 255, 255, 0.7);
        div {
            text-align: left;
        }
        &:nth-of-type(1),
        &:nth-of-type(5) {
            color: #fff;
            background: rgba(0, 0, 0, 0.8);;
            border: none;
            display: block;
            text-align: left;
            span {
                display: block;
                font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
                font-size: 18px;
                font-style: normal;
                font-weight: 800;
                line-height: 24px;
                letter-spacing: 0em;
                text-align: left;
                &::after {
                    content: ' :';
                }
            }
            p {
                display: inline;
                color: #fff;
                font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 23px;
                letter-spacing: 0em;
                text-align: left;
            }
            a {
                color: #FF645A;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 14px;
                letter-spacing: 0em;
                text-align: left;
                margin-left: 13px;
                text-decoration: underline;
            }
        }
    }

    @media only screen and (max-width: 768px) {
        max-width: 100%;
        display: grid;
        grid-template-columns: [col] 50% [col] 50%;
        grid-template-rows: [row] auto [row] auto [row] auto [row] auto;
        grid-gap: 10px;
        justify-content: center;
        .content {
            &:nth-of-type(1), &:nth-of-type(5){
                order: 1;
                grid-column: 1 / -1;
                padding: 20px;
            }
            &:nth-of-type(2), &:nth-of-type(3), &:nth-of-type(4),
            &:nth-of-type(6), &:nth-of-type(7), &:nth-of-type(8){
                order: 2;
            }
        }
    }
    
`; 

const Function = () => {
    return (
        <StyledFunctionTemplate>
            <div className="blur">
                <h2>FUNCTION</h2>
                <p>{t("metarex_about_function_p")}</p>
                <StyledContentsGrid>
                    <div className="content">
                        <span>{t("metarex_about_function_c_8_1")}</span>
                        <p>{t("metarex_about_function_c_8_2")}</p>
                        <a href="https://astercoin.org/" target="_blank" rel="noopener noreferrer" title={t("metarex_about_function_c_8_3")}>{t("metarex_about_function_c_8_4")}</a>
                    </div>
                    <div className="content">
                        {t("metarex_about_function_c_2")}
                    </div>
                    <div className="content">
                        {t("metarex_about_function_c_3_1")}<br/>
                        {t("metarex_about_function_c_3_2")}
                    </div>
                    <div className="content">
                        {t("metarex_about_function_c_4_1")}<br/>
                        {t("metarex_about_function_c_4_2")}
                    </div>
                    <div className="content">
                        <span>{t("metarex_about_function_c_8_5")}</span>
                        <p>{t("metarex_about_function_c_8_6")}</p>
                        <a href="http://www.awesomepia.kr/" target="_blank" rel="noopener noreferrer" title={t("metarex_about_function_c_8_7")}>{t("metarex_about_function_c_8_8")}</a>
                    </div>
                    <div className="content">
                        {t("metarex_about_function_c_6_1")}<br/>
                        {t("metarex_about_function_c_6_2")}<br/>
                        {t("metarex_about_function_c_6_3")}<br/>
                        {t("metarex_about_function_c_6_4")}
                    </div>
                    <div className="content">
                        {t("metarex_about_function_c_7_1")}<br/>
                        {t("metarex_about_function_c_7_2")}
                    </div>
                    <div className="content">
                        {t("metarex_about_function_c_5")}
                    </div>
                </StyledContentsGrid>
            </div>
    </StyledFunctionTemplate>
    )
}

export default Function;