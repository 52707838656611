import React, { useLayoutEffect, useState } from "react";
import styled from "styled-components";
import '../../components/animation.css';

import { t } from "i18next";
import { axiosGet } from "../../apis/AxiosAPI";

const StyledNewsTemplate = styled.section`
    background-color: #F8F8F8;
    width: 100vw;
    height: 80px;
    display: flex;
    justify-content: center;
    .container {
        max-width: 1440px;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .wrap {
            width: 984px;
            height: 100%;
            margin: 0px 228px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .news {
                font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
                color: #FF645A;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 20px;
                letter-spacing: 0em;
                text-align: left;
                &::after {
                    content: "";
                    border-right: 2px solid #000;
                    padding-left: 16px;
                }
            }
            .date {
                width: 100px;
                font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 20px;
                letter-spacing: 0em;
                margin-left: 16px;
            }
            .newsBanner {
                margin-left: 16px;
                width: 100%;
                height: 20px;
                overflow: hidden;
                display: flex;
                flex-wrap: wrap;
                .newsContent {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    .title {
                        animation: rolling 4s ease-in-out infinite running both;
                        display: block;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        a {
                            font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
                            text-decoration: none;
                            color: #000;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 20px;
                            letter-spacing: 0em;
                            &:hover {
                                transition: 0.5s;
                                color: #3D85F0;
                            }
                        }
                    }
                }

            }
        }

    }

    @media screen and (max-width:786px) {
        height: 162px;
        .container {
            max-width: 100%;
            flex-direction: column;
            .wrap {
                width: 100%;
                padding: 30px 20px;
                margin: 0px 0px;
                flex-direction: column;                
                align-items: flex-start;
                justify-content: center;
                .news {
                    margin-bottom: 5px;
                }
                .date {
                    width: 100%;
                    margin-left: 0px;
                }
                .newsBanner {
                    margin-left: 0px;
                    height: 76px;
                    .newsContent {
                        height: 76px;
                        flex-direction: column;
                        .title {
                            margin-bottom: 16px;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                            white-space: normal;
                        }
                    }
                }
            }
    }
}
`;


const News = () => {
    const [newsList, setNewsList] = useState([]);
    const [viewList, setViewList] = useState([]);

    useLayoutEffect(() => {
        부동산_핫이슈_요청하기();
    }, []);

    const 부동산_핫이슈_요청하기 = async () => {
        const NUM_PER_PAGE = 10;
        axiosGet(`hotIssue/select?pageNum=1&numPerPage=${NUM_PER_PAGE}`)
            .then(response => {
                if(JSON.parse(response.data.result)) {
                    setNewsList(response.data.data);
                }
            })
            .catch(e => {
                alert(t('metarex_alert_server_problem'));
                console.error(`error news: ${e}`);
            });
    }

    useLayoutEffect(() => {
        const ROLLING_SPEED = 4000;
        
        if(newsList.length !== 0) {
            newsContents(index);
            const interval = setInterval(() => {
                newsContents(index);
            }, ROLLING_SPEED);
            return () => clearInterval(interval);    
        }
    }, [newsList]);

    let index = 0;
    const newsContents = () => {
        const news_1 = newsList[index];
        setViewList([news_1]);
        if(index === newsList.length-1) {
            index = 0;
            return;
        }
        index++;
    }
 
    return (
        <StyledNewsTemplate>
            <div className="container">
                <div className="wrap">
                    <span className="news">NEWS</span>
                    <div className="newsBanner">
                        {viewList.length !== 0 
                            ? viewList.map((el, index) =>
                            <div className="newsContent" key={index}>
                                <div className="title">
                                    <a href={el.link} target="_blank">{el.subjectKr}</a>
                                </div>
                                <span className="date">{el.pressDate || el.insertedTs.split(" ")[0]}</span>
                            </div>)
                            : <>Loading...</>
                        }
                    </div>

                </div>
            </div>
        </StyledNewsTemplate>
    )
}


export default News;