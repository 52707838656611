import React from "react";
import styled from "styled-components";

const AuthInputAndBtnForm = styled.div`
    display: flex;
    align-items: center;
`;

const AuthInputAndBtn = ({children}) => {
    return (
        <AuthInputAndBtnForm>
            {children}
        </AuthInputAndBtnForm>
    );
}

export default AuthInputAndBtn;