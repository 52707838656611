import React, {  useEffect, useRef, useState } from 'react';
import ReactLoading from 'react-loading'; 

import MarketViewChart from './MarketViewChart'
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faInfoCircle, faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
import { isSessionValidate } from '../../apis/BrowserStorageAPI';
import { isNull, mobile } from '../../apis/Validation';
import MarketBoard from './MarketBoard';
import ModalScreen from '../../components/Modal/ModalScreen';

import { useSelector } from 'react-redux';
import ModalSmall from '../../components/Modal/ModalSmall';
import BuyRealestate from '../../components/Modal/contents/BuyRealestate';

import { axiosGet } from '../../apis/AxiosAPI';
import { addComma } from '../../apis/CommonFunc';
import { AsterpayAPI } from '../../apis/AsterpayAPI';
import { getPointAmount, buyByPoint } from '../../apis/PointAPI';
import { getPriceDollarKRW } from "../../apis/PriceDollarAPI";

import { setPayment } from '../../apis/PointAPI';

import './ForSale.css';

function minusComma(num) {
    let parts = num.toString().split("."); 
    return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1] : "");
}

function getPosition ( event ) { 
    // 익스플로러의 버전 체크하기 
    var version_IE = "0"; 
    var ieLower = navigator.userAgent.match( /MSIE [0-9]{1,}/ ); 
    if ( ieLower != null ){  version_IE = ieLower.toString().replace( "MSIE " , "" );  } 

    event = event || window.event; 

    var x = 0; // 마우스 포인터의 좌측 위치 
    var y = 0; // 마우스 포인터의 위쪽 위치 

    x = event.offsetX; 
    y = event.offsetY; 

    return { positionX : x, positionY : y }; 
} 

function MarketModal({data , MarketViewPopUp}) {

    const marketModalLang = localStorage.getItem("locale");

    const {t} = useTranslation();
    // 거래추이
    const [tradingBoardBoolean , setTradingBoardBoolean] = useState(false)
    const [tradingMobileBoardBoolean, setTradingMobileBoardBoolean] = useState(false)
    const tradingBoardToggle = () => {  
        if(mobile() === false){
            setTradingBoardBoolean(!tradingBoardBoolean)
        }
    }

    useEffect(()=>{
        if(mobile() === true){
            setTradingBoardBoolean(false)
            setTradingMobileBoardBoolean(true)
        }else if(mobile() === false){
            setTradingMobileBoardBoolean(false)
        }
    },[mobile()], tradingMobileBoardBoolean)

    let pos = getPosition()
    return(
        <div className='marketModalContainer'>
            <button className="MarketModalCloseBtn" onClick={MarketViewPopUp}>x</button>
            <ul className="marketModalDetailContainer">
                <li><span>{t("market_view_modal_title")}</span></li>
                <li>
                    <a href={data.urlLink}>{marketModalLang === "ko" ? data.address : data.addressJibunEn}</a>
                </li>
                <li>    
                    {
                        data.areaSter ? 
                        <>
                            <span>{t("market_view_area")}</span>
                            <span>{addComma(data.areaSter)} ster </span>
                        </>
                        :
                            ""
                    }
                </li>
            </ul>
            <div className="marketModalBr"></div>
            <ul className="marketModalPriceContainer"> 
                <li>
                    <span>{t("market_view_modal_buy_price")}</span>
                    <span>{ (data.type === 'p2p매물' && data.previousPrice) ? addComma(data.previousPrice) : addComma(data.priceMetarex)}  {t("map_land_price_area_won")}</span>
                </li>
                <li>
                {data.priceMetarexAsk && 
                    <> 
                        <span>{t("market_view_modal_sell_price")}</span>
                        <span>{addComma(data.priceMetarexAsk)} {t("map_land_price_area_won")}</span>
                    </>
                }
                </li>
            </ul>
            {
                    (data.arrayListPriceTrend === undefined || !data.arrayListPriceTrend) ? 
                    ""
                    : 
                    <>
                        <div className="marketModalBr"></div>
                        <div className="chartTitle" onClick={tradingBoardToggle}>
                            <MarketViewChart chartData = {data.arrayListPriceTrend}></MarketViewChart> 
                            {
                                (tradingBoardBoolean === true && tradingMobileBoardBoolean === false) && 
                                <div className='marketBoardContainer' style={{"left" : `${pos.positionX}` + "px" , "top" : `${pos.positionY}` + "px" , "position" : "fixed" }}  >
                                    <MarketBoard data={data}></MarketBoard>
                                </div>
                            }
                        </div>
                            {
                                (tradingBoardBoolean === false && tradingMobileBoardBoolean === true) && 
                                <div className='marketBoardContainer'  >
                                    <MarketBoard data={data}></MarketBoard>
                                </div>
                            }
                    </>
                }
        </div>
    )
}


function MarketInfo({data , marketKeyWord, asterPayments, setIsPaymentShow, setNeedPoint, setPaymentParams}){
    const {t} = useTranslation();
    const MIN_PRICE = 100000;
    // 로그인 확인용
    // saleCancleUn    
    const [marKetModalPopUp, setMarketModalPopUp ] = useState(true)
    const MarketViewPopUp = () => {
        setMarketModalPopUp(!marKetModalPopUp)
    }
    // data주소값 확인
    const datas = isNull(data.address) ? '' : data.address;
    let searchList = datas.indexOf(marketKeyWord);
    // const searchList = datas.includes(marketKeyWord);

    const fixYieldData = isNull(data.yield) ? 0 : data.yield;
    const fixYield = fixYieldData.toFixed(2);

    // 아스터 페이 start
    const [startAsterPayments, setStartAsterPayment] = useState(false);
    const [json, setJson] = useState({});


    const RECOMMENDED_ITEM = (data.type === '추천매물') ? true : false;
    const [open, setOpen] = useState(false);
    const [ownAmount, setOwnAmount] = useState(0);
    const [reservedAmount, setReservedAmount] = useState(0);
    const [isAsterLoading, setIsAsterLoading] = useState(false);
    const RATIO_FOR_SALE = Number(data.offRatio) * 100;
    const typeUrl = (data.type === '추천매물') ? 'realestate/recommendation' : (data.type === 'p2p매물') ? 'realestate/p2p' : (data.type === 'NORMAL_NEW') ? 'realestate' : '';
    const asterpay = new AsterpayAPI(typeUrl);

    window.onmessage = function(e) {
        asterpay.onMessage(e, json, openModal, setIsAsterLoading, setStartAsterPayment);
    }

    const userSelector = useSelector((state) => state.user); // redux user값


    const onClickBuy = (quantity) => {
        if(!isSessionValidate()) {
            alert(t("metarex_alert_expired_session"));
            window.location.href ='/signin';
            return false;
        }

        let asterPrice;
        if(data.type === '일반매물' || data.type === 'p2p매물') {
            asterPrice = Number(data.priceMetarexAsk);
        } else if(data.type === 'NORMAL_NEW') {
                asterPrice = Number(data.priceMetarex);
        } else if(data.type === '추천매물'){
            asterPrice = Number(quantity) * Number(data.priceMetarexUnit);
            if(data.offPriceMetarexUnit) {
                asterPrice = Number(quantity) * Number(data.offPriceMetarexUnit);
            }
        }

        if(isNull(asterPrice)) {
            alert(t("market_view_priced_incorrectly"));
            return false;
        }

        if(process.env.REACT_APP_MODE === 'development' || process.env.REACT_APP_MODE === 'test' || process.env.REACT_APP_MODE === 'production') {            
            {/* 신규 payment. */}
            setPayment(asterPrice, setNeedPoint, setIsPaymentShow, data, setPaymentParams);
        } else {
            {/* 기존 아스터페이 로직 */}
            if(asterPrice < MIN_PRICE) {
                alert("최소 송금 수량 제한으로 10만원 미만의 매물은 구매요청하실 수 없습니다.");
                return false;            
            }
        
            // 아스터페이가 로딩되기 전에, 로딩바를 보여주기 위한 변수
            setIsAsterLoading(true);
    
            const randNumber    = String(Math.floor((Math.random() * 1986) + 1)) + String(Math.floor((Math.random() * 9860) + 1)) + String(Math.floor((Math.random() * 8605) + 1)) + String(Math.floor((Math.random() * 6052) + 1));
            const dataOrderName = localStorage.getItem('locale') !== 'ko' ? data.addressJibunEn : data.address;
            const historySellNo = String(data.historySellNo);
            const realestateNo  = String(data.realestateNo);
            const sessionId     = localStorage.getItem('sessionId');
            const orderItemType = data.type;
            
            const asterpayObj = {
                currencyType: 'Astercoin',
                amount: asterPrice,
                orderId: String(`${Number(new Date())}_${randNumber}`).replace(/\s/g,'_'),
                orderName: dataOrderName,
                orderItemType,
                customerName: userSelector.name,
                mobile: userSelector.mobile,
                email: userSelector.email,
                successUrl: `${process.env.REACT_APP_API_METAREX}/api/asterpay/success`,
                failUrl: `${process.env.REACT_APP_API_METAREX}/api/asterpay/fail`,
                orderNumber: (data.type === '추천매물' || data.type === 'NORMAL_NEW') ? realestateNo : historySellNo,
                customerSession: sessionId
            }
    
            if(data.type === '추천매물') {
                const purchaseQuantity = String(quantity);
                setJson({realestateNo, sessionId, purchaseQuantity});
            } else if (data.type === 'NORMAL_NEW') {
                setJson({realestateNo});
            } else {
                setJson({realestateNo, historySellNo, sessionId});
            }
            
            asterpay.아스터페이_실행하기(asterPayments, asterpayObj);
        }
        
    }
    // 아스터페이 end

    const getUserStockAmount = () => {
        axiosGet(`api/realestate/my-purchase-quantity?realestateNo=${+(data.realestateNo)}`)
            .then(response => {
                if(JSON.parse(response.data.result)){
                    setOwnAmount(+(response.data.data));
                    getUserReserveStockAmount();
                }
            })
            .catch(e => console.error(e));
    }

    const getUserReserveStockAmount = () => {
        axiosGet(`api/realestate/my-reserved-quantity?realestateNo=${+(data.realestateNo)}`)
            .then(response => {
                if(JSON.parse(response.data.result)){
                    setReservedAmount(+(response.data.data));
                }
            })
            .catch(e => console.error(e));
    }

    const openModal = (e) => {
        if(!RECOMMENDED_ITEM){
            return undefined;
        }

        if(isNull(e)) {
            setOpen(!open);
            return;
        }

        e.preventDefault();
        if(!isSessionValidate()) {
            alert(t("market_view_login"));
            window.location.href ='/signin';
            return false;
        }

        if(!isNull(data.soldoutTs)){
            return;
        }

        if(+(data.quantityLimitPerPerson) <= 0) {
            return false;
        }

        getUserStockAmount();
        setOpen(!open);
    }
    
    const forSaleLangs = localStorage.getItem("locale");

    return(
        <>
            {open && 
                <ModalScreen>
                    {isAsterLoading 
                        ? <ReactLoading type={'spin'} color={'skyblue'} height={'40px'} width={'40px'} />
                        : <ModalSmall title={t('recommended_purchase_share')} data={data} closeModal={openModal}>
                            <BuyRealestate closeModal={openModal} item={data} ownAmount={ownAmount} reservedAmount={reservedAmount} onClickBuy={onClickBuy}/>
                        </ModalSmall>
                    }
                </ModalScreen>
            }
            {
                // 기본이 0, 검색값이 없는게 -1. 그렇기에 검색했을때 0인값 즉 존재하는값만 나타난다.
                (searchList !== -1)&&
                    <div className="forSaleBoxContainer">
                        {
                            // 결제 시간
                            data.paymentStartedTs !== null
                                ? 
                                    <p className="marketBuyingIcon">{t("market_view_trading_btn")}</p>
                                : data.soldoutTs ? 
                                    <p className="marketSoldOutIcon">{t("sale_history_sold_out_btn")}</p>
                                :
                                <p className="marketBuyingIconOpacity">{t("market_view_trading_btn")}</p>
                        }
                        <ul>
                            <li className="marketAddress">
                                {
                                    forSaleLangs === "ko" ?
                                        <a href={data.urlLink} >{data.address}</a>
                                        :
                                        <a href={data.urlLink} >{data.addressJibunEn}</a>
                                }
                            </li>
                            <li className="marketArea">
                                { data.buildingAlias && <span>추천 타이틀 : {data.buildingAlias}</span> }   
                            </li>
                            <li className="marketArea">
                                { data.priceMetarexUnit && <span>지분 수량 : {Number(data.reservationsNum) + Number(data.soldoutNum)} / {data.totalUnit}</span> }   
                            </li>
                            <li className="marketArea">
                                { (Number(data.offRatio) > 0) && <span>할인율 : {Number(data.offRatio) * 100} %</span> }   
                            </li>
                            <li className="marketArea">
                                {
                                    data.areaSter ? 
                                        <span>{t("market_view_area")} {addComma(data.areaSter)} ster</span>
                                    :
                                        ""
                                }
                                <span className="buyHistoryInfoIconContainer">
                                    <FontAwesomeIcon icon={faInfoCircle} className="buyHistoryInfoIcon"/>
                                </span>
                            </li>
                            {
                                (data.type === "p2p매물" && data.previousPrice) &&
                                <li className="marketPrevPrice">
                                    <span>{t("market_view_previous_price")}</span> 
                                    <span>{addComma(data.previousPrice)} {t("map_land_price_area_won")}</span>
                                </li>
                            }
                            <li className="marketTodayPrice">
                                <span>{data.priceMetarexAsk ? t("market_view_now_price") : "판매가"}</span> 
                                <span style={RATIO_FOR_SALE !== 0 && RECOMMENDED_ITEM ? {color:'#FF645A', textDecoration:'line-through', fontSize:'15px', marginRight: '10px'} : {}}>{data.priceMetarexAsk ? addComma(data.priceMetarexAsk) : addComma(data.priceMetarex)} {t("map_land_price_area_won")}</span>
                                {RATIO_FOR_SALE !== 0 && RECOMMENDED_ITEM 
                                    && 
                                    <div>
                                        <FontAwesomeIcon icon={faLongArrowAltRight} style={{color:'#888888'}}/> 
                                        <span style={{fontWeight: '800', fontSize: '20px', marginLeft: '8px'}}>{addComma(data.offPriceMetarex)} {t("map_land_price_area_won")}</span> 
                                    </div>
                                }
                            </li>
                            <li className="marketPriceChange">
                                {
                                    data.yield < 0 &&  data.yield !== 0 ?
                                        <>
                                            <span className="saleTriangleBlue"></span> 
                                            <span className="saleInCreaseBlue">{minusComma(fixYield)} %</span>
                                        </>
                                    : 
                                    data.yield > 0 &&  data.yield !== 0 ?
                                        <>
                                            <span className="saleTriangleRed"></span> 
                                            <span className="saleInCreaseRed">{minusComma(fixYield)} %</span>
                                        </>
                                    :
                                    data.yield === 0 && 
                                        <span className="saleInCrease">{minusComma(fixYield)} %</span>
                                }
                            </li>
                        </ul>
                        <div>
                            <button className="lookBtn" onClick={MarketViewPopUp}>{t("market_view_modal_info_btn")}</button>
                            {startAsterPayments 
                            ? <button className="saleBtn disabled" disabled={true}>{t('market_view_payment_btn')}</button>
                            : data.soldoutTs !== null 
                                            ? <button className="saleBtn disabled" disabled={true}>{t('market_view_soldout_btn')}</button> 
                                                                                                        : data.paymentStartedTs !== null 
                                                                                                                                    ? <button className="saleBtn disabled" disabled={true}>{t('market_view_trading_btn')}</button>
                                                                                                                                    : <button className="saleBtn" onClick={RECOMMENDED_ITEM ? openModal : onClickBuy}>{t("market_view_buy_btn")}</button>
                            }
                        </div>
                    </div>
            }
            {!marKetModalPopUp &&
                <ModalScreen>
                    <MarketModal data={data} MarketViewPopUp={MarketViewPopUp}/>
                </ModalScreen>
            }
        </>
    )
}


const ForSale = ({  marketViewData, marketKeyWord, asterPayments, setIsPaymentShow, setNeedPoint, setPaymentParams }) => {
    // 부모값 스타일 바꾸기 위해 ref사용
    const MarketInfoRef = useRef();

    return(
        <div className="forSaleSlideContainerWrap">
            <div className='forSaleSlideContainer' ref={MarketInfoRef}>
                {   
                    marketViewData && marketViewData.data.map((data , i) =>
                        <MarketInfo 
                            key={i} 
                            data={data} 
                            marketKeyWord={marketKeyWord} 
                            asterPayments={asterPayments} 
                            setIsPaymentShow={setIsPaymentShow} 
                            setNeedPoint={setNeedPoint} 
                            setPaymentParams={setPaymentParams}
                        />
                    )
                }
            </div>

        </div>
    )
}

export default ForSale;
