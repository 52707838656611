import React, { useEffect, useState } from "react";
import { t } from "i18next";
import styled from "styled-components";

import AuthTemplate from "../components/Auth/AuthTemplate";
import AuthFieldSet from "../components/Auth/AuthFieldSet";
import AuthInputAndBtn from "../components/Auth/AuthInputAndBtn";
import AuthInput from "../components/Auth/AuthInput";
import AuthButton from "../components/Auth/AuthButton";
import AuthCapsLock from "../components/Auth/AuthCapsLock";

import { axiosPost } from "../apis/AxiosAPI";
import { 휴대폰_인증번호_요청하기 } from "../apis/AuthAPI";
import { moveTopOnPageAuto } from "../apis/CommonFunc";
import { isCellPhoneForKorea, isCellPhoneStartWithoutZero, isNull } from "../apis/Validation";

const StyledMobilePhoneDiv = styled.div`
    margin-bottom: 20px;
`;

const FindPassword = () => {
    const [isCapsLock, setIsCapsLock] = useState(false);
    const [requestFindPassword, setRequestFindPassword] = useState({
        userMobile: '',
        verifyMobileCode: '',
        userPassword:'',
        userRepassword: ''
    });

    useEffect(()=>{
        moveTopOnPageAuto();
    },[]);

    const onChangeFindPasswordForm = (e) => {
        setRequestFindPassword({...requestFindPassword, [e.target.name]: e.target.value});
    }

    const onClickVerifyMobile = () => {
        const userMobile = requestFindPassword.userMobile;
        if(isNull(userMobile) || userMobile === "") {
            alert(t("find_pw_alert_phone"));
            return false;
        }
        if(isCellPhoneStartWithoutZero(userMobile)) {
            userMobile = '0' + userMobile;
        }
        if(!isCellPhoneForKorea(userMobile)) {
            alert(t('find_pw_alert_phone_fail'));
        }
    
        localStorage.setItem('findIdMobile', userMobile.replace(/\s+/g, '').trim());
        휴대폰_인증번호_요청하기( userMobile );
    }

    const onClickFindIdRequestButton = () => {
        const userMobile = requestFindPassword.userMobile;
        const verifyMobileCode = requestFindPassword.verifyMobileCode;
        const authTokenMobile = localStorage.getItem('authTokenMobile');
        const userPassword = requestFindPassword.userPassword;
        const userRepassword = requestFindPassword.userRepassword;

        if(isNull(userMobile) || userMobile === "") {
            alert(t('find_pw_alert_phone'));
            return false;
        }
        if(isNull(verifyMobileCode) || verifyMobileCode === "") {
            alert(t('find_pw_alert_phone_veri'));
            return false;
        }
        if(isNull(authTokenMobile) || authTokenMobile === "") {
            alert(t('find_pw_alert_phone_auth'));
            return false;
        }
        if(isNull(userPassword) || userPassword === "") {
            alert(t('find_pw_alert_pw'));
            return false;
        }
        if(isNull(userPassword)) {
            alert(t('find_pw_alert_pw_veri'));
            return false;
        }
        if(isNull(userRepassword) || userRepassword === "") {
            alert(t('find_pw_alert_re_pw'));
            return false;
        }
        if(userPassword !== userRepassword) {
            alert(t('find_pw_alert_pw_incorrect'));
            return false;
        }
        패스워드_변경_요청하기({
            userMobile,
            verifyMobileCode,
            authTokenMobile,
            userPassword,
            userRepassword,
        });
    }

    const 패스워드_변경_요청하기 = async (json) => {
        axiosPost('selfCertification/changePassword', json)
            .then(response => {
                alert(response.data.message);
                if(JSON.parse(response.data.result)) {
                    window.location.href = '/signin';
                } else {
                    alert(response.data.message);
                }
            }).catch(e => {
                console.log(`error message: ${e}`);                
            });
    }

    const onKeyUpEvent = (e) => {
        setIsCapsLock(e.getModifierState("CapsLock"));
        e.target.value = e.target.value.replace(/[ㄱ-ㅎㅏ-ㅣ가-힣]/g, '');
    }
    
    return (
        <AuthTemplate type="find_pw">
            <AuthFieldSet title={t("find_pw_title")}>
                <StyledMobilePhoneDiv>
                    <label htmlFor="userMobile">{t("find_pw_sub_title_mobile")}</label>
                    <AuthInputAndBtn>
                        <AuthInput 
                            type="tel" 
                            id="userMobile" 
                            name="userMobile"
                            placeholder={t("find_pw_placeholder_number")} 
                            onChange={onChangeFindPasswordForm}
                        />
                        <AuthButton onClick={onClickVerifyMobile} auth={true}>{t("find_pw_verification_code")}</AuthButton>
                    </AuthInputAndBtn>
                    <AuthInput 
                        type="tel"
                        name="verifyMobileCode"
                        placeholder={t("find_pw_placeholder_input")} 
                        onChange={onChangeFindPasswordForm}
                    />
                </StyledMobilePhoneDiv>

                <>
                <label htmlFor="userPassword">{t("find_pw_input_change_pw")}</label>
                <AuthInput 
                    type="password" 
                    id="userPassword" 
                    name="userPassword"
                    placeholder={t("find_pw_placeholder_enter")}
                    onChange={onChangeFindPasswordForm}
                    onKeyUp={onKeyUpEvent}
                />
                <label htmlFor="userRepassword">{t("find_pw_reinput_change_pw")}</label>
                <AuthInput 
                    type="password" 
                    name="userRepassword" 
                    placeholder={t("find_pw_placeholder_confirm")}
                    onChange={onChangeFindPasswordForm}
                    onKeyUp={onKeyUpEvent}
                />
                <AuthCapsLock isCapsLock={isCapsLock}/>
                <AuthButton onClick={onClickFindIdRequestButton}>{t("find_pw_change_pw")}</AuthButton>
                </>
            </AuthFieldSet>
        </AuthTemplate>
    )
}

export default FindPassword;