import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faYoutubeSquare } from "@fortawesome/free-brands-svg-icons"
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'



const StyledInformationTemplate = styled.div`
    width: 100vw;
    height: 318px;
    background-color: #fff;
    display: flex;
    justify-content: center;
`;

const StyledContentsDiv = styled.div`
    width: 100%;
    height: 100%;
    max-width: 1440px;
    .container{
        height: 164px;
        padding-top: 20px;
        border-bottom: 1px solid #D9DDE2;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .contact_us {
            display: flex;
            align-items: center;
            margin-left: 20px;
            img {
                width: 252px;
                height: 40px;
            }
            .wrap {
                margin-left: 30px;
                span {
                    font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 26px;
                    letter-spacing: 0em;
                }
            }
        }
        .fa-file-pdf {
            display: none;
        }
        .fa-file-pdf,
        .fa-youtube-square,
        .footer-social-link-icon {
            font-size: 40px;
            color: #595959;
            margin-right: 10px;
        }
        @media screen and (max-width:786px) {
            .fa-file-pdf,
            .fa-youtube-square {
                margin-bottom: 20px;
            }
        }
        
        .footer-social-link-icon {
            width: 36px;
            vertical-align: bottom;
        }
        @media screen and (max-width:786px) {
            .footer-social-link-icon {
                margin-bottom: 20px;
            }
        }

    }
    .inquiry {
        .wrap {
            padding-top: 40px;
            padding-right: 20px;
            height: 100%;
            display: flex;
            justify-content: space-between;
            span {
                font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: 0em;
                margin-left: 20px;
            }
            a {
                font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
                font-size: 20px;
                font-style: normal;
                font-weight: 800;
                line-height: 24px;
                letter-spacing: 0em;
                margin-left: 30px;
                color: #000;
                &:hover {
                    color: #3D85F0;
                }
             }
        }
    }

    @media screen and (max-width:786px) {
        max-width: 100%;        
        padding: 40px 20px;
        .container{
            height: auto;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            .contact_us {
                flex-direction: column;
                align-items: flex-start;
                margin: 0px;
                img {
                    width: 160px;
                    height: 26px;
                    margin-bottom: 20px;
                }
                .wrap {
                    margin-left: 0px;
                    margin-bottom: 40px;
                    span {
                        font-size: 14px;
                        line-height: 20px;
                    }
                }
            }
        }
        .inquiry {
            .wrap {
                padding: 20px 0px 40px 0px;
                flex-direction: column;
                justify-content: flex-start;
                span {
                    margin-left: 0px;
                    font-size: 14px;
                    margin-bottom: 40px;
                }
                a {
                    margin-left: 0px;
                    font-size: 20px;
                    line-height: 24px;
                    letter-spacing: 0em;
                    margin: 0px 30px 0px 0px;
                }
            }
        }

    }

`;

const InformationMetarex = () => {
    const {t} = useTranslation();
    const YOUTUBE_CHANNEL_URL       = "https://www.youtube.com/channel/UC4FibIV1vNUMNy8g5L-BTFw";
    const NAVER_CAFE_URL            = "https://cafe.naver.com/bluegrayfwirt";
    const SOCIAL_MEDIAS_COLLECTION  = "/Menu";

    return (
        <StyledInformationTemplate>
            <StyledContentsDiv>
                <div className="container">
                    <div className="contact_us">
                        <img src={`${process.env.PUBLIC_URL}/images/logo/metaREX_logo_footer.svg`} alt="metaREX_logo"/>
                        <div className="wrap">
                            <span>{t("footer_korea_office")}: 070-4693-2688({t("footer_working_time")})</span><br/>
                            <span>{t("footer_fax")}: 02-6356-0101 | {t("footer_partnership")}: support@tsnet.sg</span>
                        </div>
                    </div>
                    <div>
                        <a href={YOUTUBE_CHANNEL_URL} target="_blank"><FontAwesomeIcon icon={faFilePdf}/></a>
                        <a href={YOUTUBE_CHANNEL_URL} target="_blank"><FontAwesomeIcon icon={faYoutubeSquare} alt="go to metarex youtube"/></a>
                        <a href={NAVER_CAFE_URL} target="_blank"><img className="footer-social-link-icon" src={`${process.env.PUBLIC_URL}/images/footer/social/naver_cafe_grayscale_small.png`} alt="go to metarex naver cafe"/></a>
                        <a href={SOCIAL_MEDIAS_COLLECTION} target="_blank"><img className="footer-social-link-icon" src={`${process.env.PUBLIC_URL}/images/footer/social/socialMedias_grayscale_small.png`} alt="go to metarex social media collection page"/></a>
                    </div>                    
                </div>
                <div className="inquiry">
                    <div className="wrap">
                        <span className="address">111 SOMERST ROAD #06-07T 111 SOMERSET SINGAPORE (238164) | 201933593Z</span>
                        <div className="inquiry_wrap">
                            <Link to="/contactus">{t("footer_contact_us")}</Link>
                            <Link to="/terms-of-service">{t("footer_terms_of_use")}</Link>
                        </div>
                    </div>
                </div>
            </StyledContentsDiv>
        </StyledInformationTemplate>
    )


}

export default InformationMetarex;