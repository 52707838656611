import React from "react";
import styled from "styled-components";

const StyledContactInputBox = styled.input`
    display: block;
    width: 100%;
    height: 44px;
    padding: 14px;
    border: 1px solid #D9DDE2;
    border-radius: 2px;
    box-sizing: border-box;
    margin-bottom: 10px;

    font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: -0.02em;
    text-align: left;
`; 

const ContactInputBox = ({type, placeholder, item, setItem}) => {
    const onChangeEvent = (e) => {
        setItem(e.target.value);
    }

    return (
        <StyledContactInputBox 
            type={type} 
            placeholder={placeholder}
            maxlength="128"
            onChange={onChangeEvent}
            value={item}
        />
    )
}

export default ContactInputBox;