import axios from 'axios';
import KakaoMapLoading from './KakaoMapLoading'

const AxiosUrl =  ( keyword ) => {
    axios.defaults.withCredentials = true
    const responses =  axios.get(`${process.env.REACT_APP_API_METAREX}/gio/info/get?keyWord=${encodeURI(keyword)}` ,  {withCredentials : true});
    KakaoMapLoading(responses)
    return responses
}

export { 
    AxiosUrl
}