import React, { useEffect, useState } from "react";
import styled from "styled-components";

const ModalScreenDiv = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, .6);
    z-index: 999;
`;

// 모달창이 뜰 경우 배경에 깔리는 검은색 배경
const ModalScreen = ({children}) => {
    const [isStart, setIsStart] = useState(true);
    useEffect(()=>{
        var root = document.getElementsByTagName( 'html' )[0];
        if(isStart){
            root.setAttribute( 'class', 'stop-scroll' );
        }
        return () => { 
            root.removeAttribute( 'class', 'stop-scroll' );
            setIsStart(false);
        }
    }, [])

    return (
        <ModalScreenDiv>
            {children}
        </ModalScreenDiv>
    )
}

export default ModalScreen;
