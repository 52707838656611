import React from "react";
import styled from "styled-components";

const ModalDiv = styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0px;
    top: 0px;
    background: rgba(0,0,0,0.7);
    padding: 0px;
    z-index:1000;

    .popup-image {
        width: 100%;
        cursor: pointer;
    }
    .botom-right-no-display-in-today-check-box-wrapper {
        bottom: 0px;
        right: 0px;
        padding: 5px;
        text-align: right;

        & * {
            cursor: pointer;
        }
        
    }
`;

const ContentDiv = styled.div`
    width: 320px;
    height: auto;
    max-height: 90vh;
    background: rgba(255,255,255);
    padding: 0px;
    margin: 0px auto;
    position: relative;
`;

const CloseBtnSpan = styled.span`
    position: absolute;
    top: 10px;
    right: 10px;
    width: 27px;
    height: 27px;
    cursor: pointer;
    &::before {
        content: ' ';
        position: absolute;
        width: 27px;
        height: 1px;
        top: calc(50% - 1px);
        background: #000;
        transform-origin: center;
        transition: .3s;
        transform: rotate(45deg);
    }
    &::after{
        content: ' ';
        position: absolute;
        width: 27px;
        height: 1px;
        background: #000;
        top: calc(50% - 1px);
        transform-origin: center;
        transition: .3s;
        transform: rotate(-45deg);
    }

    &:hover {
        &::before {
            transform: rotate(0deg);
        }
        &::after{
            transform: rotate(-0deg);
        }
    }

`;

const TitleDiv = styled.div`
    color: #444;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    text-align: center;
    padding: 20px 0px;
    border-bottom: 3px solid #DFE1E5;
`;

const Popup = ({
    setIsShow, 
    popupTitle,
    children}) => {
    
    const closeModal = (e) => {
        e.preventDefault();
        setIsShow(false);
    }

    return (
        <ModalDiv>
            <ContentDiv>
                <CloseBtnSpan onClick={closeModal}/>
                <TitleDiv>
                    {popupTitle}
                </TitleDiv>
                {children}
            </ContentDiv>
        </ModalDiv>
    )
}

export default Popup;