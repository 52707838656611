import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import { t } from 'i18next';
import GetItOn from "../MainBanner/GetItOn";
/*const Default = ({ children }) => {
  const isNotMobile = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  return isNotMobile ? children : null;
};*/


const MainDiv = styled.div`
  .mobile-only {
    display: none;
    @media only screen and (max-width: 768px) {
      display: block;
    }
  }
`;
const Container = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR&display=swap");

  font-weight: 400;

  @-webkit-keyframes glow {
    from {
      text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #e60073,
        0 0 40px #e60073, 0 0 50px #e60073, 0 0 60px #e60073, 0 0 70px #e60073;
    }
    to {
      text-shadow: 0 0 20px #fff, 0 0 30px #ff4da6, 0 0 40px #ff4da6,
        0 0 50px #ff4da6, 0 0 60px #ff4da6, 0 0 70px #ff4da6, 0 0 80px #ff4da6;
    }
  }

  & h1 {
    font-family: "Roboto", sans-serif;
  }

  & p {
    font-family: "Noto Sans KR", sans-serif;
  }
  & button {
    font-family: "Noto Sans KR", sans-serif;
  }

  .city1-wrapper {
    padding: 10px 20px;

    .xr-virtual-based {
      @media only screen and (max-width: 768px) {
        font-size: 30px;
      }
    }
    .city1-description {
      padding-top: 20px;
      font-weight: 300;
      line-height: 35px;
      @media only screen and (max-width: 768px) {
        font-size: 15px;
        line-height: 24px;
      }
    }
  }
`;


const UserWrapCity1 = styled.div`
  position: relative;
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 0px;
  display: block;  
  @media only screen and (max-width: 768px) {    
    background-image: url("images/renewal20221005/city1.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 15% bottom;
    background-color: rgb(204, 207, 212);
    height: 100vh;
  }
  .city1-wrapper {
    .city1 {
      filter: brightness(0.7);
      @media only screen and (max-width: 768px) {
        height: 100vh;
        width: auto;        
        display: none;
      }
    }
  }

  .city1-metarex-logo {
    width: 300px;
    margin-right: 59px;
    @media only screen and (max-width: 768px) {
      width: 280px;
      margin-bottom: 20px;
    }
  }

  .section-two {
    margin-top: 200px;
    margin-left: 90px;    
    @media only screen and (max-width: 768px) {      
      margin-top: 30vh;
      margin-left:0px;
    }
    width: 100%;
    display: flex;
    @media only screen and (max-width: 768px) {
      flex-direction: column-reverse;
    }

    .city1-metarex-second {
      font-size: 38px;
      @media only screen and (max-width: 768px) {
        font-size: 28px;
        text-align: left;
      }

      .second-earth {
        color: #ffffff;
      }
      .second-dollar {
        color: #ffffff;
      }
    }

    .city1-metarex-second-description {
      font-size: 20px;
      text-shadow: -1px 0 #555, 0 1px #555, 1px 0 #555, 0 -1px #555;
      text-align: left;
      color: #ffb86c;
      -webkit-animation: glow 1s ease-in-out infinite alternate;
      -moz-animation: glow 1s ease-in-out infinite alternate;
      animation: glow 1s ease-in-out infinite alternate;
      width: 547px;
      @media only screen and (max-width: 768px) {        
        color: #ffdf55;
        width: 100%;
      }
    }
    .title-and-button {
      width: 30%;
      @media only screen and (max-width: 768px) {
        width: 100%;
      }
      text-align: center;
    }
    .earth-wrapper {
      width: 70%;
      @media only screen and (max-width: 768px) {
        width: 100%;
        text-align: center;
      }
      img {
        float: right;
        padding-right: 200px;
        @media only screen and (max-width: 768px) {
          float: unset;
          padding-right: 0px;
        }
      }
    }
  }
`;

const UserWrap = styled.div`
  position: relative;
  display: block;
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 0px;
  display: block;
  &.xr-description {
    height: 600px;
    @media only screen and (max-width: 768px) {
      height: 500px;
    }
    background-image: url("images/renewal20221005/human.png");
    background-repeat: no-repeat;
    background-size: 821px;
    @media only screen and (max-width: 768px) {
      background-size: 400px;
    }
    background-position: 15% bottom;
    background-color: rgb(204, 207, 212);
  }
  .city1-wrapper {
    .city1 {
      filter: brightness(0.7);
    }
  }

`;
const UserWrap1 = styled.div`
  width: 100%;

  height: 600px;
  position: relative;
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 0px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  background-image: url("images/renewal20221005/city2.jpg");
  filter: brightness(1);
  p {
    text-align: center;
    padding-top: 40px;
    span {
      color: white;
      font-size: 30px;
      padding: 20px 0px;
      border-bottom: 0px solid #ffffff;
      @media only screen and (max-width: 768px) {        
        font-size: 22px;
      }
    }
  }
`;

const UserWrap2 = styled.div`
  width: 100%;
  height: 600px;
  @media only screen and (max-width: 768px) {        
    height: 500px;
  }
  position: relative;
  display: flex;
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 0px;
  background-color: rgb(145, 183, 186);
  background-repeat: no-repeat;
  background-size: 1200px;
  @media only screen and (max-width: 768px) {        
    background-size: 550px;
  }
  background-position: right bottom;
  background-image: url("images/renewal20221005/interaction.png");
  .image-left,
  .image-right {
    width: 50%;
    height: 100%;
  }
  .image-left {
    @media only screen and (max-width: 768px) {        
      width: 100%;
    }
  }
  .image-right {
    @media only screen and (max-width: 768px) {        
      width: 0%;
    }
  }
  .text-wrapper {
    margin-top: 120px;
    margin-left: 170px;
    @media only screen and (max-width: 768px) {              
      margin-top: 40px;
      margin-left: 0px;
      padding: 20px;
    }
    .main-text {
      font-size: 45px;
      @media only screen and (max-width: 768px) {
        font-size: 28px;
      }
      text-align: left;
    }
    .sub-text {
      padding-top: 20px;
      font-size: 27px;
      @media only screen and (max-width: 768px) {
        font-size: 20px;
      }
      font-weight: 100;
    }
  }
`;

const UserTextCity1 = styled.div`
  position: absolute;
  top: 45%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  font-family: "cursive";
  text-align: left;

  @media only screen and (max-width: 768px) {   
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: unset;
    background-color: rgba(0,0,0,0.3);
  }

  .city1-contents { 
    @media only screen and (max-width: 768px) {
      text-align: left;      
      padding: 20px; 
    }
  }

`;

const UserText = styled.div`
  position: absolute;
  top: 40%;
  left: 105%;  
  width: 100%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  font-family: "cursive";
  text-align: left;
  @media only screen and (max-width: 768px) {    
    position: static;
    transform: unset;
    padding-top: 40px;
  }
`;
const Btn1 = styled.button`
  padding: 10px 30px;
  font-size:40;
  width:180px;  
  _background: rgb(245, 12, 110);
  background: rgba(255,255,255,0.12);
  color:white;  
  cursor: pointer;
  @media only screen and (max-width: 768px) {
    padding: 10px 30px;
    width: auto;
    background: rgba(255,255,255,0.12);
    color: #ffffff;
    font-weight: 700;
  }
  solid:white;
  margin-right: 20px;
  border: 1px solid #ffffff;
  _box-shadow: inset -3px -3px #a9a9a9;
  
  &:hover {
    background: rgba(0,0,0,0);
  }

`;
const Earth = styled.img`
  border: 0px solid white;
`;
const UserText1 = styled.div`
  position: absolute;
  top: 40%;
  left: 48%;
  width: 100%;
  padding-left: 40px;
  transform: translate(-50%, -50%);
  font-size: 20px;
  font-family: "ypseo";
  text-align: right;
  border=0
`;

const Video = styled.div`
  margin-bottom: "0px";
  margin-top: "0px";
  border= "0";
  font-size: "0";
  margin:0px auto;
  vertical-align:bottom;
  display: flex;
  padding: 0;
  width: 100vw;
  height: 100vh;
  @media only screen and (max-width: 768px) {
    height: auto;
  }
  overflow: hidden;
  video {
    margin-top: 0px;
    z-index: -1;
    width: 100vw;
    position: absolute;
    @media only screen and (max-width: 768px) {
      position: unset;
      width: 100vw;
      height: auto;
    }
    
  }
  
`;

const Home = () => {
  return (
    <MainDiv>
        {
          process.env.REACT_APP_MODE === 'development.local' && false
          ?
            <GetItOn />
          :
            <div>
              <UserWrapCity1>
                <div className="city1-wrapper">
                  <img
                    className="city1"
                    src="images/renewal20221005/city1.jpg"
                    alt="profile"
                    width="100%"
                    height="auto"
                    border="0"
                  ></img>
                </div>
                <UserTextCity1 className="UserTextCity1">
                  <Container>
                    <div className="section-two">
                      <div className="title-and-button">
                        <font
                          className="event-page-wrapper"
                          color="white"
                          size="30"
                          padding-left="40px"
                        >
                          <p className="city1-contents">
                            <img
                              className="city1-metarex-logo"
                              src="/images/logo/metaREX_logo.svg"
                              alt="Metarex"
                              title="Metarex"
                            />
                            <p className="city1-metarex-second">
                              <span className="second-earth">{t("second_earth_second_dollar")}</span>
                              <span className="second-dollar"></span>
                            </p>
                            <p className="city1-metarex-second-description">
                            {t("aster_is_the_second_dollar_used_on_the_second_earth_aster_is_aiming_at_metaverse_coin_constructing_a_whole_new_ecosystem_as_a_base_currency_in_metaverse")}
                            </p>
                          </p>
                        </font>
                        <Link to="/map">
                          <Btn1>{t("buy")}</Btn1>
                        </Link>
                        <Link to="/how-to-use">
                          <Btn1>{t("user_page")}</Btn1>
                        </Link>
                      </div>
                    </div>
                  </Container>
                </UserTextCity1>
              </UserWrapCity1>

              <Video>
                <video
                  src="videos/renewal20221005/20221121_1502_metarex_main_video.mp4"
                  position="absolute"
                  controls
                  autoPlay
                  loop
                  muted
                  margin-bottom="0"
                  margin-top="0"
                  border="0"
                  padding="0"
                  display="block"
                  margin="0"
                ></video>
              </Video>

              <UserWrap className="xr-description">
                <UserText>
                  <Container>
                    <p className="city1-wrapper">
                      <font color="black" size="30" padding-left="40xp">
                        <span className="xr-virtual-based" dangerouslySetInnerHTML={
                          {__html: t('based_on_xr_virtual_convergence_technology', {interpolation: {escapeValue: false}})}
                        } />
                        <font size="5">
                          <div className="city1-description">
                            {t("on_the_planet_aster_a_digital_earth_a_new_human_race")}
                            <br />
                            {t("lets_be_born_as_digital_humans_and_feel_the_happiness_of_everyday_life")}
                          </div>
                        </font>
                      </font>
                    </p>
                  </Container>
                </UserText>
              </UserWrap>
              <UserWrap1>
                <p>
                  <span dangerouslySetInnerHTML={
                      {__html: t('the_world_of_virtual_tourism_and_commerce_leaving_the_world_in_the_interactive_digital_world', {interpolation: {escapeValue: false}})}
                  } />
                </p>
              </UserWrap1>
              <UserWrap2>
                <div className="image-left">
                  <div className="text-wrapper">
                    <p className="main-text">{t("meta_rex_with_meta_live")}</p>
                    <p className="sub-text">
                      {t("lets_express_ourselves_and_communicate_with_multiple_personas")}
                    </p>
                  </div>
                </div>
                <div className="image-right"></div>
              </UserWrap2>
            <div>        
              <GetItOn />
            </div>
          </div>
        }
    </MainDiv>
  );
};

export default Home;
