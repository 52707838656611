import React from "react";
import styled from "styled-components";

const MypageModalTemplate = styled.div`
    width: 320px;
    height: auto;
    background: #fff;
    padding: 20px 18px;
    position: relative;
    >p{
        font-family:'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
        font-size:14px;
        font-style:normal;
        font-weight:700;
        line-height:14px;
        letter-spacing:0em;
        text-align:left;
        color:#05141F;
        text-decoration:underline;
        text-underline-position:under;
        margin-bottom:30px;
    }
`;

const CloseBtnSpan = styled.span`
    position:absolute;
    width:16px;
    height:16px;
    top:20px;
    right:18px;
    cursor:pointer;
    display:flex;
    align-items:center;
    justify-content:center;
    &::before{
        position:absolute;
        content:"";
        width:20px;
        height:1px;
        background:#000;
        transform:rotate(45deg);
        transition:.3s;
    }
    &::after{
        position:absolute;
        content:"";
        width:20px;
        height:1px;
        background:#000;
        transform:rotate(-45deg);
        transition:.3s;
    }
    &:hover{
        &::before{
            transform: rotate(0deg);
            transition: .3s;
        }
        &::after{
            transform: rotate(0deg);
            transition: .3s;
        }   
    }
`;

// 마이페이지에서 사용되는 modal 창
const ModalMypage = ({title, closeModal, children}) => {
    return (
        <MypageModalTemplate>
            <CloseBtnSpan onClick={closeModal}/>
            <p>{ title }</p>
            {children}
        </MypageModalTemplate>
    );
}

export default ModalMypage;