import { t } from "i18next";
import React from "react";
import styled from "styled-components";

const StyledVisionTemplate = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100%;
    background-color: #fff;
    padding-top: 180px;
    .metarex {
        margin-bottom: 200px;
        h2 {
            font-family: 'Roboto';
            font-size: 80px;
            font-style: normal;
            font-weight: 900;
            line-height: 94px;
            letter-spacing: 0.03em;
            margin-bottom: 80px;
            text-align: center;
        }
        p {
            font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
            font-size: 20px;
            font-weight: 400;
            line-height: 30px;
            text-align: center;
        }
    }
    .vision {
        h2 {
            color: #FF645A;
            font-family: 'Roboto';
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 21px;
            letter-spacing: 0.3em;
            text-align: center;
            margin-bottom: 30px;
        }
        p {
            font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
            font-size: 24px;
            font-weight: 800;
            line-height: 27px;
            text-align: center;
        }
        .icons {
            margin-top: 121px;
            margin-bottom: 200px;
            display: flex;
            text-align: center;
            .icon {
                margin-right: 76px;
                &:last-of-type {
                    margin-right: 0px;
                }
            }
        }
    }

    @media only screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100vw;
        height: 100%;
        background-color: #fff;
        padding: 100px 20px;
        .metarex {
            margin-bottom: 200px;
            h2 {
                font-size: 50px;
                line-height: 50px;
            }
        }
        .vision {
            width: 100%;
            .icons {
                width: 100%;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                margin-top: 120px;
                margin-bottom: 0px;
                .icon {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    margin-right: 0px;
                    margin-bottom: 60px;
                    &:last-of-type{
                        margin-bottom: 0px;
                    }
                }
            }
        }
    }
`;


const StylediconDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 158px;
    height: 158px;
    background-color: #F8F8F8;
    border-radius: 50%;
    margin-bottom: 20px;
    img {
        width: 80px;
    }
    span {
        font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: center;
    }
`;

const Vision = () => {
    return (
        <StyledVisionTemplate>
            <div className="metarex">
                <h2>METAREX</h2>
                <p>
                {t("metarex_about_vision_p_1")}<br/>
                {t("metarex_about_vision_p_2")}<br/><br/> 
                {t("metarex_about_vision_p_3")}<br/>
                {t("metarex_about_vision_p_4")}
                </p>
            </div>

            <div className="vision">
                <h2>VISION</h2>
                <p>{t("metarex_about_vision_p_5")}</p>
                <div className="icons">
                    {/* 1. 소유 */}
                    <div className="icon">
                        <StylediconDiv>
                            <img src={`${process.env.PUBLIC_URL}/images/metarex/possession.svg`} alt="possession"/>
                        </StylediconDiv>
                        <span>{t("metarex_about_vision_span_1")}</span>
                    </div>

                    {/* 2. 자산 유동화 */}
                    <div className="icon">
                        <StylediconDiv>
                            <img src={`${process.env.PUBLIC_URL}/images/metarex/asset_securitization.svg`} alt="asset_securitization"/>
                        </StylediconDiv>
                        <span>{t("metarex_about_vision_span_2")}</span>
                    </div>

                    {/* 3. 비즈니스 기회 */}
                    <div className="icon">
                        <StylediconDiv>
                            <img src={`${process.env.PUBLIC_URL}/images/metarex/business_chance.svg`} alt="business_chance"/>
                        </StylediconDiv>
                        <span>{t("metarex_about_vision_span_3")}</span>
                    </div>
                </div>
            </div>
        </StyledVisionTemplate>
    )
}

export default Vision;