import React from "react";
import styled from "styled-components";
import '../../components/animation.css';
import MetarexTemplate from "../../feature/MetaRex/MetarexTemplate";
import PageBanner from "../../components/PageBanner";
import PageTab from "../../components/PageTab";

const StyledChartDiv = styled.div`
    width: 100%;
    height: auto;
    max-width: 1100px;
    margin-top: 180px;
    margin-bottom: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .top {
        animation: slideUp 1s 0s;
        animation-fill-mode: both;
        width: 200px;
        height: 200px;
        background: rgba(61, 133, 240, .6);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 120px;
        &:before {
            content: 'CEO';
            display: inline-block;
            width: 180px;
            height: 180px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            background: #3D85F0;
            font-family: 'Roboto';
            color: #fff;
            font-size: 22px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px;
        }
    }

    .middle {
        animation: slideUp 1s 1s;
        animation-fill-mode: both;
        width: 100%;
        height: auto;
        display: flex;
        justify-content: space-between;
        margin-bottom: 150px;
        position: relative;
        .box-middle {
            width: 450px;
            height: 63px;
            border: 2px solid #05141F;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: 'Roboto';
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
        }

        .line {
            position: absolute;
            top: 50%;
            left: calc(50% - 100px);
            height: 1px;
            width: 200px;
            background: #ccc;
            z-index:-1;
        }
    }

    .row {
        animation: slideUp 1s 2s;
        animation-fill-mode: both;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3, 352px);
        grid-template-rows: repeat(2, 63px);
        grid-gap: 21px;
        position: relative;
        .box-row {
            display: flex;
            justify-content: center;
            align-items: center;
            background: #EEE;
            color: #05141F;
            font-family: 'Roboto';
            font-size: 19px;
            font-style: normal;
            font-weight: 400;
            line-height: 19px;
            text-align: center;
        }
        .line-wrap {
            width: 100%;
            position: absolute;
            top: -70px;
            left: 0;
            .line {
                position: absolute;
                width: 1px;
                height: 75px;
                background: #ccc;
                &:nth-of-type(1) {
                    left: 176px;
                    &:before {
                        content: '';
                        position: absolute;
                        display: inline-block;
                        box-sizing: border-box;
                        width: 10px;
                        height: 10px;
                        border: 3px solid #ccc;
                        border-radius: 50%;
                        background: #fff;
                        top: calc(100% - 10px);
                        left: calc(0% - 5px);
                    }
                }
                &:nth-of-type(2) {
                    left: 50%;
                    top: -263px;
                    height: 333px;
                    &:before {
                        content: '';
                        position: absolute;
                        display: inline-block;
                        box-sizing: border-box;
                        width: 10px;
                        height: 10px;
                        border: 3px solid #ccc;
                        border-radius: 50%;
                        background: #fff;
                        top: calc(100% - 5px);
                        left: calc(0% - 4.5px);
                    }
                }
                &:nth-of-type(3) {
                    left: calc(100% - 176px);
                    &:before {
                        content: '';
                        position: absolute;
                        display: inline-block;
                        box-sizing: border-box;
                        width: 10px;
                        height: 10px;
                        border: 3px solid #ccc;
                        border-radius: 50%;
                        background: #fff;
                        top: calc(100% - 10px);
                        left: calc(0% - 5px);
                    }
                }
                &:nth-of-type(4) {
                    width: 747px;
                    height: 1px;
                    left: 176px;
                }
            }
        }
    }

    @media only screen and (max-width: 768px) {
        padding: 0px 20px;
        margin-bottom: 100px;
        .top {
            margin-bottom: 60px;
        }

        .middle {
            flex-wrap: wrap;
            margin-bottom: 50px;
            z-index: 2;
            .box-middle {
                width: 100%;
                background: #fff;
                margin-bottom: 10px;
            }
        }

        .row {
            grid-template-columns: 100%;
            grid-template-rows: repeat(6, 63px);
            grid-gap: 10px;
            .line-wrap {
                width: 100%;
                position: absolute;
                top: -70px;
                left: 0;
                .line {
                    position: absolute;
                    width: 1px;
                    height: 75px;
                    background: #ccc;
                    &:nth-of-type(1) {
                        display: none;
                    }
                    &:nth-of-type(2) {
                        top: -186px;
                        height: 257px;
                    }
                    &:nth-of-type(3) {
                        display: none;
                    }
                    &:nth-of-type(4) {
                        display: none;
                    }
                }
            }
        }
    }
`

const OrganizationChart = () => {
    return (
        <>
            <MetarexTemplate>
                <PageBanner style={{backgroundImage: "url('/images/bg/metarex_bg.png')"}}>The world’s first Virtual Real Estate Platform<br/>linked to the Real Economy</PageBanner>
                <PageTab category="metarex" type="chart" />

                <StyledChartDiv>
                    <div className="top"/>
                    <div className="middle">
                        <div className="line"/>
                        <div className="box-middle">Reaserch Institute</div>
                        <div className="box-middle">Advisory group</div>
                    </div>
                    <div className="row">
                        <div className="line-wrap">
                            <div className="line"/>
                            <div className="line"/>
                            <div className="line"/>
                            <div className="line"/>
                        </div>
                        <div className="box-row">Strategy biz team</div>
                        <div className="box-row">Development team</div>
                        <div className="box-row">Platform biz team</div>
                        <div className="box-row">Marketing team</div>
                        <div className="box-row">Management Support team</div>
                        <div className="box-row">Partnership cooperation</div>
                    </div>
                </StyledChartDiv>
            </MetarexTemplate>
        </>
    );
}

export default OrganizationChart;