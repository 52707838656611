import React , { useEffect, useState } from 'react';
import styled from "styled-components";
import { useLocation } from 'react-router-dom';
import { t } from 'i18next';
import OwnerConfirmPurchaseRequisitionItem from '../../feature/SelfPurchase/OwnerConfirmPurchaseRequisitionItem'
import queryString from "query-string";
import { isSessionValidate, isValidationSessionId } from '../../apis/BrowserStorageAPI';
import { moveTopOnPageAuto } from '../../apis/CommonFunc';
import { axiosGet } from '../../apis/AxiosAPI';

const MainDiv = styled.div`
    .selfMainContainer{
        width: 100%; 
        height: 100%; 
        margin: 0 auto; 
        top: 130px;
        padding-bottom: 200px;
        position: relative;

        @media only screen and (max-width: 768px) {
            width: 100%; 
            height: auto; 
            padding-top: 100px;
            padding-bottom: 100px;
            top: 0;
            left: 0;
            background: #fff;
        }

        .notes-wrapper {
            padding: 10px 20px;

            .do-not-share-this-link{
                width: 100%;
                margin: 0 auto;
                text-align: center;
                font-size: 16px;
                display: block;
                color: #FF645A;
            }
            .choose-one {
                width: 100%;
                margin: 0 auto;
                text-align: center;
                font-size: 16px;
                display: block;
                color: #333333;
                margin-top: 10px;
            }
        }
    }
`;

const OwnerConfirmPurchaseRequisition = (props) => {

    const [selfAxios , setSelfAxios] = useState(undefined);

    const location = useLocation();

    useEffect(() => {
        if(!isSessionValidate()) {
            alert(t('market_view_login'));
            location.href ='/signin';
            return;
        }

        if(!isValidationSessionId()) {
            alert(t('metarex_alert_expired_session'));
            location.href = '/signin';
            return;
        }
        
        moveTopOnPageAuto();
    }, []);

    useEffect(() => {
        let params = location.search;
        let selfUrl = (params) => `api/realestate/owner/whether-to-allow-purchase-yes-or-no${params}`;
        let res = axiosGet(selfUrl(params))
        .then(res =>{   
            if(res.data.result === "false"){
                alert(res.data.message);
            }else {
                console.log(res.data.data);
                setSelfAxios(res.data.data ? res.data.data : null)
            }
        })
        .catch(e => {
            console.log(`error message: ${e}`);
        });
        window.history.pushState('',null, location.href);
    }, []);


    let selfAxiosData = [];
    selfAxiosData.push(selfAxios);


    return(
        <MainDiv>
            <section className="selfMainContainer">
                <div className='notes-wrapper'>
                    <h1 className="do-not-share-this-link">이 링크를 절대 다른사람과 공유하지 마세요!</h1>
                    <h1 className="do-not-share-this-link">이 링크만 있으면 '거절' 또는 '승인'이 가능합니다.</h1>
                    <h1 className="choose-one">다른 사용자가 고객님의 부동산 구매를 희망합니다. 제시된 금액은 아래를 참고해주세요.</h1>
                    <h1 className="choose-one">판매 여부를 '거절' 또는 '승인'으로 선택해주세요.</h1>
                    {  selfAxios && selfAxios.length === 0 && 
                        <h1 className="historyDataNoneTitle">{t("buy_history_date_null")}</h1>
                    }
                </div>
                {selfAxiosData.map((data, i) => 
                (
                    <OwnerConfirmPurchaseRequisitionItem 
                        key                 = {i} 
                        sbrData             = {data}
                        queryString         = {queryString.parse(location.search)}
                    />
                ))}
            </section>
        </MainDiv>
    )
}

export default OwnerConfirmPurchaseRequisition;
