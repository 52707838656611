import React, { useEffect, useState } from 'react';
import { t } from 'i18next';

import { axiosGet, axiosPost } from '../../apis/AxiosAPI';
import { addComma } from '../../apis/CommonFunc';
import { isValidAstercoinAddr } from '../../apis/Validation';

import './SalesModal.css'

const SalesModal = (props) => {
    const saleModalId = props.unId
    const saleModalData = props.salesModalDatas
    const modalToggle = props.modal
    // 한빗코 선택시 alert뛰움
    const [ hanbitcoAlert, setHanbitcoAlert ] = useState()
    // 희망가가 1보다 작을시 얼럿 뛰움
    const [ salesPriceModalData , setSalesPriceModalData ] = useState()
    // 지갑주소 틀릴경우
    const [ salesWalletModalData , setSalesWalletModalData ] = useState()

    const hanbitcoSelcetData = (e) =>{
        setHanbitcoAlert(e.target.value)
    }

    // 가격
    const salesModalPrice = (e) => {
        Number(setSalesPriceModalData(e.target.value)) 
    }
    
    const salesModalWallet = (e) => {
        setSalesWalletModalData(e.target.value)
    }

    const modalClose = () => {
        props.setModal(true)    
    }

    const requestBuyItem = (saleModalData) => {
        if( false ) {
            if(!(hanbitcoAlert === "1" || hanbitcoAlert === "2" || hanbitcoAlert === "3")){
                alert(t("sales_registration_modal_confirm13"))
            }else if(salesPriceModalData <= addComma(1)){
                alert(t("sales_registration_modal_confirm14"))
            }
            if(isValidAstercoinAddr( salesWalletModalData ) === false){
                alert(t("sales_registration_modal_confirm15")); 
            }else if(isValidAstercoinAddr( salesWalletModalData ) === true &&  typeof salesPriceModalData === 'undefined'){
                alert(t("sales_registration_modal_confirm16"))
            }else if(salesPriceModalData > addComma(1) && isValidAstercoinAddr( salesWalletModalData ) === true && hanbitcoAlert){
                
            }
        }

        
        if(window.confirm(
                t("sales_registration_modal_confirm1") + `\n` +
                t("sales_registration_modal_confirm2") + `\n` +
                t("sales_registration_modal_confirm3") + `: ${addComma(salesPriceModalData)}`+ t("map_land_price_area_won") +`\n`
            )){
                const requestItemParams = {
                    askPrice:  salesPriceModalData,
                    realestateNo: JSON.stringify(saleModalData.realestateNo)
                };        

                let salesModalUrl = `realestate/trade/sell`
                axiosPost(salesModalUrl , requestItemParams)
                .then(response => {
                    const data = response.data;
                    alert(data.message);
                    if(JSON.parse(data.result)) {
                        window.location.href = '/SalesRegistRation';
                    } else {
                    }
                }).catch(e => {
                    console.log(`error message: ${e}`);                
                });
            } else {
                alert("취소했습니다")
            }
            props.setModal(true)    
        }
    const saleModalLang = localStorage.getItem("locale");
    
    // 거래소 받아오는 axios 요청
    const [exchangeName, setExchangeName] = useState()
    useEffect(()=>{
        axiosGet('/api/sales/exchange-list')
        .then(res=>setExchangeName(res.data))
        .catch(e => {
            console.log(`error message: ${e}`);                
        });
    },[modalToggle])
    
    // 언어에 따라 경고창 표시를 변경하는 로직
    useEffect(()=>{
        if(saleModalLang === 'ko' && hanbitcoAlert === '1'){
            alert(exchangeName.data[0].selectionAlertKr)
        }else if(saleModalLang === 'en' && hanbitcoAlert === '1'){
            alert(exchangeName.data[0].selectionAlertEn)
        }else if(saleModalLang === 'cn' && hanbitcoAlert === '1'){
            alert(exchangeName.data[0].selectionAlertCn)
        }
    },[hanbitcoAlert , saleModalLang])
    
    return (
        <>
            {
                // 고유의 값 비교. realestateNo 이거를 id값으로 줄거다 그때 바꿔주면 됨 
                saleModalData.realestateNo === saleModalId[0].realestateNo && 
                    <div className="saleModalContainer">
                        <button className="salesModalCloseBtn" onClick={modalClose}>X</button>
                        <ul className="saleModalInfoBox">
                            <li>
                                <span>{t("sales_registration_modal_title")}</span>
                            </li>
                            <li>
                                {
                                    saleModalLang === "ko" ?
                                        saleModalData.address
                                        :
                                        saleModalData.addressJibunEn
                                }
                            </li>
                            <li>
                                {
                                    saleModalData.areaSter ?  
                                        <>
                                            <span>{t("sales_registration_area")}</span>
                                            <span> {saleModalData.areaSter} ster</span>
                                        </>
                                        :
                                        <span className="salesModalAreaNone">{t("sales_registration_area")}</span>
                                }
                            </li>
                            <li>
                                <span>{t("sales_registration_purchase_price")} </span>
                                <span> {addComma(saleModalData.priceMetarex)}</span>
                            </li>
                        </ul>   
                        <ul className="saleModalMarket">                              
                            {false && <li>
                                <span className="saleModalInputTitle">{t("sales_registration_modal_exchange")}</span>
                                <select name="exchange" onChange={hanbitcoSelcetData} id="selectBox" defaultValue={'DEFAULT'}>
                                    <option value="DEFAULT" disabled="disabled">{t("sales_registration_modal_exchange_select")}</option>
                                    <option value="1">
                                        {/* 언어에 따라 받아오는 데이터의 종류를 변경 */}
                                        {
                                            saleModalLang === 'ko' ? exchangeName.data[0].exchangeNameKr : exchangeName.data[0].exchangeNameEn
                                        }
                                    </option>
                                    <option value="2">
                                        {
                                            saleModalLang === 'ko' ? exchangeName.data[1].exchangeNameKr : exchangeName.data[1].exchangeNameEn
                                        }
                                    </option>
                                    <option value="3">
                                        {
                                            saleModalLang === 'ko' ? exchangeName.data[2].exchangeNameKr : exchangeName.data[2].exchangeNameEn
                                        }
                                    </option>
                                </select>
                            </li>
                            }
                            {false && <li>
                                <span className="saleModalInputTitle">{t("sales_registration_modal_wallet_address")}</span>
                                <input placeholder={t("sales_registration_modal_confirm10")} onChange={salesModalWallet}></input>
                            </li>
                            }
                            <li>
                                <span className="saleModalInputTitle">{t("sales_registration_modal_hope_price")}</span>
                                <input type ="number" placeholder={t("sales_registration_modal_confirm12")} onChange={salesModalPrice} value={salesPriceModalData ? salesPriceModalData : ""}>
                                </input>
                            </li>
                            <li>
                                <button onClick={ () => requestBuyItem(saleModalData)} className="saleModalInfoBtn">{t("sales_registration_modal_completion_btn")}</button>
                            </li>
                        </ul>
                    </div>
            }
        </>
    )
}

export default SalesModal;
