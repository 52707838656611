import React, {useState, useEffect} from "react";

import styled from "styled-components";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const StyledButton = styled.button`
    display: inline-block;
    width: 50px;
    height: 50px;
    border: none;
    border-radius: 50%;
    box-sizing: border-box;
    background-color: transparent;
    margin-left: 10px;

`;

// 슬라이드 좌우 버튼 컴포넌트
const SlideBtn = ({direction, pageNum, setPageNum, totalCount, perPage}) => {
    const [totalPage, setTotalPage] = useState(0);

    const [style, setStyle] = useState({
        color: '#05141F',
        border: '1px solid #05141F',
        cursor: 'pointer'
    })

    const [fontColor, setFontColor] = useState({
        color: "#05141F"
    });

    const setFirstLastStyle = () => {
        setStyle({
            color: '#D9DDE2',
            border: '1px solid #D9DDE2',
            cursor: 'auto'
        });
        setFontColor({
            color: "#D9DDE2"
        });
    }

    const setResetStyle = () => {
        setStyle({
            color: '#05141F',
            border: '1px solid #05141F',
            cursor: 'pointer'
        });
        setFontColor({
            color: "#05141F"
        });
    }

    useEffect(() => {
        setTotalPage(Math.ceil(totalCount / perPage));

        if(pageNum === 1 && direction === 'prev'){
            setFirstLastStyle();
            return;
        } else {
            setResetStyle();
        }
        
        if(totalPage <= pageNum && direction === 'next'){
            setFirstLastStyle();
            return;
        } else {
            setResetStyle();
        }
    }, [totalCount, totalPage, pageNum]);


    const slideEventHandle = (e) => {
        // 첫 번째 페이지 체크
        if(pageNum <= 1 && direction === 'prev') {
            return false;
        }

        // 마지막 페이지 체크
        if(totalPage <= pageNum && direction === 'next') {
            return false;
        }

        // 첫 번째 페이지 + 다음 페이지로 이동
        if(direction === 'next') {
            setPageNum(pageNum+1);
            return false;
        }

        // 마지막 페이지 + 이전 페이지로 이동
        if(direction === 'prev') {
            setPageNum(pageNum-1);
            return false;
        }   
        
        console.log('그 외의 버튼 동작');
    }

    return (
        <StyledButton onClick={slideEventHandle} style={style}>
            <FontAwesomeIcon icon={direction === "prev" ? faChevronLeft : faChevronRight} style={fontColor}/>
        </StyledButton>
    )
}

export default SlideBtn;