import React from "react";
import Popup from "../Popup";
import PopupPurchaseRequisitionContent from "./PopupPurchaseRequisitionContent";


const PopupPurchaseRequisition = ({
    setIsShow, 
    popupTitle, 
    popupData}) => {
        
    return (
        <Popup
            setIsShow={setIsShow}
            popupTitle={popupTitle}
            children={
                <PopupPurchaseRequisitionContent 
                popupData={popupData}
                />
            }
        />
    )
}

export default PopupPurchaseRequisition;