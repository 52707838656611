import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { t } from "i18next";

import { isNull } from "../apis/Validation";
import { removeLocalStorage, removeServerSessionId } from "../apis/BrowserStorageAPI";
import { moveTopOnPageAuto } from "../apis/CommonFunc";
import { 로그인_요청하기 } from "../apis/AuthAPI";

import AuthTemplate from "../components/Auth/AuthTemplate";
import AuthFieldSet from "../components/Auth/AuthFieldSet";
import AuthInput from "../components/Auth/AuthInput";
import AuthCapsLock from "../components/Auth/AuthCapsLock";
import AuthButton from "../components/Auth/AuthButton";
import AuthNav from "../components/Auth/AuthNav";

const SignIn = () => {
    const [userId, setUserId] = useState("");
    const [userPassword, setUserPassword] = useState("");
    const [isCapsLock, setIsCapsLock] = useState(false);
    const dispatch = useDispatch();
    
    useEffect(() => {
        moveTopOnPageAuto();
        removeLocalStorage();
        removeServerSessionId(dispatch);
    }, []);

    const isValidation = () => {
        if(isNull(userId) || userId === "") {
            alert(t("sign_in_alert_id"));
            return false;
        }
        if(isNull(userPassword) || userPassword === "") {
            alert(t("sign_in_alert_pw"));
            return false;
        }
        return true;
    }

    const onClickSigninButton = () => {
        if(!isValidation()) { return; } // ID, PW 체크
        로그인_요청하기({userId, userPassword}, dispatch);
    }

    const onKeyUpEvent = (e) => {
        // enter event
        if(window.event.code === 'Enter'){
            onClickSigninButton();
            return;
        }

        setIsCapsLock(e.getModifierState("CapsLock"));                     // capsLock check
        e.target.value = e.target.value.replace(/[ㄱ-ㅎㅏ-ㅣ가-힣]/g, '');  // 한글 입력 불가
    }

    return (
        <AuthTemplate type="sign_in">
            <AuthFieldSet title={t("sign_in_login")}>
                    <AuthInput type="text"
                        onChange={(e) => setUserId(e.target.value)}
                        onKeyUp={onKeyUpEvent}
                        placeholder={t("sign_in_placeholder_id")}
                        required={true}
                    />
                    <AuthInput
                        type="password"
                        onChange={(e) => setUserPassword(e.target.value)}
                        onKeyUp={onKeyUpEvent}
                        placeholder={t("sign_in_placeholder_pw")}
                        title={t("sign_in_title_pw")}
                        required={true}
                    />
                    <AuthCapsLock isCapsLock={isCapsLock}/>
                    <AuthButton onClick={onClickSigninButton}>{t("sign_in_login_btn")}</AuthButton>
                    <AuthNav>
                        <Link to="/signup">{t("sign_in_sign_up")}</Link>
                        <Link to="/findid">{t("sign_in_forget_id")}</Link>
                        <Link to="/findpassword">{t("sign_in_fotget_pw")}</Link>
                    </AuthNav>
            </AuthFieldSet>
        </AuthTemplate>

    )
}

export default SignIn;