import React from "react";
import styled from "styled-components";
import ProfileCard from "../../feature/MetaRex/profile/ProfileCard";
import MetarexTemplate from "../../feature/MetaRex/MetarexTemplate"
import PageBanner from "../../components/PageBanner";
import PageTab from "../../components/PageTab";
import { t } from "i18next";

const StyledProfileDiv = styled.div`
    width: 100%;
    max-width: 1240px;
    display: flex;
    flex-wrap: wrap;
    margin: 150px 0px 200px 0px;
    justify-content: space-between;
    @media only screen and (max-width: 768px) {
        max-width: 100%;
        flex-wrap: nowrap;
        flex-direction:column;
        margin: 80px 0px;
        padding: 0px 20px;
    }
`;

const Profile = () => {
    const profiles = [
        {
            img_name: "profile_kangbongjoon.jpg?v=20211215_1227",
            position: "CEO",
            name_kr: t("bong_joon_kang"),
            name_en: "",
            history_cur: t("ceo_of_tsnet_co_ltd") + "<br>" + 
                t("cmo_of_buzz_online_co_ltd") + "<br>",
            history_cur_etc: 
            t("samsung_fire_insurance_new_system_promotion_team") + "<br>" + 
            t("meritz_financial_services_hybrid_channel") + "<br>" + 
            t("completion_of_doctoral_course_at_korea_university_graduate_school_of_technology_management"),
            history_prev: "",
            history_prev_etc: ""
        },
        {
            img_name: "profile_gojinseok.jpg",
            position: "CTO",
            name_kr: t("jin_suk_ko"),
            name_en: "",
            history_cur:
                t("cto_of_tsnet_co_ltd") + "<br>" + 
                t("cto_of_awesomepia_co_ltd") + "<br>" + 
                t("cto_of_iloveschool_co_ltd"),
            history_cur_etc: 
                t("seoul_national_university_computer_engineering_major"),
            history_prev: "",
            history_prev_etc: ""
        },
        {
            img_name: "profile_coo.jpg",
            position: "COO",
            name_kr:
                t("kyoung_ok_lee"),
            name_en: "",
            history_cur:
                t("ceo_of_buzz_online_co_ltd") + "<br>" + 
                t("former_chairman_of_korea_social_network_association") + "<br>" + 
                t("former_lecturer_at_hankyong_national_university"),
            history_cur_etc: 
                t("korea_university_graduate_school_of_technology_management_masters_degree"),
            history_prev: "",
            history_prev_etc: ""
        },
        {
            img_name: "profile_minmunho.png",
            position:
                t("advisor"),
            name_kr:
                t("mun_ho_min"),
            name_en: "",
            history_cur:
                t("ceo_of_awesomepia_co_ltd"),
            history_cur_etc: 
                t("sungkyunkwan_university_graduate_school_of_media_culture_convergence_adjunct_professor") + "<br>" + 
                t("executive_director_korea_artificial_intelligence_ethics_association") + "<br>" + 
                t("expert_member_of_the_seoul_business_agency") + "<br>" + 
                t("sejongro_governance_forum_metaverse_chairperson_sungkyunkwan_university_masters_degree_in_cultural_convergence"),
            history_prev:
                t("formerly_ceo_of_sk_ok_cashbag_culture_membership_mall_operator"),
            history_prev_etc: 
                t("former_it_kosdaq_listed_company_head_of_business_division_and_management_representative_of_subsidiaries") + "<br>" + 
                t("hanyang_university_graduate_school_of_business_adjunct_professor") + "<br>" + 
                t("author_of_metaverse_gold_rush"),
        },
        {
            img_name: "profile_yong.jpg",
            position:
                t("overseas_investment_advisor"),
            name_kr:
                t("yong_moon_kim"),
            name_en: "",
            history_cur:
                t("ceo_of_tembusu_partners_credit_swiss_asset_management_global_stock_management"),
            history_cur_etc: 
                t("head_of_equity_management_asia_pacific_aberdeen_asset_management") + "<br>" + 
                t("ubs_ib_finance_research_fellow") + "<br>" + 
                t("ceo_of_mirae_asset_hong_kong_singapore_asset_management") + "<br>",
            history_prev: "",
            history_prev_etc: ""
        }
    ]

    return (
    <>
        <MetarexTemplate>
            <PageBanner style={{backgroundImage: "url('/images/bg/metarex_bg.png')"}}>The world’s first Virtual Real Estate Platform<br/>linked to the Real Economy</PageBanner>
            <PageTab category="metarex" type="profile" />
            
            <StyledProfileDiv>
                {profiles.length !== 0 &&
                    profiles.map((el, index) => <ProfileCard key={index} profileData={el}/>)
                }
            </StyledProfileDiv>
        </MetarexTemplate>
    </>
    )
}

export default Profile;