import { t } from "i18next";
import React from "react";
import styled from "styled-components";

const StyledPartnersTemplate = styled.div`
    width: 100vw;
    height: 1100px;
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    h2 {
        color: #FF645A;
        font-family: 'Roboto';
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 19px;
        letter-spacing: 0.3em;
        text-align: center;
        margin-bottom: 30px;
    }
    p {
        font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 800;
        line-height: 30px;
        letter-spacing: 0em;
        text-align: center;
        margin-bottom: 130px;
    }
    @media only screen and (max-width: 768px) {
        height: auto;
        margin-bottom: 150px;
        padding: 20px;
    }
`

const StyledPictureDiv = styled.div`
    width: 600px;
    height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .metarex {
        position: absolute;
        width: 181px;
    }
    .partners {
        .tenspace {
            position: absolute;
            top: 40px;
            left: calc(50% - 125px);
            img {
                margin-bottom: 7.5px;
            }
            p {
                font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
                color: #909090;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 14px;
            text-align: center;
            }
        }

        .awesomepia {
            position: absolute;
            top: 135px;
            left: -65px;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            img {
                margin-bottom: 7.5px;
                width: 200px;
            }
            p {
                font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
                color: #909090;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 14px;
                text-align: center;
                padding-right: 30px;
            }
        } 

        .alliance_partners {
            position: absolute;
            top: 150px;
            left: 480px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            p {
                font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
                color: #909090;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 14px;
                text-align: center;
            }
            .title {
                width: 250px;
                margin-bottom: 15px;
                font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
                color: #000;
                font-size: 16px;
                font-style: normal;
                font-weight: 800;
                line-height: 14px;
                letter-spacing: 0em;
                text-align: left;
            }
        }

        .openppl {
            position: absolute;
            top: 430px;
            left: -50px;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            p {
                font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
                color: #909090;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 14px;
                text-align: center;
                margin: 0px;
                margin-bottom: 10px;
                margin-right: 85px;
            }
        } 

        .incutek {
            position: absolute;
            top: 430px;
            left: 475px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            img {
                width: 110px;
                height: 34px;
            }
            p {
                font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
                color: #909090;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 14px;
                text-align: center;
                margin: 0px;
                margin-bottom: 10px;
            }
        }

        .tsnet {
            position: absolute;
            top: 510px;
            left: calc(50% - 125px);
            display: flex;
            flex-direction: column;
            align-items: center;
            p {
                font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
                color: #909090;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 14px;
                text-align: center;
                margin: 0px;
                margin-bottom: 10px;
            }
        }
    }


    .point {
        position: absolute;
        top: 0;
        left: 0;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: #3D85F0;
        &:nth-of-type(1){
            top: calc(125px - 3px);
            left: calc(50% - 3px);
        }
        &:nth-of-type(2){
            top: calc(200px - 3px);
            left: calc(150px + 3px);
        }
        &:nth-of-type(3){
            top: calc(200px - 3px);
            left: calc(440px + 0px);
        }
        &:nth-of-type(4){
            top: calc(400px - 3px);
            left: calc(150px + 3px);
        }
        &:nth-of-type(5){
            top: calc(400px - 3px);
            left: calc(440px - 1px);
        }
        &:nth-of-type(6){
            top: calc(475px - 4px);
            left: calc(50% - 3px);
        }
    }
    .circle {
        width: 350px;
        height: 350px;
        border: 1.5px dashed #BBBBBB;
        border-radius: 50%;
    }

    @media only screen and (max-width: 768px) {
        width: 100%;
        height: auto;
        flex-direction: column;
        .point {
            &:nth-of-type(1){
                top: calc(0% - 3px);
                left: calc(50% - 3px);
            }
            &:nth-of-type(2){
                top: calc(25% - 3px);
                left: calc(4% + 3px);
            }
            &:nth-of-type(3){
                top: calc(25% - 3px);
                left: calc(93% - 1px);
            }
            &:nth-of-type(4){
                top: calc(72% - 3px);
                left: calc(4% - 2px);
            }
            &:nth-of-type(5){
                top: calc(72% - 3px);
                left: calc(93% + 3px);
            }
            &:nth-of-type(6){
                top: calc(100% - 3px);
                left: calc(50% - 3px);
            }
        }
        .circle {
            position: relative;
            width: 300px;
            height: 300px;
            border: 1.5px dashed #BBBBBB;
            border-radius: 50%;
        }
        .metarex {
            top: calc(150px - 14px);
        }
        .partners {
            display: grid;
            grid-template-columns: [col] 48.5% [col] 48.5%;
            grid-template-rows: [row] 100px [row] 100px [row] 100px;
            grid-gap: 10px;
            margin-top: 50px;
            padding: 10px;
            .tenspace,
            .awesomepia,
            .alliance_partners,
            .openppl,
            .incutek,
            .tsnet {
                position: static;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                img {
                    width: 100%;
                    margin: 0px;
                    margin-bottom: 10px;
                }
                p {
                    width: 100%;
                    margin-bottom: 0px;
                    text-align: center;  
                    padding: 0px;
                    margin: 0px;
                }
                &:nth-of-type(1) {
                    order: 4;
                }
                &:nth-of-type(2) {
                    order: 2;
                }
                &:nth-of-type(3) {
                    .title {
                    width: 100%;
                    text-align: center;
                    margin: 0px;
                    margin-bottom: 19px;
                    }
                    order: 6;
                }
                &:nth-of-type(4) {
                    order: 3;
                }
                &:nth-of-type(5) {
                    img { 
                        width: 110px; 
                    }
                    order: 5;
                }
                &:nth-of-type(6) {
                    order: 2;
                }
            }

            .openppl,
            .incutek,
            .tsnet {
                flex-direction: column-reverse;
            }
        }
    }
`;

const Partners = () => {
    return (
        <StyledPartnersTemplate>
            <h2>Partners</h2>
            <p>{t('metarex_about_partners_title')}</p>
            <StyledPictureDiv>
                <div className="circle">
                    <div className="point"/>
                    <div className="point"/>
                    <div className="point"/>
                    <div className="point"/>
                    <div className="point"/>
                    <div className="point"/>
                </div>
                <img src={`${process.env.PUBLIC_URL}/images/metarex/partners/metaREX.png`} alt="metaREX" className="metarex"/>

                <div className="partners">
                    <div className="tenspace">
                        <img src={`${process.env.PUBLIC_URL}/images/metarex/partners/tenspace.png`} alt="tenspace"/>
                        <p>{t('metarex_about_partners_tenspace')}</p>
                    </div>
                    <div className="awesomepia">
                        <img src={`${process.env.PUBLIC_URL}/images/metarex/partners/awesomepia.png`} alt="awesomepia"/>
                        <p>{t('metarex_about_partners_awesomepia')}</p>
                    </div>
                    <div className="alliance_partners">
                        <p className="title">Alliance Partners</p>
                        <p>{t('metarex_about_partners_alliance')}</p>
                    </div>
                    <div className="openppl">
                        <p>{t('metarex_about_partners_openppl')}</p>
                        <img src={`${process.env.PUBLIC_URL}/images/metarex/partners/openppl.png`} alt="openppl"/>
                    </div>
                    <div className="incutek">
                        <p>{t('metarex_about_partners_incutek')}</p>
                        <img src={`${process.env.PUBLIC_URL}/images/metarex/partners/incutek.png`} alt="incutek"/>
                    </div>
                    <div className="tsnet">
                        <p>{t('metarex_about_partners_tsnet')}</p>
                        <img src={`${process.env.PUBLIC_URL}/images/metarex/partners/tsnet.png`} alt="tsnet"/>
                    </div>
                </div>
            </StyledPictureDiv>
        </StyledPartnersTemplate>
    );
}

export default Partners;