import React, { useEffect } from 'react';
import KakaoMapScript from './KakaoMapScript';
import './kakaoMap.css';

/**
 *  카카오 맵 안에 데이터들을 넣어주는 통로
 */
const KakaoMap = (props) => {
    // 여기다 뉴스나 이벤트 매물등 값을 넣어놓은후. kakaoMapScript 안에 인자값으로 state값 test 전달. kakaomapscript안에 있는 customOverlay 안에 값을 넣어준후. content안에 받은 값을 넣어놓기만 하면 해결된다.
    // const [test, setTest ] = useState()
    // useEffect(() => {
    //     (async () => { 
    //         let res = await CustomOverlayAxios()
    //         .then(response => {
    //             setTest(response);
    //         }).catch(e => {
    //             console.log(`error message: ${e}`);
    //         });
    //     })();
    // }, [ CustomOverlayAxios,setTest]);

    /* 카카오맵 api 객체는 useEffect의 속성들이 변경되면 다시 생성됨.  */
    useEffect(() => {

        
        const kakaoMap = KakaoMapScript(props.keyword, props.setSearchType, props.setAddress, props.isMobile, props.clickedSoldoutItem, props.setKeywordData, props.keywordData, props.setIsPopupPurchaseRequisitionShow);
        

        // 카카오맵 api 객체 생성 후 상위에 전달
        props.setKakaoMap(kakaoMap);
    }, [props.keyword, props.setSearchType, props.setAddress, props.setKakaoMap, props.isMobile]);

    return (
        <>
            <div id="myMap" className={'myMaps'}></div>
        </>
    );
};

export default KakaoMap;
