import React from "react";
import styled from "styled-components";

const DrawerMenuDiv = styled.div`
    width: 25px;
    height: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    span {
        width: 25px;
        height: 2px;
        background: transparent;
        transition: .2s;
        &::before {
            content: "";
            transition: .2s;
            position: absolute;
            top: 0;
            height: 2px;
            width: 25px;
            background: #fff;
        }

        &::after {
            content: "";
            position: absolute;
            height: 2px;
            width: 25px;
            transition: .2s;
            bottom: 0;
            background: #fff;
        }
    }

    &.clicked {
        span {
            &::before {
                transition: .2s;
                top: calc(50% - 1px);
                transform: rotate(45deg);
            }

            &::after {
                transition: .2s;
                bottom: calc(50% - 1px);
                transform: rotate(-45deg);
            }
        }
    }
`;

// 모바일 메뉴 햄버거 버튼
const DrawerMenu = ({clicked}) => {
    return (
        <DrawerMenuDiv className={clicked ? "clicked" : ""}>
            <span></span>
        </DrawerMenuDiv>
    );
}

export default DrawerMenu;