import './header.css';
import React, { useRef } from 'react';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { useTranslation} from "react-i18next";

// PC화면 상단영역
const Header = (props) => {
    const {t} = useTranslation();
    const keywordRef = useRef();

    // input focus시 검색목록 사라지도록
    const setListOnOffH = props.setListOnOff; 
    const listOnOffH = props.listOnOff ;

    // 검색창에서 엔터 이벤트
    const inputKeyPress = (e) => {
        if(e.charCode === 13) {
            if(keywordRef.current.value === '') {
                alert(t("map_pc_input_placeholder"));
                return;
            }
            // 엔터 입력 후 검색
            clickSearch();
        }
    };

    // 클릭 버튼 클릭
    const clickSearch = () => {
        setListOnOffH(true)
        if(keywordRef.current.value === '') {
            alert(t("map_pc_input_placeholder"));
            return;
        }
        // 클릭 버튼 클릭 후 Map 컴포넌트에 검색어와 검색 타입 전달
        props.setKeyword(keywordRef.current.value);
        props.setSearchType('search');
    };

    return (
        <header>
            <section id={'search'}>
                <div className='inputBox' onClick={(e) => setListOnOffH(false)}>
                    <input type="text" ref={keywordRef} id="keyword" size="15" maxLength='35'  onKeyPress={inputKeyPress} className='inputText' placeholder={t("map_pc_input_placeholder")} /> 
                </div> 
                <button type="submit" className='searchIcon' onClick={clickSearch} >
                    <FontAwesomeIcon icon={faSearch} className="searchSvg"/>
                </button>
            </section>
        </header>
    );
};

export default Header;