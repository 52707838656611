import { t } from "i18next";
import React, { useState } from "react";
import styled from "styled-components";

import { axiosPost } from "../../../apis/AxiosAPI";
import { isValidPassword } from "../../../apis/Validation"; 
import AuthCapsLock from "../../Auth/AuthCapsLock";

const ChangePwForm = styled.form`
    display: flex;
    justify-content: center;
    flex-direction: column;
    >label{
        font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
    }
    >input[type=password]{
        width: 100%;
        height: 48px;
        margin: 8px 0px 20px 0px;
        padding: 15px 10px;
        font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        text-align: left;
        border: 1px solid #D9DDE2;
        border-radius: 2px;
        &:focus {
            outline: 1px solid #05141F;
        }
        &:nth-of-type(3){
            margin-bottom: 0px;
        }
    }
    >input[type=submit]{
        width: 100%;
        height: 48px;
        border: none;
        font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
        font-size: 14px;
        font-weight: 700;
        line-height: 16px;
        text-align: center;
        border-radius: 2px;
        cursor: pointer;
        color: #fff;  
        background: #05141F;
        -webkit-appearance: none;
        -webkit-border-radius: 0;
        border: none;
        &:hover{
            background:#3D85F0;;
            transition:.3s;
        }
        &:last-of-type{
            color: #05141F;
            background: #fff;
            border: 1px solid #05141F;
            box-sizing: border-box;
            margin-top: 10px;
            &:hover{
                background:#3D85F0;;
                transition:.3s;
                color: #fff;
                border: none;
            }
        }
    }
`;

// modal 페이지에 들어가는 content - 마이페이지 > 비밀번호 변경
const ChangePw = ({closeModal, setIsProceeding}) => {
    const [currentPw, setCurrentPw] = useState("");         // 현재 비밀번호
    const [changePw, setChangePw] = useState("");           // 변경 할 비밀번호
    const [reChangePw, setReChangePw] = useState("");       // 변경 할 비밀번호 재입력
    const [disabled, setDisabled] = useState(false);        // 버튼의 활성화 여부
    const [isCapsLock, setIsCapsLock] = useState(false);    // 비밀번호 capslock 버튼 확인

    const onSubmit = (e) => {
        e.preventDefault();

        setDisabled(true);

        if(currentPw === '' || changePw === '' || reChangePw === ''){
            setDisabled(false);
            alert(t('metarex_alert_validation_pw_1'));
            return;
        }

        if(!isValidPassword(currentPw) || !isValidPassword(changePw) || !isValidPassword(reChangePw)){
            setDisabled(false);
            alert(t('metarex_alert_validation_pw_2'));
            return;
        }

        if(changePw !== reChangePw) {
            setDisabled(false);
            alert(t('metarex_alert_validation_pw_3'));
            return;
        }

        requestChangePassword({
            currentPassword: currentPw,
            newPassword: changePw,
            reNewPassword: reChangePw
        });
    }

    const requestChangePassword = (json) => {
        setIsProceeding(true);
        axiosPost('api/user/changeUserPassword', json)
            .then(response => {
                alert(response.data.message);
            })
            .catch(error => {
                console.log(`changeUserPassword, error message: ${error}`);
                alert(t('mypage_password_alert_failed_1'));
            }).finally(() =>{
                closeModal();
                setIsProceeding(false);
            });
    }

    const onKeyUpEvent = (e) => {
        setIsCapsLock(e.getModifierState("CapsLock"));
        e.target.value = e.target.value.replace(/[ㄱ-ㅎㅏ-ㅣ가-힣]/g, '');
    }

    return (
        <ChangePwForm onSubmit={onSubmit}>
            <label htmlFor="current_pw">{t('mypage_change_current_password')}</label>
            <input 
                type="password" 
                id="current_pw" 
                onKeyUp={onKeyUpEvent}
                onChange={e=> setCurrentPw(e.target.value.trim())} 
                placeholder={t('mypage_change_current_password')}
            />
            <label htmlFor="changed_pw">{t('mypage_change_changed_password')}</label>
            <input 
                type="password" 
                id="changed_pw" 
                onKeyUp={onKeyUpEvent}
                onChange={e => setChangePw(e.target.value.trim())} 
                placeholder={t('mypage_change_changed_password')}
            />
            <label htmlFor="re_changed_pw">{t('mypage_change_confirm_password')}</label>
            <input 
                type="password" 
                id="re_changed_pw" 
                onKeyUp={onKeyUpEvent}
                onChange={e => setReChangePw(e.target.value.trim())} 
                placeholder={t('mypage_change_confirm_password')}
            />
            <AuthCapsLock isCapsLock={isCapsLock}/>
            <input type="submit" value={t('metarex_common_confirm')} disabled={disabled}/>
            <input type="submit" value={t('metarex_common_cancel')} onClick={closeModal}/>
        </ChangePwForm>
    );
}

export default ChangePw;

