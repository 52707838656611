import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import SocialButton from "../../feature/Renewal20221005/component/SocialButton";
import SocialButton1 from "../../feature/Renewal20221005/component/SocialButton1";
import SocialButton2 from "../../feature/Renewal20221005/component/SocialButton2";
import Template from "../../feature/Renewal20221005/component/Template";
import Template1 from "../../feature/Renewal20221005/component/Template1";
import Template2 from "../../feature/Renewal20221005/component/Template2";

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  return isDesktop ? children : null;
};
const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  return isTablet ? children : null;
};
const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return isMobile ? children : null;
};

function Menu() {
  return (
    <div>
      <Desktop>
        <Template>
          <SocialButton
            onClick={() =>
              window.open(
                "https://www.youtube.com/channel/UC4FibIV1vNUMNy8g5L-BTFw/featured",
                "_blank"
              )
            }
          >
            유튜브
          </SocialButton>
          <SocialButton
            onClick={() =>
              window.open("https://twitter.com/ASTERmanager", "_blank")
            }
          >
            트위터
          </SocialButton>
          <SocialButton
            onClick={() =>
              window.open(
                "https://www.instagram.com/aster_global_official/",
                "_blank"
              )
            }
          >
            인스타그램
          </SocialButton>
          <SocialButton
            onClick={() =>
              window.open(
                "https://www.facebook.com/profile.php?id=100079668669203",
                "_blank"
              )
            }
          >
            페이스북
          </SocialButton>
          <SocialButton
            onClick={() =>
              window.open(
                "https://www.linkedin.com/in/aster-coin-72846223b/",
                "_blank"
              )
            }
          >
            링크드인
          </SocialButton>
          <SocialButton
            onClick={() =>
              window.open("https://discord.gg/mxFSP2yFU2", "_blank")
            }
          >
            디스코드
          </SocialButton>
        </Template>
      </Desktop>
      <Tablet>
        <Template1>
          <SocialButton1
            onClick={() =>
              window.open(
                "https://www.youtube.com/channel/UC4FibIV1vNUMNy8g5L-BTFw/featured",
                "_blank"
              )
            }
          >
            유튜브
          </SocialButton1>
          <SocialButton1
            onClick={() =>
              window.open("https://twitter.com/ASTERmanager", "_blank")
            }
          >
            트위터
          </SocialButton1>
          <SocialButton1
            onClick={() =>
              window.open(
                "https://www.instagram.com/aster_global_official/",
                "_blank"
              )
            }
          >
            인스타그램
          </SocialButton1>
          <SocialButton1
            onClick={() =>
              window.open(
                "https://www.facebook.com/profile.php?id=100079668669203",
                "_blank"
              )
            }
          >
            페이스북
          </SocialButton1>
          <SocialButton1
            onClick={() =>
              window.open(
                "https://www.linkedin.com/in/aster-coin-72846223b/",
                "_blank"
              )
            }
          >
            링크드인
          </SocialButton1>
          <SocialButton1
            onClick={() =>
              window.open("https://discord.gg/mxFSP2yFU2", "_blank")
            }
          >
            디스코드
          </SocialButton1>
        </Template1>
      </Tablet>
      <Mobile>
        <Template2>
          <SocialButton2
            onClick={() =>
              window.open(
                "https://www.youtube.com/channel/UC4FibIV1vNUMNy8g5L-BTFw/featured",
                "_blank"
              )
            }
          >
            유튜브
          </SocialButton2>
          <SocialButton2
            onClick={() =>
              window.open("https://twitter.com/ASTERmanager", "_blank")
            }
          >
            트위터
          </SocialButton2>
          <SocialButton2
            onClick={() =>
              window.open(
                "https://www.instagram.com/aster_global_official/",
                "_blank"
              )
            }
          >
            인스타그램
          </SocialButton2>
          <SocialButton2
            onClick={() =>
              window.open(
                "https://www.facebook.com/profile.php?id=100079668669203",
                "_blank"
              )
            }
          >
            페이스북
          </SocialButton2>
          <SocialButton2
            onClick={() =>
              window.open(
                "https://www.linkedin.com/in/aster-coin-72846223b/",
                "_blank"
              )
            }
          >
            링크드인
          </SocialButton2>
          <SocialButton2
            onClick={() =>
              window.open("https://discord.gg/mxFSP2yFU2", "_blank")
            }
          >
            디스코드
          </SocialButton2>
        </Template2>
      </Mobile>
    </div>
  );
}

export default Menu;
