import React, { useEffect, useState } from "react";
import { axiosGet } from "../apis/AxiosAPI";
import BoardTemplate from "../components/Board/BoardTemplate";
import PageBanner from "../components/PageBanner";
import PageTab from "../components/PageTab";
import SupportTemplate from "../components/SupportTemplate";
import Footer from "../feature/Footer/Footer";

const Faq = () => {
    const [pageNum, setPageNum] = useState(1);
    const [faqList, setFaqList] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const PAGE_PER_NUM = 5;

    useEffect(()=>{
        requestFaqList();
    },[pageNum]);

    const requestFaqList = () => {
        axiosGet(`faq/select?pageNum=${pageNum}&numPerPage=${PAGE_PER_NUM}`)
            .then(response => {
                if(JSON.parse(response.data.result)) {
                    setFaqList(response.data.data);
                    setTotalCount(response.data.totalCount);
                }
            })
            .catch(e => {
                console.log(`get FAQ API, error message: ${e}`);
            });
    }

    return (
        <>
        <SupportTemplate>
            <PageBanner style={{backgroundImage: "url('/images/bg/support_banner.jpg')"}} textAlign="center">FAQ</PageBanner>
            <PageTab category="support" type="faq"/>
            <div>
                <BoardTemplate boardContents={faqList} pageNum={pageNum} setPageNum={setPageNum} totalCount={totalCount} PAGE_PER_NUM={PAGE_PER_NUM} url='/faq'/>
            </div>
        </SupportTemplate>
        <Footer/>
        </>
    );
}

export default Faq;