import { useEffect, useState } from "react";
export const  useDevice = () => {
    const [device, setDevice] = useState('');
    
    const [isMobile, setMobile] = useState(window.innerWidth);


    /**
     * 화면 리사이즈 이벤트
     */
     const resizeHandler = () => {
        if(window.innerWidth < 1024) {
            setMobile(true);            
            setDevice('mobile');
        } else if (window.innerWidth > 1024){
            setMobile(false);
            setDevice('pc');
        }
    };

    /**
     * 화면 로딩 직후, 화면 리사이즈 이벤트
     */
     useEffect(() => {
        window.addEventListener('load', resizeHandler);
        window.addEventListener('resize', resizeHandler);
        
        return () => {
            window.removeEventListener('load', resizeHandler);
            window.removeEventListener('resize', resizeHandler);
        } 
    }, []  );
    

    return device;
};