import { ADD_USER, REMOVE_USER, UPDATE_USER_NAME } from "./types"

export const addUser = (data) => {
    return {
        type: ADD_USER,
        data,
    }
}

export const removeUser = () => {
    console.log('remove');
    return {
        type: REMOVE_USER,
        data: {},
    }
}

export const updateUserName = (name) => {
    return {
        type: UPDATE_USER_NAME,
        data: name
    }
}
