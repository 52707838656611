import React from "react";
import styled from "styled-components";

const StyledMoreBtn = styled.button`
    margin: 0 auto;
    width: 54px;
    height: 54px;
    border: 1px solid #05141F;
    background: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    span {
        width: 12px;
        height: 12px;
        position: relative;
        &::before {
            content: ' ';
            top: calc(50% - 1px);
            left: 0px;
            position: absolute;
            width: 12px;
            height: 2px;
            background: #05141F;
            transform: rotate(90deg);
        }
        &::after {
            content: ' ';
            position: absolute;
            top: calc(50% - 1px);
            left: 0px;
            width: 12px;
            height: 2px;
            background: #05141F;
        }
    }
    &.disabled {
        cursor: auto;
        border: 1px solid #D9DDE2;
        span {
            &::before {
                background: #D9DDE2;
            }
            &::after {
                background: #D9DDE2;
            }
        }
    }
`;

// 더보기 버튼 컴포넌트
const MoreButton = ({disabled, onClick}) => {
    return (
        <StyledMoreBtn className={disabled && "disabled"} onClick={onClick} disabled={disabled}>
            <span/>
        </StyledMoreBtn>
    );
}

export default MoreButton;