import React, { useEffect, useState, useRef } from 'react';
import './header.mobile.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { useTranslation} from "react-i18next";

// mobile 화면 상단영역
const HeaderM = (props) => {
    const {t} = useTranslation();
    // 주소 정보
    const address = props.address;
    // 검색 타입
    const searchType = props.searchType;
    // 검색 목록의 출력여부 전달 콜백
    const setShowList = props.setShowList;
    // 검색 버튼 클릭 여부 전달 콜백
    const setClickSearch = props.setClickSearch;
    // 검색어 전달 콜백
    const setKeyword = props.setKeyword;
    // 검색 타입 전달 콜백 
    const setSearchType = props.setSearchType;
    
    // 검색 모드 여부. input on / off
    const [searchMode, setSearchMode] = useState(false);
    const keywordRef = useRef();

    // 검색창에서 엔터 클릭 이벤트
    const inputKeyPress = (e) => {
        if(e.charCode === 13) {
            if(keywordRef.current.value === '') {
                alert(t("map_pc_input_placeholder"));
                return;
            }
            
            clickSearch();
        }
    };

    // 검색 버튼 클릭 이벤트
    const clickSearch = () => {
        if(!searchMode) {
            // 검색 모드가 아닌 경우
            
            setSearchMode(true);
            setClickSearch(new Date());
            
            if(setKeyword)    setKeyword(keywordRef.current.value);
            if(setSearchType) setSearchType('search');
        } else { 
            // 검색 모드인 경우

            if(keywordRef.current.value === '') {
                alert(t("map_pc_input_placeholder"));
                return;
            }

            // 검색어 입력 후 검색 버튼을 클릭하면 주소 목록을 보여주기 위해 상위에 목록 출력 여부 전달
            setShowList(true);
            
            if(setKeyword)    setKeyword(keywordRef.current.value);
            if(setSearchType) setSearchType('search');
        }
    };

    // 검색 취소 버튼 클릭
    const clickCancel = () => {
        // window.location.href = '/';
        setSearchType('list')
    };

    useEffect(() => {
        // 검색 타입에 따라 검색 모드인지 아닌지 구분
        setSearchMode(searchType !== 'list' && searchType !== 'mapClick' && searchType !== 'url' && searchType !== '');
    }, [address, searchType]);

    return (
        <header>
            <section id={'search'}>
                <input 
                    type="text" 
                    ref={keywordRef} 
                    id="keyword" 
                    size="15" 
                    maxLength='35'  
                    onKeyPress={inputKeyPress} 
                    placeholder={t("map_pc_input_placeholder")}
                    className={searchMode ? '' : 'hide'}
                />
                <button className={searchMode ? 'cancel-search' : 'cancel-search hide'} onClick={clickCancel}>취소</button>
                {/* <button type="submit" className={'input-search'} onClick={clickSearch}><IoSearchOutline /></button> */}
                
                <button type="submit" className={'input-search'} onClick={clickSearch}>
                    <FontAwesomeIcon icon={faSearch} className="searchSvg" />
                </button> 
            </section>
        </header>
    );
};

export default HeaderM;