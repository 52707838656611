import React from "react";
import styled from "styled-components";

const StyledContentTemaplate = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    height: auto;
    width: 100%;
    max-width: 1200px;
    .subject{
        width: 100%;
        height: 88px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #D9DDE2;
        h2 {
        width: 100%;
        font-weight: 600;
        color: #05141F;
        font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
        letter-spacing: -0.02em;
        text-align: left;
        }
        span {
            color: #05141F;
            &:first-of-type {
                width: 97px;
                text-align: center;
                font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 14px;
                letter-spacing: -0.02em;                
            }
            &:last-of-type {
                width: 90px;
                font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 14px;
                letter-spacing: -0.02em;
                text-align: left;
            }
        }
    }

    @media only screen and (max-width: 768px) {
        width: 100%;
        max-width: 100%;
        .subject{
            height: 80px;
            padding: 0px 20px;
            align-items: flex-start;
            flex-direction: column-reverse;
            justify-content: center;
            border-bottom: 1px solid #D9DDE2;
            h2 {
                font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
                font-size: 13px;
                font-style: normal;
                font-weight: 700;
                line-height: 14px;
                letter-spacing: -0.02em;
                text-align: left;
            }
            span {
                &:first-of-type {
                    display: none;
                }
                &:last-of-type {
                    width: 100%;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 14px;
                    letter-spacing: -0.02em;
                    text-align: left;
                    margin-bottom: 6px;
                }
            }
        }
    }
`;

// 게시글 자세히 보기 페이지 컴포넌트
const BoardContent = ({ children, style }) => {
    return (
        <StyledContentTemaplate style={style}>
            {children}
        </StyledContentTemaplate>
    )
}

export default BoardContent;