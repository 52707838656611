import React, { useEffect, useState } from 'react';
import SaleRegistRation from '../../feature/SalesRegistRation/SaleRegistRation'
import UnRegistRation from '../../feature/SalesRegistRation/UnRegistRation'
import UnSaleInput from '../../feature/SalesRegistRation/UnSaleInput'
import SaleInput from '../../feature/SalesRegistRation/SaleInput'
import './SalesRegistRation.css'
import { isSessionValidate, isValidationSessionId } from '../../apis/BrowserStorageAPI';
import { moveTopOnPageAuto } from '../../apis/CommonFunc';
import Footer from '../../feature/Footer/Footer';
import { axiosGet } from '../../apis/AxiosAPI';
import PageBanner from '../../components/PageBanner';
import PageTab from '../../components/PageTab';
import styled from 'styled-components';
import { t } from 'i18next';

const MarketTemplate = styled.div`
    padding-top: 60px;
`;

const SalesRegistRation = (props) => {
    // PC
    const [salePageNum , setSalePageNum] = useState(1)
    const [unSalePageNum , setUnSalePageNum] = useState(1)
    // MOBILE
    const [saleMPageNum , setSaleMPageNum] = useState(1)
    const [unSaleMPageNum , setUnSaleMPageNum] = useState(1)

    // SALE axios
    const [salesAxios , setSalesAxios] = useState(undefined)
    // UNSALE axios
    const [unSalesAxios , setUnSalesAxios] = useState(undefined)

    // axios total count
    const [unSaleTotalCount , setUnSaleTotalCount] = useState(0)
    // axios total count
    const [saleTotalCount , setSaleTotalCount] = useState(0)
    
    // width값 토대로 모바일 pc 구분.
    const [salesRegistWidth , setSalesRegistWidth] = useState(false);

    // 모바일 사이즈 확인
    const salesRegistResizeHandler = () => {
        if(window.innerWidth < 769) {
            setSalesRegistWidth(true);
        } else if (window.innerWidth > 769){
            setSalesRegistWidth(false);
        }
    };
    
    /** 첫 로딩 */
    useEffect(()=>{
        if(!isSessionValidate()) {
            alert(t('market_view_login'));
            window.location.href ='/signin';
            return false;
        }
        isValidationSessionId()
            .then(() => {
                if(!isSessionValidate()) {
                    window.location.href ='/signin';
                    return;
                }
            });

        moveTopOnPageAuto();
    },[]);

    useEffect(() => {
        window.addEventListener('load', salesRegistResizeHandler);
        window.addEventListener('scroll', salesRegistResizeHandler);
        window.addEventListener('resize', salesRegistResizeHandler);
        
        return () => {
            window.removeEventListener('load', salesRegistResizeHandler);
            window.removeEventListener('scroll', salesRegistResizeHandler);
            window.removeEventListener('resize', salesRegistResizeHandler);
        } 
    }, [salesRegistWidth , salesRegistResizeHandler]);

    // 매물등록 취소
    useEffect(() => {
        const saleUrl = (pageNum, numPerPage) => `realestate/getMyOwn/sale?pageNum=${encodeURI(pageNum)}&numPerPage=${encodeURI(numPerPage)}`

        let res = axiosGet(saleUrl(salesRegistWidth === true ? saleMPageNum : salePageNum , salesRegistWidth === true ? 1 : 4))
        .then(res=> {
            if(JSON.parse(res.data.result)){
                salesRegistResizeHandler()
                setSalesAxios(res.data.data ? res.data.data : null)
                setSaleTotalCount(res.data.totalCount);
            }
        }).catch(e => {
            console.log(`error message: ${e}`);
        })
    }, [setSalesAxios, salePageNum , setSaleTotalCount , salesRegistWidth , saleMPageNum]);
    let SalesArray = []
    SalesArray.push(salesAxios)


    // 미등록 매물
    useEffect(() => {
        let unSaleUrl = (pageNum,numPerPage) => `realestate/getMyOwn/notSale?pageNum=${encodeURI(pageNum)}&numPerPage=${encodeURI(numPerPage)}`
        
        let res = axiosGet(unSaleUrl(salesRegistWidth === true ? unSaleMPageNum : unSalePageNum, salesRegistWidth === true ? 1 : 4))
        .then(res=> {
            if(JSON.parse(res.data.result)){
                salesRegistResizeHandler()
                setUnSalesAxios(res.data.data ? res.data.data : null)
                setUnSaleTotalCount(res.data.totalCount);
            }
        }).catch(e => {
            console.log(`error message: ${e}`);
        })
        
    }, [ setUnSalesAxios , unSalePageNum , setUnSaleTotalCount , salesRegistWidth , unSaleMPageNum]);


    let unSalesArray = []
    unSalesArray.push(unSalesAxios)
    
    // modal창 열고 닫기
    const [modal, setModal] = useState(true)
    
    // 판매중 물건 취소시 해당 div제거 후 saleCancle값에 저장.
    const [saleCancle, setSaleCancle] = useState()

    // input 값 담아오기
    const [unSaleKeyWord, setUnSaleKeyWord] = useState("");
    const [saleKeyWord, setSaleKeyWord] = useState("");

    return(
        <MarketTemplate>
            <PageBanner style={{backgroundImage: "url('/images/bg/market_banner.jpg')"}}>{t("header_sales_registration")}</PageBanner>
            <PageTab category="market" type="sale" />
            <section className="SaleRegistContainer">
                <div>
                    <div className="salesTitleBoder">
                        <h3 className="unRegistTitle">{t("sales_registration_unregistered_title")}</h3>
                        <UnSaleInput setUnSaleKeyWord={setUnSaleKeyWord} ></UnSaleInput>
                    </div>
                    {/* 미등록 매물 */}
                    {
                        unSalesArray && unSalesArray.map((data, i) => 
                            (
                                <UnRegistRation 
                                    key={i} 
                                    UnRegistDatas={data} 
                                    unSaleKeyWord={unSaleKeyWord} 
                                    saleCancle={saleCancle} 
                                    unWidth={salesRegistWidth}
                                    setModal={setModal}
                                    modal={modal}

                                    // pc axios 페이지
                                    setUnSalePageNum={setUnSalePageNum}
                                    unSalePageNum={unSalePageNum}

                                    // mobile axios 페이지
                                    unSaleMPageNum={unSaleMPageNum}
                                    setUnSaleMPageNum={setUnSaleMPageNum}

                                    // total count  
                                    unSaleTotalCount={unSaleTotalCount}
                                    >
                                </UnRegistRation>
                            ))
                    } 
                </div>
                <div>
                    <div className="salesTitleBoder">
                        <h3 className="saleRegistTitle">{t("sales_registration_Sale_title")}</h3>
                        <SaleInput setSaleKeyWord={setSaleKeyWord}></SaleInput>
                    </div>
                    {/* 매물 등록 취소 */}
                    {
                        SalesArray && SalesArray.map((data, i) => 
                            (
                                <SaleRegistRation 
                                    key={i} 
                                    registDatas={data} 
                                    saleKeyWord={saleKeyWord}
                                    setSaleCancle={setSaleCancle}
                                    saleWidth={salesRegistWidth}

                                    // axios totalcount
                                    saleTotalCount={saleTotalCount}

                                    // pc axios 페이지
                                    salePageNum={salePageNum}
                                    setSalePageNum={setSalePageNum}

                                    // 모바일 axios 페이지
                                    saleMPageNum = {saleMPageNum} 
                                    setSaleMPageNum={setSaleMPageNum}
                                >
                                </SaleRegistRation>
                            ))
                    }
                </div>
            </section>
            <Footer/>
        </MarketTemplate>
    )
}

export default SalesRegistRation;