import { t } from "i18next";
import { addUser } from "../redux/user/actions";
import { axiosGet, axiosPost } from "./AxiosAPI";

// 회원가입 시작
export const 회원가입_휴대폰_인증번호_요청하기 = async (userName, userMobile) => {
    axiosGet(`signup/mobile/auth/get?userName=${userName}&userMobile=${userMobile}`)
        .then(response => {
            alert(response.data.message);
        }).catch(e => {
            console.log(`sign up mobile error message: ${e}`);
        });
}

export const 회원가입_이메일_인증번호_요청하기 = async (userName, userMobile, userEmail) => {
    axiosGet(`signup/email/auth/get?userName=${userName}&userMobile=${userMobile}&userEmail=${userEmail}`)
        .then(response => {
            alert(response.data.message);
        }).catch(e => {
            console.log(`sign up email error message: ${e}`);
        });
}

export const 회원가입_요청하기 = async (json) => {
    axiosPost('signup/user/set', json)
        .then(response => {
            alert(response.data.message);
            if(JSON.parse(response.data.result)){
                window.location.href='/signin';
            } else {
                if(response.data.message === '이미 가입된 계정이 존재합니다.'){
                    window.location.href='/signin';
                    return;
                }
            }
        }).catch(e => {
            console.log(`error message: ${e}`);
        });
}

// 로그인 시작
export const 로그인_요청하기 = async (json, dispatch) => {
    axiosPost('signin/email/set', json)
    .then(response => {
        if(!(JSON.parse(response.data.result))) {
            alert(response.data.message);
            return;
        }

        dispatch(addUser(response.data));   // redux

        localStorage.setItem('email', response.data.email);
        localStorage.setItem('mobile', response.data.mobile);
        localStorage.setItem('name', response.data.name);
        localStorage.setItem('sessionId', response.data.sessionId);

        // 결제 링크 로그인시 뒤로가기 로직
        const referrer = String(document.referrer);
        if( referrer.indexOf(`/sbr?u=`) > -1 ) {
            window.history.back();
        } else {
            window.location.replace("/");
        }
    }).catch(e => {
        console.log(`sign in error : ${e}`);
    });
}

// 로그인 종료
export const 휴대폰_인증번호_요청하기 = async (userMobile) => {
    localStorage.removeItem('authTokenMobile');
    axiosGet(`selfCertification/mobile?userMobile=${userMobile}`)
        .then(response => {
            if(JSON.parse(response.data.result)) {
                localStorage.setItem('authTokenMobile', response.data.authToken);
            }
            alert(response.data.message);
        }).catch(e => {
            alert(t('metarex_alert_server_problem'));
            console.log(`error message: ${e}`);
        });
};
