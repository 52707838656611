import React from "react";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

const StyledItemsDiv = styled.div`
    height:70px;
    @media screen and (max-width:786px) {
        height:50px;
    }
    width:145px;
    display:flex;
    align-items:center;
    justify-content:center;
    overflow:hidden;
    cursor:pointer;
    user-select: none;
    span {
        display:block;
        color:#fff;
        font-family:'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
        font-size:17px;
        font-style:normal;
        font-weight:600;
        line-height:20px;
        letter-spacing:0em;
        text-align:center; 
    }

    &.lang {
        width:120px;
        .fa-globe {
            font-size: 23px;
        }            
    }

    &.sign_in {
        width:150px;
        a {
            width:150px;
            height:50px;
            color:#fff;
            font-family:'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
            font-size:17px;
            font-style:normal;
            font-weight:600;
            line-height:20px;
            text-align:center; 
            display:flex;
            justify-content:center;
            align-items:center;
        }
    }

    @media screen and (max-width:786px) {
        width:100%;
        >span{
            display:flex;
            justify-content:space-between;
            width:100%;
            padding:0px 25px;
        }
        &.lang {
            width: 100%;
        }
        &.sign_in {
            width: 100%;
            background: #3D85F0;
        }
    }
`; 

const StyledDropdownContents = styled.ul`
    display:none;
    position:absolute;
    top:70px;
    left:inherit;
    background-color:#05141F;
    width:145px;
    z-index:1;
    a {
        height:50px;
        display:flex;
        justify-content:flex-start;
        align-items:center;
        color:#B0B0B0;
        font-family:'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
        font-size:17px;
        font-style:normal;
        font-weight:700;
        letter-spacing:0em;
        text-align:left;
        padding:5px 16px;
        &:hover {
            color: #fff;
            text-decoration: underline;
            text-underline-position: under;
            transition: 0.5s;
        }
    }
    &.lang {
        width: 120px;
        span {
            color: #B0B0B0;
            font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
            font-size: 17px;
            font-weight: 700;
            padding: 14px 16px;
            display: flex;
            align-items: center;
            height: 60px;
            &:hover {
                transition: 1s;
                cursor: pointer;
                color: #fff;
                text-decoration: underline;
                text-underline-position: under;
            }
        }
    }
    &.sign_in {
        width: 150px;
        a {
            color: #B0B0B0;
            display: flex;
            justify-content: flex-start;
            &:hover {
                transition: 1s;
                cursor: pointer;
                color: #fff;
                text-decoration: underline;
                text-underline-position: under;
            }
        }  
    }

    
    @media screen and (max-width:786px) {
        width: 100%;
        display: none;
        background-color: #fff;
        position: inherit;
        a {
            color: #595959;
            padding:0px 30px;
        }

        &.lang {
            width: 100%;
            padding: 0px 30px;
            li>span{
                color: #595959;
                text-align: left;
                &:hover {
                    color: #05141F;
                }
            }
        }
        &.sign_in {
            width: 100%;
            li>a{
                color: #595959; 
                &:hover {
                    color: #05141F;
                }
            }
        }
    }
`;

const Menu = ({children, className, onClick, category, categoryName, active}) => {
    return (
        <div>
            <StyledItemsDiv className={className} onClick={onClick} data-dropdown-type={category}>
                <span className="dropbtn">
                    { categoryName } 
                    <FontAwesomeIcon 
                        style={active[category] 
                                ? {transform: "rotateX(180deg)", transition: ".3s", marginLeft: '5px'}
                                : {transform: "rotateX(0deg)", transition: ".3s", marginLeft: '5px'}}
                        icon={faChevronUp}/>
                </span>
            </StyledItemsDiv>
            <StyledDropdownContents className={`dropdown-content ${className ? className : ""}${active[category] ? " clicked" : ""}`}>
                {children}
            </StyledDropdownContents>
        </div>
    );
}

export default Menu;