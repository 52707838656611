import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import React from "react";
import styled from "styled-components";

const StyledAwesomepiaTemplate = styled.div`
    width: 100%;
    height: auto;
    max-width: 1440px;
    display: flex;
    margin-top: 180px;
    @media only screen and (max-width: 768px) {
        max-width: 100%;
        flex-direction: column;
    }
`;

const StyledInformationDiv = styled.div`
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 160px;
    &:first-of-type {
        padding-left: 100px;
    }

    h2 {
        font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
        font-size: 40px;
        font-style: normal;
        font-weight: 800;
        line-height: 60px;
        letter-spacing: 0em;
        text-align: left;
    }
    p {
        color: #666;
        width: 100%;
        font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 800;
        line-height: 30px;
        letter-spacing: 0em;
        text-align: left;
        margin-bottom: 40px;
    }

    a {
        color: #3D85F0;
        width: 220px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #3D85F0;
        box-sizing: border-box;
        border-radius: 50px;

        font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;

        padding: 15px 20px;
        .fa-long-arrow-alt-right {
            width: 20px;
            margin-left: 10px;
        }
    }

    li {
        font-size: 17px;
        font-weight: 800;
        line-height: 36px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 120px;
        padding-right: 150px;
        span{
            font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
            width: auto;
            height: 72px;   
        }
        img {
            width: 50px;
            height: 50px;
        }
    }
    @media only screen and (max-width: 768px) {
        width: 100%;
        margin-bottom: 110px;
        &:first-of-type {
            padding: 0px 20px;
        }
        h2 {
            font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
            font-size: 30px;
            font-style: normal;
            font-weight: 800;
            line-height: 50px;
            letter-spacing: 0em;
            text-align: left;
        }
        p {
            padding: 0px 20px;
        }
        a {
            margin-top: 40px;
            margin-left: 20px;
        }
        li {
            margin-bottom: 80px;
            padding-right: 0px;
            padding: 0px 20px;
            img {
                width: 40px;
                height: auto;
            
            }
        }
    }
`;


const Awesomepia = () => {
    return (
        <StyledAwesomepiaTemplate>
            <>
            <StyledInformationDiv>
                <h2>{t("metarex_future_awesomepia_title")}</h2>
            </StyledInformationDiv>
            <StyledInformationDiv>
                <p>
                    {t("metarex_future_awesomepia_p_1")}<br/>
                    {t("metarex_future_awesomepia_p_2")}<br/>
                    {t("metarex_future_awesomepia_p_3")}<br/>
                    {t("metarex_future_awesomepia_p_4")}<br/>
                    {t("metarex_future_awesomepia_p_5")}
                </p>
                <a href="http://www.awesomepia.kr/" rel="noopener noreferrer" title={t("metarex_future_awesomepia_hover")}>
                    {t("metarex_future_awesomepia_btn")}
                    <FontAwesomeIcon icon={faLongArrowAltRight}/>
                </a>
            </StyledInformationDiv>
            </>
            {/* <AwesomepiaService t={t}/> */}
        </StyledAwesomepiaTemplate>
    );

}

export default Awesomepia;