import { t } from "i18next";
import React from "react";
import styled from "styled-components";


const StyledServiceTemplate = styled.div`
    width: 1200px;
    height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 240px;
    @media only screen and (max-width: 768px) {
        width: 100%;
        height: 1450px;
        display: block;
        position: relative;
        overflow: hidden;
        margin-bottom: 100px;
    }
`;

const StyledCircleTemplate = styled.div`
    width: 500px;
    height: 500px;
    display: grid;
    grid-template-columns: [col] 242px [col] 242px;
    grid-template-rows: [row] 242px [row] 242px;
    grid-gap: 16px;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    .circle {
        box-sizing: border-box;
        &:nth-of-type(1){
            background: rgba(61, 133, 240, 0.8);
        }
        &:nth-of-type(2){
            background: rgba(61, 133, 240, 0.6);
        }
        &:nth-of-type(3){
            background: rgba(61, 133, 240, 0.1);
        }
        &:nth-of-type(4){
            background: rgba(61, 133, 240, 0.3);
        }
    }
    .center {
        position: absolute;
        top: calc(50% - 100px);
        left: calc(50% - 100px);
        width: 200px;
        height: 200px;
        border-radius: 50%;
        background-color: #fff;
    }

    @media only screen and (max-width: 768px) {
        width: 410px;
        height: 410px;
        grid-template-columns: [col] 205px [col] 205px;
        grid-template-rows: [row] 205px [row] 205px;
        left: 50px;
        top: 0px;
        overflow-x: hidden;
        .center {
            top: calc(50% - 90px);
            left: calc(50% - 90px);
            width: 180px;
            height: 180px;
        }
    }

`;

const StyledOverlayDiv = styled.div`
    position: absolute;
    width: 1200px;
    height: 600px;
    display: grid;
    grid-template-columns: [col] 50% [col] 50%;
    grid-template-rows: [row] 50% [row] 50%;
    box-sizing: border-box;
    z-index: 2;
    .information {
        display: flex;
        align-items: center;
        &:nth-of-type(odd) {
            justify-content: flex-start;
            padding-left: 30px;
        }
        &:nth-of-type(even) {
            justify-content: flex-end;
        }
    }
    @media only screen and (max-width: 768px) {
        width: 80%;
        left: 20px;
        top: 25%;
        height: auto;
        display: flex;
        flex-direction: column;
        .information {
            flex-wrap: wrap;
            &::before {
                content: '';
                position: relative;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                top: -16px;
                left: 0;
                background-color: rgba(61,133,240,0.8);
            }
            &:nth-of-type(odd) {
                padding-left: 0px;
            }
            &:nth-of-type(even) {
                justify-content: flex-start;
            }
        }   
    }
`;

const StyledInfoCardDiv = styled.div`
    color: #05141F;
    width: 290px;
    .title {
        margin-bottom: 50px;
        h3 {
            font-family: 'Roboto';
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: left;
            margin-bottom: 8px
        }
        span {
            font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 800;
            line-height: 16px;
            letter-spacing: 0em;
            text-align: left;
        }
        p{
            font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 22px;
            letter-spacing: 0em;
            text-align: left;
        }
    }
    @media only screen and (max-width: 768px) {
        width: 100%;
        margin-bottom: 90px;
    }
}
`;

const StyledLineDiv = styled.div`
    width: 100px;
    height: 100px;
    position: absolute;
    .point {
        position: absolute;
        background-color: #3D85F0;  
        width: 6px;
        height: 6px;
        border-radius: 3px;
        text-indent: -9999px; 
    }
    &:nth-of-type(1) {
        top: 80px;
        left: 30%;
        border-right: 1px solid #3D85F0;
        border-top: 1px solid #3D85F0;
        .point {
            top: calc(100% - 2px);
            left: calc(100% - 2px);
        }
    }
    &:nth-of-type(2) {
        top: 80px;
        right: 30%;
        border-left: 1px solid #3D85F0;
        border-top: 1px solid #3D85F0;
        .point {
            top: calc(100% - 3px);
            left: calc(0% - 3px);
        }
    }
    &:nth-of-type(3) {
        top: calc(100% - 170px);
        left: 30%;
        border-right: 1px solid #3D85F0;
        border-bottom: 1px solid #3D85F0;
        .point {
            top: calc(0% - 3px);
            left: calc(100% - 2px);
        }
    }
    &:nth-of-type(4) {
        top: calc(100% - 170px);
        right: 30%;
        border-left: 1px solid #3D85F0;
        border-bottom: 1px solid #3D85F0;
        .point {
            top: calc(0% - 3px);
            left: calc(0% - 3px);
        }
    }

    @media only screen and (max-width: 768px) {
        display: none;
    }
`;

const TenspaceService = () => {
    return (
        <StyledServiceTemplate>
            <StyledOverlayDiv>
                <StyledLineDiv>
                    <div className="point">ASTER BANK</div>
                </StyledLineDiv>
                <StyledLineDiv>
                    <div className="point">ASTER RM</div>
                </StyledLineDiv>
                <StyledLineDiv>
                    <div className="point">AI LOAN</div>
                </StyledLineDiv>
                <StyledLineDiv>
                    <div className="point">SI</div>
                </StyledLineDiv>

                <div className="information">
                    <StyledInfoCardDiv>
                        <div className="title">
                            <h3>ASTER BANK</h3>
                            <span>{t("metarex_future_tenspace_s_span_1")}</span>
                        </div>
                        <p>
                            {t("metarex_future_tenspace_s_p_1_1")}<br/>
                            {t("metarex_future_tenspace_s_p_1_2")}
                        </p>
                    </StyledInfoCardDiv>
                </div>
                <div className="information">
                    <StyledInfoCardDiv>
                        <div className="title">
                            <h3>ASTER RM</h3>
                            <span>{t("metarex_future_tenspace_s_span_2")}</span>
                        </div>
                        <p>
                            {t("metarex_future_tenspace_s_p_2_1")}<br/>
                            {t("metarex_future_tenspace_s_p_2_2")}<br/>
                            {t("metarex_future_tenspace_s_p_2_3")}<br/>
                        </p>
                    </StyledInfoCardDiv>
                </div>
                <div className="information">
                    <StyledInfoCardDiv>
                        <div className="title">
                            <h3> AI LOAN</h3>
                            <span>
                                {t("metarex_future_tenspace_s_span_3_1")}<br/>
                                {t("metarex_future_tenspace_s_span_3_2")}
                            </span>
                        </div>
                        <p>
                            {t("metarex_future_tenspace_s_p_3_1")}<br/>
                            {t("metarex_future_tenspace_s_p_3_2")}<br/>
                            {t("metarex_future_tenspace_s_p_3_3")}<br/>
                            {t("metarex_future_tenspace_s_p_3_4")}
                        </p>
                    </StyledInfoCardDiv>
                </div>
                <div className="information">
                    <StyledInfoCardDiv>

                        <div className="title">
                            <h3>SYSTEM<br/>INTERGRATION</h3>
                            <span>{t("metarex_future_tenspace_s_span_4")}</span>
                        </div>
                        <p>
                            {t("metarex_future_tenspace_s_p_4_1")}<br/>
                            {t("metarex_future_tenspace_s_p_4_2")}<br/>
                            {t("metarex_future_tenspace_s_p_4_3")}
                        </p>
                    </StyledInfoCardDiv>
                </div>
            </StyledOverlayDiv>
            <StyledCircleTemplate>
                <div className="circle"/>
                <div className="circle"/>
                <div className="circle"/>
                <div className="circle"/>
                <div className="center"/>
            </StyledCircleTemplate>
        </StyledServiceTemplate>
    );
}

export default TenspaceService;