import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { t } from "i18next";
import styled from "styled-components";

import AuthTemplate from "../components/Auth/AuthTemplate";
import AuthFieldSet from "../components/Auth/AuthFieldSet";
import AuthButton from "../components/Auth/AuthButton";
import AuthInputAndBtn from "../components/Auth/AuthInputAndBtn";
import AuthInput from "../components/Auth/AuthInput";
import AuthCapsLock from "../components/Auth/AuthCapsLock";

import { moveTopOnPageAuto } from "../apis/CommonFunc";
import { isCellPhoneForKorea, isCellPhoneStartWithoutZero, isNull } from "../apis/Validation";
import { axiosPost } from "../apis/AxiosAPI";
import { 휴대폰_인증번호_요청하기 } from "../apis/AuthAPI";

const StyleResultIdDiv = styled.div`
    display: flex;
    flex-direction: column;
    font-family: 'Roboto', 'Nanum Gothic', 'Noto Sans SC', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: center;
    span {
        font-family: 'Roboto', 'Nanum Gothic', 'Noto Sans SC', sans-serif;
        margin: 30px 0px 70px 0px;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: center;
    }
    a {
        width: 100%;
        height: 48px;
        cursor: pointer;
        padding: 10px;
        margin-top: 20px;
        font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .signIn {
        background-color: #05141F;
        color: #fff;
    }

    .findPw {
        background-color: #fff;
        border: 1px solid #05141F;
        color: #05141F;
    }

`;

const FindId = () => {
    const [userId, setUserId] = useState([]);
    const [requestFindId, setRequestFindId] = useState({
                                                userMobile: '',
                                                verifyMobileCode: '',
                                            });
    useEffect(()=>{
        moveTopOnPageAuto();
    }, []);

    // start mobile verify code
    const onClickVerifyMobile = () => {
        const userMobile = requestFindId.userMobile;
        if(isNull(userMobile) || userMobile === "") {
            alert(t('find_id_alert_phone'));
            return false;
        }
        if(isCellPhoneStartWithoutZero(userMobile)) {
            userMobile = '0' + userMobile;
        }
        if(!isCellPhoneForKorea(userMobile)) {
            alert(t('find_id_alert_phone_fail'));
        }
    
        localStorage.setItem('findIdMobile', userMobile.replace(/\s+/g, '').trim());
        휴대폰_인증번호_요청하기( userMobile );
    }

    // start request data
    const onClickFindIdRequestButton = () => {
        const userMobile = requestFindId.userMobile;
        const verifyMobileCode = requestFindId.verifyMobileCode;
        const authTokenMobile = localStorage.getItem('authTokenMobile');

        if(isNull(userMobile) || userMobile === "") {
            alert(t('find_id_alert_phone'));
            return false;
        }
        if(isNull(verifyMobileCode) || verifyMobileCode === "") {
            alert(t('find_id_alert_phone_veri'));
            return false;
        }
        if(isNull(authTokenMobile) || authTokenMobile === "") {
            alert(t('find_id_alert_phone_auth'));
            return false;
        }

        아이디_찾기_요청하기({
            userMobile,
            verifyMobileCode,
            authTokenMobile
        });
    }

    const 아이디_찾기_요청하기 = async (json) => {
        axiosPost('selfCertification/findId', json)
            .then(response => {
                if(JSON.parse(response.data.result)) {
                    setUserId(response.data.data);
                } else {
                    alert(response.data.message);
                }
            }).catch(e => {
                console.log(`error message: ${e}`);                
            });
    }
    // end request data

    return (
        <>
        <AuthTemplate type="find_id">
            {userId.length === 0 ? (
                <AuthFieldSet title={t("find_id_title")}>
                    <label htmlFor="userMobile">{t("find_id_sub_title_mobile")}</label>
                    <AuthInputAndBtn>
                        <AuthInput 
                            type="tel" 
                            id="userMobile"
                            name="userMobile"
                            placeholder={t("find_id_placeholder_number")} 
                            onChange={(e) => setRequestFindId({...requestFindId, [e.target.name]: e.target.value})}
                        />
                        <AuthButton onClick={onClickVerifyMobile} auth={true}>{t("find_id_verification_code")}</AuthButton>
                    </AuthInputAndBtn>
                    <AuthInput
                        type="tel"
                        name="verifyMobileCode"
                        placeholder={t("find_id_placeholder_input")} 
                        onChange={(e) => setRequestFindId({...requestFindId, [e.target.name]: e.target.value})}
                    />
                    <AuthCapsLock/>
                    <AuthButton onClick={onClickFindIdRequestButton}>{t("find_id_fotget_id")}</AuthButton>
                </AuthFieldSet>
            ) : (
                <AuthFieldSet title={t("find_id_result")}>
                    <StyleResultIdDiv className="result-id">
                        {userId}
                        <span>{t("find_id_result_warning")}</span>
                        <Link to="signin" className="signIn">{t("find_id_result_login")}</Link>
                        <Link to="findpassword" className="findPw">{t("find_id_result_pw")}</Link>
                    </StyleResultIdDiv>
                </AuthFieldSet>
            )}
 
        </AuthTemplate>
        </>
    )
}

export default FindId;