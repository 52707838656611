import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import MainBanner from "../feature/MainBanner/MainBanner"
import GetItOn from "../feature/MainBanner/GetItOn";
import News from "../feature/News/News";
import ThemeArea from "../feature/RecommendItem/ThemeArea";
import Footer from "../feature/Footer/Footer";
import LastestTradingHistory from "../feature/MainBanner/LastestTradingHistory";
import EventPopup20220614 from "../components/Modal/MainPopup/EventPopup20220614";
import EventPopup from "../components/Modal/MainPopup/EventPopup";

import Home from "../feature/Renewal20221005/Home"


import { removeLocalStorage } from "../apis/BrowserStorageAPI";
import { moveTopOnPageAuto } from "../apis/CommonFunc";
import { getPriceDollarKRW } from "../apis/PriceDollarAPI";



function getCookie(name) {
    var Found = false
    var start, end
    var i = 0

    while(i <= document.cookie.length) {
        start = i
        end = start + name.length

        if(document.cookie.substring(start, end) == name) {
            Found = true
            break
        }
        i++
    }

    if(Found == true) {
    start = end + 1
    end = document.cookie.indexOf(";", start)
      if(end < start)
        end = document.cookie.length
    return document.cookie.substring(start, end)
    }
    return ""
}

const Main = () => {

    //2022-08-22 거래소는 MEXC만 지원한다는 팝업
    const [isPopup20221124Reward30pAndSNSShow , setIsPopup20221124Reward30pAndSNSShow] = useState(true);



    useEffect(()=>{
        moveTopOnPageAuto();
        removeLocalStorage();
        getPriceDollarKRW();

        if( getCookie("isPopup20221124Reward30pAndSNSShow") !== "true" ) {
            setIsPopup20221124Reward30pAndSNSShow(true);
        } else {
            setIsPopup20221124Reward30pAndSNSShow(true);
        }

    }, []);



    return (
        <>
            {
                process.env.REACT_APP_MODE === 'development.local'
                ?
                    <div>
                        <GetItOn />
                    </div>
                    

                :
                    <div>
                    {
                        false
                        ?
                            <div>
                                <LastestTradingHistory/>
                                <MainBanner/>
                                {/** Metalive app download for Android */}
                                <GetItOn />
                                <News/>
                                <ThemeArea/>
                            </div>
                        :
                            <Home/>
                    }
                    <Footer/>
                    </div>
                
            }
          
            

            { false && process.env.REACT_APP_MODE === 'development' && isPopup20221124Reward30pAndSNSShow &&

                <EventPopup
                setIsPopupShow={setIsPopup20221124Reward30pAndSNSShow}
                popupTitle={"이벤트 안내"}
                popupImageUrl={"images/popup/popup-20230301-01.jpg"}
                popupImageClickLink={"/events/event20221224"}
                popupImageClickLinkTarget={"_self"}
                showsHidingCheckBoxForADay={false}
                >
                    <div className="popup-20230301-01">
                        <ul>
                            <li>
                                <Link to="/events/event20221224">
                                    <button className="event-join-button" type="button">{"자세히보기"}</button>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </EventPopup>
            }
        </>
    )
}

export default Main;
