import i18n from "i18next";
import {initReactI18next } from "react-i18next";
import queryString from 'query-string';
import korean from './languages/ko.json';
import english from './languages/en.json';
import chinese from './languages/cn.json';
import { Cookies } from "react-cookie";
import { isNull } from "../../../apis/Validation";


const resource = {
    ko: {
        translation: korean
    },
    en: {
        translation: english
    },
    cn: {
        translation: chinese
    }
}

// query string 으로 접근시
const parsed = queryString.parse(window.location.search);

let language = "";
if(parsed.lang) {
    language = parsed.lang;
    localStorage.setItem("locale", language);
} else {
    language = localStorage.getItem("locale");
    if(isNull(language)){
        localStorage.setItem("locale", "ko");
        language = "ko";
    }
}

console.log(`current language=${language}`);

const cookies = new Cookies();
cookies.set("locale", language, {
    path: "/",
    secure: true,
    sameSite: "none"
});

i18n
    .use(initReactI18next)
    .init({
        resources: resource,
        lng: [language],
        fallbackLng: "ko", // 초기화값
        debug: false,
        keySeparator: false,
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
