import React from "react";
import { t } from "i18next";
import styled from "styled-components";

import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { addComma } from "../../apis/CommonFunc";

const ModalDiv = styled.div`
    width:320px;
    height:630px;
    position:relative;
    background:#fff;
`;

const ItemInfoDiv = styled.div`
    width:100%;
    height: auto;
    background:#F8F8F8;
    padding: 20px 20px 10px 20px;
    >p{
        font-family:'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
        font-size:14px;
        font-style:normal;
        font-weight:700;
        line-height:14px;
        letter-spacing:0em;
        text-align:left;
        color:#05141F;
        text-decoration:underline;
        text-underline-position:under;
        margin-bottom:30px;
    }
    >span{
        font-family:'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 800;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
    }
    >div{
        font-family:'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 14px;
        letter-spacing: -0.02em;
        text-align: left;
        color: #888;
        margin: 30px 0px;
        P{
            margin-top: 12px;
        }
    }
`;

const CloseBtnSpan = styled.div`
    position:absolute;
    width:20px;
    height:20px;
    top:-15px;
    right:15px;
    cursor:pointer;
    display:flex;
    align-items:center;
    justify-content:center;
    &::before{
        position:absolute;
        content:"";
        width:20px;
        height:1px;
        background:#000;
        transform:rotate(45deg);
        transition:.3s;
    }
    &::after{
        position:absolute;
        content:"";
        width:20px;
        height:1px;
        background:#000;
        transform:rotate(-45deg);
        transition:.3s;
    }
    &:hover{
        &::before{
            transform: rotate(0deg);
            transition: .3s;
        }
        &::after{
            transform: rotate(0deg);
            transition: .3s;
        }   
    }
`;

const PurchaseInfoDiv = styled.div`
    width: 100%;
    height: 355px;
    background: #fff;
    box-shadow: 0px -2px 6px 0px #90909033;
    padding: 20px;
`;

// 2022-04-19 기준 > 추천매물에서 지분 거래 가능한 매물 클릭시 등장하는 modal 창
const ModalSmall = ({title, data, closeModal, children}) => {
    const RATIO_FOR_SALE = Number(data.offRatio) * 100;

    return (
        <ModalDiv>
            <ItemInfoDiv>
                <CloseBtnSpan onClick={closeModal}/>
                <p>{ title }</p>
                <span>{localStorage.getItem('locale') !== 'ko' ? data.addressJibunEn : data.address}</span>
                <div>
                    {data.areaSter && <p>{t('recommended_item_area')}: {addComma(data.areaSter)} Ster</p>}
                    <div style={{display: 'flex', alignItems:'center'}}>
                        <p style={{marginRight: '5px'}}>{t('recommended_price_one_share')}</p>                    
                        {RATIO_FOR_SALE !== 0 
                        ?
                            <>
                                <p style={data.offPriceMetarexUnit && {textDecoration:"line-through", color:"#FF645A"}}>{addComma(data.priceMetarexUnit)} {t('recommended_item_won')}</p>
                                <FontAwesomeIcon icon={faLongArrowAltRight} style={{margin:"12px 10px 0px 10px"}}/>
                                <p>{addComma(data.offPriceMetarexUnit)} {t('recommended_item_won')}</p>
                            </>
                        :   <p>{addComma(data.priceMetarexUnit)} {t('recommended_item_won')}</p>
                        }
                    </div>
                </div>
            </ItemInfoDiv>
            <PurchaseInfoDiv>
                {children}
            </PurchaseInfoDiv>
        </ModalDiv>
    );
}

export default ModalSmall;