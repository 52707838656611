import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import styled from "styled-components";

import PageBanner from '../../components/PageBanner';
import Paying from '../../feature/BuyHistory/Paying';
import Footer from '../../feature/Footer/Footer';
import HistoryBox from '../../feature/BuyHistory/HistoryBox';

import { axiosGet } from '../../apis/AxiosAPI';
import { isSessionValidate, isValidationSessionId } from '../../apis/BrowserStorageAPI';
import { moveTopOnPageAuto } from '../../apis/CommonFunc';

const MyDiv = styled.div`
    .buyHistoryContainer{
        width: 1310px; 
        height: 100%; 
        margin: 0 auto; 
        padding-top: 130px;
        margin-bottom: 100px;
        position: relative;
        @media only screen and (max-width: 768px) {
            width: 100%; 
            height: auto; 
            padding-top: 0px;
            top: 0;
            left: 0;
            background: #fff;
        }

        .metalive-description {
            @media only screen and (max-width: 768px) {
                font-size: 13px;
                padding: 20px;
                text-align: justify;
            }

            .download-meta-live-wrapper {
                padding-top: 20px;
                @media only screen and (max-width: 768px) {
                    text-align: left;
                }   
            }
        }

        
    }
`;

const BuyHistory = (props) => {
    // width값 토대로 모바일 pc 구분.
    const [buyHistoryWidth , setBuyHistoryWidth] = useState(false);

    // 모바일 사이즈 확인
    const buyResizeHandler = () => {
        if(window.innerWidth < 769) {
            setBuyHistoryWidth(true);
        } else if (window.innerWidth > 769){
            setBuyHistoryWidth(false);
        }
    };
    
    /**
     * 화면 로딩 직후, 화면 리사이즈 이벤트
     */
    useEffect(() => {
        window.addEventListener('load', buyResizeHandler);
        window.addEventListener('scroll', buyResizeHandler);
        window.addEventListener('resize', buyResizeHandler);
        
        return () => {
            window.removeEventListener('load', buyResizeHandler);
            window.removeEventListener('scroll', buyResizeHandler);
            window.removeEventListener('resize', buyResizeHandler);
        } 
    }, [buyHistoryWidth , buyResizeHandler]);

    // axios 데이터 넣음
    const [buyAxios , setBuyAxios] = useState(undefined);

    // axios 데이터 넣음
    const [payingAxios , setPayingAxios] = useState(undefined);
    // 총개수
    const [buyTotalCount , setBuyTotalCount] = useState(0);
    // 총개수
    const [payingTotalCount , setPayingTotalCount] = useState(0);
    
    // pc버전 클릭여부에 따라 스타일값 초기화
    const [buyClickCount , setBuyClickCount] = useState(0);
    
    // 페이징
    const [buyPagePcNum, setBuyPagePcNum] = useState(1);
    const [buyPageMobileNum, setBuyPageMobileNum] = useState(1);
    // 페이징
    const [payingPagePcNum, setPayingPagePcNum] = useState(1);
    const [payingPageMobileNum, setPayingPageMobileNum] = useState(1);
    
    // 모바일 슬라이더 버튼 클릭 회수
    const [historyMobileClick , setHistoryMobileClick] = useState(1);
    const [payingMobileClick , setPayingMobileClick] = useState(1);
    
    // pc 슬라이더 버튼 클릭 회수
    const [payingPcClick , setPayingPcClick] = useState(1);
    const [historyPcClick , setHistoryPcClick] = useState(1);

    useEffect(() => {
        if(!isSessionValidate()) {
            alert(t('market_view_login'));
            window.location.href ='/signin';
            return;
        }
        isValidationSessionId()
            .then(() => {
                if(!isSessionValidate()) {
                    window.location.href ='/signin';
                    return;
                }
            });
        moveTopOnPageAuto();
    }, []);
    
    useEffect(() => {
        let buyUrl = (pageNum , numPerPage) =>  `realestate/getMyOwn?pageNum=${encodeURI(pageNum)}&numPerPage=${encodeURI(numPerPage)}`
        //buyPageMobileNum,buyPagePcNum 이렇게 모바일,pc를 따로 페이지수를 나눔. 매물개수는 모바일일때는 1개를, pc일때는 3개씩 매물을 나타나게 한다.
        axiosGet(buyUrl(buyHistoryWidth === true ? buyPageMobileNum : buyPagePcNum  ,  buyHistoryWidth === true ? 1 : 3 ))
            .then(response => {
                if(JSON.parse(response.data.result)){
                    // 모바일인지 pc인지 데이터를 받아오기전에 판별하기 위해 이곳에 buyResizeHandler() 함수 적용
                    buyResizeHandler()
                    // 받아온 데이터를 저장, 데이터가 없을시 null처리
                    setBuyAxios(response.data.data ? response.data.data : null);
                    // totalCount값을 저장
                    setBuyTotalCount(response.data.totalCount);
                } 
            }).catch(e => {
                console.log(`error message: ${e}`);
            });
    }, [ setBuyAxios , buyHistoryWidth , historyMobileClick , historyPcClick  , setBuyTotalCount]);
    
    let buyHistoryArray = []
    buyHistoryArray.push(buyAxios)

    useEffect(() => {
        let payingUrl = (pageNum , numPerPage) => `api/realestate/paying?pageNum=${encodeURI(pageNum)}&numPerPage=${encodeURI(numPerPage)}`
        
        let res = axiosGet(payingUrl( buyHistoryWidth === true ? payingPageMobileNum : payingPagePcNum  ,  buyHistoryWidth === true ? 1 : 3 )) 
        .then(response => {
            if(JSON.parse(response.data.result)){
                setPayingAxios(response.data.data ? response.data.data : null);
                setPayingTotalCount(response.data.totalCount);
            }
        }).catch(e => {
            console.log(`error message: ${e}`);
        });
    }, [ setPayingAxios , buyHistoryWidth , payingMobileClick , payingPcClick  ]);
    
    let payingHistoryArray = []
    payingHistoryArray.push(payingAxios)

    return(
        <MyDiv style={{paddingTop: '60px'}}>
            <PageBanner style={{backgroundImage: "url('/images/bg/buying_banner.jpg')"}}>{t('header_purchase_history')}</PageBanner>
            <section className="buyHistoryContainer">
                {buyHistoryArray.map((data, i) => 
                (
                    <HistoryBox
                        key={i} 
                        historyData={data} 

                        // 화살표 클릭 개수 가져옴
                        buyClickCount={buyClickCount}
                        setBuyClickCount={setBuyClickCount}
                        
                        // 페이징 pc
                        setBuyPagePcNum={setBuyPagePcNum}
                        buyPagePcNum={buyPagePcNum}

                        // 페이징 mobile
                        buyPageMobileNum = {buyPageMobileNum}
                        setBuyPageMobileNum = {setBuyPageMobileNum}

                        buyHistoryWidth={buyHistoryWidth}
                        buyTotalCount={buyTotalCount}

                        // 클릭 모바일
                        setHistoryMobileClick={setHistoryMobileClick}
                        historyMobileClick={historyMobileClick}

                        // 클릭 pc
                        historyPcClick={historyPcClick}
                        setHistoryPcClick={setHistoryPcClick}

                        buyHistoryArray={buyHistoryArray}
                    />
                ))}                
                <div>
                    <div className="metalive-description">
                        <p className="what-is-this-function-description">
                        {t('the_purchased_land_will_be_provided_with_an_indoor_space_on_meta_live_in_the_additionally_implemented_interior_a_function_to_experience_the_purple_camera_will_be_provided')}
                        </p>
                        <div className="download-meta-live-wrapper">
                            <a href="https://www.metalive.kr/" target="_blank">메타라이브 다운로드하기 (링크 클릭) https://www.metalive.kr/</a>
                        </div>
                    </div>
                </div>



                {payingHistoryArray.map((data, i) => 
                (
                    <Paying
                        key={i} 
                        historyDatas={data} 

                        // 화살표 클릭 개수 가져옴
                        buyClickCount={buyClickCount}
                        setBuyClickCount={setBuyClickCount}
                        
                        // 페이징 pc
                        setPayingPagePcNum={setPayingPagePcNum}
                        payingPagePcNum={payingPagePcNum}

                        // 페이징 mobile
                        payingPageMobileNum = {payingPageMobileNum}
                        setPayingPageMobileNum = {setPayingPageMobileNum}

                        buyHistoryWidth={buyHistoryWidth}
                        payingTotalCount={payingTotalCount}

                        // 클릭 모바일
                        setPayingMobileClick={setPayingMobileClick}
                        payingMobileClick={payingMobileClick}

                        // 클릭 pc
                        payingPcClick={payingPcClick}
                        setPayingPcClick={setPayingPcClick}

                        payingHistoryArray={payingHistoryArray}
                    />
                ))}
            </section>
            <Footer />
        </MyDiv>
    )
}

export default BuyHistory;