import React from "react";
import styled from "styled-components";

const AuthNavForm = styled.div`
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    padding-top: 30px;
    div {
        width: 320px;
        display: flex;
        justify-content: space-between;
        text-align: center;
        a {
            width: 33%;
            color: #595959;
            font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: 0em;
            border-right: 1px solid #D9DDE2;
            display: flex;
            justify-content: center;
            align-items: center;
            &:hover {
                color: #3D85F0;
                transition: 0.5s;
            }
            &:last-child {
                border: none;
            }
        }
    }
`;

const AuthNav = ({children}) => {
    return (
        <AuthNavForm>
            <div>
                {children}
            </div>
        </AuthNavForm>
    );

}

export default AuthNav;