const { kakao } = window;

/**
 * 주소 목록 검색
 * @param {*} keyword 검색 키워드
 * @param {*} callback 검색 후 콜백함수
 */
const searchAddressList = (keyword, callback) => {
    const kw = !keyword ? ' ' : keyword;

    const kakaoMapPlace = new kakao.maps.services.Places();

    kakaoMapPlace.keywordSearch(kw, (data, status, pagination) => {
        if(callback && status) {
            const returnParams = {
                data: data,
                status: status,
                pagination: pagination
            };

            callback(returnParams);
        }
    });
};

export { searchAddressList };