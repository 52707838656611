import React, { useLayoutEffect, useState } from "react";
import styled from "styled-components";
import { t } from "i18next";

import { addComma } from "../../../apis/CommonFunc";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";

const BuyRealestateDiv = styled.div`
    width: 100%;
    height: 100%;
    .buttons{
        height: 100px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        position: absolute;
        bottom: 20px;
        
    }
    >span{
        display: block;
        font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
        font-size: 13px;
        color: #3D85F0;
        margin-top: 5px;
    }
    >p{
        font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
        font-size: 13px;
        color: #FF645A;
        margin-top: 5px;
    }
`;

const ContentDiv = styled.div`
    width: 100%;
    height: auto;
    min-height: 30px;
    display: flex;
    justify-content: space-between;
    align-items:center;
    p{
        font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
        font-size: 14px;
        color: #05141F;
    }
`;

const StyledLabel = styled.label`
    font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    color: #05141F;
`;

const StyledButton = styled.button`
    width: 280px;
    height: 44px;
    border: none;
    background: #05141F;
    color: #fff;
    display: flex;
    justify-content:center;
    align-items:center;
    cursor: pointer;
    &:hover{
        transition: .3s;
        background:#3D85F0;
    }
    &:disabled{
        background: #ccc;
        cursor: auto;
    }
`;

const StyledSelect = styled.select`
    box-shadow: 0px 2px 2px 0px #90909033;
    border: 1px solid #EEE;
    width:60px;
    height:25px;
    padding: 0px 10px;
    border-radius: 4px;
    box-sizing: border-box;
    &:focus {
        outline: none
    }
    option {
        height: 20px;
    }
`;

// modal 페이지에 들어가는 content - 추천매물 > 지분구매 modal
const BuyRealestate = ({closeModal, item, ownAmount, onClickBuy, reservedAmount}) => {
    const [amount, setAmount] = useState(1);                            // 구매하려는 구매수량 - select box에서 선택한 숫자
    const [options, setOptions] = useState([]);                         // 구매수량의 select box list 값
    const [availableBuyAmount, setAvailableBuyAmount] = useState(1);    // 구매 가능 수량

    const LIMITED_MIN = 100000; // 구매가능 최소 금액 설정
    const RATIO_FOR_SALE = Number(item.offRatio) * 100; // 할인율
    const REMAIN_SHARES = Number(item.totalUnit) - Number(item.soldoutNum) - Number(item.reservationsNum);  // 남은 주식 수량 개수

    useLayoutEffect(() => {
        const perShares = (item.quantityLimitPerPerson) - Number(ownAmount) - Number(reservedAmount);
        setAvailableBuyAmount(REMAIN_SHARES < (item.quantityLimitPerPerson) - +ownAmount - +reservedAmount ? REMAIN_SHARES : perShares);
        printSelectBox(availableBuyAmount);
    }, [item, ownAmount, reservedAmount, availableBuyAmount]);

    const printSelectBox = () => {
        const list = [];

        if(availableBuyAmount <= 0) {
            setAmount(0);
            setAvailableBuyAmount(0);
        }

        for(let i = 1; i <= availableBuyAmount; i++) {
            list.push(<option value={i} key={i}>{i}</option>);
        } 
        setOptions(list);
    }


    return (
        <BuyRealestateDiv>
            <ContentDiv>
                <StyledLabel htmlFor="buying_amount">{t('recommended_buying_amount')}</StyledLabel>
                <p id="buying_amount">{availableBuyAmount} {t('recommended_item_stock')}</p>
            </ContentDiv>
            <ContentDiv>
                <StyledLabel htmlFor="own_amount">{t('recommended_own_amount')}</StyledLabel>
                <p id="own_amount">{ownAmount} {t('recommended_item_stock')}</p>
            </ContentDiv>
            <ContentDiv>
                <StyledLabel htmlFor="reserved_amount">{t('recommended_waiting_amount')}</StyledLabel>
                <p id="reserved_amount">{+(item.quantityLimitPerPerson) < +reservedAmount ? item.quantityLimitPerPerson : reservedAmount} {t('recommended_item_stock')}</p>
            </ContentDiv>
            <ContentDiv>
                <StyledLabel htmlFor="amount">{t('recommended_item_amount')}</StyledLabel>
                <StyledSelect id="amount" name="amount" onChange={(e) => setAmount(e.target.value)} disabled={availableBuyAmount <= 0}>
                    {options.map(el => el)}
                </StyledSelect>
            </ContentDiv>
            <ContentDiv>
                <StyledLabel>{t('recommended_item_price')}</StyledLabel>
                    <div style={{display:"flex", alignItems:"center"}}>
                        
                        {
                            RATIO_FOR_SALE !== 0 
                                ? <p style={{textDecoration:"line-through", color:"#FF645A"}}>{0 < availableBuyAmount ? addComma(item.priceMetarexUnit * +(amount)) : 0} {t('recommended_item_won')}</p>
                                : <p>{0 < availableBuyAmount ? addComma(item.priceMetarexUnit * +(amount)) : 0} {t('recommended_item_won')}</p>
                        }
                        {
                            RATIO_FOR_SALE !== 0 &&
                                <>
                                <FontAwesomeIcon icon={faLongArrowAltRight} style={{margin:"0px 10px"}}/>
                                <p>{0 < availableBuyAmount ? addComma(item.offPriceMetarexUnit * +(amount)) : 0} {t('recommended_item_won')}</p>
                                </>
                        }
                    </div>
            </ContentDiv>
            <span>(* {t('recommended_item_warning')})</span>
            <p>{item.priceMetarexUnit * +(amount) < LIMITED_MIN && t('recommended_item_price_min')}</p>
            <div className="buttons">
                <StyledButton onClick={() => onClickBuy(amount)} disabled={item.priceMetarexUnit * +(amount) < LIMITED_MIN}>{item.priceMetarexUnit * +(amount) < LIMITED_MIN ? '구매 가능한 수량이 없습니다' : t('metarex_common_purchase')}</StyledButton>
                <StyledButton onClick={closeModal}>{t('metarex_common_cancel')}</StyledButton>
            </div>
        </BuyRealestateDiv>
    );
}

export default BuyRealestate;