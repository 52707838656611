import React, { useEffect, useState } from "react";
import styled from "styled-components";

const StyledButton = styled.button`
  outline: none;
  border: none;
  border-radius: 8px;
  color: white;
  font-weight: bold;
  cursor: pointer;
  padding-left: 1rem;
  padding-right: 1rem;
  text-align: center;

  height: 5rem;
  font-size: 1rem;

  background: #228be6;
  &:hover {
    bakground: #339af0;
  }
  &:active {
    background: #1c7ed6;
  }

  & + & {
    margin-top: 3rem;
  }
`;

function SocialButton2({ children, ...rest }) {
  return <StyledButton {...rest}>{children}</StyledButton>;
}

export default SocialButton2;
