import { applyMiddleware, compose, createStore } from "redux";
import logger from 'redux-logger'
import { persistStore } from "redux-persist";
import rootReducer from "./rootReducer";

let getStore;
if(process.env.REACT_APP_MODE === 'development') {
    getStore = createStore(rootReducer, compose(
        applyMiddleware(logger),
        typeof window.__REDUX_DEVTOOLS_EXTENSION__ === 'undefined'
        ? a => a
        : window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    ));
} else {
    getStore = createStore(rootReducer);
}


export const store = getStore;
export const persistor = persistStore(store);