import { 
    searchAddrFromCoords, 
    searchDetailAddrFromCoords, 
    displayCenterInfo,
} from './KakaoMapScriptUtil';
import { AxiosUrl } from './axiosUrl';
import { axiosGet, axiosPost } from "../../../apis/AxiosAPI";
import KakaoMapLoading from './KakaoMapLoading';
import { 
    CustomOverlay
} from './customOverlay.js'

import { 
    requestBuyItem
} from '../sideInfo/sideInfoUtil';

const { kakao } = window; // 이걸 해줘야 카카오 api 에서 사용하는 변수들을 리엑트가 알 수 있다.

export default function KakaoMapScript(keyword, setSearchType, setAddress, isMobile, clickedSoldoutItem, setKeywordData, keywordData, setIsPopupPurchaseRequisitionShow) {


    // 지도에 표시할 선을 생성합니다
    // const polyline = new kakao.maps.Polyline({
    //     path: [], // 선을 구성하는 좌표배열 입니다
    //     strokeWeight: 5, // 선의 두께 입니다
    //     strokeColor: '#FFAE00', // 선의 색깔입니다
    //     strokeOpacity: 0.7, // 선의 불투명도 입니다 1에서 0 사이의 값이며 0에 가까울수록 투명합니다
    //     strokeStyle: 'solid', // 선의 스타일입니다
    // });
    // 지도에 표시할 다각형(영역)
    const polyline = new kakao.maps.Polygon({
        path:[], // 그려질 다각형의 좌표 배열입니다
        strokeWeight: 3, // 선의 두께입니다
        strokeColor: '#1E90FF', // 선의 색깔입니다
        strokeOpacity: 0.8, // 선의 불투명도 입니다 1에서 0 사이의 값이며 0에 가까울수록 투명합니다
        strokeStyle: 'solid', // 선의 스타일입니다

        // kakaomap.jsx에서 받아온 데이터값을 토대로. 구매매물이면 무슨색, 테마매물이면 무슨색 이런식으로 조건문 작성.
        fillColor: '#ACF3FF', // 채우기 색깔입니다
        fillOpacity: 0.3 // 채우기 불투명도 입니다
    });
    
    const container = document.getElementById("myMap"); // 가이드는 Map이다
    // 카카오맵을 로드하기전 카카오맵을 세팅할 태그의 자식 요소들을 모두 제거한다.
    while(container.firstChild) {
        container.removeChild(container.firstChild);
    }

    const options = {
        center: new kakao.maps.LatLng(37.48647151051701, 127.02065165954758),
        level: isMobile ? 1 : 2,
    };

    let map = new kakao.maps.Map(container, options);

    // gps
    // HTML5의 geolocation으로 사용할 수 있는지 확인합니다
                                               
    //개발 서버라면,,
    if( true && process.env.REACT_APP_MODE === 'development' ) {            
        let lat = 38.0225070235, // 위도
            lon = 127.0697109745; // 경도
        
        let locPosition = new kakao.maps.LatLng(lat, lon); // 마커가 표시될 위치를 geolocation으로 얻어온 좌표로 생성합니다
        
        map.setCenter(locPosition);

    } else if (navigator.geolocation) {
        // GeoLocation을 이용해서 접속 위치를 얻어옵니다
        navigator.geolocation.getCurrentPosition(function(position) {
            
            let lat = position.coords.latitude, // 위도
                lon = position.coords.longitude; // 경도
            
            let locPosition = new kakao.maps.LatLng(lat, lon); // 마커가 표시될 위치를 geolocation으로 얻어온 좌표로 생성합니다
            
            map.setCenter(locPosition);      
        });
        
    } else { // HTML5의 GeoLocation을 사용할 수 없을때 마커 표시 위치와 인포윈도우 내용을 설정합니다
        //alert("gps 정보가 없습니다") 
    }

    // map 기본타입을 위성사진으로
    map.setMapTypeId(kakao.maps.MapTypeId.HYBRID);

    // 일반 지도와 스카이뷰로 지도 타입을 전환할 수 있는 지도타입 컨트롤을 생성합니다
    let mapTypeControl = new kakao.maps.MapTypeControl();

    // 지도에 컨트롤을 추가해야 지도위에 표시됩니다
    // kakao.maps.ControlPosition은 컨트롤이 표시될 위치를 정의하는데 TOPRIGHT는 오른쪽 위를 의미합니다
    map.addControl(mapTypeControl, kakao.maps.ControlPosition.TOPRIGHT);

    
    // 주소-좌표 변환 객체를 생성합니다
    let geocoder = new kakao.maps.services.Geocoder();

    let marker = new kakao.maps.Marker(); // 클릭한 위치를 표시할 마커입니다

    // 현재 지도 중심좌표로 주소를 검색해서 지도 좌측 상단에 표시합니다
    searchAddrFromCoords(geocoder, map.getCenter(), displayCenterInfo);


    // 지도를 클릭했을 때 클릭 위치 좌표에 대한 주소정보를 표시하도록 이벤트를 등록합니다
    kakao.maps.event.addListener(map, 'click',  function(mouseEvent) {
        searchDetailAddrFromCoords(geocoder, mouseEvent.latLng, async function(result, status) {
            
            if (status === kakao.maps.services.Status.OK) {
                // 도로명 주소가 없는 경우 라인셋, 마커, 상세정보 출력 등의 처리를 하지 않음. 
                if(result[0].road_address === null) {
                    return;
                }
                
                setAddress(result[0]);
                setSearchType('mapClick');

                // 마커를 클릭한 위치에 표시합니다 
                marker.setPosition(mouseEvent.latLng);
                marker.setMap(map);

                const detail = !!result[0].address ? result[0].address.address_name : '  ';
                lineSet(detail, polyline, map, isMobile, clickedSoldoutItem, setKeywordData);
                removeLineSet(result[0], polyline);
                
            }
        });
    });

    {/*판매 완료된 매물 표시하는 라인 - 내꺼 아님*/}
    const polylineSoldout = new kakao.maps.Polygon({
        path:[], // 그려질 다각형의 좌표 배열입니다
        strokeWeight: 3, // 선의 두께입니다
        strokeColor: '#ffffff', // 선의 색깔입니다
        strokeOpacity: 0.8, // 선의 불투명도 입니다 1에서 0 사이의 값이며 0에 가까울수록 투명합니다
        strokeStyle: 'solid', // 선의 스타일입니다

        // kakaomap.jsx에서 받아온 데이터값을 토대로. 구매매물이면 무슨색, 테마매물이면 무슨색 이런식으로 조건문 작성.
        fillColor: '#ACF3FF', // 채우기 색깔입니다
        fillOpacity: 0.3 // 채우기 불투명도 입니다
    });

    {/*판매 완료된 매물 표시하는 라인 - 내꺼 임.*/}
    const polylineSoldoutMine = new kakao.maps.Polygon({
        path:[], // 그려질 다각형의 좌표 배열입니다
        strokeWeight: 3, // 선의 두께입니다
        strokeColor: '#a88817', // 선의 색깔입니다
        strokeOpacity: 0.8, // 선의 불투명도 입니다 1에서 0 사이의 값이며 0에 가까울수록 투명합니다
        strokeStyle: 'solid', // 선의 스타일입니다

        // kakaomap.jsx에서 받아온 데이터값을 토대로. 구매매물이면 무슨색, 테마매물이면 무슨색 이런식으로 조건문 작성.
        fillColor: '#a88817', // 채우기 색깔입니다
        fillOpacity: 0.3 // 채우기 불투명도 입니다
    });

    const forLineSet = {
        setAddress,
        setSearchType,
        polyline, 
        isMobile,
        clickedSoldoutItem,
        setKeywordData,
        keywordData,
        setIsPopupPurchaseRequisitionShow
    }



    // 지도가 확대 또는 축소되면 마지막 파라미터로 넘어온 함수를 호출하도록 이벤트를 등록합니다
    kakao.maps.event.addListener(map, 'zoom_changed', function() {        
        
        // 지도의 현재 레벨을 얻어옵니다
        var level = map.getLevel();
        
        var message = '현재 지도 레벨은 ' + level + ' 입니다';
        //console.log(message);

        getInfo(map);
        getItemsOnScreen(polylineSoldout, polylineSoldoutMine, map, forLineSet);
        
    });

    // 마우스 드래그로 지도 이동이 완료되었을 때 마지막 파라미터로 넘어온 함수를 호출하도록 이벤트를 등록합니다
    kakao.maps.event.addListener(map, 'dragend', function() {        
        
        getInfo(map);
        getItemsOnScreen(polylineSoldout, polylineSoldoutMine, map, forLineSet);
        
    });

    lineSet(keyword, polyline, map, isMobile, clickedSoldoutItem, setKeywordData);

    //CustomOverlay(map);

    return {
        changeLineSet: kw => lineSet(kw, polyline, map, isMobile, clickedSoldoutItem, setKeywordData),
        changeKakaoLineSet: (kw, lat, lng) => kakaoLineSet(kw, polyline, map, lat, lng),
        kakaoMapPanTo: (lat, lng) => kakaoMapPanTo(map, lat, lng, isMobile),
        // // 커스텀 오버레이 부분 
        // coustomLine: (lat, lng) => coustomLine( polyline, map, lat, lng, data),
    };
}

let markers = [];
let selectedMarker = null;
{/** 판매 완료된 매물에 마커 표기 */}
const soldoutMarker = (map, positions, forLineSet) => {

    // 마커 이미지의 이미지 주소입니다
    var imageSrc = "https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/markerStar.png"; 
        
    for (var i = 0; i < positions.length; i ++) {
        
        // 마커 이미지의 이미지 크기 입니다
        var imageSize = new kakao.maps.Size(24, 35); 
        
        // 마커 이미지를 생성합니다    
        var markerImage = new kakao.maps.MarkerImage(imageSrc, imageSize, {alt: positions[i].addressJibun}); 
        
        // 마커를 생성합니다
        markers.push(
            new kakao.maps.Marker({
                map: map, // 마커를 표시할 지도
                position: positions[i].latlng, // 마커를 표시할 위치
                title : positions[i].addressJibun, // 마커의 타이틀, 마커에 마우스를 올리면 타이틀이 표시됩니다
                image : markerImage, // 마커 이미지
                addressJibun: positions[i].addressJibun // 지번 주소
            })
        );
        
        // 커스텀 오버레이에 표시할 컨텐츠 입니다
        // 커스텀 오버레이는 아래와 같이 사용자가 자유롭게 컨텐츠를 구성하고 이벤트를 제어할 수 있기 때문에
        // 별도의 이벤트 메소드를 제공하지 않습니다 
        var content = 
        `<div class="wrap">` + 
        `    <div class="info">` +
        `        <div class="title">` +
        `            <span style="color:#222222; background-color: rgba(255,255,255,0.8); padding: 10px 15px; position: absolute; top: 8px; left:-50px; border-radius: 20px;">SOLD OUT</span>` +
        `            <div class="close" onclick="closeOverlay()" title="닫기"></div>` +
        `        </div>` +
        `    </div>` +
        `</div>`;
        
        // 마커 위에 커스텀오버레이를 표시합니다
        // 마커를 중심으로 커스텀 오버레이를 표시하기위해 CSS를 이용해 위치를 설정했습니다
        if( true ) {
            var overlay = new kakao.maps.CustomOverlay({
                content: content,
                map: map,
                position: markers[markers.length-1].getPosition()       
            });
        }
        addSoldoutMarkerClickEvent(markers[markers.length-1], map, forLineSet);
        
    }
}

function addSoldoutMarkerClickEvent(marker, map, forLineSet) {
    // SOLD OUT 마커를 클릭했을 때 커스텀 오버레이를 표시합니다
    kakao.maps.event.addListener(marker, 'click', function() {
        //console.log('thkim 20221128 17:18 %o', marker.getImage());
        //thkim 20221128 주소를 marker로 부터가져와야 함.         

        //개발 서버라면,,
        if( true && process.env.REACT_APP_MODE === 'development' ) {            
            //console.log('thkim 20221128 17:18 %o\n%o\n%o\n%o', marker, marker.getImage(), marker.getTitle(), getAllFuncs(marker));
            //alert(marker.getTitle());  ^
        }

        // 클릭된 마커가 없고, click 마커가 클릭된 마커가 아니면
        // 마커의 이미지를 클릭 이미지로 변경합니다
        if (!selectedMarker || selectedMarker !== marker) {
        }

        const addressObj = {
            "road_address": {
                "address_name": marker.getTitle()
            },
            "address": {
                "address_name": marker.getTitle()
            }
        }

        const areURequestBuyItem = true;
        forLineSet.setAddress(addressObj);
        forLineSet.setSearchType('mapClick');
        lineSet(marker.getTitle(), forLineSet.polyline, map, forLineSet.isMobile, forLineSet.clickedSoldoutItem, forLineSet.setKeywordData, areURequestBuyItem, forLineSet);

        // 클릭된 마커를 현재 클릭된 마커 객체로 설정합니다
        selectedMarker = marker;
        
    });
}


function getAllFuncs(toCheck) {
    const props = [];
    let obj = toCheck;
    do {
        props.push(...Object.getOwnPropertyNames(obj));
    } while (obj = Object.getPrototypeOf(obj));
    
    return props.sort().filter((e, i, arr) => { 
       if (e!=arr[i+1] && typeof toCheck[e] == 'function') return true;
    });
}

// 커스텀 오버레이를 닫기 위해 호출되는 함수입니다 
function closeOverlay() {
    //overlay.setMap(null);
    alert("닫아야 함");
}

/**
 * 카카오맵의 라인셋을 제거
 */
const removeLineSet = (address, polyline) => {
    if(address.road_address === null) {
        polyline.setMap(null);
    }
};

/**
 * 카카오맵 매물 테두리 및 이동
 */
const kakaoMapPanTo = (map, lat, lng, isMobile) => {
    let moveLatLng = new kakao.maps.LatLng(lat , lng );
    if( true && process.env.REACT_APP_MODE === 'development' ) {
    }
    map.panTo(moveLatLng);
};

/**
 * 검색, 검색목록 클릭시 
 */
const kakaoLineSet = async (keyword, polyline, map, lat, lng, isMobile) => {
    
    let res = await AxiosUrl(keyword)

    if(!!res.data.estimated_price){
        const other_parcel = res.data.estimated_price.other_parcel
        let focusingCoord       = null;
        let totalLinePath       = [];
        for( let idx in other_parcel) {
            const coord = res.data.estimated_price.other_parcel[idx].geometry.coordinates[0];
            //console.log('%o 20220919 1335', coord);
            const linePath = coord.map( ([x,y]) => {
                return new kakao.maps.LatLng(y,x);
            });
            totalLinePath.push(linePath);
            focusingCoord = coord;
        }
        
        if(res !== null){
            polyline.setPath(totalLinePath);
            polyline.setMap(map);
            kakaoMapPanTo(map, focusingCoord[0][1], focusingCoord[0][0], isMobile);
        }else {
            polyline.setMap(null);
        }
    } else {
        kakaoMapPanTo(map, lat, lng, isMobile);
    }
};


/**
 * 맵 클릭시 영역표시
 */
const lineSet = async (keyword, polyline, map, isMobile, clickedSoldoutItem, setKeywordData, areURequestBuyItem, forLineSet) => {
    
    let res = await AxiosUrl(keyword)

    if(!!res.data.estimated_price){
        setKeywordData(res.data);
        if( typeof areURequestBuyItem !== undefined && areURequestBuyItem ) {
            requestBuyItem(keyword, res.data, forLineSet.setIsPopupPurchaseRequisitionShow);
        }
        
        const other_parcel = res.data.estimated_price.other_parcel
        let focusingCoord       = null;
        let totalLinePath       = [];
        for( let idx in other_parcel) {
            const coord = res.data.estimated_price.other_parcel[idx].geometry.coordinates[0];
            //console.log('%o 20220919 1338', coord);
            const linePath = coord.map( ([x,y]) => {
                return new kakao.maps.LatLng(y,x);
            });
            totalLinePath.push(linePath);
            focusingCoord = coord;
        }
        if(res !== null){
            polyline.setPath(totalLinePath);
            polyline.setMap(map);

            kakaoMapPanTo(map, focusingCoord[0][1], focusingCoord[0][0], isMobile);
        }else {
            polyline.setMap(null);
        }

    } else if(res.data.parcel_info) {
        const coord = res.data.parcel_info.geometry.coordinates[0];
        const linePath = coord.map( ([x,y]) => {
            return new kakao.maps.LatLng(y,x)
        });

        if(res !== null){
            polyline.setPath(linePath);
            polyline.setMap(map);
            kakaoMapPanTo(map, coord[0][1], coord[0][0], isMobile);
        }else {
            polyline.setMap(null);
        }
    } else {
        polyline.setMap(null);
    }

    //alert('res.data.isSoldout2' + res.data.isSoldout);
    if( res && res.data && res.data.isSoldout && JSON.parse(res.data.isSoldout) ) {
        clickedSoldoutItem();
    }
    
};


function getInfo(map) {
    // 지도의 현재 중심좌표를 얻어옵니다 
    var center = map.getCenter(); 
    
    // 지도의 현재 레벨을 얻어옵니다
    var level = map.getLevel();
    
    // 지도타입을 얻어옵니다
    var mapTypeId = map.getMapTypeId(); 
    
    // 지도의 현재 영역을 얻어옵니다 
    var bounds = map.getBounds();
    
    // 영역의 남서쪽 좌표를 얻어옵니다 
    var swLatLng = bounds.getSouthWest(); 
    
    // 영역의 북동쪽 좌표를 얻어옵니다 
    var neLatLng = bounds.getNorthEast(); 
    
    // 영역정보를 문자열로 얻어옵니다. ((남,서), (북,동)) 형식입니다
    var boundsStr = bounds.toString();
    
    
    var message = '지도 중심좌표는 위도 ' + center.getLat() + ', <br>';
    message += '경도 ' + center.getLng() + ' 이고 <br>';
    message += '지도 레벨은 ' + level + ' 입니다 <br> <br>';
    message += '지도 타입은 ' + mapTypeId + ' 이고 <br> ';
    message += '지도의 남서쪽 좌표는 ' + swLatLng.getLat() + ', ' + swLatLng.getLng() + ' 이고 <br>';
    message += '북동쪽 좌표는 ' + neLatLng.getLat() + ', ' + neLatLng.getLng() + ' 입니다';
    
    // 개발자도구를 통해 직접 message 내용을 확인해 보세요.
    //console.log(message);
    
}

const getItemsOnScreen = async (polylineSoldout, polylineSoldoutMine, map, forLineSet) => {

    // 지도의 현재 영역을 얻어옵니다 
    const bounds = map.getBounds();    
    // 영역의 남서쪽 좌표를 얻어옵니다 
    const swLatLng = bounds.getSouthWest();     
    // 영역의 북동쪽 좌표를 얻어옵니다 
    const neLatLng = bounds.getNorthEast(); 

    const southWestLongitude        = Number(swLatLng.getLng());
    const southWestLatitude         = Number(swLatLng.getLat());
    const northEastLongitude        = Number(neLatLng.getLng());
    const northEastLatitude         = Number(neLatLng.getLat());
    
    getSoldoutItemsOnScreen(southWestLongitude, southWestLatitude, northEastLongitude, northEastLatitude, polylineSoldout, polylineSoldoutMine, map, forLineSet);
    //getBoraItemsOnScreen(southWestLongitude, southWestLatitude, northEastLongitude, northEastLatitude, polylineSoldout, polylineSoldoutMine, map);
}

const getSoldoutItemsOnScreen = async (southWestLongitude, southWestLatitude, northEastLongitude, northEastLatitude, polylineSoldout, polylineSoldoutMine, map, forLineSet) => {

    const MAXIMUM_LEVEL = 7;
    // 지도의 현재 레벨을 얻어옵니다
    const level = map.getLevel();

    {/* 매물이 많아지면 이 값을 줄여야 함*/}
    if( level > MAXIMUM_LEVEL ) {
        return false;
    }

    axiosGet(`api/realestate/items-on-screen?southWestLongitude=${southWestLongitude}&southWestLatitude=${southWestLatitude}&northEastLongitude=${northEastLongitude}&northEastLatitude=${northEastLatitude}`)
        .then(response => {
            if(JSON.parse(response.data.result)){
                lineSetForitemsOnScreen(polylineSoldout, polylineSoldoutMine, response.data.data, map, forLineSet);
            } else {
                alert(response.data.message);
            }
        }).catch(e => {
            console.log(`notice page error message: ${e}`);
        });
}

const getBoraItemsOnScreen = async (southWestLongitude, southWestLatitude, northEastLongitude, northEastLatitude, polylineSoldout, polylineSoldoutMine, map) => {

    const MAXIMUM_LEVEL = 13;
    // 지도의 현재 레벨을 얻어옵니다
    const level = map.getLevel();

    {/* 매물이 많아지면 이 값을 줄여야 함*/}
    if( level > MAXIMUM_LEVEL ) {
        return false;
    }

    axiosGet(`api/realestate/specify-items-on-screen?southWestLongitude=${southWestLongitude}&southWestLatitude=${southWestLatitude}&northEastLongitude=${northEastLongitude}&northEastLatitude=${northEastLatitude}`)
        .then(response => {
            if(JSON.parse(response.data.result)){
                lineSetBoraItemsOnScreen( response.data.data, map);
            } else {
                alert(response.data.message);
            }
        }).catch(e => {
            console.log(`notice page error message: ${e}`);
        });
}

const lineSetForitemsOnScreen = async (polylineSoldout, polylineSoldoutMine, data, map, forLineSet) => {
    
    if( Object.keys(data).length == 0 ) {
        return false;
    } else {
        //return false;
    }

    polylineSoldout.setMap(null);{/*라인을 지웠다가 다시 그린다*/}
    polylineSoldoutMine.setMap(null);{/*라인을 지웠다가 다시 그린다*/}

    let totalLinePath       = [];
    let totalLinePathMine   = [];

    let positions           = [];

    for( let idx in data) {
        //const obj = JSON.parse(data[idx].coordinatesBorder);
        //const coord = obj;
        const coord = JSON.parse(data[idx].coordinatesBorder);
        //console.log(`data[${idx}]%o 20220923 1022`, data[idx]);
        let isMine = false;

        if( data[idx].isMine != null && typeof data[idx].isMine !== 'undefined' && JSON.parse(data[idx].isMine) == true ) {
            isMine = true;
        }
        
        const title = `${data[idx].addressJibun}`;
        const latlng = new kakao.maps.LatLng(data[idx].latitude, data[idx].longitude);
        positions.push({
            title,
            latlng,
            addressJibun: data[idx].addressJibun
        });

        for(let idx in coord) {
            const linePath = coord[idx].map( ([x,y]) => {
                return new kakao.maps.LatLng(y,x);
            });
            if( isMine == false ) {
                totalLinePath.push(linePath);
            } else {
                totalLinePathMine.push(linePath);
            }
        }
        
    }
    polylineSoldout.setPath(totalLinePath);
    polylineSoldout.setMap(map);
    polylineSoldoutMine.setPath(totalLinePathMine);
    polylineSoldoutMine.setMap(map);
    soldoutMarker(map, positions, forLineSet);
};

// // 인자값으로 받는것을 클립보드에 복사시킨다. 
// function copyText(text) {
//     function selectElementText(element) {
//       if (document.selection) {
//         let range = document.body.createTextRange();
//         range.moveToElementText(element);
//         range.select();
//       } else if (window.getSelection) {
//         let range = document.createRange();
//         range.selectNode(element);
//         window.getSelection().removeAllRanges();
//         window.getSelection().addRange(range);
//       }
//     }
//     let element = document.createElement('DIV');
//     element.textContent = text;
//     document.body.appendChild(element);
//     selectElementText(element);
//     document.execCommand('copy');
//     element.remove();

//     /* https://www.htmlgoodies.com/html5/other/working-with-clipboard-apis-in-html5-web-apps.html#fbid=eh9tM7GHJWF */
//     let copyEvent = new ClipboardEvent('copy', { dataType: 'text/plain', data: text } );
//     document.dispatchEvent(copyEvent);

// }

const lineSetBoraItemsOnScreen = async (data, map) => {
    
    if( Object.keys(data).length == 0 ) {
        return false;
    } else {
        //return false;
    }


    let positions           = [];

    for( let idx in data) {
        
        const title = `보라카메라`;
        const latlng = new kakao.maps.LatLng(data[idx].latitude, data[idx].longitude);
        positions.push({
            title,
            latlng
        });
        
    }
  
    boraMarker(map, positions, data);
};




{/** 보라카메라에 마커를 처리한다. */}
const boraMarker = (map, positions, datas) => {

    for (var i = 0; i < positions.length; i ++) {
        addBoraMarker(map, positions[i], datas[i])
    }
    
}


{/** 보라카메라에 마커를 표시한다. */}
const addBoraMarker = (map, position, data) => {


    // 마커 이미지의 이미지 주소입니다
    var imageSrc = "/images/icon/mapItem/bora_camera_thkim_20221111_1245.png?v=20221111"; 
        
    const imageScale = 0.08;
    
    // 마커 이미지의 이미지 크기 입니다
    var imageSize = new kakao.maps.Size(499 * imageScale, 717 * imageScale); 
    
    // 마커 이미지를 생성합니다    
    var markerImage = new kakao.maps.MarkerImage(imageSrc, imageSize); 
    
    // 마커를 생성합니다
    var marker = new kakao.maps.Marker({
        map: map, // 마커를 표시할 지도
        position: position.latlng, // 마커를 표시할 위치
        title : position.title, // 마커의 타이틀, 마커에 마우스를 올리면 타이틀이 표시됩니다
        image : markerImage // 마커 이미지 
    });
    

    // 마커를 클릭했을 때 마커 위에 표시할 인포윈도우를 생성합니다
    let iwContent = ``;
    const imageWidth = 220;
    if( Object.keys(data).length > 0 ) {

        //비디오 처리
        if( Object.keys(data.videoList).length > 0 ) { 
            for( const idx01 in data.videoList ) {
                let videoUrl = String(data.videoList[idx01].url);

                if( videoUrl.indexOf("https://youtu.be") > -1 ) {
                    const youtubeId = videoUrl.replace("https://youtu.be/", "");
                    iwContent +=
                    `           <div style="background:black;">` +
                    `               <iframe style="background:black;" width="${imageWidth}" height="${imageWidth-imageWidth*0.2}" src="https://www.youtube.com/embed/${youtubeId}?playlist=${youtubeId}&autoplay=1&mute=1&loop=1&controls=0" frameBorder="0">`+
                    `               </iframe>`+
                    `           </div>`;
                }
                    
            }
        }

        //이미지 처리
        if( Object.keys(data.imageList).length > 0 ) { 
            for( const idx01 in data.imageList ) {
                iwContent +=
                `           <div style="background:black;">` +
                `               <img style="width: ${imageWidth}px; background:black;" src="${data.imageList[idx01].url}" alt="bora camera images" />`+
                `           </div>`;
            }
        }
    }
    
    
    const iwRemoveable = true; // removeable 속성을 ture 로 설정하면 인포윈도우를 닫을 수 있는 x버튼이 표시됩니다

    // 인포윈도우를 생성합니다
    const infowindow = new kakao.maps.InfoWindow({
        content : iwContent,
        removable : iwRemoveable
    });
    
    // 마커에 클릭이벤트를 등록합니다
    kakao.maps.event.addListener(marker, 'click', function() {
        // 마커 위에 인포윈도우를 표시합니다
        infowindow.open(map, marker);  
    });

    // 커스텀 오버레이에 표시할 컨텐츠 입니다
    // 커스텀 오버레이는 아래와 같이 사용자가 자유롭게 컨텐츠를 구성하고 이벤트를 제어할 수 있기 때문에
    // 별도의 이벤트 메소드를 제공하지 않습니다 
    const content = 
    `<div class="wrap">` + 
    `    <div class="info">` +
    `        <div class="title">` +        
    `            <span style="color:#ffffff; font-weight: 200; background-color: rgba(0,0,0,0.2); padding: 10px 15px; position: absolute; top: 12px; left:-70px; border-radius: 20px;">BORA CAMERA</span>` +
    `            <div class="close" onclick="closeOverlay()" title="닫기"></div>` +
    `        </div>` +
    `    </div>` +
    `</div>`;

    // 마커 위에 커스텀오버레이를 표시합니다
    // 마커를 중심으로 커스텀 오버레이를 표시하기위해 CSS를 이용해 위치를 설정했습니다
    const overlay = new kakao.maps.CustomOverlay({
        content: content,
        map: map,
        position: marker.getPosition()       
    });
    
}