import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import axios from 'axios';
import { useSelector } from 'react-redux';

import { isValidationSessionId, isSessionValidate } from '../../apis/BrowserStorageAPI';
import { addComma } from '../../apis/CommonFunc';


const MainDiv = styled.div`
    .selfInfoContainer{
        width: 100%; 
        height: 100%; 
        padding-top: 50px;
    }
    .selfInfoContainer .selfInfoContainerWrap{
        overflow: hidden;
    }

    /* 데이터 들어가는 div */

    .selfInfoContainer .selfInfoContainerWrap .info-box-container{
        width: 310px;
        height: auto;
        border: 1px solid #ccc; 
        margin: 0 auto;

        box-sizing: border-box;
        padding: 44px;
        position: relative;
    }

    .selfInfoContainer .selfInfoContainerWrap .info-box-containerNone{
        width: 310px;
        height: auto;
        border: 1px solid #ccc; 
        margin: 0 auto;

        box-sizing: border-box;
        padding: 44px;
        position: relative;
        opacity: 0;
    }
    .selfInfoContainer .selfInfoContainerWrap .info-box-container .buyHistoryDay{
        font-family:  'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
        font-style: normal;
        font-weight: 800;
        font-size: 14px;
        line-height: 14px;
        letter-spacing: -0.02em;
        color: #05141F;
        padding-bottom: 16px;
        border-bottom: 1px solid #05141F;
    }

    .info-box-container > ul >li:nth-child(1) > a{
        text-decoration: none;
        color: #05141F;
    }

    .selfInfoContainer .selfInfoContainerWrap  .info-box-container ul li:nth-child(1){
        font-family:  'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
        font-style: normal;
        font-weight: 800;
        font-size: 14px;
        line-height: 20px;
        color: #05141F;
        margin-top: 18px;
        margin-bottom: 28px;
    }

    .selfInfoContainer .selfInfoContainerWrap  .info-box-container ul li:nth-child(2){
        font-family:  'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        letter-spacing: -0.02em;
        color: #909090;
        margin-bottom: 38px;
    }

    .selfInfoContainer .selfInfoContainerWrap .info-box-container ul li.offer-price {
        color: #FF645A;
        font-size: 21px;
    }

    .selfInfoContainer .selfInfoContainerWrap .info-box-container ul li.my-bought-price {
        color: #333333;
        font-size: 15px;
    }


    /* 버튼 */

    .selfInfoContainer .selfInfoContainerWrap  .info-box-container .bottom-buttons-container{
        margin-top: 30px;
    }

    .refusal-to-sell-button{
        cursor: pointer;
        width: 109px;
        height: 44px;
        background: #fff; 
        border: 1px solid #D9DDE2;
        font-size: 14px;
        line-height: 12px;
    }

    .sales-approval-button{
        cursor: pointer;
        width: 109px;
        height: 44px;
        background-color:#3D85F0; 
        color: #fff;
        border: none;
        float: right;
    }
    .selfStopBtn{
        cursor: pointer;
        width: 109px;
        height: 44px;    
        color: #05141F;
        background-color: #D9DDE2;
        border: none;
        float: right;
    }


    /* jsonData없을때 구매내역없다. */
    .historyDataNoneTitle{
        font-size: 30px;
        position: absolute;
        top: 30%;
        left: 50%;
        transform: translateX(-50%);
    }
`;



const OwnerConfirmPurchaseRequisitionItem = (props) => {
    const {t} = useTranslation();

    
    const selfInfoLang = localStorage.getItem("locale");
    const [data, setData] = useState(null);

    const [isItemShow, setIsItemShow] = useState(true);

    useEffect(()=>{        
        setData(props.sbrData);
        
        const type="NEW_SELF_BY_REALESTATE";
        setData(prev => prev && {
            ...prev,
            type
        });
    },[props.sbrData]);


    

    const onClickRefusalToSellButton = (e) =>  {
        if(window.confirm("거절 처리를 하시겠습니까?")) {
            const requestItemParams = {
                u : props.queryString.u
            };        
    
            axios.defaults.withCredentials = true;
            axios.post(`${process.env.REACT_APP_API_METAREX}/owner/whether-to-allow-purchase-yes-or-no/no`, requestItemParams , {withCredentials : true} )
            .then(res => {
                if(res.data.result === "false"){
                    alert(res.data.message);
                } else {
                    alert(res.data.message);
                    setIsItemShow(false); {/* 매물 정보를 숨긴다 */}
                } 
                if(res.data.href){
                    window.location.replace(res.data.href)
                }
            });
        }
    }

    const onClickSalesApprovalButton = (e) =>  {
        isValidationSessionId()
        .then(() => {
            if(!isSessionValidate()) {
                window.location.href ='/signin';
                return;
            } else {
                if(window.confirm("승인 처리를 하시겠습니까?\n\n승인 후, 최종 지급은 구매 요청자가 최종 결제까지 완료해야 포인트가 지급니다.\n\n 1 포인트 = 1달러(결제 시점 시세 적용)")) {
                    
                    const requestItemParams = {
                        u : props.queryString.u
                    };        
            
                    axios.defaults.withCredentials = true;
                    axios.post(`${process.env.REACT_APP_API_METAREX}/owner/whether-to-allow-purchase-yes-or-no/yes`, requestItemParams , {withCredentials : true} )
                    .then(res => {
                        if(res.data.result === "false"){
                            alert(res.data.message);
                        } else {
                            alert(res.data.message);
                            /* 매물 정보를 숨긴다 */
                            setIsItemShow(false);
                        }                        
                        if(res.data.href){
                            window.location.replace(res.data.href)
                        }
                    });
                }

            }
        });
    }


    return(
        <MainDiv>
            {isItemShow &&
                <section className="selfInfoContainer">
                    {
                        data ? 
                        <div className="selfInfoContainerWrap">
                            <div className="info-box-container">
                                <ul>
                                    <li>
                                        {selfInfoLang !== "ko" ? 
                                            <a>{data && data.addressJibunEn}</a>
                                            :
                                            <a>{data && data.address}</a>
                                        }
                                    </li>                                
                                    <li className='my-bought-price'>
                                        <div>
                                            <span>{"과거 구매한 가격"}</span>
                                        </div>
                                        <span> {addComma(data && data.priceLatest)}</span>
                                        <span>  {t("map_land_price_area_won")}</span>
                                    </li>
                                    <li className='offer-price'>
                                        <div>
                                            <span>{"제시된 금액"}</span>
                                        </div>
                                        <span> {addComma(data && data.priceMetarexAsk)}</span>
                                        <span>  {t("map_land_price_area_won")}</span>
                                    </li>
                                    <div className="bottom-buttons-container"> 
                                        <button className="refusal-to-sell-button" onClick={onClickRefusalToSellButton}>{"거절"}</button>
                                        {
                                            <button className="sales-approval-button" onClick={onClickSalesApprovalButton}>{"승인"}</button>
                                        }
                                    </div>
                                </ul>
                            </div>
                        </div>
                        :
                        <div className="selfInfoContainerWrap">
                            <div className="info-box-containerNone">
                            </div>
                        </div>
                    }
                </section>
            }
        </MainDiv>
    )
}

export default OwnerConfirmPurchaseRequisitionItem;
