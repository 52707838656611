import React, { useEffect, useState } from "react";
import { t } from "i18next";
import { Link } from "react-router-dom";
import ReactLoading from 'react-loading'; 
import styled from "styled-components";

import ChangeName from "../components/Modal/contents/ChangeName";
import ChangePw from "../components/Modal/contents/ChangePw";
import ModalScreen from "../components/Modal/ModalScreen";
import AuthCapsLock from "../components/Auth/AuthCapsLock";
import ModalMypage from "../components/Modal/ModalMypage";
import Footer from "../feature/Footer/Footer";

import { isNull, isValidPassword } from "../apis/Validation";
import { axiosPost } from "../apis/AxiosAPI";
import { isSessionValidate, isValidationSessionId } from "../apis/BrowserStorageAPI";
import { moveTopOnPageAuto } from "../apis/CommonFunc";

const StyledMyPageTemplate = styled.div`
    width: 100vw;
    height: 100vh;
    margin-bottom: 180px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h1 {
        font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 34px;
        text-align: center;
        margin-bottom: 50px;
    }
    div { 
        width: 100%;
        display:flex;
        justify-content: center;
    }
    @media only screen and (max-width: 768px) {
        margin-bottom: 60px;

    }
`;

const FormTemplate = styled.form`
    height: auto;
    display: flex;
    flex-direction: column;
    >input[type=password]{
        width: 400px;
        height: 48px;
        border: 1px solid #D9DDE2;
        font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        padding: 0px 14px;
        &:focus {
            outline: 1px solid #05141F;
            border-radius: 2px;
        }
    }
    >input[type=submit]{
        width: 400px;
        height: 48px;
        background: #05141F;
        -webkit-appearance: none;
        -webkit-border-radius: 0;
        border: none;
        color: #fff;
        font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
        font-size: 14px;
        font-weight: 700;
        line-height: 16px;
        margin-bottom: 10px;
        cursor: pointer;
        &:hover{
            background:#3D85F0;;
            transition:.3s;
        }
    }
    >a{
        width: 400px;
        height: 48px;
        border: 1px solid #05141F;
        color: #05141F;
        font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover{
            background:#3D85F0;;
            transition:.3s;
            color: #fff;
            border: none;
        }
    }
    @media only screen and (max-width: 768px) {
        width: 100%;
        padding: 0px 20px;
        >input[type=password]{
            width: 100%;
        }
        >input[type=submit]{
            width: 100%;
        }
        >a{
            width: 100%;
        }
    }
`;

const MypageTemplate = styled.div`
    flex-direction: column;
    align-items: center;
    >button{
        cursor: pointer;
        width: 400px;
        height: 48px;
        border: 1px solid #D9DDE2;
        border-radius: 2px;
        background: #fff;
        color: #05141F;
        font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
        font-size: 15px;
        font-weight: 600;
        line-height: 18px;
        &:hover{
            color: #fff;
            background: #3D85F0;
            border: none;
            transition: .3s;
        }
        &:first-of-type{
            margin-bottom: 10px;
        }
    }
    @media only screen and (max-width: 768px) {
        width: 100%;
        padding: 0px 20px;
        >button{
            width: 100%;
        }
    }
`;

const MyPage = () => {
    const [password, setPassword] = useState("");               // 마이페이지에 접속하기 위한 비밀번호
    const [success, setSuccess] = useState(false);              // 비밀번호 인증 성공 후 마이페이지에 접속
    const [open, setOpen] = useState(false);                    // 모달의 오픈(true), 클로즈(false)
    const [current, Setcurrent] = useState("");                 // 현재 누른 버튼이 무엇인지 체크 name은 true, password는 false
    const [isProceeding, setIsProceeding] = useState(false);    // 데이터 통신 중일 경우 true, 아닐 경우 false
    const [disabled, setDisabled] = useState(false);            // 데이터 통신 중일 경우 버튼의 disabled 여부
    const [isCapsLock, setIsCapsLock] = useState(false);        // 비밀번호 capslock 버튼 확인

    /** 최초 로딩 */
    useEffect(()=>{
        if(!isSessionValidate()) {
            alert(t('market_view_login'));
            window.location.href ='/signin';
            return false;
        }

        isValidationSessionId()
            .then(() => {
                if(!isSessionValidate()) {
                    window.location.href ='/signin';
                    return false;
                }
            });

        moveTopOnPageAuto();
    },[]);

    const onSubmit = (e) => {
        e.preventDefault();
        setDisabled(true);

        if(password === '') {
            alert(t('metarex_alert_validation_pw_1'));
            setDisabled(false);
            return;
        }

        if(!isValidPassword(password)) {
            alert(t('metarex_alert_validation_pw_2'));
            setDisabled(false);
            return;
        }

        requestAuthUserByPassword({password: password});
    }

    const openModal = (e) => {
        setOpen(!open);
        if(isNull(e) || isNull(e.target)){ return; }

        e.preventDefault();
        if(e.target.tagName === "BUTTON"){
            Setcurrent(e.target.dataset.modal.split("_")[1]);
        }
    }

    const onKeyUpEvent = (e) => {
        setIsCapsLock(e.getModifierState("CapsLock"));
        e.target.value = e.target.value.replace(/[ㄱ-ㅎㅏ-ㅣ가-힣]/g, '');
    }

    const requestAuthUserByPassword = (json) => {
        axiosPost('api/user/verifyUser', json)
            .then(response => {
                alert(response.data.message);
                setSuccess(JSON.parse(response.data.result));
            })
            .catch(error => {
                console.log(`auth User by Password, error message: ${error}`);
                setSuccess(false);
            }).finally(() => {
                setDisabled(false);
            });
    }

    return (
        <>
        {open && 
            <ModalScreen>
                {
                    isProceeding 
                    ? <ReactLoading type={'spin'} color={'skyblue'} height={'40px'} width={'40px'} /> 
                    : <ModalMypage title={current==='name' ? t('mypage_change_name') : t('mypage_change_password')} closeModal={openModal}>
                        {
                        current === 'name' 
                        ? <ChangeName closeModal={openModal} setIsProceeding={setIsProceeding}/>
                        : <ChangePw closeModal={openModal} setIsProceeding={setIsProceeding}/> 
                        }
                    </ModalMypage>
                }
            </ModalScreen>
        }
        <StyledMyPageTemplate>
            <h1>{t('mypage_title')}</h1>
            {success 
            ?
            <MypageTemplate>
                <button onClick={openModal} data-modal="change_name">{t('mypage_change_name')}</button>
                <button onClick={openModal} data-modal="change_password">{t('mypage_change_password')}</button>
            </MypageTemplate>
            :
            <FormTemplate onSubmit={onSubmit}>
                <input 
                    type="password" 
                    id="change_password"
                    placeholder={t('mypage_password_placeholder')}
                    onKeyUp={onKeyUpEvent}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <AuthCapsLock isCapsLock={isCapsLock}/>
                <input type="submit" value={t('metarex_common_confirm')} disabled={disabled}/>
                <Link to="/">{t('metarex_common_cancel')}</Link>
            </FormTemplate>
        }
        </StyledMyPageTemplate>
        <Footer/>
        </>
    );
}

export default MyPage;