import React, { useState , useEffect } from 'react';
import { t } from 'i18next';
import styled from "styled-components";
import { addComma } from '../../apis/CommonFunc';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";


const MxLendingBoxContainer = styled.div`
    height: 300px;
    width: 420px;
    margin-right: 24px;
    border: 1px solid #ccc; 
    padding: 20px; 
    box-sizing: border-box;
    position: relative;

    @media only screen and (max-width: 768px) {        
        width: calc(100% - 40px);
        margin: 0 auto;
        padding: 15px; 
        font-size: 18px;
    }

    @media only screen and (max-width: 768px) {
        &> div > button {
            display: inline-block;
            width: 45%;
            height: 30px;
            border: none;
            background: rgb(52, 182, 233);
            margin-top: 10px;
            border-radius: 5px;
            color: white;
            transform: translateX(50%);
            position: absolute;
            bottom: 10px;
        }
    }

    &:nth-child(3) {
        margin-right: 0;
    }

    .buyHistoryDay{
        font-family:  'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
        font-style: normal;
        font-weight: 800;
        font-size: 14px;
        line-height: 14px;
        letter-spacing: -0.02em;
        color: #05141F;
        padding-bottom: 16px;
        border-bottom: 1px solid #05141F;
    }    
    ul {
        li {
            padding: 3px 0px;
            &.event-item {
                font-family:  'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
                font-style: normal;
                font-weight: 800;
                font-size: 14px;
                line-height: 20px;
                color: #05141F;
                margin-top: 18px;
                margin-bottom: 28px;                    
                @media only screen and (max-width: 768px) {
                    margin-bottom: 8px;
                }
            }
            &.item-price {
                padding-top: 15px;
                font-family: 'NanumSquare';
                font-style: normal;
                font-weight: 800;
                font-size: 18px;
                color: #FF645A;
                @media only screen and (max-width: 768px) {
                    margin-bottom: 8px;
                }
                .item-price-label {
                    padding-right: 3px;
                }
                .item-price-amount {
                    font-size: 23px;
                }
                .item-price-unit {
                    padding-left: 3px;
                }
            }
            &.item-address{                                
                color: #05141F; 
                font-family:  'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
                font-style: normal;
                padding-top: 20px;
                @media only screen and (max-width: 768px) {
                    color: #FF645A;
                }
                .buyHistoryInfoIconContainer{
                    display: none;
                }
                .areaSterOpacity{
                    opacity: 0;
                }
                &> a {
                    text-decoration: none;
                    color: #05141F;                    
                    font-weight: 800;
                    font-size: 14px;        
                    line-height: 20px;
                }
            }
            &.item-size {
                font-weight: 700;
                font-size: 14px;
                letter-spacing: -0.02em;
                color: #909090;
            }
            &.item-soldout-time {
                font-weight: 800;
                font-size: 14px;
                color: ##05141F;
                border-bottom: 1px solid #000000;
                padding-bottom: 20px;
            }
            &.item-metalive-code {
                font-weight: 900;
                padding-top: 20px;
                color: #333;
                line-height: 23px;
            }
        }
    }
    .historyLeftBtnNo{ 
        display: inline-block;
        outline: 0;
        width: 44px;
        height: 44px;
        background-color: transparent;
        color: rgb(217, 221, 226);
        border: 1px solid rgb(217, 221, 226);
        border-radius: 50px;
        font-size: 20px;
    }
    .historyRightBtnNo{
        display: inline-block;
        outline: 0;
        width: 44px;
        height: 44px;
        background-color: transparent;
        color: rgb(217, 221, 226);
        border: 1px solid rgb(217, 221, 226);
        border-radius: 50px;
        font-size: 20px;
        margin-left: 10px;
    }
`;


const HistoryContainer = styled.div`
    width: 100%; 
    height: 400px; 
    display: flex; 
    margin-top: 130px;
    padding-top: 50px;
    justify-content: space-around;
    position: relative;

    @media only screen and (max-width: 768px) {        
        margin-top: 30px;
        width: 100%; 
        height: 100%; 
        position: relative;
    }

    .buySlideContainerWrap {
        height: auto;
        width: 100%;
        display: flex;
        align-items: center;
        overflow: hidden;

        @media only screen and (max-width: 768px) {
            border: none;
        }

        .buySlideContainer {
            width: 100%;
            height: auto;
            transition: 0.5s;
            display: flex;
            align-items: center;
            margin-top: 30px;
        }
    }

    /* 버튼 */
    .buyHistoryBtnContainer{
        width: 100px;
        position: absolute;
        right: 0;
        top: 0;

        @media only screen and (max-width: 768px) {
            margin-right: 15px;
        }
    }

    .historyLeftBtn { 
        outline: 0;
        background: none;
        width: 44px;
        height: 44px;
        border: 1px solid #05141F;
        border-radius: 50px;
        font-size: 20px;
    }

    .historyRightBtn {
        outline: 0;
        background: none;
        width: 44px;
        height: 44px;
        border: 1px solid #05141F;
        border-radius: 50px;
        font-size: 20px;
        margin-left: 10px;
    }



    .saleRegistBtnContainer {

        .saleLeft { 
            outline: 0;
            background: none;
            width: 44px;
            height: 44px;
            border: 1px solid #05141F;
            border-radius: 50px;
            font-size: 20px;
        }
    
        .saleRight {
            outline: 0;
            background: none;
            width: 44px;
            height: 44px;
            border: 1px solid #05141F;
            border-radius: 50px;
            font-size: 20px;
            margin-left: 10px;
        }

    }


    .historyLeftBtnNo{ 
        display: inline-block;
        outline: 0;
        width: 44px;
        height: 44px;
        background-color: transparent;
        color: rgb(217, 221, 226);
        border: 1px solid rgb(217, 221, 226);
        border-radius: 50px;
        font-size: 20px;
    }
    .historyRightBtnNo{
        display: inline-block;
        outline: 0;
        width: 44px;
        height: 44px;
        background-color: transparent;
        color: rgb(217, 221, 226);
        border: 1px solid rgb(217, 221, 226);
        border-radius: 50px;
        font-size: 20px;
        margin-left: 10px;
    }

    /* 맨위로 버튼 */
    .buyHistoryTopContainer{
        display: none;
        @media only screen and (max-width: 768px) {
            display: block;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 100%);

            .buyHistoryTop {
                width: 80px;
                height: 40px;
                display: block;
                border: none;
                background: none;
                margin: 0 auto;
                .buyHistoryUpIcon {
                    width: 100%;
                    height: 100%;
                }
            }
            .buyHistoryTopText{
                font-size: 20px;
                width: 70px;
                height: 25px;
                display: block;
                text-align: center;
                margin: 0 auto;
            }            
        }
    }

    /* jsonData없을때 구매내역없다. */
    .historyDataNoneTitleContainer {
        position: relative;
        top: 30px;
        left: 50%;
        transform: translate(-50%);
        width: 100%;
        background: #fff;
        font-size: 27px;
        @media only screen and (max-width: 768px) {        
            display: block;
            top: 0;
            height: 400px;
        }
    }
    .historyDataNoneTitleContainer > .historyDataNoneTitle{
        text-align: center;
        @media only screen and (max-width: 768px) {
            font-size: 27px;
            width: 320px;
            position: absolute;
            top: 40%;
            left: 50%;
            transform: translate(-50% , -50%);
        }
    }

    .buyHistoryTitle{
        font-size: 28px;
        position: absolute;
        left: 0;
        top: 0;
        display: inline;

        @media only screen and (max-width: 768px) {
            margin-left: 15px;
        }
    }

    .button-calculate {
        margin-top: 10px;
        padding: 10px;
        background-color: #888;
        width: 100%;
        border: 1px solid #888;
        color: #ffffff;
        font-weight: 600;
    }
    .buy-date {
        margin-left: 10px;
        font-weight: 300;
    }
`;

function HistoryBoxData({data}){
    const historyLang = localStorage.getItem("locale");

    const onClick = (e) => {
        e.preventDefault();
        alert(t("mx_lending_notice_calculate"));

    }
    
    return(
        <MxLendingBoxContainer>
            <ul data-no={data.mxLendingHistoryNo}>                
                <li className='item-soldout-time'>
                    {
                        data.dday &&
                        <span>D {data.dday<0 ? '+' : '-'} {data.dday}</span>
                    }
                    {   data.insertedTs &&
                        <span className="buy-date">
                            <span>{t("mx_lending_buy_date")}: </span><span>{data.insertedTs.split(".")[0].replace('T',' ')}</span>
                        </span>
                    }
                </li>                
                <li className='item-address'>
                    {
                        historyLang === "ko" 
                            ? <a href={data.link} >{data.addressKr}</a>
                            : <a href={data.link} >{data.addressEn}</a>
                    }
                </li>
                <li className='item-point-user'>
                    <span className='item-price-label'>{t("mx_lending_point_user")}</span>&nbsp; 
                    <span className='item-price-amount'>{`${addComma(Math.floor(data.userAmount*100)/100)}`}</span><span className='item-price-unit'>{`${t("unit_point")}`}</span>
                </li>
                <li className='item-loan-user'>
                    <span className='item-price-label'>{t("mx_lending_point_loan")}</span>&nbsp; 
                    <span className='item-price-amount'>{`${addComma(Math.floor(data.lendingAmount*100)/100)}`}</span><span className='item-price-unit'>{`${t("unit_point")}`}</span>
                </li>
                <li className='item-price'>
                    <span className='item-price-label'>{t("mx_lending_point_total")}</span>&nbsp; 
                    <span className='item-price-amount'>{`${addComma(Math.floor(data.totalAmount*100)/100)}`}</span><span className='item-price-unit'>{`${t("unit_point")}`}</span>
                </li>
                <li className='item-buttons'>
                   <button className='button-calculate' type="button" onClick={onClick}>정산</button> 
                </li>
            </ul>
        </MxLendingBoxContainer>
    )
} 

const MxLendingBox = (props) => {
    
    let buyHistoryData = props.historyData && props.historyData
    let isMobile = props.isMobile

    // 클릭개수
    let historyMobileClick= props.historyMobileClick;
    let setHistoryMobileClick= props.setHistoryMobileClick;
    
    let historyPcClick = props.historyPcClick;
    let setHistoryPcClick = props.setHistoryPcClick;

    // 총개수
    let totalCount = props.totalCount;
    
    // 페이징 pc
    let setPageNumPc= props.setPageNumPc
    let pageNumPc = props.pageNumPc

    // 페이징 모바일
    let pageNumMobile = props.pageNumMobile
    let setPageNumMobile = props.setPageNumMobile

    // 타이틀 none 구분
    let buyAxios = props.buyAxios;

    // Getting items
    let getItems = props.getItems;

    const [buyLeftLast , setBuyLeftLast ] = useState(false)
    const [buyRightLast , setBuyRightLast ] = useState(false)

    // pc나 모바일일때 클릭값과 받아온 페이지 수를 1로 초기화 해줌. 받아온 매물개수가 다르기에 해당 로직
    useEffect(() => {
        if(isMobile === false){
            setHistoryMobileClick(1)
            setPageNumMobile(1)
        }else if(isMobile === true){
            setHistoryPcClick(1)
            setPageNumPc(1)
        }
    })

    // slide 왼쪽 슬라이드 버튼 disable 로직
    useEffect(()=>{
        if(historyMobileClick === 1 && isMobile === true){
            setBuyLeftLast(true)
        }else if(historyPcClick === 1 && isMobile === false){
            setBuyLeftLast(true)

        }else if(historyMobileClick > 1 && isMobile === true){
            setBuyLeftLast(false)
        }else if(historyPcClick > 1 && isMobile === false){
            setBuyLeftLast(false)
        }
    },[historyMobileClick  , setBuyLeftLast , historyPcClick , isMobile])

    // slide 오른쪽 슬라이드 버튼 disable 로직
    useEffect(()=>{
        // pc
        if(Math.ceil(totalCount / 3) > historyPcClick && isMobile === false ){
            setBuyRightLast(false)
        }else if((Math.ceil(totalCount / 3) <= historyPcClick) && isMobile === false){
            setBuyRightLast(true)
        
        // 모바일
        }else if((totalCount > historyMobileClick) && isMobile === true){
            setBuyRightLast(false)
        }else if((totalCount === historyMobileClick) && isMobile === true){
            setBuyRightLast(true)
        }
    },[historyMobileClick  , setBuyRightLast , totalCount , historyPcClick, isMobile])


    // 위에서 disable처리
    // pc와 모바일 따로 클릭값을 준다. 받아오는 매물의 개수가 다르기때문.
    // 클릭값이 1이 아닐때 1씩 빼줘서 페이지수를 조절. 
    let buyPrevSlide = (e) => {
        console.log('prev');
        if(historyMobileClick !== 1 && isMobile === true){
            setPageNumMobile(pageNumMobile - 1)
            setHistoryMobileClick(historyMobileClick -1)
        }else if(historyPcClick !== 1 && isMobile === false){
            setPageNumPc(pageNumPc - 1)
            setHistoryPcClick(historyPcClick - 1)
        }
        getItems();
    }

    // pc일때는  매물개수가 3개여서 총 개수에 3을 나눠준뒤, 그 값이 클릭값보다 클때 +1씩 더해준다.
    // 모바일일때는 매물개수가 하나여서 나누지 않고 처리 
    let buyNextSlide = (e) => {
        console.log(`next totalCount=${totalCount}, historyMobileClick=${historyMobileClick}`);
        if(Math.ceil(totalCount / 3) > historyPcClick && isMobile === false){
            console.log('next-pc');
            setPageNumPc(pageNumPc + 1);
            setHistoryPcClick(historyPcClick + 1);
        } else if(totalCount > historyMobileClick && isMobile === true){
            console.log('next-mobile');
            setPageNumMobile(pageNumMobile + 1);
            setHistoryMobileClick(historyMobileClick + 1);
        }
    }

    return(
        <HistoryContainer className="historyContainer">
            <h1 className="buyHistoryTitle">{t("mx_lending_list")}</h1>
            {  (buyHistoryData && buyHistoryData.length === 0) && 
                <div className="historyDataNoneTitleContainer">
                    <h1 className="historyDataNoneTitle">{t("buy_history_date_null")}</h1>
                </div>
            }
            <div className="buySlideContainerWrap">
                <div className="buySlideContainer" >
                    { buyHistoryData && buyHistoryData.map((data , i)=> 
                        <HistoryBoxData key={i} data={data}/>
                    )}
                </div>                
            </div>            
            <div className="buyHistoryBtnContainer">
                {
                    (buyLeftLast === true) ?
                    <button type="submit" className="historyLeftBtnNo" disabled>
                        <FontAwesomeIcon icon={faChevronLeft}/>
                    </button> 
                    :
                    <button type="submit" className="historyLeftBtn" onClick={buyPrevSlide}>
                        <FontAwesomeIcon icon={faChevronLeft}/>
                    </button> 
                } 
                {
                    
                    (buyRightLast === true) ?
                    <button type="submit" className="historyRightBtnNo" disabled>
                        <FontAwesomeIcon icon={faChevronRight}/>
                    </button>
                    :
                    <button type="submit" className="historyRightBtn" onClick={buyNextSlide}>
                        <FontAwesomeIcon icon={faChevronRight}/>
                    </button>
                }
            </div>            
        </HistoryContainer>
    )
}

export default MxLendingBox;
