/**
 *  - "지원 > 사용자 설명" 내용을 작성해둔 data
 *  - 사용자 설명의 내용 수정이 필요할 경우, 이 데이터의 내용을 수정하면 자동으로 반영 
*/

import { t } from "i18next";

export const buyMap = [
    {
        title_kr: "01. 구매 메뉴",
        detail_kr: "상단 메뉴에서 구매 클릭",
        title_en: "01. Buy menu",
        detail_en: "Click Buy in the top menu",
        title_cn: "01. 购买菜单",
        detail_cn: "点击上端菜单中的购买"
    },
    {
        title_kr: "02. 매물 보기",
        detail_kr: "하위 메뉴에서<br>구매하기 클릭",
        title_en: "02. Purchase",
        detail_en: "Click purchase in the submenu",
        title_cn: "02. 购买",
        detail_cn: "在子菜单中点击购买"
    },
    {
        title_kr: "03. 구매 하기",
        detail_kr: "구매할 매물을 고르고<br>구매 버튼 클릭",
        title_en: "03. Click purchase button",
        detail_en: "Select the item to purchase and click the purchase button",
        title_cn: "03. 点击购买按钮",
        detail_cn: "选择要购买的项目后 点击购买按钮"
    },
    {
        title_kr: "04. 관리자 승인",
        detail_kr: "관리자의 승인 후 구매 링크 안내 문자 발송",
        title_en: "04. Admin Approval",
        detail_en: "Receive text message if approved",
        title_cn: "04. 管理批准",
        detail_cn: "批准时接收短信"
    },
    {
        title_kr: "05. 결제 진행",
        detail_kr: "링크로 이동 후,<br>구매 버튼 클릭시<br>선택화면 출력",
        title_en: "05. Payment in progress",
        detail_en: "After moving to the link, click the purchase button to proceed with payment with AsterPay",
        title_cn: "05. 付款进行中",
        detail_cn: "移至链接后，单击购买按钮以继续使用 AsterPay 付款"
    },
    {
        title_kr: "06. 결제 방법 선택",
        detail_kr: "카드결제를 할 것인지 코인결제를 할 것인지 선택",
        title_en: "05. Payment in progress",
        detail_en: "After moving to the link, click the purchase button to proceed with payment with AsterPay",
        title_cn: "05. 付款进行中",
        detail_cn: "移至链接后，单击购买按钮以继续使用 AsterPay 付款"
    },
    {
        title_kr: "06-1. 코인 결제 선택시",
        detail_kr: "ATC 상장 거래소 프로세스를 따름",
        title_en: "05. Payment in progress",
        detail_en: "After moving to the link, click the purchase button to proceed with payment with AsterPay",
        title_cn: "05. 付款进行中",
        detail_cn: "移至链接后，单击购买按钮以继续使用 AsterPay 付款"
    },
    {
        title_kr: "06-2. 카드 선택시",
        detail_kr: "에스결제크로에서 카드 결제 프로세스 따라가기",
        title_en: "05. Payment in progress",
        detail_en: "After moving to the link, click the purchase button to proceed with payment with AsterPay",
        title_cn: "05. 付款进行中",
        detail_cn: "移至链接后，单击购买按钮以继续使用 AsterPay 付款"
    }
];

export const buyMarket = [
    {
        title_kr: "01. 판매 메뉴",
        detail_kr: "상단 메뉴에서 판매 클릭",
        title_en: "01. Sales menu",
        detail_en: "Click Sales in the top menu",
        title_cn: "01. 销售菜单",
        detail_cn: "点击上端菜单中的推销"
    },
    {
        title_kr: "02. 마켓 보기",
        detail_kr: "하위 메뉴에서<br>마켓보기 클릭",
        title_en: "02. View Market",
        detail_en: "Click on View the Market in the submenu",
        title_cn: "02. 查看市场",
        detail_cn: "点击子菜单中的查看市场"
    },
    {
        title_kr: "03. 구매 하기",
        detail_kr: "구매할 매물을 고르고<br>구매 버튼 클릭",
        title_en: "03. Click purchase button",
        detail_en: "Select the item to purchase and click the purchase button",
        title_cn: "03. 点击购买按钮",
        detail_cn: "选择要购买的商品，点击购买按钮"
    },
    {
        title_kr: "04. 결제 방법 선택",
        detail_kr: "카드결제를 할 것인지 코인결제를 할 것인지 선택",
        title_en: "05. Payment in progress",
        detail_en: "After moving to the link, click the purchase button to proceed with payment with AsterPay",
        title_cn: "05. 付款进行中",
        detail_cn: "移至链接后，单击购买按钮以继续使用 AsterPay 付款"
    },
    {
        title_kr: "04-1. 코인 결제 선택시",
        detail_kr: "ATC 상장 거래소 프로세스를 따름",
        title_en: "05. Payment in progress",
        detail_en: "After moving to the link, click the purchase button to proceed with payment with AsterPay",
        title_cn: "05. 付款进行中",
        detail_cn: "移至链接后，单击购买按钮以继续使用 AsterPay 付款"
    },
    {
        title_kr: "04-2. 카드 선택시",
        detail_kr: "에스결제크로에서 카드 결제 프로세스 따라가기",
        title_en: "05. Payment in progress",
        detail_en: "After moving to the link, click the purchase button to proceed with payment with AsterPay",
        title_cn: "05. 付款进行中",
        detail_cn: "移至链接后，单击购买按钮以继续使用 AsterPay 付款"
    }
];

export const sell = [
    {
        title_kr: "01. 판매 메뉴",
        detail_kr: "상단 메뉴에서 판매 클릭",
        title_en: "01. Sales menu",
        detail_en: "Click Sales in the top menu",
        title_cn: "01. 销售菜单",
        detail_cn: "点击上端菜单中的推销"
    },
    {
        title_kr: "02. 판매 등록",
        detail_kr: "'미등록 매물'중<br>원하는 매물의<br>'판매등록' 클릭",
        title_en: "02. Sales registration",
        detail_en: "Among ‘unregistered product’ for sale. Click the ‘Sales registration’ button",
        title_cn: "02. 销售登记",
        detail_cn: "出售的 “未注册产品”。点击 “注册完成” 按钮"
    },
    {
        title_kr: "03. 매물 등록",
        detail_kr: "희망가 입력 후<br>등록완료 버튼 클릭",
        title_en: "03. Proceed to register for sale",
        detail_en: "After entering Exchange to receive deposit, the address and desired price, click the Finish registration button",
        title_cn: "03. 进行销售登记",
        detail_cn: "进入交易所收取押金后，地址和所需价格，点击注册完成按钮"
    },
    {
        title_kr: "04. 등록 완료",
        detail_kr: "",
        title_en: "04. Registration completed",
        detail_en: "",
        title_cn: "04. 注册完成",
        detail_cn: ""
    }
];




export const atcExchangeProcessMap = [
    {
        title_kr: "01. 원화 입금",
        detail_kr: "원화마켓 거래소에<br>원화 입금",
        title_en: "01. Deposit in Korean Won",
        detail_en: "Deposit in Korean Won on the Won Market Exchange",
        title_cn: "01. 韩元存款",
        detail_cn: "在韩元市场交易所存入韩元"
    },
    {
        title_kr: "02. 코인 매수",
        detail_kr: "비트코인 or<br>이더리움 or 리플 매수",
        title_en: "02. Buy coins",
        detail_en: "Buy Bitcoin or Ethereum or Ripple",
        title_cn: "02. 购买加密货币",
        detail_cn: "购买比特币或以太<br>坊或瑞波币"
    
    },
    {
        title_kr: "03. ATC 구매 준비",
        detail_kr: "매수한 코인을<br>비트글로벌 or 한빗코에서 or MEXC<br>입금 및 매도",
        title_en: "03. Prepare to buy ATC",
        detail_en: "purchased coins deposit and sell to Bitglobal or Hanbitco or MEXC",
        title_cn: "03. 硬币汇款和销售。",
        detail_cn: "购买加密货币后存入<br>Bitglobal或Hanbitco或MEXC销售"
    },
    {
        title_kr: "04. ATC 매수",
        detail_kr: "입금 및 매도한<br>코인으로 ATC 매수",
        title_en: "04. Buy ATC",
        detail_en: "Buy ATC with deposited and sold coins",
        title_cn: "04. 购买ATC",
        detail_cn: "用存入和卖出的加<br>密货币购买 ATC"
    },
    {
        title_kr: "05. ATC  이체",
        detail_kr: "문자로 안내된 입금주소로<br>ATC 이체시 결제 완료",
        title_en: "05. Transfer ATC",
        detail_en: "Payment is completed when ATC is transferred to the deposit address provided by text message",
        title_cn: "05. 转移ATC",
        detail_cn: "ATC 转账至短信提供的存<br>款地址即完成支付"
    },
    {
        title_kr: "06. 대기",
        detail_kr: "코인 전송 완료 대기",
        title_en: "06. Standby",
        detail_en: "Wait for Coin Transfer Complete",
        title_cn: "06. 待机",
        detail_cn: "等待加密货币转移完成"
    }
];




export const managementItemMap = [
    {
        title_kr: "01. 구매 매물 확인",
        detail_kr: "구매내역 메뉴에서<br>구매한 매물 확인",
        title_en: "01. Confirmation of purchase",
        detail_en: "Check the purchased item in the purchase history menu",
        title_cn: "01. 购买确认",
        detail_cn: "在购买历史菜单中<br>查看购买的菜单"
    },
    {
        title_kr: "02. 포인트 출금",
        detail_kr: "우측 상단 아이디 클릭 후 ‘포인트’ 클릭. ‘출금하기’ 버튼 클릭",
        title_en: "02. Withdraw points",
        detail_en: "Click your ID in the top right corner and click ‘Points’. Click the ‘Withdraw’ button",
        title_cn: "02. 提取积分",
        detail_cn: "点击右上角您的 ID，然后点击“积分”。点击“取款”按钮"
    }
];