import React, { useState, useEffect } from 'react';
import SaleHistoryMain from '../../feature/SaleHistory/SaleHistoryMain'
import PageBanner from '../../components/PageBanner';
import PageTab from '../../components/PageTab';
import Footer from '../../feature/Footer/Footer'

import { t } from 'i18next';
import { axiosGet } from '../../apis/AxiosAPI';

import './SaleHistory.css';

const SaleHistory = (props) => {
    // 페이징 
    // width값 토대로 모바일 pc 구분.
    const [forSaleWidth , setForSaleWidth] = useState(false);

    // 모바일 사이즈 확인
    const forSaleResizeHandler = () => {
        if(window.innerWidth < 769) {
            setForSaleWidth(true);
        } else if (window.innerWidth > 769){
            setForSaleWidth(false);
        }
    };
    
    /**
     * 화면 로딩 직후, 화면 리사이즈 이벤트
     */
    useEffect(() => {
        window.addEventListener('load', forSaleResizeHandler);
        window.addEventListener('resize', forSaleResizeHandler);
        window.addEventListener('scroll', forSaleResizeHandler);
        
        return () => {
            window.removeEventListener('load', forSaleResizeHandler);
            window.removeEventListener('resize', forSaleResizeHandler);
            window.addEventListener('scroll', forSaleResizeHandler);
        } 
    }, [forSaleWidth , forSaleResizeHandler]);


    // axios 데이터 넣음
    const [saleHistoryAxios , setSaleHistoryAxios] = useState(undefined);
    // 총개수
    const [saleTotalCount , setSaleTotalCount] = useState(0);
    
    // pc버전 클릭여부에 따라 스타일값 초기화
    const [saleClickCount , setSaleClickCount] = useState(1);
    const [saleClickMCount , setSaleClickMCount] = useState(1);

    // page num
    const [salePage, setSalePage] = useState(1)
    const [saleMPage, setSaleMPage] = useState(1)

    // numPer

    useEffect(() => {
        let saleHistoryUrl = (pageNum , numPerPage) => `realestate/p2p/sell/myHistory?pageNum=${encodeURI(pageNum)}&numPerPage=${encodeURI(numPerPage)}`
        
        axiosGet(saleHistoryUrl( forSaleWidth === true ? saleMPage : salePage , forSaleWidth === true ? 1 : 3))
            .then(response => {
                if(JSON.parse(response.data.result)){
                    forSaleResizeHandler()
                    setSaleHistoryAxios(response.data.data ? response.data.data : null);
                    setSaleTotalCount(response.data.totalCount);
                } 
            }).catch(e => {
                console.log(`error message: ${e}`);
            });
    }, [ setSaleHistoryAxios , forSaleWidth ,  salePage, saleMPage ,  setSaleTotalCount ]);
    
    let saleHistoryArray = []
    saleHistoryArray.push(saleHistoryAxios)

    const [saleConcat  , setSaleConcat] = useState([])

    useEffect(()=>{
        if(forSaleWidth === true ){
            setSaleConcat(saleConcat.concat(saleHistoryArray))
        }else if (forSaleWidth === false){
            setSaleConcat(saleHistoryArray)
        }
    }, [setSaleConcat, saleHistoryAxios])

    
    useEffect(() => {
        if(forSaleWidth === false){
            setSaleClickMCount(1)
            setSaleMPage(1)
        }else if(forSaleWidth === true){
            setSaleClickCount(1)
            setSalePage(1)
        }
    })

    return(
        <div style={{paddingTop: '60px'}}>
            <PageBanner style={{backgroundImage: "url('/images/bg/market_banner.jpg')"}}>{t("sale_history_title")}</PageBanner>
            <PageTab category="market" type="history" />
            <section className="SaleHistoryContainerWarp">
                <div>
                    {
                        saleHistoryArray.map((data, i) => 
                            (
                                <SaleHistoryMain
                                    key={i} 
                                    saleHistoryDatas={data} 

                                    // 화살표 클릭 개수 가져옴
                                    saleClickCount = {saleClickCount}
                                    setSaleClickCount = {setSaleClickCount}
                                    
                                    saleClickMCount= {saleClickMCount}
                                    setSaleClickMCount= {setSaleClickMCount}

                                    // 모바일 마지막
                                    forSaleWidth={forSaleWidth}
                                    saleTotalCount={saleTotalCount}
                                
                                    salePage = {salePage}
                                    setSalePage = {setSalePage}
                                    
                                    saleMPage={saleMPage}
                                    setSaleMPage={setSaleMPage}
                                    
                                />
                            ))
                    }
                </div>
            </section>
            <Footer/>
        </div>
    )
}

export default SaleHistory;