import React, { useLayoutEffect } from 'react';
import { Route, Routes, useParams, useMatch } from 'react-router-dom';
import './App.css';

import Header from '../../feature/Header/Header';
import PaymentFailed from '../../pages/Common/PaymentFailed';
import Main from '../../pages/Main';
import About from '../../pages/MetaRex/About';
import Future from '../../pages/MetaRex/Future';
import Profile from '../../pages/MetaRex/Profile';
import OrganizationChart from '../../pages/MetaRex/OrganizationChart';
import Map from '../../pages/Map/map';
import BuyHistory from '../../pages/BuyHistory/BuyHistory';
import MarketView from '../../pages/MarketView/MarketView';
import SaleHistory from '../../pages/SaleHistory/SaleHistory';
import SalesRegistRation from '../../pages/SaleRegistration/SalesRegistRation';
import SelfMain from '../../pages/SelfPerchase/SelfMain';
import OwnerConfirmPurchaseRequisition from '../../pages/SelfPerchase/OwnerConfirmPurchaseRequisition';
import PurchaseAfterConfirmingTheOwner from '../../pages/SelfPerchase/PurchaseAfterConfirmingTheOwner';

import Notice from '../../pages/Notice';
import Faq from '../../pages/Faq';
import HowToUse from '../../pages/HowToUse';
import Videos from '../../pages/Videos';
import ContactUs from '../../pages/ContactUs';
import SignIn from '../../pages/SignIn';
import SignUp from '../../pages/SignUp';
import FindId from '../../pages/FindId';
import FindPassword from '../../pages/FindPassword';
import MyPage from '../../pages/MyPage';
import Point from '../../pages/Point';
import TermsOfService from '../../pages/TermsOfService/TermsOfService';

import Event20221224 from '../../pages/Sales/Events/Event20221224';
import Event20221224MxLending from '../../pages/Sales/Events/Event20221224MxLending';

import Menu from '../../pages/Renewal20221005/Menu';

import GlobalLiveStreaming from '../../pages/Buy/GlobalLiveStreaming';


const App = () => {
  useLayoutEffect(()=>{
    // 아스터페이먼트 로드
    const url = `${process.env.REACT_APP_ASTERPAY_URL}/js/v0.00.000.0000?v=20211215_1227`;
    const script = document.createElement('script');
    script.setAttribute('src', url);
    script.onload = function() {
      console.warn(`Asterpayment Script Load Success > ${url}`);
    }
    document.getElementsByTagName('head')[0].appendChild(script);
  }, []);

  return (
    <div className="App">
      {/* 헤더 고정 */}
      {
        process.env.REACT_APP_MODE === 'development.local'
        ?
          <div>
          </div>
        :
          <Header/>
      }

      {/* 이동할 페이지 컴포넌트 */}
          <Routes>
            {/* common */}
            <Route exact path="/payment/failed" element={<PaymentFailed/>}/>{/* Wizzpay 결제 실패 시 보여지는 화면. 이 화면에서 사용자의 Wizzpay 결제 자체는 성공했으나 메타렉스 내부에 문제가 발생하여 메타렉스의 결제는 실패했을 수 있다. 따라서 관리자는 결제 실패건을 조회 후 환불을 처리해줘야 한다.*/}
            {/* main */}
            <Route exact path="/" element={<Main/>}/>
            {/* metarex */}
            <Route path="/about" element={<About/>} />
            <Route path="/future" element={<Future/>} />
            <Route path="/profile" element={<Profile/>} />
            <Route path="/organization" element={<OrganizationChart/>} />
            {/* buy */}
            <Route path="/map" element={<Map/>} />
            <Route path="/buyHistory" element={<BuyHistory/>} />
            {process.env.REACT_APP_MODE === 'development' && <Route path="/mx-lending" element={<Event20221224MxLending/>} />}
            <Route path="/global-live-streaming" element={<GlobalLiveStreaming/>} />

            {/* sales */}
            <Route path="/marketView" element={<MarketView/>} />
            <Route path="/salesRegistRation" element={<SalesRegistRation/>} />
            <Route path="/saleHistory" element={<SaleHistory/>} />
            {/* self purchase */}
            <Route path="/sbr" element={<SelfMain/>} />
            {/* 소유자가 제시된 가격을 보고 판매할지 말지 선택하는 화면 */}
            <Route path="/owner/whether-to-allow-purchase-yes-or-no" element={<OwnerConfirmPurchaseRequisition/>} />
            {/* 소유자의 구매 승인 후, 구매희망자가 구매하는 화면 */}
            <Route path="/buyer/purchase" element={<PurchaseAfterConfirmingTheOwner/>} />
            {/* support */}
            <Route path="/notice" element={<Notice/>} />
            <Route path="/faq" element={<Faq/>} />
            <Route path="/how-to-use" element={<HowToUse/>} />
            <Route path="/videos" exact element={<Videos useParam={useParams} useMatch={useMatch}/>} />
            <Route path="/videos/:title" element={<Videos useParam={useParams} useMatch={useMatch}/>}/>
            <Route path="/contactus" element={<ContactUs/>} />
            {/* auth & myPage*/}
            <Route path="/signin" element={<SignIn/>} />
            <Route path="/signup" element={<SignUp/>} />
            <Route path="/findid" element={<FindId/>} />
            <Route path="/findpassword" element={<FindPassword/>} />
            <Route path="/mypage" element={<MyPage/>} />
            <Route path="/point" element={<Point/>} />
            {/* term of service */}
            <Route path="/terms-of-service" element={<TermsOfService/>} />
            {/* Events */}
            <Route path="/events/event20221224" element={<Event20221224/>} />
            {/* Socialmedia link */}
            <Route path="/Menu" element={<Menu/>} />

          </Routes>
      {/*
          페이지에 따라 Footer를 보여주지 않는 경우도 존재하므로, 푸터의 위치를 각 페이지의 컴포넌트로 이동합니다.
          앞으로 페이지 작업시에는 각 페이지의 최상단 컴포넌트에 Footer를 추가해주세요.
      */}
    </div>
  );
}

export default App;
