import React, { useLayoutEffect, useState } from "react";
import { Link } from "react-router-dom";
import { t } from "i18next";

import styled from "styled-components";
import Slide from "./Slide";
import SlideBtn from "./SlideBtn";
import MoreButton from "../../components/MoreButton";

import { isNull, mobile } from "../../apis/Validation";
import { axiosGet } from "../../apis/AxiosAPI";

const StyledThemeAreasTemplate = styled.section`
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 120px;
    .theme_nav {
        margin-top: 110px;
        margin-bottom: 52px;
        padding: 0px 20px;
        width: 1440px;
        height: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        h1 {
            font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
            font-size: 40px;
            font-style: normal;
            font-weight: 800;
            line-height: 40px;
            letter-spacing: 0em;
        }
    }
    @media only screen and (max-width: 768px) {
        margin-bottom: 100px;
        .theme_nav {
            width: 100%;
            max-width: 375px;
            .btns {
                display: flex;
            }
        }
    }
`;

// 추천 매물 메인 컴포넌트
const ThemeArea = () => {
    const NUM_PER_PAGE = mobile() ? 1 : 10;             // 모바일일 경우 페이지당 1개, PC의 경우는 페이지당 10개의 매물이 보여진다.
    const [pageNum, setPageNum] = useState(1);          // 현재 페이지
    const [themeList, setThemeList] = useState([]);     // 페이지 당 보여줄 매물의 목록
    const [totalCount, setTotalCount] = useState(0);    // 추천 매물의 총 개수

    const [asterPayments, setAsterPayments] = useState(null);   // 아스터페이 객체
    const CLIENT_KEY = 'test_ck_OEP59LybZ8Bdv6A1JxkV6GYo7pRe';

    useLayoutEffect(() => {
        theme매물목록요청(pageNum, NUM_PER_PAGE);

        if(isNull(asterPayments)){
            try {
                setTimeout(() => {
                    setAsterPayments(new window.AsterPayments(CLIENT_KEY));
                }, 500);
            } catch (error) {
                console.warn(`AsterPay Load failed... ... ${error}`);
            }
            return () => {};
        }
    }, [pageNum]);



    const theme매물목록요청 = async (pageNum, NUM_PER_PAGE) => {
        axiosGet(`realestate/theme/list?pageNum=${pageNum}&numPerPage=${NUM_PER_PAGE}`)
            .then(response => {
                if(JSON.parse(response.data.result)) {
                    setTotalCount(response.data.totalCount);
                    setThemeList(response.data.data);
                }
            })
            .catch(e => {
                alert(t('metarex_alert_server_problem'));
                console.error(`error recommend Area: ${e}`);
            });
    }

    return (
        <div>
            {process.env.REACT_APP_MODE === 'development'&&
                <StyledThemeAreasTemplate>
                    <div className="theme_nav">
                        <h1>{t('main_theme_items')}</h1>
                        <div className="btns">
                            <SlideBtn direction="prev" pageNum={pageNum} setPageNum={setPageNum} totalCount={totalCount} perPage={NUM_PER_PAGE}/>
                            <SlideBtn direction="next" pageNum={pageNum} setPageNum={setPageNum} totalCount={totalCount} perPage={NUM_PER_PAGE}/>
                        </div>
                    </div>
                    {
                        themeList.length !== 0
                            && <Slide asterPayments={asterPayments} dataList={themeList} pageNum={pageNum} setPageNum={setPageNum}/>
                    }
                    <Link to="/MarketView">
                        <MoreButton disabled={false}/>
                    </Link>
                </StyledThemeAreasTemplate>
            }
        </div>
    )
}

export default ThemeArea;
