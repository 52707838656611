import React, { useEffect, useState } from "react";
import styled from "styled-components";

const TemplateBlock = styled.div`
  width: 12 00px;
  height: 600px;

  position: relative;
  background: white;
  border:2px solid black;
  border-radius:16px
  box-shadow: 0 0 8px 0 rgba(0,0,0,0.04);

  margin: 0 auto;

  margin-top: 0px;
  margin-bottom:32px;
  display: flex;
  flex-direction:column;
`;

function Template({ children }) {
  return <TemplateBlock>{children}</TemplateBlock>;
}

export default Template;
