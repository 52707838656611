import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { t } from "i18next";
import styled from "styled-components";

import { moveTopOnPageAuto, openPage } from "../apis/CommonFunc";
import { isNull, isValidPassword} from "../apis/Validation";
import { 회원가입_요청하기, 회원가입_이메일_인증번호_요청하기, 회원가입_휴대폰_인증번호_요청하기 } from "../apis/AuthAPI";

import AuthTemplate from "../components/Auth/AuthTemplate";
import AuthFieldSet from "../components/Auth/AuthFieldSet";
import AuthButton from "../components/Auth/AuthButton";
import AuthInput from "../components/Auth/AuthInput";
import AuthInputAndBtn from "../components/Auth/AuthInputAndBtn";
import AuthCapsLock from "../components/Auth/AuthCapsLock";

const SignUpTermsOfUseDiv = styled.div`
    margin-bottom: 20px;
    div {
        display: flex;
        margin: 8px 0px;
        align-items: center;
    }
    .agree-checkbox-wrapper {
        justify-content: space-between;
        a {
            margin-left: 5px;
            text-decoration: underline;
            text-underline-position: under;
            color: #000;
            &:hover {
                transition: 0.5s;
                color: #3D85F0;
            }
        }
    }
    label {
        display: block;
        margin-bottom: 8px;
        width: auto;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
    }
    input[type=checkbox] {
        display: none;
    }
    input[type=checkbox] + label {
        width:20px; 
        height: 20px; 
        margin-right: 10px;
        background: #D9DDE2;
        cursor: pointer;
        border-radius: 50px;
        background-image: url('/images/sign_up_check.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 70%;
        display: flex;
        align-items: center;
    }

    input[type=checkbox]:checked + label {
        background-color: #3D85F0;
    }
`;

const SignUpSectionDiv = styled.div`
    margin-bottom: 20px;

    .warning-verify-email-filtering-as-spam {
        size: 12px;
        color: #ff2e3f;
    }
`;

const SignUp = () => {
    const [requestForm, setRequestForm] = useState({
                                                    userName: '',
                                                    userMobile: '',
                                                    verifyMobileCode: '',
                                                    userEmail: '',
                                                    verifyEmailCode: '',
                                                    userPassword: '',
                                                    userRepassword: '',
                                                    isAgreeCheckbox01Checked: false
                                                });
    const [isCapsLock, setIsCapsLock] = useState(false);

    useEffect(()=>{
        moveTopOnPageAuto();
    }, []);

    const onChangeSignUpForm = (e) => {
        if(e.target.name === 'isAgreeCheckbox01Checked') {
            setRequestForm({...requestForm, [e.target.name]: e.target.checked});
            return;
        }
        setRequestForm({...requestForm, [e.target.name]: e.target.value});
    }

    const onClickSignupRequestButton = (e) => {
        e.preventDefault();

        if(isNull(requestForm.userName) || requestForm.userName === "") {
            alert(t('sign_up_alert_name'));
            return false;
        }
        if(isNull(requestForm.userMobile) || requestForm.userMobile === "") {
            alert(t('sign_up_alert_phone'));
            return false;
        }
        if(isNull(requestForm.verifyMobileCode) || requestForm.verifyMobileCode === "") {
            alert(t('sign_up_alert_phone_veri'));
            return false;
        }
        if(isNull(requestForm.userEmail) || requestForm.userEmail === "") {
            alert(t('sign_up_alert_email'));
            return false;
        }
        if(isNull(requestForm.verifyEmailCode) || requestForm.verifyEmailCode === "") {
            alert(t('sign_up_alert_email_veri'));
            return false;
        }
        if(isNull(requestForm.userPassword) || requestForm.userPassword === "") {
            alert(t('sign_up_alert_pw'));
            return false;
        }
        if(!isValidPassword(requestForm.userPassword)) {
            alert(`${t('sign_up_alert_pw_veri_1')}\n\n${t('sign_up_alert_pw_veri_2')}`);
            return false;
        }
        if(isNull(requestForm.userRepassword) || requestForm.userRepassword === "") {
            alert(t('sign_up_alert_re_pw'));
            return false;
        }
        if(requestForm.userPassword !== requestForm.userRepassword) {
            alert(t('sign_up_alert_pw_not_correct'));
            return false;
        }
        if(!requestForm.isAgreeCheckbox01Checked) {
            alert(t('sign_up_alert_agree'));
            return false;
        }

        회원가입_요청하기(requestForm);
    }

    // start mobile validation
    const onClickVerifyMobile = () => {
        const userName = requestForm.userName;
        const userMobile =requestForm.userMobile;
        
        if(isNull(userName) || userName === "") {
            alert(t('sign_up_alert_name'));
            return false;
        }
        if(isNull(userMobile) || userMobile === "") {
            alert(t('sign_up_alert_phone'));
            return false;
        }

        회원가입_휴대폰_인증번호_요청하기(userName, userMobile);
    }

    // start email validation
    const onClickVerifyEmail = () => {
        const userName = requestForm.userName;
        const userMobile = requestForm.userMobile;
        const userEmail = requestForm.userEmail;

        if(isNull(userName) || userName === "") {
            alert(t('sign_up_alert_name'));
            return false;
        }
        if(isNull(userMobile) || userMobile === "") {
            alert(t('sign_up_alert_phone'));
            return false;
        }
        if( isNull(userEmail) || userEmail == "" ) {
            alert(t('sign_up_alert_email'));
            return false;
        }

        회원가입_이메일_인증번호_요청하기(userName, userMobile, userEmail);
    }

    const onKeyUpEvent = (e) => {
        setIsCapsLock(e.getModifierState("CapsLock"));
        e.target.value = e.target.value.replace(/[ㄱ-ㅎㅏ-ㅣ가-힣]/g, '');
    }

    return (
        <AuthTemplate type="sign_up">
            <AuthFieldSet title={t("sign_up_easy")} style={{margin: '150px 0px'}}>
                <SignUpSectionDiv>
                    <label className="signup-label" htmlFor="userName">{t("sign_up_name")}</label>
                    <AuthInput 
                        type="text" 
                        name="userName" 
                        id="userName"
                        placeholder={t("sign_up_placeholder_name")} 
                        onChange={onChangeSignUpForm}
                    />
                </SignUpSectionDiv>
                <SignUpSectionDiv>
                    <label className="signup-label" htmlFor="userMobile">{t("sign_up_placeholder_p_num")}</label>
                    <AuthInputAndBtn>
                        <AuthInput 
                            type="tel" 
                            name="userMobile" 
                            id="userMobile"
                            placeholder={t("sign_up_placeholder_p_num")} 
                            onChange={onChangeSignUpForm}
                        />
                        <AuthButton onClick={onClickVerifyMobile} auth={true}>{t("find_pw_verification_code")}</AuthButton>
                    </AuthInputAndBtn>
                    <AuthInput 
                        type="tel" 
                        name="verifyMobileCode" 
                        placeholder={t("sign_up_placeholder_p_veri")}
                        onChange={onChangeSignUpForm}
                    />
                </SignUpSectionDiv>
                <SignUpSectionDiv>
                    <label className="signup-label" htmlFor="userEmail">{t("sign_up_email")}</label>
                    <AuthInputAndBtn>
                        <AuthInput 
                            type="text" 
                            name="userEmail" 
                            id="userEmail"
                            placeholder={t("sign_up_placeholder_email")} 
                            onChange={onChangeSignUpForm}
                        />
                        <AuthButton onClick={onClickVerifyEmail} auth={true}>{t("find_pw_verification_code")}</AuthButton>
                    </AuthInputAndBtn>
                    <AuthInput
                        type="email" 
                        name="verifyEmailCode" 
                        placeholder={t("sign_up_placeholder_e_veri")}
                        onChange={onChangeSignUpForm}
                    />
                    <p className="warning-verify-email-filtering-as-spam">인증 이메일이 스팸으로 분류될 수 있습니다.</p>
                </SignUpSectionDiv>
                <SignUpSectionDiv>
                    <label className="signup-label" htmlFor="userPassword">{t("sign_up_password")}</label>
                    <AuthInput 
                        type="password" 
                        name="userPassword" 
                        id="userPassword"
                        placeholder={t("sign_up_placeholder_pw")} 
                        onKeyUp={onKeyUpEvent} 
                        onChange={onChangeSignUpForm}
                    />
                </SignUpSectionDiv>
                <SignUpSectionDiv>
                    <label className="signup-label" htmlFor="userRepassword">{t("sign_up_re_password")}</label>
                    <AuthInput 
                        type="password" 
                        name="userRepassword" 
                        id="userRepassword"
                        placeholder={t("sign_up_placeholder_re_pw")}
                        onKeyUp={onKeyUpEvent} 
                        onChange={onChangeSignUpForm} 
                    />
                </SignUpSectionDiv>
                <AuthCapsLock isCapsLock={isCapsLock}/>
                <SignUpTermsOfUseDiv>
                    <h3>{t("sign_up_agree_terms")}</h3>
                    <div className="agree-checkbox-wrapper">
                        <div>
                            <span>{t("sign_up_terms_of_use")} </span>
                            <Link to="/terms-of-service" onClick={openPage} rel="noopener noreferrer">{t("sign_up_read_toc")}</Link>
                        </div>
                        <div className="checkbox">
                            <input className="agree-checkbox" type="checkbox" id="-01" name="isAgreeCheckbox01Checked" onChange={onChangeSignUpForm}/>
                            <label htmlFor="-01" className="agree-checkbox"/>
                            <label htmlFor="-01" className="agree-text">{t("sign_up_check_agree_terms")}</label>
                        </div>
                    </div> 
                </SignUpTermsOfUseDiv>
                <AuthButton onClick={onClickSignupRequestButton}>{t("sign_up_sign_up")}</AuthButton>
            </AuthFieldSet>
        </AuthTemplate>
    )
}

export default SignUp;