import React, { useEffect, useState } from "react";
import styled from "styled-components";

const MainDiv = styled.div`
    @media only screen and (max-width: 768px) {
      width: 100vw;
    }
    img {
      @media only screen and (max-width: 768px) {
        width: 100%;
      } 
    }
`;

function User() {
  return (
    <MainDiv>
      <img src={`images/renewal20221005/process_${localStorage.getItem('locale')}.jpg`} alt="process"></img>
    </MainDiv>
  );
}

export default User;
