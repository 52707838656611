import React from "react";
import styled from "styled-components";

const StyledHistory = styled.div`
    width: 100%;
    color: #000;
    margin-top: 20px;
    p {
        font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: left;
        padding-bottom: 10px;
    }
    ul {
        padding-left: 3px;
        li {
            font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 28px;
            letter-spacing: 0em;
            text-align: left;
        }
    }

    @media only screen and (max-width: 768px) {
        margin-bottom: 10px;
        p {
            font-size: 15px;
        }
    }
`;

const History = ({history, etc}) => {
    return(
        <StyledHistory>
            {history.split("<br>").map((el, index) => <p key={index}>{el}</p>)}
            <ul>
                {etc.split("<br>").map((el, index) => <li key={index}>{el}</li>)}
            </ul>
        </StyledHistory>
    )
}

export default History;