import React, { useRef, useEffect, useState } from "react";
import { Chart, registerables } from 'chart.js';
import './MarketViewChart.css'
import { useTranslation } from "react-i18next";

const MarketViewChart = ({chartData}) => {    

    const {t} = useTranslation();

    const canvasDom = useRef(null);
    useEffect(() => {
            const ctx = canvasDom.current.getContext('2d');
            Chart.register(...registerables);

            new Chart(ctx, {
            type: "line",
            data: {
                // 라벨부분에 날짜나 시간 
                labels: ["", "", "", "", "" ],
                datasets: [
                    {
                        label: t("market_view_modal_trad_history"),
                        backgroundColor: '#47C2BA',
                        borderColor: '#47C2BA',
                        // 가격 변동 넣기
                        data: chartData,
                    },
                ],
            },
            options: {   
                scales: {
                    y: {
                        min : chartData && chartData[0]
                    }
                }
            }
        });
    }, [chartData]);


    return (
        <div className="chartWrapcontainer">
            <div className="chartContainer">
                {/* p태그로 가격추이부분을 가려준다. 클릭이벤트를 제거하기 위해 */}
                <p></p>
                <canvas ref={canvasDom}></canvas>
            </div>
        </div>
    );
}

export default MarketViewChart;