import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { t } from "i18next";

import { useDevice } from "../../hooks/useDevice";
import { useInput } from "../../hooks/useInput";

const MainDiv = styled.div`
    padding: 20px;
    .formWrapper {
        display: none;
    }
`;

const Wizzpay = ({args, setArgs}) => {

    const  { updateInput }  = useInput(setArgs);
    const deivce            = useDevice();
    
    useEffect(() => {
        console.log('20220607_1053 %o', args);
    }, [args]);

    return (
        <MainDiv>
            <div className="formWrapper">
                <form className="merForm" name="merForm">
                    <input id="GOODSNAME" placeholder="상품명" name="GOODSNAME" size="20" className="input" onChange={updateInput} value={args.GOODSNAME} />
                    <input id="AMT" placeholder="상품금액" name="AMT" size="20" className="input" onChange={updateInput} value={args.AMT} />
                    <input id="RESULTURL" placeholder="resultURL" name="RESULTURL" size="50" className="input" onChange={updateInput} value={args.RESULTURL} />
                    <input id="NOTIURL" placeholder="notiURL" name="NOTIURL" size="50" className="input" onChange={updateInput} value={args.NOTIURL} />
                    <input id="BYPASSVALUE" placeholder="bypassvalue" name="BYPASSVALUE" size="50" className="input" onChange={updateInput} value={args.BYPASSVALUE} />
                    <input id="BUYERNAME" placeholder="구매자명" name="BUYERNAME" className="input" size="20" onChange={updateInput} value={args.BUYERNAME} />
                </form>
            </div>
        </MainDiv>
    )
}

export default Wizzpay;