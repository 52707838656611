import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useDevice } from "../../hooks/useDevice";
import { useInput } from "../../hooks/useInput";
import { addComma } from "../../apis/CommonFunc";

const MainDiv = styled.div`

    padding: 0px 20px;

    & .complete-icon-wrapper {
        margin-top: 80px;
        text-align: center;
        & .complete-icon {
            width: 72px;
            height: 72px;
        }
        & .complete-description {
            margin-top: 16px;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            color: #D2465A;

        }
    }

    & .total-amount-div {
        margin-top: 40px;
        margin-bottom: 20px;
        padding: 26px 0px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 14px;
        border-top: 1px solid #DFE1E5;
        border-bottom: 1px solid #DFE1E5;
        & > span {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 14px;
            color: #595959;
        }
        & > .item-price {
            float: right;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 16px;
            text-align: right;
            color: #595959;

            &::before {
                content:'$ ';
            }
        }
    }
`;


const PaymentComplete = ({args, setArgs, setOnClickNextButtonPrevius}) => {


    const deivce = useDevice();

    useEffect(() => {
        setOnClickNextButtonPrevius (
            () => function() {
                return true;
            }
        );
    }, []);


    return (
        <MainDiv>
            <div className="complete-icon-wrapper">
                <img src={`${process.env.PUBLIC_URL}/images/icon/payment/complete.svg`} alt="complete icon" className="complete-icon"/>
                <p className="complete-description">The payment has been completed.</p>
            </div>
            <div className="total-amount-div">
                <span>
                    payment amount
                </span>
                <span className="item-price">
                    {
                        args &&
                        args.amount &&
                        addComma(args.amount)
                    }
                </span>
            </div>
        </MainDiv>
    );
}

export default PaymentComplete;
