import React from "react";
import styled from "styled-components";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";

const StyledAsterCoinTemplate = styled.div`
    width: 100vw;
    height: auto;
    padding: 34px 0px;
    background-color: #F3F4F5;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width:786px) {
        width: 100%;
        height: 100%;
    }
`;

const StyledAsterCoinInfoDiv = styled.div`
    width: 100%;
    height: 100%;
    max-width: 1440px;
    .container {
        height: 100%;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: space-evenly;
        .img_wrap {
            a {
                border: 1px solid #464648;
                color: #464648;
                display: inline-block;
                width: 191px;
                height: auto;
                padding: 12px 48px 14px 24px;
                border-radius: 50px;
                text-align: center;
                margin: 4px 10px 0px;
                @media screen and (max-width:786px) {
                    margin: 8px 0px 8px;
                }
                .wrap{
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    img {
                        width: 119px;
                        height: 22px;
                    }
                    .fa-chevron-right {
                        margin-left: 16px;
                        font-size: 20px;
                    }

                }
            }
            .hanbitco {
                border: 1px solid #E01E3C;
                color: #E01E3C;
            }

        }
        h2 {
            color: #000;
            font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
            font-size: 22px;
            font-style: normal;
            font-weight: 800;
            line-height: auto;
            letter-spacing: 0em;
            text-align: left;
            .footer-helper-title {
                text-align: left;
            }
            ol.footer-helper {
                padding: 14px;
                @media screen and (max-width:786px) {
                    padding: 19px;
                }
                li {
                    list-style: auto;
                    list-style-type: decimal;
                    padding: 3px 0px;
                    font-size: 18px;
                    font-weight: 500;
                    .footer-helper-caution {
                        display: block;
                        font-size: 17px;
                        color: #fe2356;
                    }
                }
            }
        }
    }

    @media screen and (max-width:786px) {
        max-width: 100%;
        .container {
            margin: 0px;
            height: auto;
            flex-direction: column-reverse;
            justify-content: none;
            padding: 68px 20px;
            .img_wrap {
                margin-left: 0px;
                display:flex;
                flex-direction: column;
            }
            h2 {
                margin-bottom: 17px;
                margin-right: 0px;
            }
        }
    }
`;



const PayWithAsterCoin = () => {
    return (
        <StyledAsterCoinTemplate>
            <StyledAsterCoinInfoDiv>
                <div className="container">
                    <div className="img_wrap">
                        <a href="https://www.mexc.com/exchange/ATC_USDT"
                            className="MEXC Global"
                            rel="noopener noreferrer"
                            target="_blank"
                            title="MEXC Global"
                            style={{border: "1px solid #32B489"}}
                        >
                            <div className="wrap">
                                <img src={`${process.env.PUBLIC_URL}/images/footer/mexc_logo.svg`} alt="MEXC Global"/>
                                <FontAwesomeIcon icon={faChevronRight} style={{color: "#32B489"}}/>
                            </div>
                        </a>
                        { false &&
                            <a href="https://hanbitco.com/exchange/ATC-BTC"
                                className="hanbitco"
                                rel="noopener noreferrer"
                                target="_blank"
                                title="hanbitco"
                            >
                                <div className="wrap">
                                    <img src={`${process.env.PUBLIC_URL}/images/footer/hanbitco_logo.svg`} alt="hanbitco"/>
                                    <FontAwesomeIcon icon={faChevronRight}/>
                                </div>
                            </a>
                        }
                        { false &&
                            <a href="https://www.bitglobal.com/en-us/spot/trade?q=ATC-USDT"
                                    className="bitglobal"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    title="bitglobal"
                                >
                                <div className="wrap">
                                    <img src={`${process.env.PUBLIC_URL}/images/footer/bitglobal_logo.svg`} alt="bitglobal"/>
                                    <FontAwesomeIcon icon={faChevronRight}/>
                                </div>
                            </a>
                        }
                    </div>
                    <h2 dangerouslySetInnerHTML={
                        {__html: t('footer_all_transaction', {interpolation: {escapeValue: false}})}
                    } />
                </div>
            </StyledAsterCoinInfoDiv>
        </StyledAsterCoinTemplate>
    )
}

export default PayWithAsterCoin;
