import { t } from "i18next";
import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { isNull } from "../../apis/Validation";

const StyledButton = styled.button`
    width: 320px;
    height: 48px;
    background-color: #3D85F0;
    border: none;
    color: #fff;
    cursor: pointer;
    font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: center;
`;

// 게시판의 목록으로 돌아가기 버튼 컴포넌트
const BoardButton = ({setOpenPage, setCurrentIndex, setDisabled, url}) => {
    const navigate = useNavigate();

    const onClickGoBack = () => {
        setOpenPage(false);
        if(!isNull(setCurrentIndex)) { setCurrentIndex({start: 0, end: 6}); }
        if(!isNull(setDisabled)) { setDisabled(false);}
        navigate(url);
    }

    return (
        <StyledButton onClick={onClickGoBack}>{t('metarex_common_board_list')}</StyledButton>
    )
}

export default BoardButton;
