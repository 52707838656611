import { t } from "i18next";
import { axiosGet, axiosPost } from "./AxiosAPI";

import { getPriceDollarKRW } from "./PriceDollarAPI";
import { isNull } from "./Validation";

import { addComma } from "./CommonFunc";


export const getPointAmount = async () => {
    return axiosGet(`api/mypage/point/point`);
}

export const buyByPoint = async (url, json, callbackDoneToPayment, callbackFailToPayment) => {
    axiosPost(url, json)
        .then(response => {
            if(JSON.parse(response.data.result)) {
                //alert(`Your purchase has been completed:\n${data.addressJibunEn}`);
                alert(`${response.data.message}`);
                if( callbackDoneToPayment != null && typeof callbackDoneToPayment !== 'undefined' ) {
                    callbackDoneToPayment();
                }
            } else {
                alert(`${response.data.message}`);
                if( callbackFailToPayment != null && typeof callbackFailToPayment !== 'undefined' ) {
                    callbackFailToPayment();
                }
            }
            {/* 새로고침을 할지 여부는 상황에 맞게 조건을 받아서 처리해야 함. 성공과 실패 두 callback을 등록해서 해당 callback 안에서 원하느대로 처리하는 것이 맞음. */}
            //location.reload();
        }).catch(e => {
            console.log(`error message: ${e}`);
        });
}

export const setPayment = async (asterPrice, setNeedPoint, setIsPaymentShow, data, setPaymentParams, callbackDoneToPayment, callbackFailToPayment) => {

    if(isNull(asterPrice)) {
        alert(t("market_view_priced_incorrectly"));
        return false;
    }

    let dollarKRW   = null;
    let pointAmount = null;
    {/* 다른 사용자가 구매 못하도록 찜한다. */}
    setPaymentParams(prev => prev && {
        ...prev,
        data
    });
    {/* 달러시세를가져온다. */}
    getPriceDollarKRW()
    .then(response => {
        if(JSON.parse(response.data.result)){
            dollarKRW = Number(response.data.data.price);
            {/* 현재보유 point로 100% 구매 가능한지 여부를 판단 한다. */}
            getPointAmount()
            .then(response => {
                if(JSON.parse(response.data.result)){
                    pointAmount = response.data.data;
                    const dollayAmount = Number(asterPrice/dollarKRW);
                    {/* 현재보유 point가 dollar로 환산한 수량보다 작다면*/}
                    if( pointAmount < dollayAmount ) {
                        {/*if(window.confirm("point가 부족합니다. point를 충전하시겠습니까?")){*/}
                        const needPoint = Math.ceil((dollayAmount-pointAmount)*100)/100;
                        const areYouSureChargePointConfirmString01 = `필요한 POINT: ${addComma(Math.ceil((dollayAmount*100)/100))}`;
                        const areYouSureChargePointConfirmString02 = Math.ceil(dollayAmount) == needPoint ? `` : `\n부족한 POINT: ${addComma(needPoint)}`;
                        const areYouSureChargePointConfirmString03 = `\n\n${addComma(needPoint)} ${t('not_enough_points_do_you_want_to_recharge_points')}`;
                        if(window.confirm(`${areYouSureChargePointConfirmString01}${areYouSureChargePointConfirmString02}${areYouSureChargePointConfirmString03}`)) {
                            setNeedPoint(needPoint);
                            setIsPaymentShow(true);
                        } else {
                            alert(`${t('your_purchase_has_been_cancelled')}`);
                        }
                        return false;
                    }

                    let wouldYouLikeToPurchase860529Adress01860529860529Adress02860529 = t('would_you_like_to_purchase_860529_adress_860529');
                    if (Array.isArray(data)) {
                        wouldYouLikeToPurchase860529Adress01860529860529Adress02860529 = t('would_you_like_to_purchase_860529_adress_01_860529_860529_adress_02_860529');
                    }

                    {/* 구매할 수 있는 point가 충분하므로 바로 구매하도록한다.*/}
                    if(window.confirm( eval('`'+ wouldYouLikeToPurchase860529Adress01860529860529Adress02860529 +'`') )) {
                        const buyByPointParams = data;
                        buyByPoint("api/buy/buy-by-point", buyByPointParams, callbackDoneToPayment, callbackFailToPayment);
                    } else {
                        alert(`${t('your_purchase_has_been_cancelled')}`);
                    }
                } else {
                    alert(response.data.message);
                    if( response && response.data && response.data.herf && response.data.href ) {
                        window.location.href = response.data.href;
                    }
                }
            }).catch(e => {
                console.log(`getPointAmount error message: ${e}`);
            });
        } else {
            alert(response.data.message);
        }

    }).catch(e => {
        console.log(`getPriceDollarKRW error message: ${e}`);
    });
}
