function debounce(event, time) {
    let lastTimeout = null;
    
    return (...args) => {
        if(lastTimeout){
            clearTimeout(lastTimeout);
            lastTimeout = null;
        }
        lastTimeout = setTimeout(() => {
            event(...args);
            lastTimeout = null;
        }, time);
    }
}

export default debounce;