import axios from "axios";

// same-site 관련_cors > 각 axios 함수 내에 추가, 혹은 아래와 같이 공통으로 추가
// axios.defaults.withCredentials = true;   // 공통 추가

export const axiosPost = async (UrlParam, json) => {
    const url = `${process.env.REACT_APP_API_METAREX}/${UrlParam}`;
    const instance = axios.create({
        withCredentials: true
    })
    return await instance.post(url, json);
}

export const axiosGet = async (UrlParam) => {
    const url = `${process.env.REACT_APP_API_METAREX}/${UrlParam}`;
    return await axios.get(url, {withCredentials : true});
}