import React, { useState, useEffect} from 'react';
import { t } from 'i18next';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";

import { addComma, moveTopOnPageAuto } from '../../apis/CommonFunc';
import { isSessionValidate, isValidationSessionId } from '../../apis/BrowserStorageAPI';

import './SaleHistoryMain.css';

const SaleHistoryInfo = ({ data }) => {
    const saleHistorylanguage = localStorage.getItem("locale");

    return (
        <div className="saleHistoryInfoContainer">
            <ul>
                <li>
                    {
                        saleHistorylanguage === "ko" 
                            ? data.address
                            : data.addressJibunEn
                    }
                </li>
                <li>
                    {
                        data.areaSter ?  
                            <>
                                <span>{t("sales_registration_area")}</span>
                                <span> {addComma(data.areaSter)} ster</span>
                            </>
                            :
                            <span className="salesHistoryAreaNone">{t("sales_registration_area")}</span>
                    }
                </li>
                <li>
                    <span>{t("sale_history_purchase_price")}</span>
                    <span> : {addComma(data.priceMetarex)}  {t("map_land_price_area_won")}</span>
                </li>
                <li>
                    <span>{t("sale_history_exchange")}</span>
                    {
                        saleHistorylanguage === 'ko' 
                        ? <span> : {data.exchangeNameKr}</span>
                        : <span> : {data.exchangeNameEn}</span>
                    }
                </li>
                <li>
                    <span>{t("sale_history_wallet_address")} :</span>
                    <span>  {data.depositWalletAddr}</span>
                </li>
                <li>
                    <span>{t("sale_history_hope_price")}</span>
                    <span>  {addComma(data.priceMetarexAsk)}  {t("map_land_price_area_won")}</span>
                </li>
            </ul>
        </div>
    );
}

const SaleHistoryMain = (props) => {
    const saleHistoryData = props.saleHistoryDatas && props.saleHistoryDatas
    const forSaleWidth = props.forSaleWidth

    // click 
    const saleClickCount = props.saleClickCount
    const setSaleClickCount = props.setSaleClickCount
    const saleClickMCount = props.saleClickMCount
    const setSaleClickMCount = props.setSaleClickMCount

    // total
    const saleTotalCount = props.saleTotalCount

    const salePage = props.salePage
    const setSalePage = props.setSalePage
    const saleMPage = props.saleMPage
    const setSaleMPage = props.setSaleMPage

    /** 첫 로딩시 */
    useEffect(() => {
        if(!isSessionValidate()) {
            alert(t('market_view_login'));
            window.location.href ='/signin';
            return;
        }

        isValidationSessionId()
            .then(() => {
                if(!isSessionValidate()) {
                    window.location.href ='/signin';
                    return;
                }
            });

        moveTopOnPageAuto();
    }, []);

    const [saleLeftLast , setSaleLeftLast ] = useState(false)
    const [saleRightLast , setSaleRightLast ] = useState(false)

    
    // slide 왼쪽
    useEffect(()=>{
        if(saleClickMCount === 1 && forSaleWidth === true){
            setSaleLeftLast(true)
        }else if(saleClickCount === 1 && forSaleWidth === false){
            setSaleLeftLast(true)

        }else if(saleClickMCount > 1 && forSaleWidth === true){
            setSaleLeftLast(false)
        }else if(saleClickCount > 1 && forSaleWidth === false){
            setSaleLeftLast(false)
        }
    },[saleClickMCount  , setSaleLeftLast , saleClickCount , forSaleWidth])


     // slide 오른쪽 마지막 슬라이드 버튼 disable
    useEffect(()=>{
        // pc
        if(Math.ceil(saleTotalCount / 3) > saleClickCount && forSaleWidth === false ){
            setSaleRightLast(false)
        }else if((Math.ceil(saleTotalCount / 3) <= saleClickCount) && forSaleWidth === false){
            setSaleRightLast(true)
            // 모바일
        }else if((saleTotalCount > saleClickMCount) && forSaleWidth === true){
            setSaleRightLast(false)
        }else if((saleTotalCount === saleClickMCount) && forSaleWidth === true){
            setSaleRightLast(true)
        }
    },[saleClickCount  , setSaleRightLast , saleTotalCount , saleClickMCount , forSaleWidth])

    // slide left
    let saleHistoryPrevSlide = (e) => {
        if(saleClickMCount !== 1 && forSaleWidth === true){
            setSaleMPage(saleMPage - 1)
            setSaleClickMCount(saleClickMCount -1)
        }else if(saleClickCount !== 1 && forSaleWidth === false){
            setSalePage(salePage - 1)
            setSaleClickCount(saleClickCount - 1)
        }

        if(saleClickCount === 1){
            return false;
        }
        setSalePage(salePage - 1)
        setSaleClickCount(saleClickCount -1)
    }
    
    // slide right
    let saleHistoryNextSlide = (e) => {
        if(Math.ceil(saleTotalCount / 3) > saleClickCount && forSaleWidth === false){
            setSalePage(salePage + 1)
            setSaleClickCount(saleClickCount + 1)
        }else if(saleTotalCount > saleClickMCount && forSaleWidth === true){
            setSaleMPage(saleMPage + 1)
            setSaleClickMCount(saleClickMCount + 1)
        }
    }

    return (
        <section className="saleHistoryContainer">
            <div className="saleHistoryWarp">
                <div className="saleHistoryWarpContainer" >
                    {
                        saleHistoryData && saleHistoryData.map( (data , i) => (
                        <SaleHistoryInfo data={data} key={i} />
                    ))}
                </div>
                
                {  
                    (saleTotalCount === 0) && 
                        <div className="saleHistoryDataNoneTitleContainer">
                            <h1 className="saleHistoryDataNoneTitle">{t("sale_history_null")}</h1>
                        </div>
                }
            </div>
            
            <div className='saleBtnTitleContainer'> 
                <div className="saleHistoryBtnContainer">
                    {
                        (saleLeftLast === true) ?
                        <button type="submit" className="saleHistoryLeftNo" disabled>
                            <FontAwesomeIcon icon={faChevronLeft}/>
                        </button>
                        :
                        <button type="submit" className="saleHistoryLeft" onClick={saleHistoryPrevSlide}>
                            <FontAwesomeIcon icon={faChevronLeft}/>
                        </button>
                    }
                    {
                        (saleRightLast === true) ?
                        <button type="submit" className="saleHistoryRightNo" disabled>
                            <FontAwesomeIcon icon={faChevronRight}/>
                        </button>
                        :
                        <button type="submit" className="saleHistoryRight" onClick={saleHistoryNextSlide}>
                            <FontAwesomeIcon icon={faChevronRight}/>
                        </button>
                    } 
                </div>
            </div>
        </section>

    )
}

export default SaleHistoryMain;
