import React from "react";
import { t } from "i18next";
import styled from "styled-components";

const StyledAstercoinTemplate = styled.div`
    background-color: #3D85F0;
    width: 100%;
    height: 700px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media only screen and (max-width: 768px) {
        height: auto;
    }
`;

const StyledAstercoinDiv = styled.div`
    width: 100%;
    height: 100%;
    max-width: 1440px;
    padding: 120px 100px;
    .aster {
        color: #fff;
        h2 {
            font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
            font-size: 40px;
            font-weight: 800;
            line-height: 60px;
            margin-bottom: 80px;
        }
        p {
            font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
            font-size: 24px;
            font-weight: 800;
            line-height: 36px;
        }
    }
    .icons {
        display: flex;
        .icon {
            width: 200px;
            height: auto;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-top: 120px;
            margin-right: 80px;
            img {
                width: 50px;
                height: 50px;
                margin-bottom: 40px;
            }
            p {
                color: #fff;
                font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
                font-size: 17px;
                font-style: normal;
                font-weight: 700;
                line-height: 24px;
                letter-spacing: 0em;
            }
        }
    }

    @media only screen and (max-width: 768px) {
        height: 100%;
        max-width: 100%;
        padding: 120px 20px;
        .icons {
            display: grid;
            grid-template-columns: repeat(2, 50%);
            margin-top: 20px;
            .icon {
                width: auto;
                align-items: flex-start;
                margin-top: 0px;
                margin-right: 0px;
                img {
                    margin-top: 60px;
                }
            }
        }
    }

`;

const Astercoin = () => {
    return (
    <StyledAstercoinTemplate>
        <StyledAstercoinDiv>
            <div className="aster">
                <h2>{t("metarex_future_aster_coin_h2")}</h2>
                <p>
                {t("metarex_future_aster_coin_p_1")}<br/>
                {t("metarex_future_aster_coin_p_2")}
                </p>
            </div>
            <div className="icons">
                <div className="icon">
                    <img src={`${process.env.PUBLIC_URL}/images/future/aster_coin/plant.svg`} alt="aster_coin_1"/>
                    <p>{t("metarex_future_aster_coin_p_3")}</p>
                </div>
                <div className="icon">
                    <img src={`${process.env.PUBLIC_URL}/images/future/aster_coin/sun.svg`} alt="aster_coin_2"/>
                    <p>{t("metarex_future_aster_coin_p_4")}</p>
                </div>
                <div className="icon">
                    <img src={`${process.env.PUBLIC_URL}/images/future/aster_coin/trees.svg`} alt="aster_coin_3"/>
                    <p>
                        {t("metarex_future_aster_coin_p_5")}<br/>
                        {t("metarex_future_aster_coin_p_6")}
                    </p>
                </div>
            </div>
        </StyledAstercoinDiv>
    </StyledAstercoinTemplate>
    );
}

export default Astercoin;