import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { t } from "i18next";


import WithdrawSelectExchange from "./WithdrawSelectExchange";
import WithdrawSelectPrice from "./WithdrawSelectPrice";
import WithdrawSelectResult from "./WithdrawSelectResult";


const ModalDiv = styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0px;
    top: 0px;
    background: rgba(0,0,0,0.7);
    padding: 0px;
    z-index:1000;
`;

const ContentDiv = styled.div`
    width: 320px;
    height: auto;
    _min-height: 568px;
    background: rgba(255,255,255);
    padding: 0px;
    margin: 0px auto;
    position: relative;
`;

const PrevBtnSpan = styled.span`
    position: absolute;
    top: 10px;
    left: 20px;
    width: 27px;
    height: 27px;
    cursor: pointer;
    &::before {
        content: ' ';
        position: absolute;
        width: 13px;
        height: 1px;
        top: calc(100% - 9px);
        left: 0px;
        background: #000;
        transform-origin: center;
        transition: .3s;
        transform: rotate(45deg);
    }
    &::after{
        content: ' ';
        position: absolute;
        width: 13px;
        height: 1px;
        background: #000;
        top: calc(0% + 9px);
        left: 0px;
        transform-origin: center;
        transition: .3s;
        transform: rotate(-45deg);
    }

    &:hover {
        &::before {
            transform: rotate(0.625turn);
        }
        &::after{
            transform: rotate(-0.625turn);
        }
    }

`;

const CloseBtnSpan = styled.span`
    position: absolute;
    top: 10px;
    right: 10px;
    width: 27px;
    height: 27px;
    cursor: pointer;
    &::before {
        content: ' ';
        position: absolute;
        width: 27px;
        height: 1px;
        top: calc(50% - 1px);
        background: #000;
        transform-origin: center;
        transition: .3s;
        transform: rotate(45deg);
    }
    &::after{
        content: ' ';
        position: absolute;
        width: 27px;
        height: 1px;
        background: #000;
        top: calc(50% - 1px);
        transform-origin: center;
        transition: .3s;
        transform: rotate(-45deg);
    }

    &:hover {
        &::before {
            transform: rotate(0deg);
        }
        &::after{
            transform: rotate(-0deg);
        }
    }

`;

const TitleDiv = styled.div`
    color: #444;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    text-align: center;
    padding: 20px 0px;
    border-bottom: 3px solid #DFE1E5;
`;

const NextButton = styled.button`
    margin: 0px 20px 20px 20px;
    background-color: #05141F;
    text-align: center;
    line-height: 50px;
    border-radius: 4px;
    border-style: solid;
    border-width: 0px;
    width: 280px;
    height: 50px;
    color: #fff;
    display: inline-block;
    font-family: 'Noto Sans KR';
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
`;

const WithdrawPoint = ({setIsShowWithdrawPoint, data}) => {

    const clientLocale = localStorage.getItem("locale");

    const enumStatusIndex =
    {
        WithdrawSelectExchange      : 0,
        WithdrawSelectPrice    : 1,
        WithdrawSelectResult    : 2,
        
    };

    const [args , setArgs] = useState({
        "exchange-no": "DEFAULT",
        "wallet-address" : "",
        "balance-amount": Number(data.pointBalance),
        "withdraw-amount": 0,
        "current-coin-price": 0,
        "data": data,
    });


    const closeModal = (e) => {
        setIsShowWithdrawPoint(false);        
    }
    
    useEffect(() => {
        
        return () => {
        }
    }, []);

    useEffect(() => {
        console.log('20220718 1010 %o', args);
        return () => {
        }
    }, [args]);
    
    const [currentStatus , setCurrentStatus] = useState(0);
    {/*이전 버튼을 누르기 전에 각 component에서 특정 logic을 구현하기 위해 onClickPreviusButtonPrevius가 존재한다. 해당 function은 true를 리턴해야 이전 화면으로 넘어간다.*/}
    const [onClickPreviusButtonPrevius , setOnClickPreviusButtonPrevius] = useState(() => function() {
        return true;
    });
    const onClickPreviusButton = () => {
        
        if( onClickPreviusButtonPrevius() ) {

            if( currentStatus > 0 ) {
                setCurrentStatus(prev => prev - 1);
            }

        }
    }

    const enumStatus =
    [
        {
            pageID                  : "enterPrice",
            title                   : "point_title",
            bottomButtonLabel       : "next",
        },
        {
            pageID                  : "selectMethod",
            title                   : "select_coin_price",
            bottomButtonLabel       : "determine_the_price",
        },
        {
            pageID                  : "enterCardInfo",
            title                   : "apply_for_withdrawal",
            bottomButtonLabel       : "apply_for_withdrawal",
        },
        {
            pageID                  : "complete",
            title                   : "Complete",
            bottomButtonLabel       : "Done",
        }
    ];


    {/*하단 다음 버튼을 누르기 전에 유효성 체크를 위해 각 component에서 재 정의할 수 있도록 지원한다. 해당 function은 true를 리턴해야 다음 화면으로 넘어간다.*/}
    const [onClickNextButtonPrevius , setOnClickNextButtonPrevius] = useState(() => function() {
        return true;
    });

    const onClickNextButton = () => {

        if( onClickNextButtonPrevius() ) {

            if( currentStatus < Object.keys(enumStatusIndex).length-1 ) {
                setCurrentStatus(prev => prev + 1);
            } else {
                setIsShowWithdrawPoint(false);
            }
        }        
    }

    return (
        <ModalDiv>
            <ContentDiv>
                {currentStatus !== 0 &&
                    <PrevBtnSpan onClick={onClickPreviusButton}/>
                }
                <CloseBtnSpan onClick={closeModal}/>
                <TitleDiv>
                    {t(enumStatus[currentStatus].title)}
                </TitleDiv>
                {currentStatus === enumStatusIndex.WithdrawSelectExchange &&
                    <WithdrawSelectExchange
                    args                            = {args}
                    setArgs                         = {setArgs}
                    setOnClickPreviusButtonPrevius  = {setOnClickPreviusButtonPrevius}
                    setOnClickNextButtonPrevius     = {setOnClickNextButtonPrevius}
                    >
                    </WithdrawSelectExchange>
                }
                {currentStatus === enumStatusIndex.WithdrawSelectPrice &&
                    <WithdrawSelectPrice
                    args                            = {args}
                    setArgs                         = {setArgs}
                    setOnClickPreviusButtonPrevius  = {setOnClickPreviusButtonPrevius}
                    setOnClickNextButtonPrevius     = {setOnClickNextButtonPrevius}
                    >
                    </WithdrawSelectPrice>
                }
                {currentStatus === enumStatusIndex.WithdrawSelectResult &&
                    <WithdrawSelectResult
                    args                            = {args}
                    setArgs                         = {setArgs}
                    setOnClickPreviusButtonPrevius  = {setOnClickPreviusButtonPrevius}
                    setOnClickNextButtonPrevius     = {setOnClickNextButtonPrevius}
                    >
                    </WithdrawSelectResult>
                }
                <NextButton onClick={ () => onClickNextButton()} className="button-set-point-withdraw">
                    {t(enumStatus[currentStatus].bottomButtonLabel)}
                </NextButton>
            </ContentDiv>
        </ModalDiv>
    )
}

export default WithdrawPoint;