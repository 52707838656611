import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { isSessionValidate } from '../../apis/BrowserStorageAPI';
import { isNull } from '../../apis/Validation';
import { useSelector } from 'react-redux';
import { addComma } from '../../apis/CommonFunc';


import { AsterpayAPI } from '../../apis/AsterpayAPI';
import { setPayment } from '../../apis/PointAPI';

const MainDiv = styled.div`
    .selfInfoContainer{
        width: 100%; 
        height: 100%; 
        padding-top: 50px;
    }
    .selfInfoContainer .selfInfoContainerWrap{
        overflow: hidden;
    }

    /* 데이터 들어가는 div */

    .selfInfoContainer .selfInfoContainerWrap .selfBoxContainer{
        width: 310px;
        height: 355px;
        border: 1px solid #ccc; 
        margin: 0 auto;

        box-sizing: border-box;
        padding: 44px;
        position: relative;
    }

    .selfInfoContainer .selfInfoContainerWrap .selfBoxContainerNone{
        width: 310px;
        height: 355px;
        border: 1px solid #ccc; 
        margin: 0 auto;

        box-sizing: border-box;
        padding: 44px;
        position: relative;
        opacity: 0;
    }
    .selfInfoContainer .selfInfoContainerWrap .selfBoxContainer .buyHistoryDay{
        font-family:  'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
        font-style: normal;
        font-weight: 800;
        font-size: 14px;
        line-height: 14px;
        letter-spacing: -0.02em;
        color: #05141F;
        padding-bottom: 16px;
        border-bottom: 1px solid #05141F;
    }

    .selfBoxContainer > ul >li:nth-child(1) > a{
        text-decoration: none;
        color: #05141F;
    }

    .selfInfoContainer .selfInfoContainerWrap  .selfBoxContainer ul li:nth-child(1){
        font-family:  'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
        font-style: normal;
        font-weight: 800;
        font-size: 14px;
        line-height: 20px;
        color: #05141F;
        margin-top: 18px;
        margin-bottom: 28px;
    }

    .selfInfoContainer .selfInfoContainerWrap  .selfBoxContainer ul li:nth-child(2){
        font-family:  'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 14px;
        letter-spacing: -0.02em;
        color: #909090;
        margin-bottom: 38px;
    }
    .selfInfoContainer .selfInfoContainerWrap  .selfBoxContainer ul li:nth-child(2) > .areaSterOpacity{
        opacity: 0;
    }
    .selfInfoContainer .selfInfoContainerWrap .selfBoxContainer ul li:nth-child(3){
        color: #FF645A;
        font-size: 21px;
    }


    /* 버튼 */

    .selfInfoContainer .selfInfoContainerWrap  .selfBoxContainer .selfBtnContainer{
        position: absolute;
        bottom: 60px;
        width: calc(100% - 88px);
    }

    .selfQuestion{
        cursor: pointer;
        width: 109px;
        height: 44px;
        background: #fff; 
        border: 1px solid #D9DDE2;
        font-size: 14px;
        line-height: 12px;
    }

    .selfBuy{
        cursor: pointer;
        width: 109px;
        height: 44px;
        background-color:#3D85F0; 
        color: #fff;
        border: none;
        float: right;
    }
    .selfStopBtn{
        cursor: pointer;
        width: 109px;
        height: 44px;    
        color: #05141F;
        background-color: #D9DDE2;
        border: none;
        float: right;
    }


    /* jsonData없을때 구매내역없다. */
    .historyDataNoneTitle{
        font-size: 30px;
        position: absolute;
        top: 30%;
        left: 50%;
        transform: translateX(-50%);
    }

`;
const PurchaseAfterConfirmingTheOwnerItem = (props) => {
    const {t} = useTranslation();
    window.onmessage = function(e) {
        // 아스터페이 닫기 버튼 눌렀을 경우
        if(e.data === 'onStopAsterPayment') {
            //selfOnStopAsterPayment();
            return;
        }
        if(e.data === 'close') {
            if( typeof window.closeMessageHandler != 'undefined' )  {
                window.closeMessageHandler();
            }
        } 
    };

    
    const selfInfoLang = localStorage.getItem("locale");
    const [data, setData] = useState(null);

    useEffect(()=>{        
        setData(props.sbrData);
        
        const type="P2P Real Estate - Buy by Asking the Owner";
        setData(prev => prev && {
            ...prev,
            type
        });
    },[props.sbrData]);

    
    
    
    let asterPayment = props.asterPayment
    const asterpay = new AsterpayAPI('realestate/requestItem');

    const [selfAddress , setSelfAddress ] = useState();
    useEffect(()=>{        
        if( isNull(data) == false ) {
            if(selfInfoLang !== "ko"){
                setSelfAddress(data.addressJibunEn) 
            } else{
                setSelfAddress(data.address)
            }
        }
    },[selfInfoLang , data]);
    
    const [startAsterPayments, setStartAsterPayment] = useState(false);
    const [selfJson, setSelfJson] = useState({});

    // asterPay
    window.onmessage = function(e) {
        asterpay.onMessage(e, selfJson, undefined, undefined, setStartAsterPayment);
    }

    const selfOnClickBuy = (e) => {
        if(!isSessionValidate()) {
            alert(t("market_view_login"));
            window.location.href ='/signin';
            return false;
        }
        const dataAmount = data.priceMetarexAsk;        
        if(isNull(dataAmount)) {
            alert(t("market_view_priced_incorrectly"));
            return false;
        }
        readyToPayment();
    }


    const readyToPayment = () => {

        const setNeedPoint      = props.setNeedPoint;
        const setIsPaymentShow  = props.setIsPaymentShow;
        const setPaymentParams  = props.setPaymentParams;
        
        let asterPrice;
        asterPrice = Number(data.priceMetarexAsk);

         
        setPayment(asterPrice, setNeedPoint, setIsPaymentShow, data, setPaymentParams, callbackDoneToPayment);
    }

    const [isItemShow, setIsItemShow] = useState(true);
    const callbackDoneToPayment = () => {
        setIsItemShow(false);
        window.close();
    }

    return(
        <MainDiv>
            {isItemShow &&
                <section className="selfInfoContainer">                
                {data ? 
                    <div className="selfInfoContainerWrap">
                        <div className="selfBoxContainer">
                            <ul>
                                <li>
                                    {selfInfoLang !== "ko" ? 
                                        <a>{data && data.addressJibunEn}</a>
                                        :
                                        <a>{data && data.address}</a>
                                    }
                                </li>
                                <li>
                                    <span>{t("market_view_area")}  </span>
                                    <span>{data && addComma(data.areaSter)}  </span>
                                    <span> ster </span>
                                </li>
                                <li>
                                    <span>{t("sales_registration_purchase_price")}</span>
                                    <span> {addComma(data && data.priceMetarexAsk)}</span>
                                    <span>  {t("map_land_price_area_won")}</span>
                                </li>
                                <div className="selfBtnContainer">
                                    <Link to={`/contactus?title=${selfAddress}&content=${selfAddress}`}>
                                        <button className="selfQuestion">{t('self_info_btn')}</button>
                                    </Link>
                                    {
                                        ( startAsterPayments === false ) &&
                                        <button className="selfBuy" onClick={selfOnClickBuy}>{t("market_view_buy_btn")}</button>
                                    }
                                    {/* {
                                        (data.paymentStartedTs === null && startAsterPayments === false ) ? 
                                        // ( startAsterPayments === false ) ? 
                                        <button className="selfBuy" onClick={selfOnClickBuy}>{t("market_view_buy_btn")}</button>
                                        :
                                        <button className="selfStopBtn" disabled={true}>{t("market_view_payment_btn")}</button>
                                    } */}
                                </div>
                            </ul>
                        </div>
                    </div>
                    :
                    <div className="selfInfoContainerWrap">
                        <div className="selfBoxContainerNone">
                        </div>
                    </div>
                }
                </section>
            }
        </MainDiv>
    )
}

export default PurchaseAfterConfirmingTheOwnerItem;
