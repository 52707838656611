import React from "react";
import styled from "styled-components";

const AuthInputForm = styled.input`
    width: 100%;
    height: 48px;
    font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
    font-size: 15px;
    padding: 10px;
    border: 1px solid #D9DDE2;
    margin: 5px 0px;
    &:focus {
        outline: 1px solid #05141F;
    }
`
const AuthInput = ({type, id, name, onChange, onKeyUp, placeholder, required, title}) => {
    return (
        <AuthInputForm 
            type={type}
            id={id}
            name={name}
            onChange={onChange} 
            onKeyUp={onKeyUp}
            placeholder={placeholder}
            required={required}
            title={title}
            maxLength="64"
        />
    );
}

export default AuthInput;