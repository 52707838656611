import React , { useEffect, useState } from 'react';
import SelfInfo from '../../feature/SelfPurchase/SelfInfo'
import './SelfMain.css'
import { useLocation } from 'react-router-dom';
import { isSessionValidate, isValidationSessionId } from '../../apis/BrowserStorageAPI';
import { moveTopOnPageAuto } from '../../apis/CommonFunc';
import { axiosGet } from '../../apis/AxiosAPI';
import { t } from 'i18next';

import { getPriceDollarKRW } from "../../apis/PriceDollarAPI";
import Payment from '../../components/Payment/Payment';

{/** /sbr */}
const SelfMain = (props) => {
    const location = useLocation();
    const [asterPayment, setAsterPayment] = useState(null);
    const CLIENT_KEY = 'test_ck_OEP59LybZ8Bdv6A1JxkV6GYo7pRe';

    const [selfAxios , setSelfAxios] = useState(undefined);
    {/** MX-Lending 데이터 */}
    const [mxLendingData , setMxLendingData] = useState(undefined);

    useEffect(() => {
        if(!isSessionValidate()) {
            alert(t('market_view_login'));
            window.location.href ='/signin';
            return;
        }

        if(!isValidationSessionId()) {
            //window.dispatch(removeUser());
            alert(t('metarex_alert_expired_session'));
            window.location.href = '/signin';
            return;
        }
        
        moveTopOnPageAuto();
        
        try {
            setTimeout(() => {
                setAsterPayment(new window.AsterPayments(CLIENT_KEY));
            }, 500);
        } catch (error) {
            console.warn(`AsterPay Load failed... ... ${error}`);
        }

        getPriceDollarKRW();
    }, []);

    useEffect(() => {
        let params = location.search;
        let selfUrl = (params) => `api/realestate/sbr${params}`;
        let res = axiosGet(selfUrl(params))
        .then(res =>{   
            if(res.data.result === "false"){
                alert(res.data.message);
            }else {
                console.log(res.data.data);
                setSelfAxios(res.data.data && res.data.data.realestateRequested ? res.data.data.realestateRequested : null);                
                setMxLendingData(res.data.data && res.data.data.mxLending ? res.data.data.mxLending : null);
            }
        })
        .catch(e => {
            console.log(`error message: ${e}`);
        });
        // 링크타고 들어올시 뒤로가기 
        window.history.pushState('',null, document.location.href);
    }, []);


    let selfAxiosData = [];
    selfAxiosData.push(selfAxios);


    // 충전 필요 point
    const [needPoint , setNeedPoint] = useState(0);
    {/*포인트 충전 시, 자동 결제 되도록 하기 위해, Payment에 넘겨줄 부가 정보를 담는 용도로 사용*/}
    const [paymentParams , setPaymentParams] = useState({});
    //포인트 결제 팝업 보여주기 여부. true = show
    const [isPaymentShow , setIsPaymentShow] = useState(false);

    return(
        <section className="selfMainContainer">
            <h1 className="selfMainTitle"> {t("purchase_title")}</h1>
            {  selfAxios && selfAxios.length === 0 && 
                <h1 className="historyDataNoneTitle">{t("buy_history_date_null")}</h1>
            }
            {selfAxiosData.map((data, i) => 
            (
                <SelfInfo 
                    key                 = {i} 
                    sbrData             = {data} 
                    asterPayment        = {asterPayment}
                    setIsPaymentShow    = {setIsPaymentShow}
                    setNeedPoint        = {setNeedPoint}
                    setPaymentParams    = {setPaymentParams}
                    mxLendingData       = {mxLendingData}
                />
            ))}

            {isPaymentShow &&
                <Payment 
                    setIsPaymentShow    = {setIsPaymentShow}
                    asterPayment        = {asterPayment}
                    needPoint           = {needPoint}
                    paymentParams       = {paymentParams}
                >
                </Payment>
            }
        </section>
    )
}

export default SelfMain;
